import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory, Route, Redirect } from 'react-router-dom';
import AppContext from './AppContext';

function PrivateRoute({ component: Component, path, ...props }) {
  const { currentUser } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let storedUser;
    if (!currentUser) {
      try {
        storedUser = JSON.parse(localStorage.getItem('zukoAppUser'));
      } catch (e) {/* Do nothing as we can assume there is no user object */}
    }
    if (!currentUser && !storedUser) history.replace('/login', {
      from: {
        path: location.pathname,
        search: location.search,
        hash: location.hash
      }
    });
  },[history, currentUser, location.pathname, location.search, location.hash]);


  useEffect(() => {
    const onBuildPage = (
      location.pathname === '/builder/forms' ||
        (location.pathname.replace(/\/$/, '').match(/[^/]*$/)?.[0] === 'build'));
    if (!onBuildPage) window.fcWidget?.show();
    if (onBuildPage) window.fcWidget?.hide();
  }, [location.pathname]);

  return (<>
    {currentUser && <>
      {(currentUser.organisations?.[0]?.signUpProduct === 'builder' && path === '/') ?
        <Redirect to={'/builder'} /> :
        <Route path={path}>
          <Component {...props}/>
        </Route>}
    </>}
  </>);
}

export default PrivateRoute;
