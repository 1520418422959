import { useEffect, useState, useRef, useContext } from 'react';
import moment from 'moment-timezone';
import api from './api';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import AppContext from './AppContext';
import { formatFiltersForSelected, getSession } from './utils';
import { acceptedGranularity } from './App';
import Forms from './forms';
import camelcaseKeys from 'camelcase-keys';
import { fieldSegmentComparisonMetrics } from './Page/SegmentComparisonField';
import { useFieldSegmentComparisons } from './hooks/field_segment_comparisons';

// Can be used to set an app-wide message to appear on all pages.
export function useAppAlerts() {
  const [alerts, setAlerts] = useState([
    // Example alert:
    // {variant: 'warning|danger|info', message: 'Example <a href="/login">click here</a> message', dismissible: true|false},
  ]);

  return [alerts, (newAlerts) => setAlerts(newAlerts)];
}

// Specific alerts to check for the Org's session limit, displayed on most pages which concern a single Org.
export function useOrgAppAlerts(currentOrg) {
  const [alerts, setAlerts] = useState([]);

  // Compile session limit alerts for the currentOrg
  useEffect(() => {
    if (currentOrg?.uuid) {
      (async () => {
        setAlerts([]);
        try {
          const sessionLimitAlerts = [];
          const orgUuid = currentOrg.uuid;
          let sessionLimit = currentOrg.sessionLimit;
          let contractType = currentOrg.contractType;

          if (!sessionLimit) { // Whilst sessionLimit is missing from some org responses we need to request it
            const { data: { organisation } } = await api.get(`/organisations/${orgUuid}`);
            sessionLimit = organisation.sessionLimit;
          }

          const sessionStatsParams = { organisationUuid: orgUuid, metric: 'count' };
          if (contractType === 'monthly') sessionStatsParams.timePeriod = {
            start: moment().utc().startOf('month').format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
          };

          const { data: { count: sessionCount } } = await api.get(`/data/sessions/stats`, { params: sessionStatsParams });

          if (sessionCount > 0 && sessionCount >= sessionLimit) {
            switch(contractType) {
            case 'monthly':
              const BillingComponent = () => {
                const [error, setError] = useState();

                const handleBillingLinkClick = async ({uuid}) => {
                  try {
                    const {data: {portalSession: {url: targetUrl}}} =
                      await api.post(`/organisations/${uuid}/customer_portal_sessions`); // TODO add a return_url to pass onto the stripe portal
                    window.location.href = targetUrl;
                  } catch (e) {
                    setError('Sorry something went wrong accessing Billing. Please reload the page.');
                  }
                };
                return <p className="alert-text m-0">We've tracked {sessionCount} sessions, so your monthly allowance has been used up. <span
                  className="clickable-text" onClick={() => handleBillingLinkClick({uuid: orgUuid})}>Update your plan</span> to increase
                  your monthly session limit.{error && <span className="error-text"> {error}</span>}</p>;
              };

              if (currentOrg.plan) {
                sessionLimitAlerts.push({variant: 'warning', Component: BillingComponent, dismissible: true});
              } else {
                sessionLimitAlerts.push({variant: 'warning', message: `We've tracked ${sessionCount} sessions, so your monthly allowance has been used up. `+
                `Tracking will start again on the 1st. Contact your account manager if you'd like to increase your monthly session limit.`, dismissible: true});
              }
              break;
            case 'trial':
              if (currentOrg.type !== 'AgencyClient') sessionLimitAlerts.push({variant: 'warning', message:
              `We've tracked ${sessionCount} sessions and your trial has now ended. ` +
              `Click <a href='/organisations/${orgUuid}/choose-plan'>here</a> and sign up to a plan to continue tracking sessions.`, dismissible: true});
              if (currentOrg.type === 'AgencyClient') sessionLimitAlerts.push({variant: 'warning', message:
              `We've tracked ${sessionCount} sessions and your trial has now ended. Please contact your Agency, or <a href="mailto:support@zuko.io">support@zuko.io</a>.`, dismissible: true});
              break;
            case 'fixed':
              sessionLimitAlerts.push({variant: 'warning', message: `We've tracked ${sessionCount} sessions and your project has now ended. Get in ` +
              `contact with your account manager if you'd like to us to track more.`, dismissible: true});
              break;
            default:
              sessionLimitAlerts.push({variant: 'warning', message: `We've tracked ${sessionCount} sessions. Get in ` +
              `contact with your account manager if you'd like to us to track more.`, dismissible: true});
            }
          }

          if (!sessionLimit && currentOrg.type === 'AgencyClient') {
            sessionLimitAlerts.push({variant: 'warning', message: 'This Client does not have a session limit - forms will not be tracking. Please manage session limts via the Agency page', dismissible: true});
          }
          if (sessionLimitAlerts.length) setAlerts(sessionLimitAlerts);
        } catch (e) { /* Do nothing else for now - simply don't display any session limit alerts */ }
      })();
    }
  }, [currentOrg?.uuid, currentOrg?.plan, currentOrg?.sessionLimit, currentOrg?.contractType, currentOrg?.type]);

  return [alerts, (newAlerts) => setAlerts(newAlerts)];
}

// Specific alert to check for the builder Org's submission limit
export function useBuilderOrgAppAlert(org) {
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (org?.builderSubmissionLimit && org.builderSubmissionLimit > 0) {
      (async () => {
        setAlert(null);
        try {
          const orgUuid = org.uuid;
          const submissionLimit = org.builderSubmissionLimit;

          const { data: { count: submissionCount = 0 } } = await api.get(`/builder/organisations/${org.uuid}/submissions/stats`, { params: {metric: 'count'} });

          const closeToLimit = submissionCount > 0 && (submissionCount >= (submissionLimit * 0.8));

          if (submissionCount === 0) {
            setAlert({variant: 'info', message: `No submissions received yet. Your monthly allowance is ${submissionLimit.toLocaleString()}.`});
          }

          if (submissionCount > 0 && !closeToLimit && submissionCount < submissionLimit) {
            setAlert({variant: 'info', message: `We've received a total of ${submissionCount.toLocaleString()} submissions this month across all of your forms. Your monthly allowance is ${submissionLimit.toLocaleString()}.`});
          }

          if (closeToLimit && submissionCount < submissionLimit) {
            setAlert({variant: 'warning', message: `We've received a total of ${submissionCount.toLocaleString()} submissions this month across all of your form. Your monthly allowance of ${submissionLimit.toLocaleString()} is nearly used up. ` +
            `<a href='/builder/organisations/${orgUuid}/choose-plan'>Upgrade</a> to access all of your submissions.`});
          }

          if (submissionCount >= submissionLimit) {
            const overLimitDelta = (submissionCount || 0) - (submissionLimit || 0);
            setAlert({
              variant: 'danger',
              message: `We've received a total of ${submissionCount.toLocaleString()} submissions. ` +
                `You can only view submissions within your allowance of ${submissionLimit.toLocaleString()}. ` +
                `<br/>Please <a href='/builder/organisations/${orgUuid}/choose-plan'>upgrade</a> to ensure you don't miss out submissions. ` +
                `<br/><a href="mailto: support@zuko.io">Get in touch</a> to get access to the <b>${overLimitDelta}</b> submissions that you've missed out on this month.`
            });
          }
        } catch (e) { /* Do nothing else for now - simply don't display any submission limit alerts */ }
      })();
    }
  }, [org?.uuid, org?.builderSubmissionLimit]);

  return [alert, (newAlert) => setAlert(newAlert)];
}

/**
 * Provides access to the previous state
 * @param {T} value
 * @returns {T | undefined}
 */
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useAppQuery = () => {
  const {
    currentUser,
    formsGroupedByOrg,
    formsGroupedByFormUuid,
    query,
    setQuery,
  } = useContext(AppContext);

  const location = useLocation();

  const [selectingIncomingQuery, setSelectingIncomingQuery] = useState(true);

  const prevLocationKey = usePrevious(location.key);
  const prevQuery = usePrevious(query);

  const {comparisons, comparisonsLoaded} = useFieldSegmentComparisons();

  // Select the query from params, or if no params then from the session
  useEffect(() => {
    if (currentUser && ((!prevLocationKey && selectingIncomingQuery) || (!prevLocationKey && location.key) || (prevLocationKey && (location.key !== prevLocationKey)))) {
      let newForm;
      let newTime;
      let newFilters;
      let newGranularity;
      let newSessionOutcomes;
      let newIncludeCustomEvents;
      let newFieldComparisonUuid;
      let newFieldComparisonMetric;
      let newSubmitFieldIdentifier;
      let newFlowFieldIdentifier;

      const path = location.pathname;

      const queryParams = camelcaseKeys(
        qs.parse(location.search, { ignoreQueryPrefix: true, arrayLimit: 100 }),
        {
          deep: true,
          stopPaths: ['filters'], // Keys in here shouldn't be touched
        }
      );
      const session = getSession();

      const expectedQueryByPath = {
        '/form_aggregate': ['form', 'time', 'filters', 'granularity', 'submitFieldIdentifier'],
        '/field_aggregate': ['form', 'time', 'filters', 'granularity'],
        '/field-flow': ['form', 'time', 'filters', 'sessionOutcomes', 'includeCustomEvents', 'flowFieldIdentifier'],
        '/funnel': ['form', 'time', 'filters'],
        '/session_explorer': ['form', 'time','filters', 'sessionOutcomes', 'interactedWithFieldIdentifiers',
          'abandonedFieldIdentifier', 'metrics', 'includeCustomEvents'],
        '/session-replay': ['form', 'time','filters', 'sessionOutcomes', 'interactedWithFieldIdentifiers',
          'abandonedFieldIdentifier', 'metrics'],
        '/form-segment-comparison': ['granularity'],  // NB. time is set at the page level when we know the selected comparison's timezone
        '/field-segment-comparison': ['time', 'comparisonUuid', 'metric'],
        '/live': ['form'],
        '/insights': ['form', 'time', 'submitFieldIdentifier', 'filters'],
      };

      const incomingForm = (queryParams?.form?.uuid || session?.form?.uuid);

      if (expectedQueryByPath[path]?.includes('form') && incomingForm) {
        // TODO: Only save the form uuid in the query  - so change logic to read other attributes from the Forms 'store'
        newForm = Forms.getByUuid(incomingForm) ||
          { uuid: incomingForm };
      }

      if (expectedQueryByPath[path]?.includes('time') && expectedQueryByPath[path]?.includes('form')) {
        // Only set the incoming time if we know the timeZone
        if (((queryParams?.ts?.start && queryParams?.ts?.end) || (session?.timeframe?.start && session?.timeframe?.end)) &&
        incomingForm && Forms.getByUuid(incomingForm)) {
          const { organisation: { timeZone }} = Forms.getByUuid(incomingForm);
          if (timeZone) {
            newTime = {
              start: moment.tz(queryParams?.ts?.start || session?.timeframe?.start, timeZone),
              end: moment.tz(queryParams?.ts?.end || session?.timeframe?.end, timeZone),
            };
          }
        }
      }

      if (expectedQueryByPath[path]?.includes('filters')) {
        if (location.search.length && queryParams?.filters && Object.keys(queryParams.filters).length) {
          newFilters = formatFiltersForSelected(queryParams.filters);
        } else {
          newFilters = []; // Allow filters to be unset by not being present
        }

        if (!location.search.length && session?.filters && Object.keys(session.filters).length) {
          newFilters = formatFiltersForSelected(session.filters);
        }
      }

      if (expectedQueryByPath[path]?.includes('granularity')) {
        if ((queryParams?.granularity || session?.granularity) && acceptedGranularity.includes(queryParams?.granularity || session?.granularity)) {
          newGranularity = queryParams?.granularity || session?.granularity;
        } else {
          newGranularity = 'day';
        }
      }

      if (expectedQueryByPath[path]?.includes('submitFieldIdentifier')) {
        if (queryParams?.hasOwnProperty('submitFieldIdentifier')) {
          newSubmitFieldIdentifier = queryParams?.submitFieldIdentifier;
        } else {
          newSubmitFieldIdentifier = session?.submitField;
        }
      }

      if (expectedQueryByPath[path]?.includes('sessionOutcomes')) {
        if (location.search.length && queryParams?.sessionOutcomes) {
          newSessionOutcomes = queryParams?.sessionOutcomes.filter(outcome => ['abandoned', 'completed'].includes(outcome))
            .map((outcome) => ({
              key: 'sessionOutcome',
              value: outcome,
              label: outcome.charAt(0).toUpperCase() + outcome.slice(1), // Capitalise
            }));
        } else {
          newSessionOutcomes = []; // Allow session outcomes to be unset by not being present
        }

        if (!location.search.length && session?.sessionOutcomes) {
          newSessionOutcomes = session?.sessionOutcomes.filter(outcome => ['abandoned', 'completed'].includes(outcome))
            .map((outcome) => ({
              key: 'sessionOutcome',
              value: outcome,
              label: outcome.charAt(0).toUpperCase() + outcome.slice(1), // Capitalise
            }));
        }
      }

      if (path === '/field-flow') {
        if (expectedQueryByPath[path]?.includes('includeCustomEvents')) {
          const { fieldFlow: { includeCustomEvents } = {} } = (queryParams || session);
          newIncludeCustomEvents = includeCustomEvents === 'true' || includeCustomEvents === true; // queryParams contains `'true'` and the session contains `true`.
        }

        if (expectedQueryByPath[path]?.includes('flowFieldIdentifier')) {
          if (queryParams?.fieldFlow?.hasOwnProperty('flowFieldIdentifier')) {
            newFlowFieldIdentifier = queryParams.fieldFlow.flowFieldIdentifier;
          } else {
            newFlowFieldIdentifier = session?.fieldFlow?.flowFieldIdentifier;
          }
        }
      }

      if (path === '/field-segment-comparison') {
        // Comparison
        if (expectedQueryByPath[path]?.includes('comparisonUuid')) {
          newFieldComparisonUuid = (queryParams?.fieldSegmentComparison?.comparisonUuid || // Always set what is in QS
            comparisons.getByUuid(session?.fieldSegmentComparison?.comparisonUuid)); // Only set the LS comparison if it is available
        }

        // Comparison's time
        if (expectedQueryByPath[path]?.includes('time')) {
          if (((queryParams?.ts?.start && queryParams?.ts?.end) || (session?.timeframe?.start && session?.timeframe?.end))) {
            const { org: { timeZone } = {} } = comparisons.getByUuid(newFieldComparisonUuid) || {};
            if (timeZone) {
              newTime = {
                start: moment.tz(queryParams?.ts?.start || session?.timeframe?.start, timeZone),
                end: moment.tz(queryParams?.ts?.end || session?.timeframe?.end, timeZone),
              };
            } else { // Unset time to read it again when the comparisons have loaded
              newTime = {};
            }
          }
        }

        // Metric
        if (expectedQueryByPath[path]?.includes('metric')) {
          const incomingFieldComparisonMetric = (queryParams?.fieldSegmentComparison?.metric || session?.fieldSegmentComparison?.metric);
          if (fieldSegmentComparisonMetrics) {
            newFieldComparisonMetric = incomingFieldComparisonMetric || fieldSegmentComparisonMetrics[0].value;
          }
        }
      }

      // TODO: Allow browser back to go to default selections

      const query = {
        ...newForm && {form: newForm},
        ...newTime && {time: newTime},
        ...newGranularity && {granularity: newGranularity},
        ...newFilters && {filters: newFilters},
        ...newSessionOutcomes && {sessionOutcomes: newSessionOutcomes},
        ...(newSubmitFieldIdentifier !== undefined) && {submitFieldIdentifier: newSubmitFieldIdentifier},
        ...path === '/field-flow' && {fieldFlow: {
          includeCustomEvents: newIncludeCustomEvents,
          ...(newFlowFieldIdentifier !== undefined) && {flowFieldIdentifier: newFlowFieldIdentifier},
        }},
        ...path === '/field-segment-comparison' && {
          fieldSegmentComparison: {
            ...newFieldComparisonUuid && {comparisonUuid: newFieldComparisonUuid},
            metric: newFieldComparisonMetric,
          }
        },
      };

      if (path === '/session_explorer') {
        query.sessionExplorer = {};

        if (queryParams?.hasOwnProperty('sessionExplorer')) { // Query params applied so set/unset as provided
          const { sessionExplorer } = queryParams;

          if (expectedQueryByPath[path]?.includes('includeCustomEvents')) {
            query.sessionExplorer.includeCustomEvents = sessionExplorer.includeCustomEvents?.toString() === 'true';
          }
        }
      }

      if (path === '/session-replay' || path === '/session_explorer') {
        query.sessionFilters = {};

        if (queryParams?.hasOwnProperty('sessionFilters')) { // Query params applied so set/unset as provided
          const { sessionFilters } = queryParams;

          if (expectedQueryByPath[path]?.includes('interactedWithFieldIdentifiers')) {
            query.sessionFilters.interactedWithFieldIdentifiers = sessionFilters.interactedWithFieldIdentifiers || null;
          }

          if (expectedQueryByPath[path]?.includes('abandonedFieldIdentifier')) {
            query.sessionFilters.abandonedFieldIdentifier = sessionFilters.abandonedFieldIdentifier || null;
          }

          if (expectedQueryByPath[path]?.includes('metrics')) {
            query.sessionFilters.metrics = sessionFilters.metrics || null;
          }
        }
      }

      setQuery(prevQuery => ({...prevQuery, ...query}));

      setSelectingIncomingQuery(false);
    }
  }, [currentUser,
    setQuery, prevQuery,
    prevLocationKey, location.key, location.search, location.pathname,
    selectingIncomingQuery,
    comparisons, comparisonsLoaded,
  ]);

  // Select a default form
  useEffect(() => {
    const { form: queryForm } = query || {};
    const orgs = formsGroupedByOrg && Object.values(formsGroupedByOrg);
    // TODO: Check what happens if first org doesn't have any forms
    const firstOrgForm = orgs?.length && orgs[0]?.forms.length && orgs[0].forms[0];
    if ((!queryForm || !queryForm?.uuid) && !selectingIncomingQuery && firstOrgForm &&
    location.pathname !== '/field-segment-comparison' // Stop setting a default form on a page that doesn't need it
    ) {
      setQuery({...query, form: firstOrgForm});
    }

  }, [query, formsGroupedByOrg, setQuery, selectingIncomingQuery, location.pathname]);

  // Add label to selected form - missing when page requested via search params
  useEffect(() => {
    const formUuids = formsGroupedByFormUuid && Object.keys(formsGroupedByFormUuid);
    const { form } = query || {};
    if (form?.uuid && !form.label && formUuids?.length && formsGroupedByFormUuid[form.uuid]) {
      setQuery((prevQuery) => ({...prevQuery, form: formsGroupedByFormUuid[form.uuid]}));
    }
  }, [query, setQuery, formsGroupedByFormUuid]);

  // The requested form is not recognised in existing forms list, so determine validity
  useEffect(() => {
    const formUuids = formsGroupedByFormUuid && Object.keys(formsGroupedByFormUuid);
    const { form } = query || {};
    if (form?.uuid && !form.hasOwnProperty('label') && formUuids?.length && !formsGroupedByFormUuid[form.uuid]) {
      // Attempt to fetch the form in case it has recently been added
      (async () => {
        let availableForm;
        try {
          const { data: { form: fetchedForm } } = await api.get(`/forms/${form.uuid}`);
          availableForm = fetchedForm;
          // Reset fetched forms so this new form will be added to the list
          Forms.reload();
        } catch (e) {
        } finally {
          // Still set the form with the uuid to allow the api to respond appropriately
          setQuery((prevQuery) => ({
            ...prevQuery,
            ...availableForm ? {form: availableForm} : {form: {uuid: form.uuid, label: '', organisation: {timeZone: 'UTC', uuid: null}}}
          }));
        }
      })();
    }
  }, [query, setQuery, formsGroupedByFormUuid]);

  // Set default time now we have the timeZone from the selected form
  useEffect(() => {
    if (query?.form?.organisation?.timeZone && query && !query.time) {
      let newTime;
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      const session = getSession();

      if ((queryParams?.ts?.start && queryParams?.ts?.end) || (session?.timeframe?.start && session?.timeframe?.end)) {
        newTime = {
          start: moment.tz(queryParams?.ts?.start || session?.timeframe?.start, query?.form?.organisation?.timeZone),
          end: moment.tz(queryParams?.ts?.end || session?.timeframe?.end, query?.form?.organisation?.timeZone)
        };
      } else {
        newTime =  {
          start: moment.tz(query?.form?.organisation?.timeZone).subtract(7, 'days').startOf('day'),
          end: moment.tz(query?.form?.organisation?.timeZone).endOf('day'),
        };
      }

      setQuery((prevQuery) => ({
        ...prevQuery, time: newTime})
      );
    }
  }, [query, setQuery, location.search]);

  // Select a default field segment comparison
  useEffect(() => {
    if (
      location.pathname === '/field-segment-comparison'
      && query?.fieldSegmentComparison && !query.fieldSegmentComparison.hasOwnProperty('comparisonUuid')
      && comparisonsLoaded
    ) {
      if (comparisonsLoaded) {
        let comparisonUuid;

        // Choose from the current app form
        if (query.form?.uuid) {
          comparisonUuid = Object.values(comparisons.getByFormUuid(query.form.uuid) || {})?.[0]?.uuid;
        }

        // No current app-wide form, so select default
        if (!comparisonUuid) {
          comparisonUuid = Object.values(comparisons.groupedByUuid() || {})?.[0]?.uuid;
        }

        if (comparisonUuid) setQuery(prev => ({...prev, fieldSegmentComparison: {...prev.fieldSegmentComparison, comparisonUuid}}));
      }
    }
  }, [
    location.pathname,
    query?.fieldSegmentComparison, query?.form?.uuid,
    comparisons, comparisonsLoaded,
    setQuery
  ]);

  // Set default time when we have the timeZone for the incoming comparisonUuid
  useEffect(() => {
    // We can only run this when we have the comparison's form's org's timezone. So are relying on that state update
    if (
      location.pathname === '/field-segment-comparison' &&
      query?.fieldSegmentComparison?.comparisonUuid && (!query.time || !query.time?.start) &&
      comparisonsLoaded && formsGroupedByOrg && Object.keys(formsGroupedByOrg).length > 0 // TODO: change to formsLoaded when in place
    ) {
      const { org: { timeZone } = {} } = comparisons.getByUuid(query?.fieldSegmentComparison?.comparisonUuid) || {};
      if (timeZone) {
        let newTime;
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        const session = getSession();

        if ((queryParams?.ts?.start && queryParams?.ts?.end) || (session?.timeframe?.start && session?.timeframe?.end)) {
          newTime = {
            start: moment.tz(queryParams?.ts?.start || session?.timeframe?.start, timeZone),
            end: moment.tz(queryParams?.ts?.end || session?.timeframe?.end, timeZone)
          };
        } else {
          newTime =  {
            start: moment.tz(timeZone).subtract(7, 'days').startOf('day'),
            end: moment.tz(timeZone).endOf('day'),
          };
        }

        setQuery((prev) => ({...prev, time: newTime}));
      }
    }
  }, [
    query?.time, query?.fieldSegmentComparison?.comparisonUuid,
    setQuery,
    location.search, location.pathname,
    formsGroupedByOrg,
    comparisons, comparisonsLoaded,
  ]);

  return {
    query,
    prevQuery,
  };
};

export const useAppForms = () => {
  const { formsGroupedByOrg, formsGroupedByFormUuid, formsLoading, formsLoadingError,
    setFormsLoading, setFormsLoadingError, setFormsGroupedByOrg, setFormsGroupedByFormUuid } = useContext(AppContext);

  // Fetch forms the first time a user logs in
  useEffect(() => {
    (async () => {
      try {
        setFormsLoading(true);
        setFormsLoadingError(null);
        setFormsGroupedByFormUuid(await Forms.groupByUuid());
        setFormsGroupedByOrg(await Forms.groupByOrgUuid());
      } catch (e) {
        setFormsLoadingError((e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      } finally {
        setFormsLoading(false);
      }
    })();
  }, [setFormsLoading, setFormsLoadingError, setFormsGroupedByOrg, setFormsGroupedByFormUuid]);

  return {formsLoading, formsLoadingError, formsGroupedByOrg, formsGroupedByFormUuid};
};
