import React, { useEffect, useState, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import commaNumber from 'comma-number';
import countries from 'i18n-iso-countries';
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const ChoosePlanCompanyDetailsForm = ({handleSubmit, customer, zukoSlug, zukoRef, product,
  selectedPlan, selectedPlanInterval, getCurrencySymbolFromCode, creatingCustomerError, handleCancelCreateCustomer}) => {
  const [name, setName] = useState();
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [countryCode, setCountryCode] = useState();
  const [saveAttempted, setSaveAttempted] = useState();
  const countryOptions = useMemo(() => Object.entries(countries.getNames('en')).map(([k, v], i) => ({ label: v, value: k })), []);

  const dummySelectElement = document.createElement('select');
  dummySelectElement.id = 'addressCountry';

  useEffect(() => {
    if (customer && Object.keys(customer).length) {
      setName(customer.name || '');
      setAddressLine1(customer.address?.line1 || '');
      setAddressLine2(customer.address?.line2 || '');
      setCity(customer.address?.city || '');
      setPostalCode(customer.address?.postalCode || '');
      setCountryCode(customer.address?.country);
    }

    zukoRef.current = window.Zuko?.trackForm({target:document.getElementById('details-form'), slug: zukoSlug})
      .setAttribute('product', product || 'analytics').trackEvent(window.Zuko?.FORM_VIEW_EVENT);
  },[customer, zukoSlug, zukoRef, product]);

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      if (!saveAttempted) setSaveAttempted(true);

      // The react-select fields aren't native 'required' form fields, so we need to exit if no content
      if (!countryCode) {
        zukoRef?.current?.trackEvent({type: 'Attempted submit but no country selected'});
        return;
      }

      handleSubmit({
        name,
        address: {
          line1: addressLine1,
          line2: addressLine2,
          city,
          postalCode,
          country: countryCode,
        }
      });
    }} id="details-form" className="mx-auto">
      <Row className="g-0 selected-plan-row">
        <Col className="continue-btn ps-0 pt-0 d-flex align-items-center">
          {Object.keys(selectedPlan).length > 0 &&
            <span className="selected-plan-pill h-100 py-2 px-3">
              {product === 'builder' ?
                <p className="mb-0"><strong>Selected Plan:</strong> Up to {commaNumber(selectedPlan.submissionLimit)} submissions per month | {getCurrencySymbolFromCode(selectedPlan.currency)}{commaNumber(selectedPlan.unitAmount / 100)} per {selectedPlanInterval}</p> :
                <>
                  <p className="mb-0"><strong>Selected Plan:</strong> Up to {commaNumber(selectedPlan.sessionCountLimit)} sessions per month | {getCurrencySymbolFromCode(selectedPlan.currency)}{commaNumber(selectedPlan.unitAmount / 100)} per {selectedPlanInterval}</p>
                  {selectedPlan.replayEnabled && <p className="mb-0 mt-1 text-center"><span className="session-replay-item">Session replay included</span></p>}
                </>}
            </span>}
          <Button variant="outline-secondary" className="back-btn me-0 py-2 px-3" onClick={handleCancelCreateCustomer} id="change-plan-btn">Change</Button>
        </Col>
      </Row>
      <FormGroup className="form-group" controlId="companyName">
        <Form.Label>Company Name</Form.Label>
        <Col className="input-col">
          <Form.Control type="text" required autoComplete="organization"
            value={name || ''} onChange={({target: {value}}) => setName(value)}/>
        </Col>
      </FormGroup>
      <FormGroup className="form-group" controlId="addressLine1">
        <Form.Label>Address Line 1</Form.Label>
        <Col className="input-col">
          <Form.Control type="text" required autoComplete="address-line1"
            value={addressLine1 || ''} onChange={({target: {value}}) => setAddressLine1(value)}/>
        </Col>
      </FormGroup>
      <FormGroup className="form-group" controlId="addressLine2">
        <Form.Label>Address Line 2 <small><i>Optional</i></small></Form.Label>
        <Col className="input-col">
          <Form.Control type="text" autoComplete="address-line2"
            value={addressLine2 || ''} onChange={({target: {value}}) => setAddressLine2(value)}/>
        </Col>
      </FormGroup>
      <FormGroup className="form-group" controlId="addressCity">
        <Form.Label>City / Town <small><i>Optional</i></small></Form.Label>
        <Col className="input-col">
          <Form.Control type="text" autoComplete="address-level2"
            value={city || ''} onChange={({target: {value}}) => setCity(value)}/>
        </Col>
      </FormGroup>
      <FormGroup className="form-group" controlId="addressPostalCode">
        <Form.Label>Postcode / Zip Code</Form.Label>
        <Col className="input-col">
          <Form.Control type="text" required autoComplete="postal-code"
            value={postalCode || ''} onChange={({target: {value}}) => setPostalCode(value)}/>
        </Col>
      </FormGroup>
      <FormGroup className="form-group">
        <Form.Label htmlFor="addressCountry">Country</Form.Label>
        <Col id="country-select" className="input-col">
          <Select
            inputId="addressCountry"
            autoComplete="country"
            styles={{
              option: (styles, state) => {
                return {...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null},
                  ...state.data.accountManager && { backgroundColor: '#f0eef9'},
                };
              },
              menu: (styles, state) => ({...styles,
                marginTop: '1px',
                borderRadius: '4px',
                boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
              }),
              control: (styles, state) => ({...styles,
                border:  (saveAttempted && !countryCode) ?  '1px solid #dc3545' : '1px solid #ced4da',
                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : (saveAttempted && !countryCode) ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)' : null,
              }),
              dropdownIndicator: (styles, state) => ({...styles,
                cursor: 'pointer',
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                transition: 'transform .5s ease',
              }),
            }}
            options={countryOptions}
            onChange={(e) => {
              setCountryCode(e.value);
              zukoRef?.current?.trackEvent({type: 'change', target: dummySelectElement});
            }}
            onMenuOpen={() => {
              zukoRef?.current?.trackEvent({type: 'click', target: dummySelectElement});
            }}
            placeholder="Select a country..."
            value={countryCode ? [{ label: countries.getName(countryCode, 'en', {select: "official"}), value: countryCode }] : null}
            onInputChange={(e) => {
              // Allow the countryCode to be set from a pre-populated autofill input
              if (!countryCode && e) {
                setCountryCode(countryOptions.find(o => o.label.toLowerCase() === e.toLowerCase())?.value);
                zukoRef?.current?.trackEvent({type: 'change', target: dummySelectElement});
              }
            }}
          />
          {(saveAttempted && !countryCode) && <div className="invalid-input-feedback">Country required to calculate tax.</div>}
        </Col>
      </FormGroup>
      <div className="form-actions">
        <Row className="g-0">
          <Col className="p-0 continue-btn col-auto d-flex align-items-center">
            <Button type="submit" id="continue-to-payment-btn">Continue to Payment</Button>
          </Col>
        </Row>
        {creatingCustomerError && <p className="text-danger mb-0">An error occurred. Please try again or <a href="mailto:support@zuko.io">contact support</a> for help.</p>}
      </div>
    </Form>);
};

export default ChoosePlanCompanyDetailsForm;