import { useContext, useRef, useState, FormEvent } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { VscTrash } from 'react-icons/vsc';
import EditInlineIcon from '../EditInlineIcon';
import { useShowEditIcon } from '../../hooks';

const Label = ({ id, label, stepIndex, questionIndex, itemIndex, type, options, }: {
  id: string,
  label: string,
  stepIndex: number,
  questionIndex: number,
  itemIndex: number,
  type: 'question-option',
  options: Array<string>,
}) => {
  const { inEdit, handleSaveInlineEditItem, handleRemoveMessages, setIdentifyItem } = useContext(SessionContext) as SessionContextType;
  const [inError, setInError] = useState<boolean | null>();
  const labelRef = useRef<HTMLLabelElement>(null);
  const [showEditIcon, setShowEditIcon] = useShowEditIcon({type: 'question', stepIndex, questionIndex});

  const maxChars = 255;

  return <>{inEdit ? <div className="d-inline-flex align-items-center">
    <span className="mark d-inline-flex align-items-center"><label
      // NB. we don't declare the htmlFor to avoid engaging the input whilst in edit mode
      ref={labelRef}
      className={`editable-inline ${inError ? 'in-error': ''}`}
      contentEditable={inEdit}
      suppressContentEditableWarning={true}
      spellCheck="false"
      onClick={() => setShowEditIcon(false)}
      onInput={(e: FormEvent) => {
        const target = e.target as HTMLElement;
        const innerText = target.innerText;
        if (labelRef.current && (innerText?.length === 0 || innerText === '\n')) {
          labelRef.current.innerHTML = '';
          labelRef.current.focus();
          setInError(true);
        } else {
          setInError(false);
        }
      }}
      onBlur={(e) => {
        if (!inEdit) return;
        const innerText = labelRef?.current?.innerText;
        if (innerText?.length === 0) {
          labelRef?.current?.focus();
          setInError(true);
        } else {
          setInError(false);
        }

        if (innerText && label !== innerText) {
          handleRemoveMessages();
          const textValidLength = innerText.slice(0, maxChars);
          labelRef.current.innerText = textValidLength;
          handleSaveInlineEditItem({type, item: textValidLength, stepIndex, questionIndex, itemIndex});
        }

        const clickTarget = e.relatedTarget as HTMLElement;
        if (clickTarget && [...clickTarget.classList].includes('configure-icon-container')) setIdentifyItem({stepIndex, type: 'question', questionIndex, showConfigure: true});
      }}
    >{label}</label>
    {showEditIcon && <EditInlineIcon setShowEditIcon={setShowEditIcon} itemRef={labelRef} />}</span>
    <div>
      {options.length > 1 &&
        <VscTrash size="17px" className="ms-3 delete-option"
          onClick={() => {
            const newOptions = [...options];
            newOptions.splice(itemIndex, 1);
            handleSaveInlineEditItem({type: 'question-options', item: newOptions, stepIndex, questionIndex});
          }} title="Delete option"/>}
    </div>
  </div> :
    <label htmlFor={id}>{label}</label>}</>;
};

export default Label;
