import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

import './MultiStepProgressBar.scss';

const MultiStepProgressBar = ({stepValues, currentStep, stepNames, onStepNumberClick}) => {
  const [stepPercentage, setStepPercentage] = useState(0);

  useEffect(() => {
    if (currentStep === 1) {
      setStepPercentage(0);
    } else if (currentStep === stepValues.length) {
      setStepPercentage(100);
    } else if (currentStep <= stepValues.length) {
      setStepPercentage((100/(stepValues.length - 1)) * stepValues.indexOf(currentStep));
    } else {
      setStepPercentage(0);
    }
  }, [currentStep, stepValues]);


  return (
    <div className="multi-step-progress-bar position-relative">
      <div className="progress-area d-flex flex-grow-1">
        <ProgressBar now={stepPercentage}/>
      </div>
      <div className="position-absolute d-flex w-100 justify-content-between step-numbers-row">
        {stepValues.map((stepValue, stepIndex) =>
          <div className={`step-number ${(stepIndex < currentStep) ? 'completed' : ''}`} key={`step-${stepValue}`} onClick={() => onStepNumberClick && onStepNumberClick(stepValue)}>
            {stepValue}
            {stepNames && <div className="step-name">{stepNames[stepIndex]}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiStepProgressBar;
