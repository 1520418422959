import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory, Route } from 'react-router-dom';
import AppContext from './AppContext';

function ProtectedRoute({ component: Component, path, ...props }) {
  const { currentUser } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let storedUser;
    if (!currentUser) {
      try {
        storedUser = JSON.parse(localStorage.getItem('zukoAppUser'));
      } catch (e) {/* Do nothing as we can assume there is no user object */}
    }

    if (!currentUser && !storedUser) history.replace('/login', { from: { path: location.pathname } });

    if (currentUser && !currentUser.accountManager) history.replace('/');
  },[history, currentUser, location.pathname]);

  return (<>
    {currentUser && currentUser.accountManager &&
      <Route path={path}>
        <Component {...props}/>
      </Route>}
  </>);
}

export default ProtectedRoute;
