import React from 'react';
import FormContext, { FormContextType } from './contexts/FormContext';
import Copy from './components/CopyWithLink';
import ItemToConfigureWrapper from './components/ItemToConfigureWrapper';
import SuccessCheck from './components/SuccessCheck';
import Title from './components/Title';
import './Submitted.scss';

const Submitted = () => {
  const { completion } = React.useContext(FormContext) as FormContextType;
  const title = completion?.title;
  const message = completion?.message;

  return <div className="completion">
    <SuccessCheck label={title || 'Form Submitted'} height={50} />
    {title && <ItemToConfigureWrapper
      type={'completion-title'}
      noContent={title ? false : true}>
      <Title text={title} type={'completion-title'} />
    </ItemToConfigureWrapper>}
    <ItemToConfigureWrapper
      type={'completion-message'}
      noContent={message ? false : true}>
      <Copy text={message || ''} type={'completion-message'} />
    </ItemToConfigureWrapper>
  </div>;
};

export default Submitted;
