import { useState, useEffect, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import ContentEditableInput from './ContentEditableInput';
import { decodeAndSantize } from '../utils';

const ConfigureEditableText = ({
  item,
  type,
  handleSaveConfigureItem,
  handleSaveInlineEditItem,
  stepIndex,
  questionIndex,
  handleRemoveMessages,
}) => {
  const [textItem, setTextItem] = useState();
  const [saveAttempted, setSaveAttempted] = useState();
  const [label, setLabel] = useState();
  const [attr, setAttr] = useState();

  const maxChars = 2000;

  const updateText = useCallback(({text}) => {
    setTextItem(prev => ({...prev, [attr]: decodeAndSantize(text)}));
  }, [attr]);

  const saveText = useCallback(({text}) => {
    handleSaveInlineEditItem({type, item: text, stepIndex, questionIndex});
  }, [type, handleSaveInlineEditItem, stepIndex, questionIndex]);

  useEffect(() => {
    if (type) {
      switch (type) {
      case 'form-description':
      case 'step-description':
        setLabel('Description');
        setAttr('description');
        break;
      case 'paragraph-text':
        setLabel('Paragraph');
        setAttr('title');
        break;
      case 'completion-message':
        setLabel('Message');
        setAttr('message');
        break;
      default:
        // Do nothing
      }
    }
  }, [type]);

  useEffect(() => {
    setTextItem({
      ...item,
      ...item[attr] && { [attr]: decodeAndSantize(item[attr]) },
    });
  }, [item, attr]);

  return (<div className="configure-form">
    <Form className="w-100" id="builder-field-config-form">
      <FormGroup className="form-group" controlId="text">
        <ContentEditableInput
          key={item[attr]} // Replaces component when item changes - so the link config will close
          label={label}
          maxChars={maxChars}
          handleRemoveMessages={handleRemoveMessages}
          innerHtml={textItem && attr && textItem?.[attr]}
          autoFocus={textItem && attr && (textItem[attr] ? false : true)}
          updateText={updateText}
          saveText={saveText} />
      </FormGroup>
      <Row className="form-actions-row">
        <Col className="p-0 text-center">
          <Button variant="primary" className="save-builder-form-btn ms-1 me-0 mt-2" onClick={(e) => {
            e.preventDefault();
            if (!saveAttempted) setSaveAttempted(true);
            if (textItem[attr]?.length > maxChars) return;

            handleSaveConfigureItem({ item: textItem });
            setSaveAttempted(false);
          }}>Save</Button>
        </Col>
      </Row>
    </Form>
  </div>
  );
};

export default ConfigureEditableText;
