import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
import ErrorBoundary from './Page/ErrorBoundary';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase() === 'production' &&
    !process.env.REACT_APP_MIXPANEL_TOKEN) throw new Error('REACT_APP_MIXPANEL_TOKEN is required');
mixpanel.init(
  process.env.REACT_APP_MIXPANEL_TOKEN || 'NOT_SET',  // Fall back to no token if we're not in prod.
  {
    ignore_dnt: false, // Ignore the web browser's Do Not Track setting
  }
);

/**
 * Initialize the Sentry SDK - without any integrations. Replay will only start when the integration is added.
 */
if (process.env.NODE_ENV === 'production') Sentry.init({
  dsn: "https://5f6acd619616d007b4ae57048d82e61f@o4505980480454656.ingest.sentry.io/4506615502798848",
  environment: process.env.NODE_ENV || 'development',
  ignoreErrors: [
    /Non-Error promise rejection captured with value: Timeout (u)/, // Google recaptch error
    /Non-Error promise rejection captured with value: Timeout/, // Google recaptch error
    /Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:simulateEvent, ParamCount:1/, // Outlook checking the links in weekly email
    /Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:update, ParamCount:4/, // Outlook checking the links in weekly email
  ],
  integrations: [],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary mixpanel={mixpanel}>
      <MixpanelProvider mixpanel={mixpanel}>
        <App />
      </MixpanelProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
