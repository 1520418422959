import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { MetricRuleType, MetricType, MetricOperatorType, MetricValueType } from '../../types/sessions.ts';
import {
  SelectOptionType, SelectFieldOptionType,
} from '../../types/types.ts';

import Button from 'react-bootstrap/Button';
import FormElement from 'react-bootstrap/Form';

import ListBox from '../../Components/Select/ListBox.tsx';
import { VscTrash } from "react-icons/vsc";
import { FaPencilAlt } from "react-icons/fa";
import {
  formatSecondsToTimeString,
} from '../../utils.js';

interface CreateMetricParams {
  availableTrackedFields: SelectFieldOptionType[]
  handleAddMetrics: ({metricRule}: {metricRule: MetricRuleType | null}) => void
  appliedMetric?: never
  index?: never
  handleRemoveMetric?: never
  handleEditMetric?: never
};

interface EditMetricParams {
  availableTrackedFields: SelectFieldOptionType[]
  handleAddMetrics?: never
  appliedMetric: MetricRuleType
  index: number
  handleRemoveMetric: (index: number) => void
  handleEditMetric: ({index, metricRule}: {index: number, metricRule: MetricRuleType | null}) => void
};

const MetricsRuleRow = ({
  appliedMetric,
  availableTrackedFields,
  handleAddMetrics,
  handleRemoveMetric,
  handleEditMetric,
  index,
}: CreateMetricParams | EditMetricParams) => {
  const [metricRule, setMetricRule] = useState<MetricRuleType | null>(null);
  const [valueInputIsInvalid, setValueInputIsInvalid] = useState(false);
  const [isEditingMetric, setIsEditingMetric] = useState(false);

  const metricTypeOptions: SelectOptionType[] = [{value: 'rtf_count', label: 'Returns'}, {value: 'duration', label: 'Time spent'}];
  const metricForDefaultOption: SelectOptionType[] = [{value: 'total-session', label: 'Total session'}];
  const metricForAllOptions: (SelectOptionType|SelectFieldOptionType)[] = metricForDefaultOption.concat(availableTrackedFields || []);
  const metricOperatorOptions: SelectOptionType[] = [{value: 'eq', label: 'equals'}, {value: 'gte', label: 'is greater than or equal to'}, {value: 'lte', label: 'is fewer than or equal to'}];

  const handleChangeMetricType = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target?.id) {
      const type = target.id as MetricType;
      setMetricRule(prev => ({...(prev || {}), type}));
    }
  }, []);

  const handleChangeMetricFor = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target?.id) {
      const field = target.id as string;
      setMetricRule(prev => ({...(prev || {}), for: field}));
    }
  }, []);

  const handleChangeMetricOperator = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target?.id) {
      const operator = target.id as MetricOperatorType;
      setMetricRule(prev => ({...(prev || {}), operator}));
    }
  }, []);

  const handleChangeMetricValue = useCallback((e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    if (target?.id) {
      setMetricRule(prev => ({...(prev || {}), value: target.value}));
      const numberValue = Number(target.value) as MetricValueType;
      setValueInputIsInvalid(!Number.isInteger(numberValue));
    }
  }, []);

  useEffect(() => {
    if (appliedMetric) {
      setMetricRule(appliedMetric);
    } else {
      setIsEditingMetric(true);
    }
  }, [appliedMetric]);

  return (
    <tr>
      <td>
        {(!isEditingMetric && metricRule) &&
          metricTypeOptions.find(o => o.value === metricRule.type)?.label}
        {isEditingMetric &&
          <ListBox
            options={metricTypeOptions}
            handleChange={handleChangeMetricType}
            value={metricRule?.type}
          />}
      </td>
      <td id={'metric-field-select-cell'}>
        {(!isEditingMetric && metricRule) &&
          metricForAllOptions.find(o => o.value === metricRule.for)?.label}
        {isEditingMetric &&
          <ListBox
            options={metricForAllOptions}
            handleChange={handleChangeMetricFor}
            value={metricRule?.for}
            withScroll={true}
          />}
      </td>
      <td>
        {(!isEditingMetric && metricRule) &&
          metricOperatorOptions.find(o => o.value === metricRule.operator)?.label}
        {isEditingMetric &&
          <ListBox
            options={metricOperatorOptions}
            handleChange={handleChangeMetricOperator}
            value={metricRule?.operator}
          />}
      </td>
      <td>
        {(!isEditingMetric && metricRule) &&
          metricRule.value && (metricRule.type === 'duration' ? formatSecondsToTimeString(Number(metricRule.value)) : Number(metricRule.value).toLocaleString())}
        {isEditingMetric &&
          <FormElement.Group className={`form-group`} controlId="metric-value">
            <FormElement.Control type="text" required inputMode={'numeric'}
              value={metricRule?.value ?? ''} onChange={handleChangeMetricValue}/>
            {metricRule?.type === 'duration' && <div className="input-feedback">seconds</div>}
            {metricRule?.type === 'rtf_count' && <div className="input-feedback">returns</div>}
            {valueInputIsInvalid && <div className="invalid-input-feedback">Enter a whole number, e.g. 10</div>}
          </FormElement.Group>}
      </td>
      <td className="w-1">
        {(!isEditingMetric && metricRule) && <>
          <FaPencilAlt size="16px" className="mx-2 edit-option" onClick={() => setIsEditingMetric(true)} title="Edit metric" />
          <VscTrash size="18px" className="mx-2 delete-option" onClick={() => handleRemoveMetric && handleRemoveMetric(index)} title="Remove metric"/>
        </>}
        {isEditingMetric && !appliedMetric &&
          <Button variant="outline-secondary" className="mx-0 px-3 py-2 add-metric-cta text-nowrap"
            disabled={valueInputIsInvalid || !(metricRule && Object.keys(metricRule).length === 4)}
            onClick={() => handleAddMetrics({metricRule})}>Apply Rule</Button>}
        {isEditingMetric && appliedMetric &&
          <Button variant="outline-secondary" className="mx-0 px-3 py-2 add-metric-cta text-nowrap"
            disabled={valueInputIsInvalid || !(metricRule && Object.keys(metricRule).length === 4)}
            onClick={() => {
              handleEditMetric({index, metricRule});
              setIsEditingMetric(false);
            }}>Apply Rule</Button>}
      </td>
    </tr>
  );
};

export default MetricsRuleRow;
