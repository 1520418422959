import { useEffect, useState } from 'react';
import FieldSegmentComparisons from '../field_segment_comparisons';
import { useLocation } from 'react-router-dom';

export const useFieldSegmentComparisons = () => {
  const [comparisonsLoading, setComparisonsLoading] = useState(false);
  const [comparisonsLoaded, setComparisonsLoaded] = useState(false);
  const [comparisonsLoadingError, setComparisonsError] = useState(null);
  const comparisons = FieldSegmentComparisons;
  const location = useLocation();

  // Load comparisons
  useEffect(() => {
    // We only want to load comparisons the field segment comparison report
    if (location.pathname === '/field-segment-comparison') {
      (async () => {
        try {
          setComparisonsLoading(true);
          setComparisonsError(null);
          await comparisons.load();
          setComparisonsLoaded(true);
        } catch (e) {
          switch (e?.response?.status) {
          case 401:
            setComparisonsError('Not logged in');
            break;
          case 404:
            setComparisonsError('Not found');
            break;
          default:
            setComparisonsError('Oops, something went wrong loading comparisons. Please try again.');
          }
        } finally {
          setComparisonsLoading(false);
        }
      })();
    }
  }, [comparisons, location.pathname]);

  return {
    comparisons,
    comparisonsLoading, comparisonsLoadingError, comparisonsLoaded,
  };
};
