import { RxBorderSolid } from 'react-icons/rx';
import { TbArrowBigRightFilled } from 'react-icons/tb';

const ArrowGridItem = ({loading, percentDiff, greenDown}) => (
  <div className="insights-headlines-grid-item d-flex justify-content-center">
    {loading && <RxBorderSolid size="25px" className="skeleton-color"/>}
    {!loading && percentDiff !== undefined && <>
      {percentDiff > 0 && <TbArrowBigRightFilled size="35px" className={`arrow-icon arrow-up fade-in ${greenDown ? 'arrow-red' : 'arrow-green'}`}/>}
      {percentDiff < 0 && <TbArrowBigRightFilled size="35px" className={`arrow-icon arrow-down fade-in ${greenDown ? 'arrow-green' : 'arrow-red'}`}/>}
      {(!percentDiff || percentDiff === 0) && <RxBorderSolid size="25px" className={`arrow-icon fade-in`}/>}
    </>}
  </div>
);

export default ArrowGridItem;
