import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { VscCheck, VscWarning } from "react-icons/vsc";

const OrgEditSessionLimitsTab = ({org,
  onSaveSessionLimits, successMessage, handleResetSuccessMessage, errorMessage, handleResetErrorMessages, sessionLimitErrorForClient,
  currentUserIsStandard }) => {
  const [sessionLimitsByClient, setSessionLimitsByClient] = useState();
  const [totalSessionLimits, setTotalSessionLimits] = useState(0);

  const clients = org?.clients;
  const agencySessionLimit = org?.type === 'Agency' ? org?.sessionLimit : null;
  const agencyContractType =  org?.type === 'Agency' ? org?.contractType : null;

  const totalHigherThanAgency = agencySessionLimit ? totalSessionLimits > agencySessionLimit : null;

  useEffect(() => {
    if (clients?.length) {
      setSessionLimitsByClient(clients.reduce((acc, client) => {
        if (!acc[client.uuid]) acc[client.uuid] = {};
        acc[client.uuid] = client.sessionLimit;
        return acc;
      }, {}));
    }
  }, [clients]);

  useEffect(() => {
    if (sessionLimitsByClient) setTotalSessionLimits(Object.values(sessionLimitsByClient).reduce((acc, val) => acc + val, 0));
  }, [sessionLimitsByClient]);

  return (<>
    {errorMessage &&
      <Row className="alert-row g-0">
        <Alert dismissible variant="danger" closeVariant="white"
          onClose={() => handleResetErrorMessages()}>
          <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
          <p className="alert-text m-0">{errorMessage}</p>
        </Alert>
      </Row>}
    {successMessage &&
      <Row className="alert-row g-0">
        <Alert dismissible variant="success" closeVariant="white"
          onClose={() => handleResetSuccessMessage()}>
          <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
          <p className="alert-text m-0">{successMessage}</p>
        </Alert>
      </Row>}
    <Row className="alert-row g-0">
      <p className="m-0">Here you can allocate session limits to each client. Client forms will only start tracking when you have saved their session limit and installed the tracking code onto the forms.</p>
      {agencyContractType === 'monthly' ? <p className="mt-2 mb-0">Note: When a client reaches their session limit for the month, Zuko will stop tracking until the next month allowance starts.</p> : null}
    </Row>
    <Table responsive id="org-session-limits-table">
      <thead>
        <tr>
          <th className="name-col">Name</th>
          <th className="limit-col">
            <div className="d-flex justify-content-between align-items-center">
              <div><span>Session Limit</span></div>
              <div className="total-msg">
                {agencySessionLimit ? <>
                  {(agencySessionLimit === totalSessionLimits) ? `Total ${agencyContractType} allowance allocated` :
                    `${(agencySessionLimit - totalSessionLimits)?.toLocaleString()} / ${agencySessionLimit?.toLocaleString()} ${agencyContractType} allowance available`}
                  {(totalHigherThanAgency && agencyContractType === 'monthly') &&
                  <Link
                    to={{
                      pathname: `/organisations/${org.uuid}/choose-plan`,
                      state: {organisation: {name: org.name}},
                    }}><Button className="me-0 sign-up-btn">Upgrade</Button>
                  </Link>}
                </> : null}</div>
            </div></th>
        </tr>
      </thead>
      <tbody>
        {clients?.map(({name, uuid}, i) => (
          <tr key={`client-${i}`}>
            <td className="name-col">{name}</td>
            <td className="">
              <Form.Control type="number" min={0} step={1000} id={`org-sessionLimit-${uuid}`} readOnly={currentUserIsStandard}
                className={`session-limit-input ${((totalHigherThanAgency && (sessionLimitsByClient?.[uuid] !== 0)) || sessionLimitErrorForClient?.[uuid]) && "invalid-input"}`}
                value={sessionLimitsByClient?.[uuid] ?? ''} onChange={({target: {value}}) => {
                  handleResetSuccessMessage();
                  setSessionLimitsByClient((prev) => ({...prev, [uuid]: value ? parseInt(value) : null}));
                }}/>
              {totalHigherThanAgency && (sessionLimitsByClient?.[uuid] !== 0) && <div className="invalid-limit-feedback">You've allocated more sessions than your overall allowance. Please reduce the total sessions allocated to within your allowance.</div>}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    {!currentUserIsStandard &&
    <Row className="g-0 card-bottom-tools">
      <Col className="p-0 d-flex justify-content-end">
        <Button className="action-btn ms-1 me-0" onClick={() => onSaveSessionLimits(sessionLimitsByClient)} disabled={totalHigherThanAgency}>Save Session Limits</Button>
      </Col>
    </Row>}
  </>);
};

export default OrgEditSessionLimitsTab;
