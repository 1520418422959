import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Select from 'react-select';
import moment from 'moment-timezone';
import {
  LineController, LineElement, PointElement, LinearScale, TimeSeriesScale,
  Tooltip, Legend
} from 'chart.js';
import { ReactChart } from 'chartjs-react';
import 'chartjs-adapter-moment';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { VscChromeClose } from "react-icons/vsc";
import { FaCog, FaInfoCircle } from "react-icons/fa";

import AppContext from '../AppContext';
import NavBar from '../NavBar';
import NoFormsMsg from '../Components/NoFormsMsg';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import api from '../api';
import { useAppQuery, useAppForms } from '../hooks';

import './Live.scss';
import { formatFormSelectOptions } from '../utils';
import Forms from '../forms';

ReactChart.register(LineController, LineElement, PointElement, LinearScale, Tooltip, Legend, TimeSeriesScale);

const Live = ({ mixpanel }) => {
  const history = useHistory();
  useAppForms();
  const { currentUser, formsGroupedByOrg, formsGroupedByFormUuid, formsLoading, formsLoadingError } =
    useContext(AppContext);

  const { query } = useAppQuery();
  const { form } = query || {};
  const currentOrg = form?.organisation;

  const [chartLoading, setChartLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [chartError, setChartError] = useState(null);
  const [chartProgress, setChartProgress] = useState(20);
  const [showInfo, setShowInfo] = useState(null);

  // Identify the Mixpanel user on page load for any subsequent tracking
  useEffect(() => mixpanel.identify(currentUser.email), [mixpanel, currentUser.email]);

  // Send the page view mixpanel event each time the app-wide form is updated
  useEffect(() => {
    if (currentOrg?.uuid) mixpanel.track('Page View', {
      'page': 'Live',
      'Organisation Name': currentOrg.name,
      'Organisation Uuid': currentOrg.uuid,
      'Organisation Contract Type': currentOrg.contractType,
    });
  }, [mixpanel, currentOrg]);

  const loadChart = useCallback(async () => {
    const progressID = setInterval(() => setChartProgress((prevProgress) => prevProgress + 30), 50);
    try {
      setChartError(null);
      const { data } = await api.get('/events', {
        params: {
          eventTypes: ['formView', 'completion'],
          form: { uuid: form.uuid },
          timePeriod: {
            start: moment().subtract(15, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
          },
          granularity: 'minute',
          timeZone: formsGroupedByFormUuid[form.uuid]?.organisation?.timeZone,
        },
      });

      const isData = (data && data.labels.length > 0);
      if (!isData) {
        setChartError('No data to display');
      } else {
        data.datasets = data.datasets.map((d) => {
          return {
            ...d,
            backgroundColor: d.label === 'Views' ? '#05B2DA' : d.label === 'Completions' ? '#0267BF' : '',
            borderColor:  d.label === 'Views' ? '#05B2DA' : d.label === 'Completions' ? '#0267BF' : '',
            fill: false,
            borderWidth: 1,
            pointRadius: 1,
          };
        });
        setChartData(data);
      }
    } catch (e) {
      setChartError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
    } finally {
      setChartLoading(false);
      setChartProgress(0);
      clearInterval(progressID);
    }
  }, [form?.uuid, formsGroupedByFormUuid]);

  // Once a form is selected, load the chart
  useEffect(() => {
    if (form?.uuid && formsGroupedByFormUuid) {
      // Only display loading bar when first load chart or change form
      setChartData(null);
      setChartLoading(true);
      loadChart();
    }
  }, [form?.uuid, loadChart, formsGroupedByFormUuid]);

  // Once chart data has been set, auto refresh the chart every 10 seconds
  useEffect(() => {
    let id;
    if (chartData) id = setTimeout(loadChart, 10000);
    // The timeout is cleared everytime this effect runs i.e. chartData is refreshed, and on unmount
    return () => id && clearTimeout(id);
  }, [chartData, loadChart]);

  const handleFormChange = ({value: uuid}) => {
    history.push(`?form[uuid]=${uuid}`);
    mixpanel.track('Selected Form', { page: 'Live' });
  };

  const formatGroupLabel = (group) => (
    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
      <span style={{ color: '#3F4047', fontSize: 14, fontWeight: 500 }}>{group.label}</span>
      <span style={{
        backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 14,
        fontWeight: "normal", lineHeight: "1", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
        {group.options.length}
      </span>
    </div>
  );

  const formFilterOption = ({ data: { orgLabel, orgUuid, label: formLabel, value: formUuid } }, currentSearchValue ) => (
    formLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    formUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase())
  );

  return (
    <Container fluid className="page" id="live-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Live</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col md={3} className="pt-0 pb-2 mt-1" id="form-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5Ec" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={formatFormSelectOptions({formsGroupedByOrg, currentForm: form, formsLoadingError, formsLoading})}
              formatGroupLabel={formatGroupLabel}
              onChange={handleFormChange}
              placeholder="Select a form..."
              value={(form && form.label) ? form : null}
              filterOption={formsGroupedByOrg && Object.values(formsGroupedByOrg).length && formFilterOption}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
            />
          </Col>
          <Col md={5} className="pt-0 pb-2 mt-1 ps-0 d-flex align-items-center">
            {formsLoadingError && <p className="error-message m-0">{formsLoadingError}</p>}
          </Col>
        </Row>
      </div>
      <div className="main-content">
        {(formsLoading || formsLoadingError || !formsGroupedByOrg || Forms.length > 0) ?
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'Live'}
                org={currentOrg}
                messageContent={'the Live report'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            <Row className="title-row g-0">
              {currentOrg?.name && form?.label && <>
                <Col md={11} className="p-0">
                  <h1 id="form-title">
                    {`${currentOrg.name} | ${form.label} | `}
                    <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
                  </h1>
                </Col>
                <Col className="p-0 text-end m-auto">
                  <Link to={`/forms/${form?.uuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
                </Col> </>
              }
            </Row>
            <Col className="chart-content p-0">
              <div className="flip-card">
                <Card id="live" className={`flip-card-inner ${(showInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showInfo === null ? '' : showInfo === true ? 'not-visble' : showInfo === false ? 'visible' : ''}`}>
                      <Row className="g-0 card-title-row justify-content-between">
                        <Col className="p-0 col-auto">
                          <Card.Title as="h3">Live View & Completion Events</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          {chartData &&
                            <div className="d-flex justify-content-end">
                              <div className="refreshCountdown"></div>
                              <svg className="refreshCountdownIndicator">
                                <circle r="4.5" cx="5" cy="5"></circle>
                              </svg>
                              <div>
                                <p className="refreshMessage">Data refreshes every 10 seconds</p>
                              </div>
                            </div>
                          }
                          <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon" onClick={() => {setShowInfo(true); mixpanel.track('Clicked Live info', { page: 'Live' });}} title="How to use"/>
                        </Col>
                      </Row>
                      {chartData ?
                        <div className="card-vis">
                          <div data-testid="live-chart-wrapper">
                            <ReactChart
                              type="line"
                              data={chartData}
                              width={1216}
                              height={350}
                              updateMode="none" // Prevent reload animation
                              options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                  y: {
                                    title: {
                                      text: 'Count',
                                      display: true,
                                    },
                                    type: 'linear',
                                  },
                                  x: {
                                    title: {
                                      text: `Time (${form?.organisation?.timeZone})`,
                                      display: true,
                                    },
                                    type: 'timeseries',
                                    time: {
                                      unit: 'minute',
                                      displayFormats: {
                                        minute: 'HH:mm',
                                      },
                                      tooltipFormat: 'HH:mm',
                                    },
                                  },
                                },
                                elements: {
                                  line: {
                                    tension: 0.05
                                  }
                                },
                                interaction: {
                                  intersect: false,
                                  mode: 'nearest',
                                },
                                plugins: {
                                  legend: {
                                    labels: {
                                      boxWidth: 20
                                    }
                                  },
                                  tooltip: {
                                    position: 'nearest',
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                        : chartError ?
                          <div className="d-flex justify-content-center flex-grow-1">
                            <p className="text-center my-auto" data-testid="live-chart-error">{chartError}</p>
                          </div>
                          : chartLoading &&
                          <div className="progress-area d-flex flex-grow-1">
                            <ProgressBar className="my-auto" animated now={chartProgress}/>
                          </div>
                      }
                    </div>
                    <div className={`flip-card-back ${showInfo === true ? 'visible' : showInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0 card-title-row">
                          <Col className="p-0">
                            <Card.Title as="h3">Live View & Completion Events</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowInfo(false)} title="Return to graph"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={7} className="ps-0">
                            <Card.Text className="mb-3 subtitle">The live view shows the real time arrival of completion and view events into Zuko.</Card.Text>
                            <Card.Text>Note that these events have not been processed into analysed ‘sessions’ so will not tally with the data in the Form Data and Field Data reports.</Card.Text>
                            <dl>
                              <dt>View event</dt>
                              <dd>A view event is counted when Zuko receives a <code>formView</code> event which is sent in the form's tracking code.</dd>
                              <dt>Completion event</dt>
                              <dd>A completion event is counted when Zuko receives a <code>completion</code> event which is sent in the form's completion tracking code.</dd>
                            </dl>
                          </Col>
                          <Col lg={5} className="py-0">
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>This graph is best used to check whether the Zuko tracking code has been successfully loaded on your form.</Card.Text>
                              <Card.Text>Load your form and start interacting with it and you should see a View event trigger on the graph within 10 seconds. If no View data is showing after multiple attempts then
                                you can be fairly sure there is an issue with your installation and you should check whether it has been set up correctly.
                                This <a target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/guides/checking-your-code-installation">guide should help you check that</a>.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Col> :
          <NoFormsMsg mixpanel={mixpanel} page={'Live'}/>
        }
      </div>
    </Container>
  );
};

export default Live;
