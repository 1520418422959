import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { VscInfo } from 'react-icons/vsc';

import api from '../api';
import AppContext from '../AppContext';
import * as Sentry from '@sentry/react';

import './FormDetailsForm.scss';

const FormDetailsForm = ({type, handleSubmit, form,
  saveDisabled,
  handleResetErrorMessagesForField, errorMessagesForField,
  handleResetSuccessMessage,
  zuko,
}) => {
  const { currentUser, currentOrg } = useContext(AppContext);

  const formatContactForSelect = (contact) => ({ ...contact, label: contact.name ? `${contact.name} - ${contact.email}` : contact.email });

  const [availableOrgs, setAvailableOrgs] = useState();
  const [orgsError, setOrgsError] = useState();
  const [orgsLoading, setOrgsLoading] = useState();
  const [amContacts, setAmContacts] = useState();
  const [amContactsLoading, setAmContactsLoading] = useState();
  const [amContactsError, setAmContactsError] = useState();
  const [orgContacts, setOrgContacts] = useState();
  const [orgContactsError, setOrgContactsError] = useState();
  const [combinedAmOrgContacts, setCombinedAmOrgContacts] = useState();
  const [selectedOrg, setSelectedOrg] = useState();
  const [label, setLabel] = useState();
  const [contact, setContact] = useState();
  const [url, setUrl] = useState();
  const [weeklyEmailEnabled, setWeeklyEmailEnabled] = useState();
  const [industry, setIndustry] = useState();
  const [purpose, setPurpose] = useState();
  const [availableIndustries, setAvailableIndustries] = useState([]);
  const [availablePurposes, setAvailablePurposes] = useState([]);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const handleCreateIndustry = (industry) => {
    setIndustry(industry);
    setAvailableIndustries([...availableIndustries, {label: industry, value: industry}]);
  };

  const handleCreatePurpose = (purpose) => {
    setPurpose(purpose);
    setAvailablePurposes([...availablePurposes, {label: purpose, value: purpose}]);
  };

  const isUrlValid = (url) => {
    try {
      const newUrl = new URL(url);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (type === 'create') (async () => {
      try {
        setOrgsLoading(true);
        const { data: { organisations } } = await api.get('/organisations');
        setAvailableOrgs(organisations.filter(o => o.type !== 'Agency'));
      } catch (e) {
        setOrgsError('Something went wrong');
      } finally {
        setOrgsLoading(false);
      }
    })();
  }, [type]);

  // Fetch account manager specific resources
  useEffect(() => {
    if (currentUser.accountManager) {
      (async () => {
        try {
          setAmContactsLoading(true);
          let { data: { accountManagers } } = await api.get(`/account_managers`);

          // Add a flag so we know this is an account manager contact to highlight in the Select dropdown
          setAmContacts(accountManagers.map((am) => ({...am, accountManager: true})));
        } catch (e) {
          setAmContactsError('Something went wrong fetching AM contacts');
        } finally {
          setAmContactsLoading(false);
        }
      })();

      (async () => {
        try {
          const { data: { industries } } = await api.get('/forms/industries');
          setAvailableIndustries(industries.map((i) => ({label: i, value: i})));
        } catch (e) {/* No action. The available industries will remain empty */}
      })();

      (async () => {
        try {
          const { data: { purposes } } = await api.get('/forms/purposes');
          setAvailablePurposes(purposes.map((p) => ({label: p, value: p})));
        } catch (e) {/* No action. The available purposes will remain empty */}
      })();
    }
  }, [currentUser.accountManager]);

  // Fetch contacts for the selected organisation
  useEffect(() => {
    if (selectedOrg?.uuid) (async () => {
      try {
        let { data: { users } } = await api.get(`/organisations/${selectedOrg.uuid}/users`);
        users.sort((a,b) => {
          if(!a.name && b.name) return a.email.localeCompare(b.name);
          if(!a.name && !b.name) return a.email.localeCompare(b.email);
          return a.name.localeCompare(b.name);
        });

        setOrgContacts(users);
      } catch (e) {
        setOrgContactsError('Something went wrong fetching org contacts');
      }
    })();
  }, [selectedOrg?.uuid]);

  // Combine account managers with the organisation contacts
  useEffect(() => {
    if (amContacts?.length && orgContacts) {
      const accountManagerUuids = amContacts.map((am) => am.uuid);
      setCombinedAmOrgContacts([...amContacts, ...orgContacts.filter((user) => !accountManagerUuids.includes(user.uuid))]);
    }
  }, [amContacts, orgContacts]);

  useEffect(() => {
    if (form) {
      setSelectedOrg(form.organisation);
      setContact(form.contact);
      setLabel(form.label);
      setUrl(form.url);
      setWeeklyEmailEnabled(form.weeklyEmailEnabled);
      if (form.industry) setIndustry(form.industry);
      if (form.purpose) setPurpose(form.purpose);
    }

    if (type === 'create') {
      setContact(currentUser);
      setSelectedOrg(currentOrg?.type !== 'Agency' ? currentOrg : null);
    }
  },[form, type, currentOrg, currentUser]);

  return (
    <Form noValidate onSubmit={(e) => {
      e.preventDefault();
      if (!saveAttempted) setSaveAttempted(true);

      // The react-select fields aren't native 'required' form fields, so we need to exit if no content
      if (!contact || !selectedOrg || !label || !url) {
        zuko?.current?.trackEvent({type: `Attempted submit but missing:${!contact ? ' contact' : ''}${!selectedOrg ? ' organisation' : ''}` +
        `${!label ? ' label' : ''}${!url ? ' url' : ''}`});
        return;
      }

      const cleanedUrl = url.replace(/\s+/g, '');
      let urlWithProtocol;

      // First check if the inputted url is valid.
      // NB. a URL could be valid at this point, but then be rejected by the URI parser or public suffix extractor.
      if (!isUrlValid(cleanedUrl)) {
        // Include a protocol if missing
        if (!/^https?:\/\//i.test(cleanedUrl)) {
          urlWithProtocol = 'https://' + cleanedUrl;
          zuko?.current?.trackEvent({type: 'URL was missing the protocol'});
          Sentry.captureMessage(`URL was missing the protocol. URL value before: ${cleanedUrl} URL value after: ${urlWithProtocol}`);
        }
      }

      handleSubmit({label, contact, url: urlWithProtocol || cleanedUrl, weeklyEmailEnabled, industry, purpose, selectedOrg});
    }} className="zuko-app-form" id="form-details-form">
      {(currentUser.accountManager || currentUser.organisations.length > 1) && type === 'create' &&
        <FormGroup className="form-group" controlId="organisation">
          <Form.Label>Organisation {currentUser.accountManager && <span className="am-only-input-tag">AM only</span>}</Form.Label>
          <Col id="organisation-select">
            {type === 'create' &&
              <Select
                inputId="organisation"
                styles={{
                  option: (styles, state) => {
                    return {...styles,
                      color: '#3f4047',
                      backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5EC" : null,
                      '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null},
                      ...state.data.accountManager && { backgroundColor: '#f0eef9'},
                    };
                  },
                  menu: (styles, state) => ({...styles,
                    marginTop: '1px',
                    borderRadius: '4px',
                    boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                  }),
                  control: (styles, state) => ({...styles,
                    maxWidth: '600px',
                    border: ((saveAttempted && !selectedOrg) || errorMessagesForField?.hasOwnProperty('organisation')) ?  '1px solid #dc3545' : '1px solid #ced4da',
                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : ((saveAttempted && !selectedOrg) || errorMessagesForField?.hasOwnProperty('organisation')) ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)' : null,
                  }),
                  dropdownIndicator: (styles, state) => ({...styles,
                    cursor: 'pointer',
                    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                    transition: 'transform .5s ease',
                  }),
                }}
                options={orgsLoading ? [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}] : orgsError ? [] :
                  availableOrgs?.length > 0 ? availableOrgs.map((org) => ({ ...org, value: org.uuid, label: org.name })) : []}
                isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
                onMenuOpen={() => {
                  zuko?.current?.trackEvent({
                    type: 'click',
                    target: (() => {
                      const select = document.createElement('select');
                      select.id = 'organisation';
                      return select;
                    })(),
                  });
                }}
                onChange={(e) => {
                  setSelectedOrg(e);
                  handleResetErrorMessagesForField('organisation');
                  zuko?.current?.trackEvent({
                    type: 'change',
                    target: (() => {
                      const select = document.createElement('select');
                      select.id = 'organisation';
                      return select;
                    })(),
                  });
                }}
                value={form?.organisation ? { label: form.organisation.name } :
                  (type === 'create' && selectedOrg ? { ...selectedOrg, label: selectedOrg.name } : null)}
              />}
            <div className="input-feedback">The entity which holds all related forms together in one place.</div>
            {(saveAttempted && !selectedOrg) && <div className="invalid-input-feedback">Please select an organisation.</div>}
          </Col>
        </FormGroup>}
      {!selectedOrg && currentOrg?.type === 'Agency' && currentUser.organisations.filter(o => o.type === 'AgencyClient')?.length === 0 &&
        <Alert variant="info" closeVariant="white">
          <div className="alert-svg-icon my-auto"><VscInfo size="20px" className="me-2"/></div>
          <p className="alert-text m-0">No clients created yet, please first <Link to={`/organisations/${currentOrg.uuid}/agency_clients/new`}>add a client</Link>. Then add a form to the client.</p>
        </Alert>}

      <FormGroup className="form-group" controlId="label">
        <Form.Label>Label</Form.Label>
        <Form.Control className={((saveAttempted && !label) || errorMessagesForField?.hasOwnProperty('label')) && "invalid-input"} type="text" value={label || ''} required
          onChange={(e) => {
            setLabel(e.target.value);
            handleResetErrorMessagesForField('label');
            if (handleResetSuccessMessage) handleResetSuccessMessage();
          }}/>
        {(saveAttempted && !label) ? <div className="invalid-input-feedback">Please add a label to identify your form in the Zuko App. E.g. Application form or Enquiry form</div> :
          <div className="input-feedback">A label identifies your form in the Zuko App. E.g. Application form</div>}
      </FormGroup>

      <FormGroup className="form-group" controlId="url">
        <Form.Label>URL</Form.Label>
        <Form.Control className={((saveAttempted && !url) || errorMessagesForField?.hasOwnProperty('url') || errorMessagesForField?.hasOwnProperty('domain')) && "invalid-input"}
          type="url" value={url || ''} required
          onChange={(e) => {
            setUrl(e.target.value);
            handleResetErrorMessagesForField('url');
            handleResetErrorMessagesForField('domain');
            if (handleResetSuccessMessage) handleResetSuccessMessage();
          }}/>
        {((saveAttempted && !url) || errorMessagesForField?.hasOwnProperty('url') || errorMessagesForField?.hasOwnProperty('domain')) ?
          <div className="invalid-input-feedback">Please add a valid URL for your form's webpage. For example: https://example.com/application-form</div> :
          <div className="input-feedback">The full URL for your form's webpage, e.g. https://example.com/application-form. This is used to allow Zuko to track events from your domain.</div>}
      </FormGroup>

      {(type === 'edit' || (type === 'create' && currentUser.accountManager)) &&
        <FormGroup className="form-group">
          <Form.Label htmlFor="contact">Main Contact {(type === 'create' && currentUser.accountManager) && <span className="am-only-input-tag">AM only</span>}</Form.Label>
          <Col id="contact-select">
            <Select
              inputId="contact"
              styles={{
                option: (styles, state) => {
                  return {...styles,
                    color: '#3f4047',
                    backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                    '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null},
                    ...state.data.accountManager && { backgroundColor: '#f0eef9'},
                  };
                },
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                control: (styles, state) => ({...styles,
                  maxWidth: '600px',
                  border: ((saveAttempted && !contact) || errorMessagesForField?.hasOwnProperty('contact')) ?  '1px solid #dc3545' : '1px solid #ced4da',
                  boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : ((saveAttempted && !contact) || errorMessagesForField?.hasOwnProperty('contact')) ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)' : null,
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={amContactsLoading ? [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}] : (amContactsError || orgContactsError) ? [] :
                combinedAmOrgContacts?.length > 0 ? combinedAmOrgContacts.map((c) => formatContactForSelect(c)) :
                  orgContacts?.length > 0 ? orgContacts.map((c) => formatContactForSelect(c)) : []}
              onMenuOpen={() => {
                zuko?.current?.trackEvent({
                  type: 'click',
                  target: (() => {
                    const select = document.createElement('select');
                    select.id = 'contact';
                    return select;
                  })(),
                });
              }}
              onChange={(e) => {
                setContact(e);
                handleResetErrorMessagesForField('contact');
                if (handleResetSuccessMessage) handleResetSuccessMessage();
                zuko?.current?.trackEvent({
                  type: 'change',
                  target: (() => {
                    const select = document.createElement('select');
                    select.id = 'contact';
                    return select;
                  })(),
                });
              }}
              value={(contact && formatContactForSelect(contact)) || null}
            />
            {(saveAttempted && !contact) && <div className="invalid-input-feedback">Please select a contact.</div>}
            <div className="input-feedback">The contact will be notified when data is first received for this form.</div>
          </Col>
        </FormGroup>}

      {type === 'edit' &&
        <FormGroup className="form-group pt-1" controlId="weeklyEmail">
          <div className="d-inline-flex align-items-center">
            <Form.Check type="checkbox" checked={weeklyEmailEnabled}
              onChange={(e) => {
                setWeeklyEmailEnabled(e.target.checked);
              }}/>
            <Form.Label className="mb-0 ms-2">Weekly Email</Form.Label>
          </div>
          <div className="input-feedback">Include this form in your weekly update email (sent on a Monday at midday UTC).</div>
        </FormGroup>}

      {currentUser.accountManager && <>
        <FormGroup className="form-group" controlId="industry">
          <Form.Label>Industry</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          <div id="industry-select">
            <CreatableSelect
              inputId="industry"
              styles={{
                option: (styles, state) => {
                  return {...styles,
                    color: '#3f4047',
                    backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                    '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null},
                  };
                },
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                control: (styles, state) => ({...styles,
                  border: '1px solid #ced4da',
                  boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : null,
                }),
              }}
              isClearable
              placeholder="Type to enter a new industry, or select one..."
              onMenuOpen={() => {
                zuko?.current?.trackEvent({
                  type: 'click',
                  target: (() => {
                    const select = document.createElement('select');
                    select.id = 'industry';
                    return select;
                  })(),
                });
              }}
              onChange={(e) => {
                setIndustry(e?.value || '');
                zuko?.current?.trackEvent({
                  type: 'change',
                  target: (() => {
                    const select = document.createElement('select');
                    select.id = 'industry';
                    return select;
                  })(),
                });
              }}
              onInputChange={() => {
                zuko?.current?.trackEvent({
                  type: 'change',
                  target: (() => {
                    const select = document.createElement('select');
                    select.id = 'industry';
                    return select;
                  })(),
                });
              }}
              onCreateOption={handleCreateIndustry}
              options={availableIndustries}
              value={industry && {label: industry, value: industry}}
            />
          </div>
        </FormGroup>

        <FormGroup className="form-group" controlId="purpose" >
          <Form.Label>Purpose</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          <div id="purpose-select">
            <CreatableSelect
              inputId="purpose"
              styles={{
                option: (styles, state) => {
                  return {...styles,
                    color: '#3f4047',
                    backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                    '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null},
                  };
                },
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                control: (styles, state) => ({...styles,
                  border: '1px solid #ced4da',
                  boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : null,
                }),
              }}
              isClearable
              placeholder="Type to enter a new purpose, or select one..."
              onMenuOpen={() => {
                zuko?.current?.trackEvent({
                  type: 'click',
                  target: (() => {
                    const select = document.createElement('select');
                    select.id = 'purpose';
                    return select;
                  })(),
                });
              }}
              onChange={(e) => {
                setPurpose(e?.value || '');
                zuko?.current?.trackEvent({
                  type: 'change',
                  target: (() => {
                    const select = document.createElement('select');
                    select.id = 'purpose';
                    return select;
                  })(),
                });
              }}
              onInputChange={() => {
                zuko?.current?.trackEvent({
                  type: 'change',
                  target: (() => {
                    const select = document.createElement('select');
                    select.id = 'purpose';
                    return select;
                  })(),
                });
              }}
              onCreateOption={handleCreatePurpose}
              options={availablePurposes}
              value={purpose && {label: purpose, value: purpose}}
            />
          </div>
        </FormGroup>
      </>}

      <Row className="form-actions-row">
        <Col className="p-0">
          <Button variant="primary" className="submit m-0" type="submit" disabled={saveDisabled}>Save</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormDetailsForm;
