import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {Link, useLocation} from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState, useCallback } from 'react';
import AppContext from '../AppContext';
import './NavBar.scss';
import api from '../api';
import { HiExternalLink } from 'react-icons/hi';

// TODO: do intro js

const SmallWidthBanner = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth < 600 ?
    <Alert variant="danger" className="mb-0 nav-wide-alert">
      <p className="alert-text m-0 p-2">A wider screen is best to build and manage your forms.</p>
    </Alert> : null;
};

const NavBar = (props) => {
  const { currentUser, setUser, resetSession, currentUserRoleInOrgs, setCurrentOrg } = React.useContext(AppContext);
  const location = useLocation();
  const { form } = props;
  const [currentUserIsStandardInOrg, setCurrentUserIsStandardInOrg] = useState();
  const [org, setOrg] = useState();

  const logout = async () => {
    try {
      await api.post('/logout');
    } catch (e) {/* Continue with removing the user */}
    localStorage.clear();
    setUser(null);
    resetSession();
  };

  const pathLookUp = location.pathname === '/builder/forms' ? 'build' : location.pathname.replace(/\/$/, '').match(/[^/]*$/)?.[0];

  const showOrgSignUpBtn = useCallback(() => {
    if (
      currentUserIsStandardInOrg ||
      !org ||
      org?.type === 'AgencyClient') return false;

    if (org && (
      (org.builderSubscriptionStatus && org.builderSubscriptionStatus === 'canceled') ||
      (org.builderSubscriptionStatus && org.builderSubscriptionStatus === 'incomplete_expired') ||
      (!org.builderPlan))) return true;
  }, [org, currentUserIsStandardInOrg]);

  useEffect(() => {
    if (currentUser.organisations.length > 0) setOrg(currentUser.organisations[0]);
  },[currentUser?.organisations]);

  useEffect(() => setCurrentOrg(org), [org, setCurrentOrg]);

  useEffect(() => {
    if (!currentUser.accountManager && org) {
      setCurrentUserIsStandardInOrg(currentUserRoleInOrgs?.[org.uuid]?.name !== 'admin' ?? true);
    }
  }, [currentUser?.accountManager, currentUserRoleInOrgs, org]);

  useEffect(() => {
    props.mixpanel.identify(currentUser.email);
  }, [currentUser.email, props.mixpanel]);

  return ( <>
    <Navbar collapseOnSelect expand="lg" variant="dark" id="main-nav" className="form-builder-nav" data-testid="main-nav">
      <Container className="mw-100">
        <Navbar.Brand as={Link} to="/" onClick={props.handleClick}><div className="zuko-logo" role="img" alt="logo"></div></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`me-auto`} activeKey={pathLookUp}>
            <Nav.Link as={Link} to={form?.uuid ? `/builder/forms/${form.uuid}/build` : '/builder/forms'} eventKey={'build'}>Build</Nav.Link>
            <Nav.Link as={Link} to={`/builder/forms/${form?.uuid}/share-embed`} eventKey={'share-embed'} disabled={!form?.uuid}>
              Share/Embed</Nav.Link>
            <Nav.Link as={Link} to={`/builder/forms/${form?.uuid}/submissions`} eventKey={'submissions'} disabled={!form?.uuid}>
              Submissions</Nav.Link>
            <Nav.Link as={Link} to={`/builder/forms/${form?.uuid}/integrations`} eventKey={'integrations'} disabled={!form?.uuid}>
              Integrations</Nav.Link>
            <Nav.Link as={Link} to={`/builder/forms/${form?.uuid}/analytics`} eventKey={'analytics'} disabled={!form?.uuid}>
              Analytics</Nav.Link>
          </Nav>
          <Nav activeKey={pathLookUp}>
            {showOrgSignUpBtn() &&
             <Link to={{pathname: `/builder/organisations/${org.uuid}/choose-plan`, state: {organisation: {name: org.name}}}}><Button className="me-0 ms-1 sign-up-btn">Sign Up</Button></Link>}
            <NavDropdown title={`Hello, ${currentUser.name || ''}`} onClick={props.handleClick} className="user-dropdown" id="user-dropdown">
              <NavDropdown.Item to="/builder/profile" as={Link} eventKey="profile" onClick={props.handleClick}>Profile</NavDropdown.Item>
              {org && <NavDropdown.Item as={Link} to={`/builder/organisations/${org.uuid}/edit`} eventKey="organisations"
                onClick={props.handleClick}>Organisation</NavDropdown.Item>}
              {/* // TODO: link to builder guides */}
              <NavDropdown.Item target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/guides" onClick={props.handleClick}>Guides <HiExternalLink /></NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dashboard" onClick={props.handleClick} id="nav-switch-app-btn-link">
                <Button className="switch-app-btn btn-less-padding m-0">Form Analytics</Button>
              </NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <SmallWidthBanner />
  </>);
};

export default NavBar;
