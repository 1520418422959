/**
 * @typedef {{
 *   uuid: String, name: String,
 * }} Organisation
 */

/**
 * @typedef {{
 *   uuid: String, name: String, slug: String, organisation: Organisation,
 * }} Form
 */

import api from './api';

/**
 * Module for querying/reading the forms stored in the application
 */
class Forms {
  /**
   * Where the actual forms are stored when received from the API
   * @type {[]}
   * @private
   */
  static _forms = [];

  /**
   * The various indices for forms
   * @type {Object} of indices
   * @private
   */
  static _indices = {
    uuid: {},
    org: { uuid: {} },
  };

  /**
   * @returns {Promise<*>}
   * @private
   */
  static async _initialise() {
    if (!this._loading) {
      this.clear();
      this._loadForms();
      await this._loading.then(() => this._index());
    }
    return this._loading;
  }

  /**
   * Holds the loading Promise so that actions can be 'queued' up off the back of loading forms
   * @private
   */
  static _loading;

  /**
   * Loads forms from the API
   * @returns {Forms}
   * @private
   */
  static _loadForms() {
    this._loading = api.get('/forms', { params: { include: 'organisation' } }).then(({ data: { forms } }) =>
      this._forms = forms);
    return this;
  }

  /**
   * Indexes the forms from the API, grouping them in various ways
   * @returns {Forms}
   * @private
   */
  static _index(form = null) {
    if (form) { // Index a single form
      // Key by form UUID
      if (!this._indices.uuid.hasOwnProperty(form?.uuid)) this._indices.uuid[form?.uuid] = form;
      const indexedForm = this._indices.uuid[form.uuid];
      Object.assign(indexedForm, form);

      // Key by org UUID
      const org = form.organisation;
      if (!this._indices.org.uuid.hasOwnProperty(org?.uuid)) this._indices.org.uuid[org?.uuid] = {...org};
      if (!this._indices.org.uuid[org?.uuid].hasOwnProperty('forms')) this._indices.org.uuid[org?.uuid].forms = [];
      const orgForms = this._indices.org.uuid[org?.uuid].forms;
      const indexedOrgForm = orgForms[orgForms.map(({uuid}) => uuid).indexOf(form.uuid)] ||
        this._indices.org.uuid[org?.uuid].forms.push(form);
      Object.assign(indexedOrgForm, form);
    } else { // Index all forms
      for (const form of this._forms) {
        // Key by form UUID
        if (!this._indices.uuid.hasOwnProperty(form?.uuid)) this._indices.uuid[form?.uuid] = form;

        // Key by org UUID
        const org = form?.organisation;
        if (!this._indices.org.uuid.hasOwnProperty(org?.uuid)) this._indices.org.uuid[org?.uuid] = {...org};
        if (!this._indices.org.uuid[org?.uuid].hasOwnProperty('forms')) this._indices.org.uuid[org?.uuid].forms = [];
        this._indices.org.uuid[org?.uuid].forms.push(form);
      }
    }
    return this;
  }

  /**
   * Remove any cached forms in the module
   * @returns {Forms}
   */
  static clear() {
    this._forms = [];
    this._indices = {
      uuid: {},
      org: { uuid: {} },
    };
    this._loading = null;
    return this;
  }

  /**
   * Reload forms from the API
   * @returns {Promise}
   */
  static reload() {
    this._loading = null;
    return this._initialise();
  }

  /**
   * Updates a single form
   * @param {String} uuid UUID of the form to update
   * @param {Form} form The form to save to the API
   * @param {Organisation} org The org which the form belongs to
   */
  static update(uuid, form, organisation) {
    return api.put(`/forms/${uuid}`, { form })
      .then(() => (this._forms.length > 0) && this._index({uuid, ...form, organisation}));
  }

  /**
   * @returns {Promise<{String: Form}>} Hash of forms, keyed by their UUIDs
   */
  static async groupByUuid() {
    if (!Object.keys(this._indices.uuid).length) await this._initialise();
    return this._indices.uuid;
  }

  /**
   * @returns {Promise<{String: Array<Form>}>} Forms, grouped by the organisation they belong to
   */
  static async groupByOrgUuid() {
    if (!Object.keys(this._indices.org.uuid).length) await this._initialise();
    return this._indices.org.uuid;
  }

  /**
   * @returns {number} Count of forms retrieved from the API
   */
  static get length() {
    return this._forms.length;
  }

  /**
   * @param uuid Form UUID
   * @returns {Form} Form with the given UUID
   */
  static getByUuid(uuid) {
    return this._indices.uuid[uuid];
  }
}

export default Forms;
