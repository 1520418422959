import Select from 'react-select';

const MultiSelect = ({id, options, selectedOptions, handleOnChange, handleOnBlur}) => {
  return <Select
    id={id}
    styles={{
      multiValue: (provided, state) => ({...provided,
        background:'#ebedf2',
      }),
      multiValueLabel: (provided, state) => ({...provided,
        color: '#575962',
        fontSize: '1rem',
      }),
      control: (styles, state) => ({...styles,
        paddingTop: '2px',
        paddingBottom: '2px',
        border: '1px solid #EBEDF2',
      }),
      valueContainer: (styles, state) => ({...styles,
        paddingTop: '0px',
        paddingBottom: '0px',
      }),
      dropdownIndicator: (styles, state) => ({...styles,
        padding: '6px 8px',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        transition: 'transform .5s ease',
      }),
      clearIndicator: (styles, state) => ({...styles,
        padding: '6px 8px',
        '&:hover': {color: '#DE350B'}
      }),
      option: (styles, state) => ({...styles,
        color: state.isDisabled ? '#9e9e9e' : '#3F4047',
        '&:hover': {backgroundColor: state.isFocused && '#F4F5F8'}
      }),
      menu: (styles, state) => ({...styles,
        marginTop: '1px',
        borderRadius: '4px',
        border: '1px solid #EBEDF2',
        boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
      }),
      menuList: (styles, state) => ({...styles,
        maxHeight: '200px',
      }),
    }}
    options={options?.map(o => ({ value: o, label: o })) || []}
    onChange={(newOptions) => handleOnChange(newOptions.map(({value}) => value))}
    onBlur={() => handleOnBlur()}
    value={selectedOptions?.map((s) => ({ value: s, label: s }))}
    isMulti={true}
    closeMenuOnSelect={false}
  />;
};

export default MultiSelect;
