import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

import api from '../api';
import './WeeklySummaryEmailUnsubscribe.scss';

import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const WeeklySummaryEmailUnsubscribe = () => {
  const {uuid: userUuid} = useParams();
  const [confirmed, setConfirmed] = useState(null);
  const [userUpdateError, setUserUpdateError] = useState(null);

  useEffect(() => {
    const unsubscribeUser = async () => {
      try {
        await api.post(`/users/${userUuid}/weekly_summary_email_unsubscribe`, {weeklyEmailEnabled: false});
        setConfirmed(true);
      } catch (e) {
        setUserUpdateError((e.response && (e.response.status === 404)) ? 'The unsubscribe link is invalid.' : 'Something went wrong.');
      }
    };
    unsubscribeUser();
  }, [userUuid]);

  return (
    <Container fluid className="page" id="weekly-summary-unsubscribe-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Unsubscribe</title>
      </Helmet>
      <div className="nav-wrapper">
        <Navbar expand="lg" variant="dark" id="main-nav">
          <Navbar.Brand as={Link} to="/"><div className="zuko-logo" role="img" alt="logo"></div></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        </Navbar>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <Row className="title-row g-0">
            <Col className="p-0">
              <h1>Update Emails | Unsubscribe</h1>
            </Col>
          </Row>
          <div id="weekly-summary-email-unsubscribe-container">
            {!userUpdateError && !confirmed && <p><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Loading...</p>}
            {confirmed && <>
              <p>You have been unsubscribed from the Zuko update emails.</p>

              <p className="pt-3">We're sorry that you haven't found these emails useful.</p>

              <p>The <a href="mailto:support@zuko.io">Zuko Team</a> would like to hear your feedback and is happy to help you explore Zuko further.</p>

              <p className="pt-3">You can re-enable your update email in your <Link to="/profile">Profile</Link>.</p>

            </>}
            {userUpdateError && <>
              <p>{userUpdateError} Please contact <a href="mailto:support@zuko.io">Zuko support</a>.</p>
            </>}
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default WeeklySummaryEmailUnsubscribe;
