import React, { useContext, useState, useEffect, useCallback,  } from 'react';
import { useHistory } from 'react-router-dom';
import { MetricRuleType } from '../../types/sessions';
import { Time, Form, Field, Filter, FilterForSelect, FiltersForSelect, SelectFormOptionType, LoadingOptionType,
  SelectOptionType, SelectFieldOptionType,
} from '../../types/types.ts';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FormGroup from 'react-bootstrap/FormGroup';
import FormElement from 'react-bootstrap/Form';
import moment from 'moment-timezone';

import DatePicker from '../../Components/DatePicker';
import FiltersSelect from '../../Components/Select/FiltersSelect';
import FormSelect from '../../Components/Select/FormSelect';
import FieldSelect from '../../Components/Select/FieldSelect';
import { SingleValue } from 'react-select';
import AppContext from '../../AppContext';
import { FaAngleDown } from "react-icons/fa";
import {
  compileQueryString,
  defaultAttributes,
  formatSecondsToTimeString,
} from '../../utils';
import useFetchFilters from '../../hooks/useFetchFilters.ts';
import useFetchTrackedFields from './useFetchTrackedFields.ts';
import useFetchAbandonedFields from './useFetchAbandonedFields.ts';
import MetricsTable from './MetricsTable.tsx';
import './SessionsQueryNav.scss';

const SessionsQueryNav = ({page, mixpanel}) => {
  const {
    formsGroupedByFormUuid,
    query,
  } = useContext(AppContext);
  const history = useHistory();

  const {
    time,
    form,
    filters,
    sessionOutcomes,
    sessionFilters,
    sessionExplorer,
  }: {
    time: Time,
    form: Form,
    filters: FilterForSelect[],
    sessionOutcomes: Filter[],
    sessionFilters: {
      abandonedFieldIdentifier: string,
      interactedWithFieldIdentifiers: string[],
      metrics: MetricRuleType[],
    },
    sessionExplorer?: {
      includeCustomEvents: boolean,
    }
  } = query || {};

  const {
    abandonedFieldIdentifier,
    interactedWithFieldIdentifiers,
    metrics,
  } = sessionFilters || {};

  const {
    includeCustomEvents,
  } = sessionExplorer || {};

  // Query nav
  const { filtersLoading, filtersError, availableFilters }: {
    filtersLoading: boolean, filtersError: string | null, availableFilters: FiltersForSelect[] | undefined
  } = useFetchFilters({includeSessionOutcome: true});

  const { trackedFieldsLoading, trackedFieldsError, availableTrackedFields }: {
    trackedFieldsLoading: boolean, trackedFieldsError: string | null, availableTrackedFields: SelectFieldOptionType[] | undefined
  } = useFetchTrackedFields();

  const { abandonedFieldsLoading, abandonedFieldsError, availableAbandonedFields }: {
    abandonedFieldsLoading: boolean, abandonedFieldsError: string | null, availableAbandonedFields: Field[] | undefined
  } = useFetchAbandonedFields();

  const [openRules, setOpenRules] = useState(false);
  const [abandonedField, setAbandonedField] = useState<Field | null>();
  const [interactedWithFields, setInteractedWithFields] = useState<Field[] | [] | null>();

  const metricTypeOptions: SelectOptionType[] = [{value: 'rtf_count', label: 'Returns'}, {value: 'duration', label: 'Time spent'}];
  const metricForDefaultOption: SelectOptionType[] = [{value: 'total-session', label: 'Total session'}];
  const metricForAllOptions: (SelectOptionType|SelectFieldOptionType)[] = metricForDefaultOption.concat(availableTrackedFields || []);
  const metricOperatorOptions: SelectOptionType[] = [{value: 'eq', label: 'equals'}, {value: 'gte', label: 'is greater than or equal to'}, {value: 'lte', label: 'is fewer than or equal to'}];

  const handleFiltersChange = useCallback((input) => {
    const defaultSelected = input?.filter(({key}) => defaultAttributes.includes(key));
    mixpanel.track('Selected Filters', { page, ...(defaultSelected?.length > 0) && {filters: defaultSelected.map(f => f.value)}});

    const newSelectedFilters = (input || []).filter((filter) => filter.key !== 'sessionOutcome');
    const newSelectedSessionOutcomes = (input || []).filter((filter) => filter.key === 'sessionOutcome');

    history.push(compileQueryString({
      form: form,
      time: time,
      filters: newSelectedFilters,
      sessionOutcomes: newSelectedSessionOutcomes,
      ...page === 'SessionExplorer' && {sessionExplorer},
      sessionFilters: {
        interactedWithFieldIdentifiers,
        abandonedFieldIdentifier,
        metrics,
      }
    }));
  }, [mixpanel, form, history, time, abandonedFieldIdentifier, interactedWithFieldIdentifiers, metrics, page, sessionExplorer]);

  const handleDateTimeRangeChange = useCallback((start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page,
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
    });

    history.push(compileQueryString({
      form,
      time: {start, end},
      filters,
      sessionOutcomes,
      ...page === 'SessionExplorer' && {sessionExplorer},
      sessionFilters: {
        interactedWithFieldIdentifiers,
        abandonedFieldIdentifier,
        metrics,
      },
    }));
  }, [mixpanel, form, history, filters, sessionOutcomes, abandonedFieldIdentifier, interactedWithFieldIdentifiers, metrics, page, sessionExplorer]);

  const handleFormChange = useCallback((option: SingleValue<SelectFormOptionType | LoadingOptionType> | null) => {
    if (option === null || !option.value) return;
    const { value: uuid } = option;
    mixpanel.track('Selected Form', { page });

    if (uuid !== form?.uuid) {
      history.push(compileQueryString({
        form: formsGroupedByFormUuid[uuid],
        time,
        filters,
        sessionOutcomes,
        ...page === 'SessionExplorer' && {sessionExplorer},
        sessionFilters: {
          interactedWithFieldIdentifiers: null,
          abandonedFieldIdentifier: null,
          metrics: null,
        }
      }));
    }
  }, [filters, formsGroupedByFormUuid, form?.uuid, history, mixpanel, sessionOutcomes, time, page, sessionExplorer]);

  const handleAbandonedFieldChange = useCallback((field: Field) => {
    mixpanel.track('Selected abandoned field', { page });

    history.push(compileQueryString({
      form,
      time,
      filters,
      sessionOutcomes,
      ...page === 'SessionExplorer' && {sessionExplorer},
      sessionFilters: {
        interactedWithFieldIdentifiers,
        abandonedFieldIdentifier: field?.identifier || null,
        metrics,
      }
    }));
  }, [form, filters, history, mixpanel, sessionOutcomes, time, metrics, interactedWithFieldIdentifiers, page, sessionExplorer]);

  const handleInteractedWithFieldsChange = useCallback((fields: Field[]) => {
    mixpanel.track('Selected interacted with fields', { page });

    history.push(compileQueryString({
      form,
      time,
      filters,
      sessionOutcomes,
      ...page === 'SessionExplorer' && {sessionExplorer},
      sessionFilters: {
        interactedWithFieldIdentifiers: fields?.map(({identifier}: Field) => identifier) || null,
        abandonedFieldIdentifier,
        metrics,
      }
    }));
  }, [form, filters, history, mixpanel, sessionOutcomes, time, metrics, abandonedFieldIdentifier, page, sessionExplorer]);

  const handleIncludeCustomEventsChange = useCallback(({currentTarget: {checked}}) => {
    mixpanel.track('Changed Include Custom Events', { page });
    history.push(compileQueryString({
      form,
      time,
      filters,
      sessionOutcomes,
      sessionExplorer: {
        includeCustomEvents: checked,
      },
      sessionFilters,
    }));
  }, [form, filters, history, mixpanel, sessionOutcomes, time, sessionFilters, page]);

  // Set the abandoned field now we have the identifier
  useEffect(() => {
    if (abandonedFieldIdentifier && availableAbandonedFields?.length &&
        (!abandonedField || (abandonedField.identifier !== abandonedFieldIdentifier))) {
      const fieldsByIdentifier = availableAbandonedFields.reduce((acc, field) => {
        acc[field.identifier] = field;
        return acc;
      }, {});
      const field = fieldsByIdentifier[abandonedFieldIdentifier];
      if (field) setAbandonedField(field);
    }
  }, [availableAbandonedFields, abandonedField, abandonedFieldIdentifier]);

  useEffect(() => {
    if (!abandonedFieldIdentifier && abandonedField) {
      setAbandonedField(null);
    }
  }, [abandonedField, abandonedFieldIdentifier]);

  // Set the interacted with fields now we have the identifiers
  useEffect(() => {
    if (interactedWithFieldIdentifiers?.length && availableTrackedFields?.length) {
      const fieldsByIdentifier = availableTrackedFields.reduce((acc, field) => {
        acc[field.identifier] = field;
        return acc;
      }, {});
      let fields: Field[] = [];
      for (const identifier of interactedWithFieldIdentifiers) {
        fields.push(fieldsByIdentifier[identifier]);
      }
      setInteractedWithFields(fields);
    }
  }, [availableTrackedFields, interactedWithFieldIdentifiers]);

  useEffect(() => {
    if (!interactedWithFieldIdentifiers?.length && interactedWithFields?.length) {
      setInteractedWithFields(null);
    }
  }, [interactedWithFields?.length, interactedWithFieldIdentifiers]);

  return (
    <div className="query-nav-wrapper browser-only">
      <Row className="g-0 nav-primary">
        <Col className="col-md-auto col-sm-12 pt-0 pb-2 pe-md-1 mt-1 d-inline-flex" id="datepicker">
          <DatePicker
            startTime={time?.start}
            endTime={time?.end}
            onApply={handleDateTimeRangeChange}
            timeZone={form?.organisation?.timeZone}
            selectedFormTimeZone={form?.organisation?.timeZone}
          />
        </Col>
        <Col md={3} className="pt-0 px-md-1 pb-2 mt-1">
          <FormSelect
            id={'form-select'}
            handleFormChange={handleFormChange}
            selectedForm={form}
          />
        </Col>
        <Col className="pt-0 pb-2 ps-md-1 mt-1">
          <FiltersSelect
            form={form}
            filters={(sessionOutcomes || []).concat(filters || [])}
            filtersLoading={filtersLoading}
            availableFilters={availableFilters}
            filtersLoadingError={filtersError}
            handleFiltersChange={handleFiltersChange} />
        </Col>
      </Row>
      <Row className="g-0 nav-secondary pb-2">
        <Col className="py-0">
          <div className="d-flex align-items-center open-rules-cta-row justify-content-between">
            <div className="d-flex">
              <ButtonGroup>
                <Button className="mx-0 px-3 text-nowrap rules-cta-button d-flex align-items-center"
                  onClick={() => setOpenRules(!openRules)} aria-expanded={openRules}>
                  More filters
                </Button>
                <Button className="mx-0 px-2 rules-cta-button close-btn d-flex align-items-center"
                  onClick={() => setOpenRules(!openRules)} aria-expanded={openRules}>
                  <FaAngleDown className={`angle-icon ${openRules ? 'flip' : ''}`} size="20px"/>
                </Button>
              </ButtonGroup>
              <div className="d-flex align-items-center">
                <p className="mb-0">
                  {abandonedField && <span className="mx-2 my-1 badge fw-normal">
                    <span className="fw-500">Abandonment point:</span> {abandonedField.label}</span>}
                  {interactedWithFields && interactedWithFields.length > 0 && <span className="mx-2 my-1 badge fw-normal">
                    <span className="fw-500">Interacted with:</span> {interactedWithFields.map((f) => f.label).join(', ')}</span>}
                  {metrics && metrics.length > 0 && metrics.map(({type, for: forItem, operator, value}, i) => <span className="mx-2 my-1 badge fw-normal" key={`metric-badge-${type}-${forItem}-${i}`}>
                    <span className="fw-500">Metric:</span> {metricTypeOptions.find(o => o.value === type)?.label} for {metricForAllOptions.find(o => o.value === forItem)?.label} {metricOperatorOptions.find(o => o.value === operator)?.label} {value && (type === 'duration' ? formatSecondsToTimeString(Number(value)) : Number(value).toLocaleString())}</span>)}
                </p>
              </div>
            </div>
            {page === 'SessionExplorer' && <div>
              <FormGroup className="form-group d-inline-flex align-items-center mb-0 text-nowrap include-custom-events" controlId="include-custom-events">
                <FormElement.Check inline type="checkbox" className="mb-0" label="Include custom events" checked={includeCustomEvents || false} onChange={handleIncludeCustomEventsChange}/>
              </FormGroup>
            </div>}
          </div>
          <Collapse in={openRules} className="mt-2 rules-collapse-area">
            <div>
              <Row className="g-0">
                <Col className="col-md-5 col-sm-12 px-2 p-2 me-md-3 d-flex align-items-center rule-area">
                  <label className="me-2 fs-5 mb-19">Abandonment point</label>
                  <div className="d-flex flex-column flex-grow-1">
                    <FieldSelect
                      id={'abandoned-field-select'}
                      handleChange={handleAbandonedFieldChange}
                      field={abandonedField}
                      availableFields={availableAbandonedFields}
                      isMulti={false}
                      isClearable={true}
                      fieldsLoading={abandonedFieldsLoading}
                      fieldsLoadingError={abandonedFieldsError}
                    />
                    <div className="input-feedback">Filter by the field where visitors abandoned</div>
                  </div>
                </Col>
                <Col className="p-2 d-flex align-items-center rule-area">
                  <label className="me-2 fs-5 mb-19">Interacted with</label>
                  <div className="d-flex flex-column flex-grow-1">
                    <FieldSelect
                      id={'interacted-with-field-select'}
                      handleChange={handleInteractedWithFieldsChange}
                      field={interactedWithFields}
                      availableFields={availableTrackedFields}
                      isMulti={true}
                      isClearable={true}
                      fieldsLoading={trackedFieldsLoading}
                      fieldsLoadingError={trackedFieldsError}
                      placeholder={'Select fields...'}
                    />
                    <div className="input-feedback">Select fields you want the visitor to have interacted with</div>
                  </div>
                </Col>
              </Row>
              <div className="rule-area p-2 mb-0">
                <Row className="g-0 my-2">
                  <Col className="py-0">
                    <p className="mb-0 fs-5">Metric rules</p>
                    <div className="input-feedback">Create rules to filter the sessions by field returns or time spent. You can apply the rule to the whole session or an individual field.</div>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col className="py-0">
                    <MetricsTable
                      mixpanel={mixpanel}
                      availableTrackedFields={availableTrackedFields}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default SessionsQueryNav;
