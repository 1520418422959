import DOMPurify from 'dompurify';
import { decodeAndSantize } from '../../../utils';
import { useContext, useRef } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { config } from '../../helpers/sanitizer';
import EditInlineIcon from '../EditInlineIcon';
import { useShowEditIcon } from '../../hooks';

const HintWithLink = ({ text, id, display, stepIndex, questionIndex }: { text: string | null, id: string, display?: string,
  stepIndex: number, questionIndex: number }) => {
  const { inEdit, handleSaveInlineEditItem, handleRemoveMessages } = useContext(SessionContext) as SessionContextType;
  const [showEditIcon, setShowEditIcon] = useShowEditIcon({type: 'question', stepIndex, questionIndex});
  const editableContentRef = useRef<HTMLParagraphElement>(null);

  // TODO: add a chart count limit messge - similar to that shown in Configure when surpass the limit
  const maxChars = 255;
  const santizedInsert = decodeAndSantize(text);
  const classList: string[] = ['question-hint', 'editable-inline'];
  if (display) classList.push(display);

  if (!text) return null;

  return inEdit ? <span className={`mark d-inline-flex align-items-start ${display || ''}`}><p className={`mb-0 ${classList.join(' ')}`}
    ref={editableContentRef}
    id={id}
    dangerouslySetInnerHTML={{ __html: santizedInsert }}
    contentEditable={inEdit}
    suppressContentEditableWarning={true}
    spellCheck="false"
    onClick={() => setShowEditIcon(false)}
    onBlur={(e) => {
      if (!inEdit) return;
      const santizedUpdate = DOMPurify.sanitize(e.target.innerHTML, config);
      handleRemoveMessages();
      const textValidLength = santizedUpdate.slice(0, maxChars);
      e.target.innerHTML = textValidLength;
      if (text !== santizedUpdate) handleSaveInlineEditItem({type: 'question-hint', item: encodeURI(textValidLength) || null, stepIndex, questionIndex});}}
    onKeyUp={() => {
      if (!inEdit) return;
      handleRemoveMessages();
    }}
    onMouseUp={() => {
      if (!inEdit) return;
      handleRemoveMessages();
    }}
  />
  {showEditIcon && <EditInlineIcon setShowEditIcon={setShowEditIcon} itemRef={editableContentRef} />}</span> :
    <p id={id} className={classList.join(' ')} dangerouslySetInnerHTML={{ __html: santizedInsert }} />;
};

export default HintWithLink;
