import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { VscChromeClose } from "react-icons/vsc";
import { formatFiltersForSelectDropDown } from '../utils';
import FiltersSelect from '../Components/Select/FiltersSelect';

import api from '../api';

import { usePrevious } from '../hooks';

const SegmentConfig = ({segment, draggableConfig, index, handleDeleteSegment, availableForms, formsError, formsLoading, time, handleSaveEditedSegment, saveComplete}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedForm, setEditedForm] = useState(null);
  const [editedFilters, setEditedFilters] = useState(null);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [attributesLoading, setFiltersLoading] = useState(false);
  const [attributesError, setFiltersError] = useState(false);

  const prevEditedForm = usePrevious(editedForm);

  const handleFiltersChange = useCallback((e) => setEditedFilters(e || []), []);

  useEffect(() => {
    if (isEditing && ((!editedForm && segment.form && segment.form.uuid) || (editedForm && editedForm.uuid && (prevEditedForm === null || prevEditedForm.uuid !== editedForm.uuid)))) {
      const loadFilters = async () => {
        try {
          setFiltersLoading(true);
          setFiltersError(null);

          const { data: { attributes } } = await api.get(`/data/sessions/attributes`, {
            params: {
              formUuid: editedForm ? editedForm.uuid : segment.form.uuid,
              time: {
                start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              },
            },
          });
          setFiltersLoading(false);
          setAvailableFilters(formatFiltersForSelectDropDown(attributes));
        } catch (e) {
          setFiltersLoading(false);
          setFiltersError('Error when fetching attributes');
        }
      };
      loadFilters();
    }
  }, [isEditing, segment, editedForm, time?.start, time?.end, prevEditedForm]);

  useEffect(() => {
    if (saveComplete) {
      setEditedForm(null);
      setEditedFilters(null);
      setAvailableFilters([]);
    }
  }, [saveComplete]);

  const handleCancelEditing = () => {
    setIsEditing(false);
    setEditedForm(null);
    setEditedFilters(null);
    setAvailableFilters([]);
  };

  const handleSave = () => {
    handleSaveEditedSegment({index, form: editedForm ? editedForm : segment.form, filters: editedFilters ? editedFilters : segment.filters});
    setIsEditing(false);
  };

  const formLabel = editedForm && editedForm.label ? editedForm.label : segment.form?.label;
  const filters = editedFilters ? editedFilters : segment.filters;

  return (
    <div className="segment-config" data-testid={`segment-${index}-config`}>
      <div className="edit-icons-area">
        {!isEditing ? <>
          <span {...draggableConfig.dragHandleProps} className="drag-icon"><i className="fa fa-arrows-h fa-lg"/></span>
          <div>
            <span className="segment-icon" onClick={() => setIsEditing(true)} data-testid={`edit-segment-${index}`}><i className="fa fa-pencil fa-lg editing"/></span>
            <span className="segment-icon" onClick={() => handleDeleteSegment({index})}><i className="fa fa-trash fa-lg editing"/></span>
          </div> </> : <>
          <span {...draggableConfig.dragHandleProps}></span>
          <VscChromeClose size="20px" onClick={handleCancelEditing} className="segment-icon editing" title="Close"/>
        </>
        }
      </div>
      <div className="config-area">
        {!isEditing &&
        <div className="set-config text-center">
          <h5>Form</h5>
          <p className="formName">{formLabel}</p>
          <div>
            <h5 className="segmentConfigTitle">Filters</h5>
            <ul className="segmentConfigFilterList">
              {filters.length ? filters.map((filter, i) => {
                return (<li className="segmentConfigFilterItem" key={`filter-${i}`}>
                  <div className="attribute-key-value-label">{`${filter.key}: ${filter.label ? filter.label : filter.value}`}</div>
                </li>);}) : 'none'}
            </ul>
          </div>
        </div>}

        {isEditing && <>
          <div className="edit-segment">
            <div className="edit-segment-form">
              <form>
                <label className="select-label">Form:</label>
                <Select
                  id={`segment-${index}-forms`}
                  styles={{
                    control: (styles, state) => ({...styles,
                      border: '1px solid #EBEDF2',
                    }),
                    option: (styles, state) => ({...styles,
                      color: '#3f4047',
                      backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5Ec" : null,
                      '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                    }),
                    menu: (styles, state) => ({...styles,
                      marginTop: '1px',
                      borderRadius: '4px',
                      border: '1px solid #EBEDF2',
                      boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                    }),
                    dropdownIndicator: (styles, state) => ({...styles,
                      cursor: 'pointer',
                      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                      transition: 'transform .5s ease',
                    }),
                  }}
                  options={availableForms.length > 0 ? availableForms : formsError ? [] : formsLoading ?
                    [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}] : []}
                  isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
                  onChange={(form) => setEditedForm(form)}
                  placeholder="Select a form..."
                  value={(editedForm && editedForm.label) ? editedForm : {label: segment.form?.label, value: segment.form?.uuid}}
                />
                <label className="select-label"> Filters:</label>
                <div className="edit-segment-filters">
                  <FiltersSelect
                    id={`edit-segment-filters-${index}`}
                    form={editedForm || segment.form}
                    selectedFilters={editedFilters ? editedFilters : segment.filters.length ? segment.filters.map((f) => ({label: f.value, key: f.key, value: `${f.key}:${f.value}`})) : []}
                    filtersLoading={attributesLoading}
                    availableFilters={availableFilters}
                    filtersLoadingError={attributesError}
                    handleFiltersChange={handleFiltersChange}
                    placeholder={'Select...'}
                    multiValueLabelFontSize={'0.9rem'}
                    valueContainerMaxHeight={'300px'}
                    isClearable={false} />
                </div>
              </form>
            </div>
          </div>
          <div className="text-center" id="edit-segment-buttons">
            <Button className="load ms-1 me-0" disabled={!editedForm && !editedFilters} onClick={handleSave} data-testid={`save-edited-segment-${index}`}>Save</Button>
          </div>
        </>}

      </div>
    </div>
  );
};

export default SegmentConfig;
