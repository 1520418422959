import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { usePrevious } from '../../hooks';
import AppContext from '../../AppContext';
import api from '../../api';
import {
  labelForField,
  compileQueryString,
} from '../../utils';

const SubmitSelect = ({mixpanel,
  submitFieldChangeFromSelect, setSubmitFieldChangeFromSelect,
  setSubmitField, submitField, prevSubmitField,
  handleResetData,
  triggerloadVisData,
  isData,
}) => {
  const {
    query,
  } = useContext(AppContext);
  const history = useHistory();
  const [trackedFields, setTrackedFields] = useState([]);
  const [trackedFieldsLoading, setTrackedFieldsLoading] = useState();
  const [trackedFieldsError, setTrackedFieldsError] = useState();

  const prevTrackedFieldsLoading = usePrevious(trackedFieldsLoading);

  const { form, time, submitFieldIdentifier, filters } = query || {};
  const prevQuery = usePrevious(query);

  // Once a new query has been set - proceed to fetch fields
  useEffect(() => {
    if (
      query?.form?.uuid && query?.time?.start && query?.time?.end && // Required
      (!prevQuery || (prevQuery && ( // Differences
        (prevQuery?.form?.uuid !== query?.form?.uuid) ||
        (JSON.stringify(prevQuery.filters) !== JSON.stringify(query.filters)) ||
        (!prevQuery.time || (prevQuery.time &&
          (JSON.stringify(prevQuery.time.start) !== JSON.stringify(query.time.start) ||
          (JSON.stringify(prevQuery.time.end) !== JSON.stringify(query.time.end)))))
      ))) &&
      !submitFieldChangeFromSelect // Don't reload fields if only the submit field has changed
    ) {
      const { form, time, filters } = query;
      (async () => {
        try {
          setTrackedFieldsLoading(true);
          setTrackedFieldsError(null);
          setTrackedFields([]);
          const {data: {fields}} = await api.get(`/data/sessions/fields`, {
            params: {
              formUuid: form.uuid,
              time: {
                start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              },
              filters: filters?.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
            },
          });
          setTrackedFields(
            fields
              .map(field => ({...field, label: labelForField(field), value: field.identifier}))
              .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
              .sort(({order: orderA}, {order: orderB}) => (orderA === null || orderA === '') - (orderB === null || orderB === '') || (orderA > orderB) - (orderA < orderB)));
        } catch (e) {
          setTrackedFieldsError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
        } finally {
          setTrackedFieldsLoading(false);
        }
      })();
    }
  }, [prevQuery, query, submitFieldChangeFromSelect]);

  // No fields, so reset the data
  useEffect(() => {
    if (prevTrackedFieldsLoading && !trackedFieldsLoading && !trackedFields.length) {
      if (handleResetData) handleResetData({trackedFieldsError});
    }
  }, [prevTrackedFieldsLoading, trackedFieldsLoading, trackedFields, trackedFieldsError, handleResetData]);

  // No fields, so reset the field
  useEffect(() => {
    if (prevTrackedFieldsLoading && !trackedFieldsLoading && !trackedFields.length) {
      setSubmitField(null);
    }
  }, [prevTrackedFieldsLoading, trackedFieldsLoading, trackedFields, setSubmitField]);

  // When form is changed, so reset the field
  useEffect(() => {
    if (
      query?.form?.uuid && // Required
        prevQuery && (prevQuery?.form?.uuid !== query?.form?.uuid)) {
      setSubmitField(null);
    }
  }, [setSubmitField, query, prevQuery]);

  // Set the field now we have the identifier
  useEffect(() => {
    if (submitFieldIdentifier && trackedFields.length &&
        (!submitField || (submitField.identifier !== submitFieldIdentifier))) {
      const fieldsByIdentifier = trackedFields.reduce((acc, field) => {
        acc[field.identifier] = field;
        return acc;
      }, {});
      const field = fieldsByIdentifier[submitFieldIdentifier];
      if (field) setSubmitField(field);
    }
  }, [trackedFields, submitField, submitFieldIdentifier, setSubmitField]);

  // Select a default submit field when fields have loaded
  useEffect(() => {
    if (prevTrackedFieldsLoading && !trackedFieldsLoading && trackedFields.length &&
      (!submitField || // Either there was none, or the submitFieldIdentifier from params wasn't able to set a valid field
        (submitField && !submitFieldIdentifier)) // The submitFieldIdentifier will have been unset
    ) {
      // Select the first submit field or the first field that has 'submit' in the label
      setSubmitField(trackedFields.find(({htmlType, label}) =>
        (htmlType && htmlType.toLowerCase() === 'submit') || (label && label.match(/submit/i))) ||
        trackedFields[0] // Use defualt if no match found
      );
    }
  }, [prevTrackedFieldsLoading, trackedFieldsLoading, trackedFields, submitField, submitFieldIdentifier, setSubmitField]);

  // When fields change (loadTrackedFields is called each time a new query is set), then load top fields after submit
  useEffect(() => {
    if (prevTrackedFieldsLoading && !trackedFieldsLoading &&
        submitField?.identifier && prevSubmitField?.identifier &&
        (submitField.identifier === prevSubmitField.identifier) &&
        !isData) {
      triggerloadVisData();
    }
  }, [prevTrackedFieldsLoading, trackedFieldsLoading, triggerloadVisData, submitField, prevSubmitField, isData]);

  return (
    <Row className="g-0 justify-content-end">
      <Col className="p-0 pt-2 col-auto d-flex align-items-center">
        <p className="mb-0 d-flex me-2">Select your 'Submit' button:</p>
        <Select
          id="submit-field-select"
          styles={{
            container: (styles, state) => ({...styles,
              marginTop: 'auto',
              marginBottom: 'auto',
              width: '100%',
            }),
            dropdownIndicator: (provided, state) => {
              return {
                ...provided,
                cursor: 'pointer',
                transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
                transition: 'transform .5s ease',
              };
            },
            input: (provided, state) => {
              return {
                ...provided,
                cursor: 'text',
              };
            },
            option: (styles, state) => ({...styles,
              color: '#3f4047',
              backgroundColor: state.selectProps.value && (state.selectProps.value.identifier === state.value) ? "#E2E5EC" : null,
              '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
            }),
            menu: (styles, state) => ({...styles,
              marginTop: '1px',
              borderRadius: '4px',
              boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
              zIndex: '5',
            }),
          }}
          options={trackedFields}
          onChange={(field) => {
            mixpanel.track('Selected Submit Field', { page: 'Insights' });
            setSubmitFieldChangeFromSelect(true);
            history.push(compileQueryString({
              form,
              time,
              submitFieldIdentifier: field.identifier,
              filters,
            }));
          }}
          placeholder="Select a field..."
          value={submitField ? {...submitField, value: submitField.identifier} : null}
        />
      </Col>
    </Row>
  );
};

export default SubmitSelect;
