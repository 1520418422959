import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { useContext, useEffect, useRef, useCallback } from 'react';
import Hint from '../question/HintWithLink';
import Legend from '../question/Legend';
import { DateInterface } from './interfaces/DateInterface';
import { FormDataHash } from '../../types/FormDataHash';
import InputSuccessWrapper from '../InputSuccessWrapper';
import { useInputIsValid } from '../../hooks';
import Year from './Year';
import Month from './Month';
import Day from './Day';

const Date = ({ invalidFeedback, question, stepIndex, questionIndex }: {question: DateInterface, invalidFeedback: JSX.Element|JSX.Element[],
  stepIndex: number, questionIndex: number}) => {
  const { values, setValues, zuko, inEdit } = useContext(SessionContext) as SessionContextType;
  const { id, label, hint = null, required } = question;
  const dateValue = values[id];
  const dateValues = (dateValue || '--').toString().split('-');
  const formatOrder = (question?.format || 'YYYY-MM-DD').split('-');
  const yearValue = (dateValues && formatOrder?.length && formatOrder.includes('YYYY') && dateValues[formatOrder.indexOf('YYYY')]) || '';
  const monthValue = (dateValues && formatOrder?.length && formatOrder.includes('MM') && dateValues[formatOrder.indexOf('MM')]) || '';
  const dayValue = (dateValues && formatOrder?.length && formatOrder.includes('DD') && dateValues[formatOrder.indexOf('DD')]) || '';

  const yearRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);

  const [yearIsValid, setYearIsValid] = useInputIsValid({value: yearValue, inputRef: yearRef, required: required});
  const [monthIsValid, setMonthIsValid] = useInputIsValid({value: monthValue, inputRef: monthRef, required: required});
  const [dayIsValid, setDayIsValid] = useInputIsValid({value: dayValue, inputRef: dayRef, required: required});

  // TODO: validate whole date as a valid date. Do this when have the data saved in state

  const yearId = `${id}-year`;
  const monthId = `${id}-month`;
  const dayId = `${id}-day`;

  const onChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const dateValues = (values[id] || '--').toString().split('-');
    let year = dateValues[formatOrder.indexOf('YYYY')];
    let month = dateValues[formatOrder.indexOf('MM')];
    let day = dateValues[formatOrder.indexOf('DD')];
    const { value, id: targetId } = target;

    switch (targetId) {
    case yearId:
      year = value;
      setYearIsValid(null);
      if (!monthIsValid) setMonthIsValid(null);
      if (!dayIsValid) setDayIsValid(null);
      break;
    case monthId:
      month = value;
      setMonthIsValid(null);
      if (!yearIsValid) setYearIsValid(null);
      if (!dayIsValid) setDayIsValid(null);

      break;
    case dayId:
      day = value;
      setDayIsValid(null);
      if (!yearIsValid) setYearIsValid(null);
      if (!monthIsValid) setMonthIsValid(null);

      break;
    }
    setValues((prev: FormDataHash<string>) => ({ ...(prev || {}), [id]: formatOrder.map(format => {
      switch (format) {
      case 'YYYY':
        return year;
      case 'MM':
        return month;
      case 'DD':
        return day;
      default:
        return null;
      }
    }).join('-')}));
  }, [dayId, monthId, yearId, dayIsValid, monthIsValid, yearIsValid, id, setDayIsValid, setMonthIsValid, setYearIsValid, values, setValues,
    formatOrder]);

  useEffect(() => {
    if (yearIsValid === true || yearIsValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${yearIsValid ? 'valid' : 'invalid'}: ${yearId}`);
    }
  }, [yearIsValid, id, zuko, yearId]);

  useEffect(() => {
    if (monthIsValid === true || monthIsValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${monthIsValid ? 'valid' : 'invalid'}: ${monthId}`);
    }
  }, [monthId, monthIsValid, id, zuko]);

  useEffect(() => {
    if (dayIsValid === true || dayIsValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${dayIsValid ? 'valid' : 'invalid'}: ${dayId}`);
    }
  }, [dayId, dayIsValid, id, zuko]);

  return <fieldset className="date-fieldset" aria-describedby={`${id}-hint`}>
    <Legend label={label} required={required} stepIndex={stepIndex} questionIndex={questionIndex} />
    <Hint id={`${id}-hint`} text={hint} stepIndex={stepIndex} questionIndex={questionIndex} />
    {invalidFeedback}
    <InputSuccessWrapper label={label}>
      <div className={`date-fields ${(
        (formatOrder.includes('YYYY') ? yearIsValid : true) &&
        (formatOrder.includes('MM') ? monthIsValid : true) &&
        (formatOrder.includes('DD') ? dayIsValid : true)) ? 'date-is-valid' : ''}`}>
        {formatOrder?.map(format => {
          if (format === 'YYYY') return <Year
            key={`year-${question?.id}`}
            questionId={question?.id}
            questionRequired={question?.required}
            yearRef={yearRef}
            yearId={yearId}
            yearIsValid={yearIsValid}
            onChange={onChange}
            yearValue={yearValue}
            setYearIsValid={setYearIsValid}
            inEdit={inEdit}
          />;
          if (format === 'MM') return <Month
            key={`month-${question?.id}`}
            questionId={question?.id}
            questionRequired={question?.required}
            monthRef={monthRef}
            monthId={monthId}
            monthIsValid={monthIsValid}
            onChange={onChange}
            monthValue={monthValue}
            setMonthIsValid={setMonthIsValid}
            inEdit={inEdit}
          />;
          if (format === 'DD') return <Day
            key={`day-${question?.id}`}
            questionId={question?.id}
            questionRequired={question?.required}
            dayRef={dayRef}
            dayId={dayId}
            dayIsValid={dayIsValid}
            onChange={onChange}
            dayValue={dayValue}
            setDayIsValid={setDayIsValid}
            inEdit={inEdit}
          />;
          return null;
        }
        )}
      </div>
    </InputSuccessWrapper>
  </fieldset>;
};

export default Date;
