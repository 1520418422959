import Button from 'react-bootstrap/Button';
import './Gallery.scss';

const Gallery = ({
  formHasBeenCreated,
  handleCreateQuestion,
  handleCreateParagraphText,
  handleAddStepAttr,
  handleAddFormAttr,
  handleAddCompletionAttr,
  showSubmitted,
}) => {
  return (
    <div className="element-gallery scrollable-column">
      {showSubmitted && <>
        <h5>Completion</h5>
        <div className="element-grid">
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleAddCompletionAttr({attr: 'title'});
          }} disabled={!formHasBeenCreated}>Title</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleAddCompletionAttr({attr: 'message'});
          }} disabled={!formHasBeenCreated}>Message</Button>
        </div>
      </>}
      {!showSubmitted && <>
        <h5>Add Title</h5>
        <div className="element-grid">
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleAddFormAttr({attr: 'title'});
          }}>Form Title</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleAddStepAttr({attr: 'title'});
          }} disabled={!formHasBeenCreated}>Step Title</Button>
        </div>
        <h5>Add Form Field</h5>
        <div className="element-grid">
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'text',
            });
          }}>Short Text</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'textarea',
            });
          }}>Long Text</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'number',
            });
          }}>Number</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'checkbox',
            });
          }}>Single Checkbox</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'checkboxGroup',
            });
          }}>Multiple Checkboxes</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'radioButtonGroup',
            });
          }}>Radio Buttons</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'select',
            });
          }}>Dropdown</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'email',
            });
          }}>Email</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'telephoneNumber',
            });
          }}>Telephone Number</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'address',
            });
          }}>Address</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'countrySelector',
            });
          }}>Country</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateQuestion({
              componentType: 'date',
            });
          }}>Date</Button>
        </div>
        <h5>Add General Text</h5>
        <div className="element-grid">
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleAddFormAttr({attr: 'description'});
          }}>Form Description</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleAddStepAttr({attr: 'description'});
          }} disabled={!formHasBeenCreated}>Step Description</Button>
          <Button variant="outline-secondary" className="m-0 p-1 w-100 element-item d-flex align-items-center justify-content-center" onClick={() => {
            handleCreateParagraphText();
          }} disabled={!formHasBeenCreated}>Paragraph</Button>
        </div>
      </>}
    </div>
  );
};

export default Gallery;
