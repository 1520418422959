// Only provide success validation if the input is required, or has a value
export const isValidWithValue = ({target, required}: {target:  HTMLInputElement | HTMLTextAreaElement, required: boolean}) => {
  if (required) {
    return target.checkValidity();
  } else {
    return target.checkValidity() && target.value.length > 0;
  }
};

// From https://gist.github.com/codeguy/6684588?permalink_comment_id=3426313#gistcomment-3426313
export const slugify = (str: string) => str.toString()
  .normalize('NFD')                 // split an accented letter in the base letter and the acent
  .replace(/[\u0300-\u036f]/g, '')  // remove all previously split accents
  .toLowerCase()
  .trim()
  .replace(/[^a-z0-9-_ ]/g, '')       // remove all chars not letters, numbers and spaces (to be replaced)
  .replace(/\s+/g, '-');

export const keyToUse = ({key, title, questionKeys, questionIndex, stepIndex}:
  { key: string, title: string, questionKeys: Array<string>, questionIndex: number, stepIndex: number}) => {
  let questionKeyToUse = key || title;
  const keyIsDuplicated = questionKeys.filter(k => k === questionKeyToUse).length > 1;
  if (keyIsDuplicated) questionKeyToUse = `${questionKeyToUse}_${stepIndex}_${questionIndex}`;
  return questionKeyToUse;
};