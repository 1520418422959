import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ZukoLargeLogo from '../images/ZukoLargeLogo.png';

const PrintPageHeader = ({pageTitle, orgName, formLabel, formUrl, startTime, endTime, timeZone, formFilters, searchParams}) => {
  return (
    <div className="print-only page-header">
      <Row className="g-0 d-flex justify-content-between">
        <div className="d-flex flex-column">
          <Row className="g-0 d-flex justify-content-start">
            <Col className="col-auto">
              <img id="Zuko-large-logo" src={ZukoLargeLogo} alt="Zuko-logo" width="auto" height="50px"></img>
            </Col>
            <Col className="col-auto d-flex align-items-center">
              <h1 className="page-title">{pageTitle}</h1>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex align-items-center">
            <a className="btn btn-primary" href={window.location.origin + window.location.pathname + searchParams}>View in app</a>
          </div>
        </div>
      </Row>
      <Row className="g-0">
        <Col>
          {orgName && formLabel &&
            <h1 id="form-title" className="mb-0">
              {`${orgName} | ${formLabel} | `}
              <a href={formUrl}>{formUrl}</a>
            </h1>}
        </Col>
      </Row>
      <Row className="g-0 report-query">
        <Col className="col-auto d-flex">
          {startTime && endTime && timeZone && <>
            <strong>Date range:</strong> <span className="ps-2 datepicker-dates">{startTime.tz(timeZone).format('ll HH:mm')} - {endTime.tz(timeZone).format('ll HH:mm')}</span><span className="ps-2"><small>{timeZone}</small></span>
          </>
          }
        </Col>
        <Col className="col-auto d-flex">
          {formFilters && formFilters.length > 0 && <>
            <strong>Filters:</strong>
            {formFilters.map(a => (
              <p key={a.value} className="ps-2 mb-0"><span className="selected-filters">{a.key}: {a.label}</span></p>
            ))}
          </>}
        </Col>
      </Row>
    </div>
  );
};

export default PrintPageHeader;
