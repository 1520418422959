import { createContext } from 'react';
import { StepInterface } from '../interfaces/zuko_form/StepInterface';
import { StyleInterface } from '../interfaces/zuko_form/StyleInterface';

export interface FormContextType {
  uuid: string;
  builderSteps: StepInterface[];
  style?: StyleInterface;
  title: string;
  description?: string | null;
  submit?: {
    value: string,
  },
  completion?: {title: string, message: string},
  organisation: {
    builderSubscriptionStatus: string,
  }
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export default FormContext;
