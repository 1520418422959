import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';

import api from '../api';

import './RequestPasswordReset.scss';

const RequestPasswordReset = ({mixpanel}) => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState((location.state && location.state.email) ? location.state.email : '');

  const handleResetRequest = async (e) => {
    e.preventDefault();
    mixpanel.identify(email);
    mixpanel.track('Password Reset Requested', { page: 'Request Password Reset' });

    try {
      await api.post('/password-reset', {email});
      history.push('/login', { alert: { message: 'Please check your email for a link to reset your password.', type: 'success' } });
    } catch (e) {
      if ((e.response && (e.response.status === 500 || e.response.status === 400))) {
        history.push('/login', { alert: { message: 'Something went wrong requesting a password reset email. Please try again.', type: 'danger' } });
      } else {
        // Still indicate to the user to check their email for all other error responses
        history.push('/login', { alert: { message: 'Please check your email for a link to reset your password.', type: 'success' } });
      }
    }
  };

  return (
    <Container fluid className="page login-background" id="request-password-reset-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Password Reset</title>
      </Helmet>
      <Col className="center-column justify-content-md-center">
        <Row className="g-0 login-logo justify-content-center">
          <div className="logo-wrapper">
            <div className="zuko-full-colour-logo" role="img" alt="Zuko logo"></div>
          </div>
        </Row>
        <Row className="g-0 login-title mb-3 justify-content-center">
          <h1>Reset Your Password</h1>
        </Row>
        <Row className="g-0 justify-content-center" id="login-container">
          <Col className="col-auto p-0" id="login-container-col">
            <Form onSubmit={handleResetRequest} className="login-form">

              <FormGroup className="form-group" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email"  autoComplete="email" placeholder="you@example.com" value={email} onChange={({target: {value}}) => setEmail(value)} required/>
              </FormGroup>

              <Row className="g-0 login-btn justify-content-center mt-3">
                <Button variant="primary" className="submit-btn" type="submit">Send Email</Button>
              </Row>

              <Row className="g-0 justify-content-center login-link mt-4">
                <p className="mb-0 text-end">Return to <Link to="/login">login</Link></p>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default RequestPasswordReset;
