import React, { useState, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { MdOutlineChatBubbleOutline } from "react-icons/md";
import { VscCheck, VscWarning } from "react-icons/vsc";
import { FaSpinner } from "react-icons/fa";
import api from '../api';
import AppContext from '../AppContext';

const FeedbackPopover = ({outsideOverlayRef, mixpanel, page, org: orgFromPage, messageContent, classList = []}) => {
  const { currentUser } = useContext(AppContext);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState();
  const [feedback, setFeedback] = useState('');
  const [feedbackLoading, setFeedbackLoading] = useState();
  const [isOpen, setIsOpen] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!feedback) {
      setInputErrorMsg('Please enter your feedback.');
      return;
    }

    try {
      setShowSuccessMsg(null);
      setShowErrorMsg(null);
      setFeedbackLoading(true);

      const org = orgFromPage || currentUser.organisations?.[0];

      await api.post(`/users/${currentUser.uuid}/feedback`, {
        orgUuid: org?.uuid,
        feedback,
        url: window.location.href,
      });

      setFeedback(null);
      setShowSuccessMsg(true);
      mixpanel.track('Sent Feedback', { page, ...org && {
        'Organisation Name': org.name,
        'Organisation Uuid': org.uuid,
        'Organisation Contract Type': org.contractType,
      }}
      );
    } catch (e) {
      setShowErrorMsg(true);
    } finally {
      setFeedbackLoading(false);
    }
  };

  return (
    <OverlayTrigger placement="bottom-end" trigger={'click'} rootClose
      onExited={() =>  {
        setShowSuccessMsg(false);
        setShowErrorMsg(false);
        setInputErrorMsg(null);
        setIsOpen(false);
      }}
      onEntered={() => {
        setIsOpen(true);
      }}
      overlay={
        <Popover id="feedback-container" className={classList.join(' ')} data-testid="feedback-container">
          <Popover.Body>
            <Row className="g-0 header-row"><h3>Feedback</h3></Row>
            {feedbackLoading &&
              <div className="loading-area d-flex justify-content-center align-items-center">
                <FaSpinner size="18px" className="spinning-icon" title="Sending feedback..."/>
              </div>}
            {!showSuccessMsg && !showErrorMsg && !feedbackLoading &&
              <Form noValidate onSubmit={(e) => handleSubmit(e)} id="feedback-form" className="mt-1">
                <p>What do you think about {messageContent || 'this page'}? Let us know what else you'd like to see.</p>
                <FormGroup controlId="feedback" className={`form-group mb-0 ${inputErrorMsg ? 'invalid' : ''}`}>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter your feedback..."
                    value={feedback || ''}
                    onChange={({target: {value}}) => {
                      setFeedback(value);
                      if (inputErrorMsg) setInputErrorMsg(null);
                    }}
                    aria-label="feedback"
                    maxLength={1000}
                    required
                  />
                  <Form.Control.Feedback type="invalid" className="input-feedback">{inputErrorMsg}</Form.Control.Feedback>
                  {(!inputErrorMsg && feedback?.length > 990) && <Form.Control.Feedback className="input-feedback feedback-info">
                    {feedback?.length}/1000 characters. <br></br>Please share your extended feedback with <a href="mailto:support@zuko.io">Zuko support</a>.
                  </Form.Control.Feedback>}
                </FormGroup>
                <Row className="g-0 justify-content-between button-row">
                  <Button variant="outline-secondary" className="cancel mx-1 btn-less-padding" onClick={() => outsideOverlayRef.current.click()}>Cancel</Button>
                  <Button type="submit" className="mx-1 btn-less-padding">Send Feedback</Button>
                </Row>
              </Form>}
            {showSuccessMsg &&
              <Alert variant="outline-success">
                <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                <p className="alert-text m-0">Thank you for feedback.</p>
              </Alert>}
            {showErrorMsg &&
              <Alert variant="outline-danger">
                <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                <p className="alert-text m-0">Sorry your feedback was not sent. Please contact <a href="mailto:support@zuko.io">support</a>.</p>
              </Alert>}
          </Popover.Body>
        </Popover>}>
      <button className={`unstyled-btn ${isOpen ? 'is-open' : null}`}><MdOutlineChatBubbleOutline size="16px" /> Send feedback</button>
    </OverlayTrigger>);
};

export default FeedbackPopover;