import React, { useContext, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ReactPaginate from 'react-paginate';
import moment from 'moment-timezone';
import { GrDocumentCsv } from 'react-icons/gr';
import { FaSpinner } from "react-icons/fa";

import AppContext from '../AppContext';
import NavBar from '../NavBar';
import AppAlerts from '../Components/AppAlerts';
import api from '../api';
import { usePrevious } from '../hooks';

import './Accounts.scss';

import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const maxPerPage = 8;

const Accounts = ({mixpanel}) => {
  const { currentUser, apiBaseUrl } = useContext(AppContext);
  const history = useHistory();

  const [orgsLoading, setOrgsLoading] = useState(true);
  const [orgsError, setOrgsError] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersError, setUsersError] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState([]);
  const [pageUsers, setPageUsers] = useState([]);
  const [currentUsersPage, setCurrentUsersPage] = useState(0);
  const [forceUsersPage, setForceUsersPage] = useState();
  const [usersFilterInput, setUsersFilterInput] = useState();
  const [usersFilter, setUsersFilter] = useState();
  const [orgsToDisplay, setOrgsToDisplay] = useState([]);
  const [pageOrgs, setPageOrgs] = useState([]);
  const [currentOrgsPage, setCurrentOrgsPage] = useState(0);
  const [forceOrgsPage, setForceOrgsPage] = useState();
  const [orgsFilterInput, setOrgsFilterInput] = useState();
  const [orgsFilter, setOrgsFilter] = useState();
  const [csvRequested, setCsvRequested] = useState();
  const [firstUserUuidInOrg, setFirstUserUuidInOrg] = useState();
  const [agencies, setAgencies] = useState();
  const [agencyClients, setAgencyClients] = useState();
  const [agenciesFilterInput, setAgenciesFilterInput] = useState();
  const [agenciesFilter, setAgenciesFilter] = useState();
  const [agenciesToDisplay, setAgenciesToDisplay] = useState([]);
  const [pageAgencies, setPageAgencies] = useState([]);
  const [currentAgenciesPage, setCurrentAgenciesPage] = useState(0);
  const [forceAgenciesPage, setForceAgenciesPage] = useState();
  const [agencyClientsFilterInput, setAgencyClientsFilterInput] = useState();
  const [agencyClientsFilter, setAgencyClientsFilter] = useState();
  const [agencyClientsToDisplay, setAgencyClientsToDisplay] = useState([]);
  const [pageAgencyClients, setPageAgencyClients] = useState([]);
  const [currentAgencyClientsPage, setCurrentAgencyClientsPage] = useState(0);
  const [forceAgencyClientsPage, setForceAgencyClientsPage] = useState();
  const [builderOrgsUuids, setBuilderOrgsUuids] = useState();

  const prevUsersToDisplay = usePrevious(usersToDisplay);
  const prevOrgsToDisplay = usePrevious(orgsToDisplay);
  const prevAgenciesToDisplay = usePrevious(agenciesToDisplay);
  const prevAgencyClientsToDisplay = usePrevious(agencyClientsToDisplay);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    mixpanel.track('Page View', { page: 'Accounts' });
  }, [mixpanel, currentUser.email]);

  // Fetch orgs
  useEffect(() => {
    if (currentUser.accountManager) (async () => {
      try {
        const { data: { organisations } } = await api.get(`/organisations`);
        const sortedOrgs = sortOn({arr: organisations, attr: 'createdAt', direction: 'desc'});

        setOrgs(sortedOrgs.filter(o => !o.type));
        setAgencies(sortedOrgs.filter(o => o.type === 'Agency'));
        setAgencyClients(sortedOrgs.filter(o => o.type === 'AgencyClient'));
        setBuilderOrgsUuids(organisations.filter(o => o.signUpProduct === 'builder').map(o => o.uuid));
      } catch (e) {
        if (e.response && e.response.status === 403) {
          history.replace('/');
        } else {
          setOrgsError((e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading the organisations. Please try again.');
        }
      } finally {
        setOrgsLoading(false);
      }
    })();

  }, [history, currentUser.accountManager]);

  // Fetch users
  useEffect(() => {
    if (currentUser.accountManager) (async () => {
      try {
        const { data: { users } } = await api.get(`/users`);
        setUsers(sortOn({arr: users, attr: 'createdAt', direction: 'desc'}));
        setUsersLoading(false);
      } catch (e) {
        setUsersLoading(false);
        if (e.response && e.response.status === 403) {
          history.replace('/');
        } else {
          setUsersError((e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading the users. Please try again.');
        }
      }
    })();

  }, [history, currentUser.accountManager]);

  useEffect(() => {
    if (users.length) setFirstUserUuidInOrg(users.reduce((acc, user) => {
      for (const userOrg of user.organisations) {
        if (!acc[userOrg.uuid]) acc[userOrg.uuid] = user.uuid;
      }
      return acc;
    }, {}));
  },[users]);

  // Set all users to display, and update anytime they are filtered
  useEffect(() => {
    if (users.length) {
      const filteredUsers = usersFilter?.length && users.filter(({email, name, organisations}) => {
        const matchingOrgsByName = organisations.filter(o => o.name?.toLocaleLowerCase()?.includes(usersFilter?.toLocaleLowerCase()))?.length > 0;
        const matchingBuilderOrgs = usersFilter?.toLocaleLowerCase() === 'builder' && organisations?.filter(o => builderOrgsUuids?.includes(o.uuid))?.length > 0;
        return (usersFilter &&
          (email?.toLocaleLowerCase().includes(usersFilter?.toLocaleLowerCase()) ||
          name?.toLocaleLowerCase().includes(usersFilter?.toLocaleLowerCase()) ||
          matchingOrgsByName ||
          matchingBuilderOrgs
          ));
      });
      setUsersToDisplay(filteredUsers || users);
    } else {
      setUsersToDisplay([]);
    }
  }, [users, usersFilter, builderOrgsUuids]);

  // Set users per page
  useEffect(() => {
    // On first page load, set the users for the first page
    if (prevUsersToDisplay && !prevUsersToDisplay.length && usersToDisplay?.length) {
      setPageUsers(chunk(usersToDisplay, maxPerPage)[0]);
    }

    // When users are filtered, reset the users
    if (prevUsersToDisplay?.length > 0 && (prevUsersToDisplay.length !== usersToDisplay.length ||
        (prevUsersToDisplay.length === usersToDisplay.length &&
          !prevUsersToDisplay.every(({email}, i) => email === usersToDisplay[i].email)))) {
      const pages = chunk(usersToDisplay, maxPerPage);

      // If there are no more users left on the page, move to previous
      const selectedPage = (pages?.length && (pages.length - 1 < currentUsersPage)) ? pages.length - 1 : currentUsersPage;
      setPageUsers(pages[selectedPage]);
      if (currentUsersPage !== selectedPage) setCurrentUsersPage(selectedPage);
      if (!pages.length) {
        setForceUsersPage(null);
      } else {
        setForceUsersPage(selectedPage);
      }
    }

  }, [prevUsersToDisplay, usersToDisplay, currentUsersPage]);

  // Set all orgs to display, and update anytime they are filtered
  useEffect(() => {
    if (orgs.length) {
      const filteredOrgs = orgsFilter?.length && orgs.filter(({notes, name, signUpProduct, replayEnabled}) =>
        (orgsFilter && (
          notes?.toLocaleLowerCase().includes(orgsFilter?.toLocaleLowerCase()) ||
          name?.toLocaleLowerCase().includes(orgsFilter?.toLocaleLowerCase()) ||
          signUpProduct?.toLocaleLowerCase().includes(orgsFilter?.toLocaleLowerCase()) ||
          (orgsFilter?.toLocaleLowerCase() === 'enabled' && replayEnabled)
        )));
      setOrgsToDisplay(filteredOrgs || orgs);
    } else {
      setOrgsToDisplay([]);
    }
  }, [orgs, orgsFilter]);

  // Set orgs per page
  useEffect(() => {
    // On first page load, set the orgs for the first page
    if (prevOrgsToDisplay && !prevOrgsToDisplay.length && orgsToDisplay?.length) {
      setPageOrgs(chunk(orgsToDisplay, maxPerPage)[0]);
    }
    // When orgs are filtered, reset the orgs
    if (prevOrgsToDisplay?.length > 0 && (prevOrgsToDisplay.length !== orgsToDisplay.length ||
          (prevOrgsToDisplay.length === orgsToDisplay.length &&
            !prevOrgsToDisplay.every(({uuid}, i) => uuid === orgsToDisplay[i].uuid)))) {
      const pages = chunk(orgsToDisplay, maxPerPage);

      // If there are no more orgs left on the page, move to previous
      const selectedPage = (pages?.length && (pages.length - 1 < currentOrgsPage)) ? pages.length - 1 : currentOrgsPage;
      setPageOrgs(pages[selectedPage]);
      if (currentOrgsPage !== selectedPage) setCurrentOrgsPage(selectedPage);
      if (!pages.length) {
        setForceOrgsPage(null);
      } else {
        setForceOrgsPage(selectedPage);
      }
    }

  }, [prevOrgsToDisplay, orgsToDisplay, currentOrgsPage]);

  // Set all agencies to display, and update anytime they are filtered
  useEffect(() => {
    if (agencies?.length) {
      const filteredAgencies = agenciesFilter?.length && agencies.filter(({notes, name}) => (agenciesFilter && (notes?.toLocaleLowerCase().includes(agenciesFilter?.toLocaleLowerCase()) || name?.toLocaleLowerCase().includes(agenciesFilter?.toLocaleLowerCase()))));
      setAgenciesToDisplay(filteredAgencies || agencies);
    } else {
      setAgenciesToDisplay([]);
    }
  }, [agencies, agenciesFilter]);

  // Set agencies per page
  useEffect(() => {
    // On first page load, set the agencies for the first page
    if (prevAgenciesToDisplay && !prevAgenciesToDisplay.length && agenciesToDisplay?.length) {
      setPageAgencies(chunk(agenciesToDisplay, maxPerPage)[0]);
    }
    // When orgs are filtered, reset the agencies
    if (prevAgenciesToDisplay?.length > 0 && (prevAgenciesToDisplay.length !== agenciesToDisplay.length ||
          (prevAgenciesToDisplay.length === agenciesToDisplay.length &&
            !prevAgenciesToDisplay.every(({uuid}, i) => uuid === agenciesToDisplay[i].uuid)))) {
      const pages = chunk(agenciesToDisplay, maxPerPage);

      // If there are no more agencies left on the page, move to previous
      const selectedPage = (pages?.length && (pages.length - 1 < currentAgenciesPage)) ? pages.length - 1 : currentAgenciesPage;
      setPageAgencies(pages[selectedPage]);
      if (currentAgenciesPage !== selectedPage) setCurrentAgenciesPage(selectedPage);
      if (!pages.length) {
        setForceAgenciesPage(null);
      } else {
        setForceAgenciesPage(selectedPage);
      }
    }
  }, [prevAgenciesToDisplay, agenciesToDisplay, currentAgenciesPage]);

  // Set all agencyClients to display, and update anytime they are filtered
  useEffect(() => {
    if (agencyClients?.length) {
      const filteredAgencyClients = agencyClientsFilter?.length && agencyClients.filter(({notes, name}) => (agencyClientsFilter && (notes?.toLocaleLowerCase().includes(agencyClientsFilter?.toLocaleLowerCase()) || name?.toLocaleLowerCase().includes(agencyClientsFilter?.toLocaleLowerCase()))));
      setAgencyClientsToDisplay(filteredAgencyClients || agencyClients);
    } else {
      setAgencyClientsToDisplay([]);
    }
  }, [agencyClients, agencyClientsFilter]);

  // Set agencyClients per page
  useEffect(() => {
    // On first page load, set the agencyClients for the first page
    if (prevAgencyClientsToDisplay && !prevAgencyClientsToDisplay.length && agencyClientsToDisplay?.length) {
      setPageAgencyClients(chunk(agencyClientsToDisplay, maxPerPage)[0]);
    }
    // When orgs are filtered, reset the agencyClients
    if (prevAgencyClientsToDisplay?.length > 0 && (prevAgencyClientsToDisplay.length !== agencyClientsToDisplay.length ||
          (prevAgencyClientsToDisplay.length === agencyClientsToDisplay.length &&
            !prevAgencyClientsToDisplay.every(({uuid}, i) => uuid === agencyClientsToDisplay[i].uuid)))) {
      const pages = chunk(agencyClientsToDisplay, maxPerPage);

      // If there are no more agencyClients left on the page, move to previous
      const selectedPage = (pages?.length && (pages.length - 1 < currentAgencyClientsPage)) ? pages.length - 1 : currentAgencyClientsPage;
      setPageAgencyClients(pages[selectedPage]);
      if (currentAgencyClientsPage !== selectedPage) setCurrentAgencyClientsPage(selectedPage);
      if (!pages.length) {
        setForceAgencyClientsPage(null);
      } else {
        setForceAgencyClientsPage(selectedPage);
      }
    }
  }, [prevAgencyClientsToDisplay, agencyClientsToDisplay, currentAgencyClientsPage]);

  const chunk = (arr, size) => {
    const output = [];
    for (let i = 0; i < arr.length; i += size) output.push(arr.slice(i, i + size));
    return output;
  };

  const sortOn = ({arr, attr, direction}) => {
    return arr.sort((a, b) => {
      const aAttr = a[attr] ? a[attr].toLowerCase() : null;
      const bAttr = b[attr] ? b[attr].toLowerCase() : null;

      return direction === 'desc' ? (aAttr < bAttr) ? 1 : ((bAttr < aAttr) ? -1 : 0) :
        aAttr === null ? 1 : bAttr === null ? -1 : (aAttr > bAttr) ? 1 : ((bAttr > aAttr) ? -1 : 0);
    });
  };

  const handleUsersPageChange = (e) => {
    const selectedPageIndex = e.selected;
    const chunks = chunk(usersToDisplay, maxPerPage);
    setCurrentUsersPage(selectedPageIndex);
    setPageUsers(chunks[selectedPageIndex]);
  };

  const handleOrgsPageChange = (e) => {
    const selectedPageIndex = e.selected;
    const chunks = chunk(orgsToDisplay, maxPerPage);
    setCurrentOrgsPage(selectedPageIndex);
    setPageOrgs(chunks[selectedPageIndex]);
  };

  const handleAgenciesPageChange = (e) => {
    const selectedPageIndex = e.selected;
    const chunks = chunk(agenciesToDisplay, maxPerPage);
    setCurrentAgenciesPage(selectedPageIndex);
    setPageAgencies(chunks[selectedPageIndex]);
  };

  const handleAgencyClientsPageChange = (e) => {
    const selectedPageIndex = e.selected;
    const chunks = chunk(agencyClientsToDisplay, maxPerPage);
    setCurrentAgencyClientsPage(selectedPageIndex);
    setPageAgencyClients(chunks[selectedPageIndex]);
  };

  // Artificially disable the download spinner
  useEffect(() => {
    if (csvRequested) setTimeout(() => {setCsvRequested(null);}, 8000);
  }, [csvRequested]);


  return (
    <Container fluid className="page" id="accounts-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Accounts</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <AppAlerts />
          <Row className="title-row g-0 justify-content-between">
            <Col className="p-0">
              <h1 id="accounts-title">Accounts</h1>
            </Col>
          </Row>
          <Col className="p-0">
            <Card>
              <Card.Body className="pb-0">
                <Row className="g-0 card-title-row">
                  <Col className="p-0">
                    <Card.Title as="h3">Users</Card.Title>
                  </Col>
                  <Col className="p-0 text-end card-tooltip">
                    <span className="icon-overlay-container">
                      {!csvRequested ?
                        <a download href={`${apiBaseUrl}/export/accounts`} onClick={() => setCsvRequested(true)} data-testid="export-users"><GrDocumentCsv size="20px" title="Download users as CSV" className="csv-export-icon data-loaded"/></a> :
                        csvRequested ? <>
                          <GrDocumentCsv size="20px" title="Download users as CSV" className="csv-export-icon generating"/>
                          <FaSpinner size="18px" className="spinning-icon" title="Generating CSV..."/> </> : null
                      }
                    </span>
                  </Col>
                </Row>
                <div className="card-vis">
                  {usersError && <p>{usersError}</p>}
                  {!usersError && <>
                    <Row className="g-0 justify-content-end table-search-row">
                      <Form className="col-auto d-inline-flex align-items-center">
                        <p className="mb-0 me-2">Filter users on their: <i>name</i>, <i>email</i>, <i>Organisation name</i>. Or for product, enter 'builder'</p>
                        <input
                          id="user-search"
                          value={usersFilterInput || ''}
                          onChange={(({target: {value}}) => {
                            if (value) {
                              setUsersFilterInput(value);
                            } else {
                              setUsersFilterInput('');
                              setUsersFilter('');
                            }
                          })}
                        />
                        <Button type="submit" disabled={!usersFilterInput?.length} onClick={(e) => {e.preventDefault(); setUsersFilter(usersFilterInput);}}>Search</Button>
                      </Form>
                    </Row>
                    <Row className="g-0 card-table">
                      <Table responsive borderless hover data-testid="users-table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Created</th>
                            <th>Dashboard</th>
                            <th>Profile</th>
                            <th>Usage</th>
                            <th>Organisation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {usersLoading && <tr><td><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Loading...</td></tr>}
                          {pageUsers?.map(({uuid, name, email, createdAt, organisations = []}) => {
                            return (
                              <tr key={`user-${uuid}`}>
                                <td>{name}</td>
                                <td>{email}</td>
                                <td>{createdAt && moment(createdAt).format('D MMM YYYY')}</td>
                                <td className="link-col"><Link to={`/dashboard/${uuid}`}>Dashboard</Link></td>
                                <td className="link-col"><Link to={`/profile?userUuid=${uuid}`}>Profile</Link></td>
                                <td className="link-col"><Link to={`/users/${uuid}/organisations_usage`}>Usage</Link></td>
                                <td className="link-col">
                                  {organisations.map(({uuid, name}) => <div key={`user-org-${uuid}`}>
                                    <Link to={`/organisations/${uuid}/edit`}>{name}</Link>{builderOrgsUuids?.includes(uuid) && ` (builder)`}
                                  </div>)}
                                </td>
                              </tr>);
                          })}
                        </tbody>
                      </Table>
                    </Row>
                    <ReactPaginate
                      pageCount={Math.ceil(usersToDisplay.length / maxPerPage)}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={0}
                      onPageChange={handleUsersPageChange}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      activeClassName={'active'}
                      forcePage={forceUsersPage ?? -1}
                    />
                  </>}
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body className="pb-0">
                <Row className="g-0 card-title-row justify-content-between mb-2">
                  <Col className="p-0">
                    <Card.Title as="h3">Organisations</Card.Title>
                  </Col>
                  <Col className="p-0 text-end m-auto">
                    <Link to={'/organisations/new'}>
                      <Button className="pink-pill-btn">Create Organisation</Button>
                    </Link>
                  </Col>
                </Row>
                <div className="card-vis">
                  {orgsError && <p>{orgsError}</p>}
                  {!orgsError && <>
                    <Row className="g-0 justify-content-end table-search-row">
                      <Form className="col-auto d-inline-flex align-items-center">
                        <p className="mb-0 me-2">Filter orgs on their: <i>name</i>, <i>notes</i>, <i>product</i>. For orgs with replay, enter 'enabled'</p>
                        <input
                          id="orgs-search"
                          value={orgsFilterInput || ''}
                          onChange={(({target: {value}}) => {
                            if (value) {
                              setOrgsFilterInput(value);
                            } else {
                              setOrgsFilterInput('');
                              setOrgsFilter('');
                            }
                          })}
                        />
                        <Button type="submit" disabled={!orgsFilterInput?.length} onClick={(e) => {e.preventDefault(); setOrgsFilter(orgsFilterInput);}}>Search</Button>
                      </Form>
                    </Row>
                    <Row className="g-0 card-table">
                      <Table responsive borderless hover data-testid="org-table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Notes</th>
                            <th>Created</th>
                            <th>Product</th>
                            <th>Replay</th>
                            <th>Usage</th>
                            <th>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orgsLoading && <tr><td><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Loading...</td></tr>}
                          {pageOrgs?.map(({uuid, name, notes, createdAt, signUpProduct, replayEnabled}) => {
                            return (
                              <tr key={`org-${uuid}`}>
                                <td>{name}</td>
                                <td>{notes}</td>
                                <td>{createdAt && moment(createdAt).format('D MMM YYYY')}</td>
                                <td>{signUpProduct}</td>
                                <td>{replayEnabled ? 'enabled' : null}</td>
                                <td className="link-col">{firstUserUuidInOrg?.[uuid] && <Link to={`/users/${firstUserUuidInOrg?.[uuid]}/organisations_usage`}>Usage</Link>}</td>
                                <td className="link-col"><Link to={`/organisations/${uuid}/edit`}>Edit</Link></td>
                              </tr>);
                          })}
                        </tbody>
                      </Table>
                    </Row>
                    <ReactPaginate
                      pageCount={Math.ceil(orgsToDisplay.length / maxPerPage)}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={0}
                      onPageChange={handleOrgsPageChange}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      activeClassName={'active'}
                      forcePage={forceOrgsPage ?? -1}
                    />
                  </>}
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body className="pb-0">
                <Row className="g-0 card-title-row justify-content-between mb-2">
                  <Col className="p-0">
                    <Card.Title as="h3">Agencies</Card.Title>
                  </Col>
                  <Col className="p-0 text-end m-auto">
                    <Link to={'/agencies/new'}>
                      <Button className="pink-pill-btn">Create Agency</Button>
                    </Link>
                  </Col>
                </Row>
                <div className="card-vis">
                  {orgsError && <p>{orgsError}</p>}
                  {!orgsError && <>
                    <Row className="g-0 justify-content-end table-search-row">
                      <Form className="col-auto d-inline-flex">
                        <input
                          id="agencies-search"
                          value={agenciesFilterInput || ''}
                          onChange={(({target: {value}}) => {
                            if (value) {
                              setAgenciesFilterInput(value);
                            } else {
                              setAgenciesFilterInput('');
                              setAgenciesFilter('');
                            }
                          })}
                        />
                        <Button type="submit" disabled={!agenciesFilterInput?.length} onClick={(e) => {e.preventDefault(); setAgenciesFilter(agenciesFilterInput);}}>Search</Button>
                      </Form>
                    </Row>
                    <Row className="g-0 card-table">
                      <Table responsive borderless hover>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Notes</th>
                            <th>Created</th>
                            <th>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orgsLoading && <tr><td><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Loading...</td></tr>}
                          {pageAgencies?.map(({uuid, name, notes, createdAt}) => {
                            return (
                              <tr key={`org-${uuid}`}>
                                <td>{name}</td>
                                <td>{notes}</td>
                                <td>{createdAt && moment(createdAt).format('D MMM YYYY')}</td>
                                <td className="link-col"><Link to={`/organisations/${uuid}/edit`}>Edit</Link></td>
                              </tr>);
                          })}
                        </tbody>
                      </Table>
                    </Row>
                    <ReactPaginate
                      pageCount={Math.ceil(agenciesToDisplay.length / maxPerPage)}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={0}
                      onPageChange={handleAgenciesPageChange}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      activeClassName={'active'}
                      forcePage={forceAgenciesPage ?? -1}
                    />
                  </>}
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body className="pb-0">
                <Row className="g-0 card-title-row justify-content-between mb-2">
                  <Col className="p-0">
                    <Card.Title as="h3">Agency Clients</Card.Title>
                  </Col>
                </Row>
                <div className="card-vis">
                  {orgsError && <p>{orgsError}</p>}
                  {!orgsError && <>
                    <Row className="g-0 justify-content-end table-search-row">
                      <Form className="col-auto d-inline-flex">
                        <input
                          id="agency-clients-search"
                          value={agencyClientsFilterInput || ''}
                          onChange={(({target: {value}}) => {
                            if (value) {
                              setAgencyClientsFilterInput(value);
                            } else {
                              setAgencyClientsFilterInput('');
                              setAgencyClientsFilter('');
                            }
                          })}
                        />
                        <Button type="submit" disabled={!agencyClientsFilterInput?.length} onClick={(e) => {e.preventDefault(); setAgencyClientsFilter(agencyClientsFilterInput);}}>Search</Button>
                      </Form>
                    </Row>
                    <Row className="g-0 card-table">
                      <Table responsive borderless hover>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Notes</th>
                            <th>Created</th>
                            <th>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orgsLoading && <tr><td><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Loading...</td></tr>}
                          {pageAgencyClients?.map(({uuid, name, notes, createdAt}) => {
                            return (
                              <tr key={`org-${uuid}`}>
                                <td>{name}</td>
                                <td>{notes}</td>
                                <td>{createdAt && moment(createdAt).format('D MMM YYYY')}</td>
                                <td className="link-col"><Link to={`/organisations/${uuid}/edit`}>Edit</Link></td>
                              </tr>);
                          })}
                        </tbody>
                      </Table>
                    </Row>
                    <ReactPaginate
                      pageCount={Math.ceil(agencyClientsToDisplay.length / maxPerPage)}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={0}
                      onPageChange={handleAgencyClientsPageChange}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      activeClassName={'active'}
                      forcePage={forceAgencyClientsPage ?? -1}
                    />
                  </>}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Col>
      </div>
    </Container>
  );
};

export default Accounts;
