import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const SkeletonField = ({loading, field}) => (loading ?
  <span className="loading-placeholder d-inline-flex skeleton-background"></span> : (field !== undefined &&
    <OverlayTrigger placement="top" overlay={<Popover id="html-attributes-popover">
      <Popover.Body className="text-start">
        {field?.label &&
          <dl className="d-flex mb-0">
            <dt className="me-2 text-nowrap">Field Label:</dt>
            <dd>{field?.label}</dd>
          </dl>}
        {field?.htmlTagName && field?.htmlTagName !== 'null' &&
          <dl className="d-flex mb-0">
            <dt className="me-2 text-nowrap">HTML Tag Name:</dt>
            <dd>{field?.htmlTagName}</dd>
          </dl>}
        {field?.htmlType && field?.htmlType !== 'null' &&
          <dl className="d-flex mb-0">
            <dt className="me-2 text-nowrap">HTML Type:</dt>
            <dd>{field?.htmlType}</dd>
          </dl>}
        {field?.htmlName && field?.htmlName !== 'null' &&
          <dl className="d-flex mb-0">
            <dt className="me-2 text-nowrap">HTML Name:</dt>
            <dd>{field?.htmlName}</dd>
          </dl>}
        {field?.htmlId && field?.htmlId !== 'null' &&
          <dl className="d-flex mb-0">
            <dt className="me-2 text-nowrap">HTML ID:</dt>
            <dd>{field?.htmlId}</dd>
          </dl>}
      </Popover.Body>
    </Popover>}>
      <span className="text-more-info headline-field-label">{field?.label}</span>
    </OverlayTrigger>)
);

export default SkeletonField;
