import { RefObject, useContext, Fragment } from 'react';
import Title from './step/Title';
import ProgressionContext, { ProgressionContextType } from '../contexts/ProgressionContext';
import CTAs from './CTAs';
import Question from './Question';
import Copy from './CopyWithLink';
import ItemToConfigureWrapper from './ItemToConfigureWrapper';
import { StepInterface } from '../interfaces/zuko_form/StepInterface';
import { slugify } from '../utils';
import './Form.scss';

function Form({ step, formRef, questionKeys }: {step: StepInterface, formRef: RefObject<HTMLFormElement>, questionKeys: Array<string>}) {
  const { currentStepIndex } = useContext(ProgressionContext) as ProgressionContextType;
  const { description = null } = step || {};

  return <form onSubmit={(e) => e.preventDefault()} noValidate={true} ref={formRef}>
    <div key={`step-${currentStepIndex}`} className="step fade-in">
      {step?.title &&
        <ItemToConfigureWrapper stepIndex={currentStepIndex} type={'step-title'}>
          <Title text={step?.title} stepIndex={currentStepIndex} />
        </ItemToConfigureWrapper>}
      <ItemToConfigureWrapper
        type={'step-description'}
        noContent={description !== null ? false : true}
        stepIndex={currentStepIndex}>
        <Copy text={step?.description} type={'step-description'} stepIndex={currentStepIndex} />
      </ItemToConfigureWrapper>
      <div className="fields-container">
        {step?.builderQuestions.map(({
          questionIndex, // Keys not needed after this point
          key, title, // Keys we need to remove, and transfer to input-relevant key-names
          // TODO: type the descructured keys
          componentType,
          ...question}: any, i: number) => {
          let questionKeyToUse = key || title;
          const keyIsDuplicated = questionKeys.filter(k => k === questionKeyToUse).length > 1;
          if (keyIsDuplicated) questionKeyToUse = `${questionKeyToUse}_${currentStepIndex}_${i}`;

          return <Fragment key={componentType === 'paragraphText' ? `paragraph-text-${i}` : questionKeyToUse}>
            {componentType === 'paragraphText' ?
              <ItemToConfigureWrapper
                type={'paragraph-text'}
                stepIndex={currentStepIndex}
                questionIndex={i}
                questionsLength={step.builderQuestions.length}>
                <Copy text={title} type={'paragraph-text'} stepIndex={currentStepIndex} questionIndex={i} />
              </ItemToConfigureWrapper>
              : <ItemToConfigureWrapper
                type={'question'}
                stepIndex={currentStepIndex}
                questionIndex={i}
                questionsLength={step.builderQuestions.length}
              >
                <Question question={{...question, id: slugify(questionKeyToUse) , label: title}} componentType={componentType} stepIndex={currentStepIndex} questionIndex={i} />
              </ItemToConfigureWrapper>}
          </Fragment>;
        })}
      </div>
    </div>
    <CTAs />
  </form>;
}

export default Form;
