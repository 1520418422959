import{ useContext, useState, useEffect, useCallback } from 'react';
import { usePrevious } from '../../hooks.js';
import AppContext from '../../AppContext.js';
import {
  labelForField,
} from '../../utils.js';
import api from '../../api.js';

import { Time, Form, Field, FilterForSelect, SelectFieldOptionType } from '../../types/types.ts';

const useFetchTrackedFields = () => {
  const {
    query,
  } = useContext(AppContext);

  const [availableTrackedFields, setAvailableTrackedFields] = useState<SelectFieldOptionType[] | undefined>();
  const [trackedFieldsLoading, setTrackedFieldsLoading] = useState(false);
  const [trackedFieldsError, setTrackedFieldsError] = useState<string | null>(null);

  const {
    time,
    form,
    filters,
  }: {
    time:Time,
    form: Form,
    filters: FilterForSelect[],
  } = query || {};

  const prevForm = usePrevious(form);
  const prevTime = usePrevious(time);
  const prevFilters = usePrevious(filters);

  const fetchTrackedFields = useCallback(async () => {
    try {
      setTrackedFieldsLoading(true);
      setTrackedFieldsError(null);
      const {data: {fields: availableFormFields}} = await api.get(`/data/sessions/fields`, {
        params: {
          formUuid: form?.uuid,
          time: {
            start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
          filters: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });
      setAvailableTrackedFields(
        availableFormFields
          .map(field => ({...field, label: labelForField(field), value: field.identifier}))
          .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
          .sort((a: Field, b: Field) => {
            const orderA: number | null | '' | undefined = a.order;
            const orderB: number | null | '' | undefined = b.order;
            // @ts-ignore TODO: fix this sort
            return ((orderA === null || orderA === '') - (orderB === null || orderB === '')) || ((orderA > orderB) - (orderA < orderB));
          })
      );
    } catch (e) {
      setTrackedFieldsError('Error when fetching fields');
    } finally {
      setTrackedFieldsLoading(false);
    }
  }, [form, time, filters]);

  // Initial load
  useEffect(() => {
    // First loaded page after login - so wait for default time to be set
    if ((prevForm?.uuid && form?.uuid && (prevForm.uuid === form.uuid)) && (!prevTime && time?.start)) {
      fetchTrackedFields();
    }

    // Moved to page from another
    if ((!prevForm && form?.uuid) && (!prevTime && time?.start)) {
      fetchTrackedFields();
    }
  }, [form, time, fetchTrackedFields, prevForm, prevTime]);

  // Form changed
  useEffect(() => {
    // Form changed
    if (form?.uuid && prevForm?.uuid && (prevForm.uuid !== form.uuid)) {
      fetchTrackedFields();
    }
  }, [form?.uuid, fetchTrackedFields, prevForm]);

  // Time changed
  useEffect(() => {
    if (time?.start && time?.end && prevTime?.start && prevTime?.end &&
      (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
    ) {
      fetchTrackedFields();
    }
  }, [time, fetchTrackedFields, prevTime]);

  // Filters changed
  useEffect(() => {
    if (form?.uuid && time?.start && // Required
    ((!prevFilters && filters?.length) || // Filters first set
    (prevFilters?.length && !filters?.length) || // Filters have been removed
    (!prevFilters?.length && filters?.length) || // Filters have been added
    (prevFilters?.length !== filters?.length) // Filters have changed
    )) {
      fetchTrackedFields();
    }

  }, [form?.uuid, time?.start, filters, prevFilters, fetchTrackedFields]);

  return {trackedFieldsLoading, trackedFieldsError, availableTrackedFields};
};

export default useFetchTrackedFields;
