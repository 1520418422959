import { useContext, useCallback } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import Hint from '../question/HintWithLink';
import Legend from '../question/Legend';
import Label from '../question/Label';
import Text from './Text';
import { AddressInterface } from './interfaces/AddressInterface';
import InputSuccessWrapper from '../InputSuccessWrapper';

export const addressFieldIds = (id: string) => [`${id}-line-1`, `${id}-line-2`, `${id}-town-city`, `${id}-postcode`];

const AddressManual = ({ invalidFeedback, question, stepIndex, questionIndex }: {question: AddressInterface, invalidFeedback: JSX.Element|JSX.Element[],
stepIndex: number, questionIndex: number}) => {
  const { values, setValues, inEdit } = useContext(SessionContext) as SessionContextType;
  const { id, label, hint = null, required } = question;
  const { subLabels, ...restOfQuestion } = question;
  const address1 = subLabels?.addressLine1 || 'Address 1';
  const address2 = subLabels?.addressLine2 || 'Address 2';
  const addressLevel1 = subLabels?.addressLevel1 || 'Town or city';
  const postalCode = subLabels?.postalCode || 'Postcode/ZIP code';

  const onChange = useCallback(({ target: { value, id } }: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, [id]: value }), [values, setValues]);

  const [line1Id, line2Id, townCityId, postcodeId] = addressFieldIds(id);

  return <fieldset className="address-fieldset" aria-describedby={`${id}-hint`}>
    <Legend label={label} required={required} stepIndex={stepIndex} questionIndex={questionIndex} />
    <Hint id={`${id}-hint`} text={hint} stepIndex={stepIndex} questionIndex={questionIndex} />
    {invalidFeedback}
    <div className="address-fields">
      <div className="field">
        <Label id={inEdit ? '' : line1Id} label={address1} required={required} stepIndex={stepIndex} questionIndex={questionIndex}
          type={'question-sub-label-address-line-1'} />
        <Hint id={`${line1Id}-hint`} display={'d-none'} aria-hidden="false" text={`The ${address1} of the address`}
          stepIndex={stepIndex} questionIndex={questionIndex} />
        <InputSuccessWrapper label={address1}>
          <Text
            {...restOfQuestion}
            id={line1Id}
            value={values[line1Id]?.toString() || ''}
            onChange={onChange}
            autoComplete="address-line1"
          />
        </InputSuccessWrapper>
      </div>
      <div className="field">
        <Label id={inEdit ? '' : line2Id} label={address2} required={false} stepIndex={stepIndex} questionIndex={questionIndex}
          type={'question-sub-label-address-line-2'} />
        <Hint id={`${line2Id}-hint`} display={'d-none'} aria-hidden="false" text={`The ${address2} of the address`}
          stepIndex={stepIndex} questionIndex={questionIndex} />
        <InputSuccessWrapper label={address2}>
          <Text
            {...restOfQuestion}
            id={line2Id}
            required={false}
            value={values[line2Id]?.toString() || ''}
            onChange={onChange}
            autoComplete="address-line2"
          />
        </InputSuccessWrapper>
      </div>
      <div className="field">
        <Label id={inEdit ? '' : townCityId} label={addressLevel1} required={required} stepIndex={stepIndex} questionIndex={questionIndex}
          type={'question-sub-label-address-level-1'} />
        <Hint id={`${townCityId}-hint`} display={'d-none'} aria-hidden="false" text={`The ${addressLevel1} of the address`}
          stepIndex={stepIndex} questionIndex={questionIndex} />
        <InputSuccessWrapper label={addressLevel1}>
          <Text
            {...restOfQuestion}
            id={townCityId}
            size={'md'}
            value={values[townCityId]?.toString() || ''}
            onChange={onChange}
            autoComplete="address-level1"
          />
        </InputSuccessWrapper>
      </div>
      <div className="field">
        <Label id={inEdit ? '' : postcodeId} label={postalCode} required={required} stepIndex={stepIndex} questionIndex={questionIndex}
          type={'question-sub-label-postal-code'} />
        <Hint id={`${postcodeId}-hint`} display={'d-none'} aria-hidden="false" text={`The ${postalCode} for the address`}
          stepIndex={stepIndex} questionIndex={questionIndex} />
        <InputSuccessWrapper label={postalCode}>
          <Text
            {...restOfQuestion}
            id={postcodeId}
            size={'sm'}
            value={values[postcodeId]?.toString() || ''}
            onChange={onChange}
            autoComplete="postal-code"
          />
        </InputSuccessWrapper>
      </div>
    </div>
  </fieldset>;
};

export default AddressManual;
