import { RefObject } from 'react';
import { FaPencilAlt } from 'react-icons/fa';

const EditInlineIcon = ({setShowEditIcon, itemRef}: {
  setShowEditIcon: Function,
  itemRef: RefObject<HTMLLabelElement | HTMLParagraphElement | HTMLHeadingElement>,
}) => {
  return <FaPencilAlt className="ms-2 edit-inline-icon" onClick={() => {
    if (itemRef?.current) {
      itemRef.current.focus();
      window.getSelection()?.selectAllChildren(itemRef.current);
      window.getSelection()?.collapseToEnd();
      setShowEditIcon(false);
    }
  }} size={'16px'} />;
};

export default EditInlineIcon;
