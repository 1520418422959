import MultiSelect from './MultiSelect';
import { countries } from '../form-components/components/fields/CountrySelector';

const CountryMultiSelect = ({selectedOptions, handleOnChange, handleOnBlur}) => {
  return <MultiSelect
    id="country-select"
    selectedOptions={selectedOptions}
    options={countries}
    handleOnChange={handleOnChange}
    handleOnBlur={handleOnBlur}
  />;
};

export default CountryMultiSelect;
