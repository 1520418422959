import { useContext, useEffect, useRef, useState } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import Hint from '../question/HintWithLink';
import Legend from '../question/Legend';
import GroupItemLabel from '../question/GroupItemLabel';
import { RadioButtonGroupInterface } from './interfaces/RadioButtonGroupInterface';
import { useInputIsValid } from '../../hooks';
import { slugify } from '../../utils';
import OtherOption from './OtherOption';
import Button from 'react-bootstrap/Button';
import { FaPlus, FaList } from 'react-icons/fa';
import BulkOptions from './BulkOptions';

const RadioButtonGroup = ({ invalidFeedback, question, stepIndex, questionIndex }: {question: RadioButtonGroupInterface, invalidFeedback: JSX.Element|JSX.Element[],
  stepIndex: number, questionIndex: number}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { values, setValues, zuko, inEdit, handleSaveInlineEditItem } = useContext(SessionContext) as SessionContextType;
  const { id, label, hint = null, required, options } = question;
  const value = values[id] ?? '';
  const [isValid, setIsValid] = useInputIsValid({value: value.toString(), inputRef, required: required});
  const { otherOption } = question;
  const otherIsEnabled = otherOption?.enabled || false;
  const [showBulkAddOptions, setShowBulkAddOptions] = useState(false);

  const classList: string[] = [];
  if (required) {
    if (isValid === false) classList.push('is-invalid');
    // NB. is-valid style is not applied
  }

  useEffect(() => {
    if (isValid === true || isValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${isValid ? 'valid' : 'invalid'}: ${id}`);
    }
  }, [isValid, id, zuko]);

  const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [id]: value });
    if (required && value) setIsValid(true);
  };

  return (<fieldset className="radio-fieldset" aria-describedby={`${id}-hint`}>
    <Legend label={label} required={required} stepIndex={stepIndex} questionIndex={questionIndex} />
    <Hint id={`${id}-hint`} text={hint} stepIndex={stepIndex} questionIndex={questionIndex} />
    {invalidFeedback}
    {inEdit && showBulkAddOptions &&
      <BulkOptions
        options={options}
        stepIndex={stepIndex}
        questionIndex={questionIndex}
        setShowBulkAddOptions={setShowBulkAddOptions} />}
    {!showBulkAddOptions && <div className="radio-button-group">{options?.map((option, i) =>
      <div className="radio-button-option" key={`${id}-${option}-${i}`}>
        <input
          required={required}
          id={`${id}-${slugify(option)}`}
          type="radio"
          name={id}
          value={option}
          {...(i === 0) && {ref: inputRef}} // Just using the first option will provide the validity of this group
          onChange={onChange}
          className={classList.join(' ')}
          aria-describedby={`${id}-hint`}
          checked={values[id] === option} />
        <GroupItemLabel id={inEdit ? '' :  // NB. we don't declare the htmlFor to avoid engaging the input whilst in edit mode
          `${id}-${slugify(option)}`} label={option} stepIndex={stepIndex} questionIndex={questionIndex}
        itemIndex={i} type={'question-option'} options={options} />
      </div>
    )}
    {otherIsEnabled &&
      <OtherOption
        type={'radio'}
        id={id}
        required={required}
        classList={classList}
        stepIndex={stepIndex}
        questionIndex={questionIndex}
      />}
    {inEdit && <div className="mb-2">
      <Button variant="outline-primary" className="ms-0 mt-1 px-3 py-2 option-button" onClick={() => {
        handleSaveInlineEditItem({type: 'question-options', item: (options || []).concat('Option'), stepIndex, questionIndex});
      }}><FaPlus className="me-2 plus-inside-btn" />Add Option</Button>
      <Button variant="outline-primary" className="ms-2 me-0 mt-1 px-3 py-2 option-button" onClick={() => setShowBulkAddOptions(true)}>
        <FaList className="me-2 plus-inside-btn" />Paste List</Button>
      {!otherIsEnabled &&
        <Button variant="outline-primary" className="ms-2 mt-1 px-3 py-2 option-button" onClick={() => {
          handleSaveInlineEditItem({type: 'question-other-option', item: { enabled: true }, stepIndex, questionIndex});
        }}><FaPlus className="me-2 plus-inside-btn" />Add 'Other' Option</Button>}
    </div>}
    </div>}
  </fieldset>);
};

export default RadioButtonGroup;
