import React, { useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FeedbackPopover from './FeedbackPopover';
import ReviewPopover from './ReviewPopover';

import './FeedbackRow.scss';

const FeedbackRow = ({mixpanel, page, org: orgFromPage, messageContent, classList = []}) => {
  const outsideOverlay = useRef();

  return (
    <Row className="g-0 page-feedback-row justify-content-end browser-only" ref={outsideOverlay}>
      <Col className="p-0 d-flex justify-content-end">
        <FeedbackPopover
          outsideOverlayRef={outsideOverlay}
          mixpanel={mixpanel}
          page={page}
          orgFromPage={orgFromPage}
          messageContent={messageContent}
          classList={classList}
        />
        <div className="ms-3">
          <ReviewPopover
            mixpanel={mixpanel}
            classList={classList}
            outsideOverlayRef={outsideOverlay}
            page={page}
          />
        </div>
      </Col>
    </Row>
  );
};

export default FeedbackRow;