import React, { useState, useEffect, useCallback }  from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { VscWarning, VscCheck } from 'react-icons/vsc';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { HiExternalLink } from 'react-icons/hi';
import { splitName } from '../utils';

import api from '../api';
import { usePrevious } from '../hooks';
import passwordPolicy from '../password_policy';

import './InvitedAccountSetPassword.scss';

const ValidInputWrapper = ({children}) => (
  <div className="d-flex align-items-center justify-content-center">
    {children}
    <div className="valid-input-icon"><VscCheck size="30px"/></div>
  </div>
);

const InvitedAccountSetPassword = ({mixpanel}) => {
  const { token } = useParams();
  const history = useHistory();

  const [nameIsValid, setNameIsValid] = useState();
  const [nameErrorMsg, setNameErrorMsg] = useState();
  const [passwordIsValid, setPasswordIsValid] = useState();
  const [passwordErrorMsg, setPasswordErrorMsg] = useState();
  const [passwordShown, setPasswordShown] = useState(false);

  const [name, setName] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [termsSelected, setTermsSelected] = useState();
  const [tokenValid, setTokenValid] = useState(null);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createAccountError, setCreateAccountError] = useState(null);
  const [nameAlreadySet, setNameAlreadySet] = useState();

  const prevName = usePrevious(name);
  const prevPassword = usePrevious(password);

  const termsIsInvalid = ((submitAttempted && termsSelected === undefined) || termsSelected === false);

  const slug = '8077bf77fe143ae2';

  useEffect(() => {
    const retrieveToken = async () => {
      try {
        const { data: { user } } = await api.get(`/password-reset/${token}`);

        if (user.name) {
          setName(user.name);
          setNameAlreadySet(true);
        } else {
          setNameAlreadySet(false);
        }

        setTokenValid(true);

        if (user.email) {
          setEmail(user.email);
          mixpanel.identify(user.email);
          mixpanel.track('Page View', { page: 'Create Account' });
        }
      } catch (e) {
        history.push('/login', { alert: {
          message: 'The create account link you visited is invalid or has expired. Please contact <a href="mailto:support@zuko.io">Zuko support</a>.',
          type: 'danger',
          linkIncluded: true,
        }});
      }
    };
    retrieveToken();
  }, [history, token, mixpanel]);

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    // Native form validation
    if (!e.currentTarget.checkValidity()) {
      if (!name) validateName();
      if (!password) validatePassword();
      // NB terms error message will automatically display if not selected

      setIsSubmitting(false);
      return;
    }

    setCreateAccountError(null);
    try {
      await api.put(`/password-reset/${token}`, {
        password,
        ...!nameAlreadySet && {name},
      });

      const profile = {
        $email: email
      };

      if (name) {
        const nameMatch = splitName(name);
        const firstName = nameMatch[1];
        const lastName = nameMatch[2];
        if (firstName) profile.$first_name = firstName;
        if (lastName) profile.$last_name = lastName;
      }

      mixpanel.people.set(profile);
      if (email) mixpanel.track('Password Created', { page: 'Create Account' });

      // Zuko completion tracking
      window.Zuko?.trackForm({slug}).trackEvent(window.Zuko?.COMPLETION_EVENT);

      setIsSubmitting(false);
      history.push('/login', { alert: { message: 'Your account has been created. Please now log in.', type: 'success' } });
    } catch (e) {
      if ((e.response && (e.response.status === 422))) {
        setCreateAccountError(`We're sorry, something has gone wrong creating your account. The${nameAlreadySet ? '' : ' name and/or'} password is invalid. Please try a different one or contact <a href='mailto:support@zuko.io'>Zuko support</a>.`);
        if (!nameAlreadySet) setNameIsValid(false);
        setPasswordIsValid(false);
      } else {
        setCreateAccountError("We're sorry, something has gone wrong creating your account. Please try again or contact <a href='mailto:support@zuko.io'>Zuko support</a>.");
      }
      setIsSubmitting(false);
    }
  };

  const validateName = useCallback(() => {
    if (!name) {
      setNameIsValid(false);
      setNameErrorMsg("Please enter your name, we'll need it to say hello.");
      return;
    }

    setNameIsValid(true);
  },[name]);

  const validatePassword = useCallback(() => {
    if (!password) {
      setPasswordIsValid(false);
      setPasswordErrorMsg('You need to create a password to access your account securely.');
      return;
    }

    if (password?.length < passwordPolicy.length.min) {
      setPasswordIsValid(false);
      setPasswordErrorMsg(`Your password needs to be a minimum of ${passwordPolicy.length.min} characters.`);
      return;
    }

    if (password?.length > passwordPolicy.length.max) {
      setPasswordIsValid(false);
      setPasswordErrorMsg(`Your password needs to be no more than ${passwordPolicy.length.max} characters.`);
      return;
    }

    setPasswordIsValid(true);
  },[password]);

  // Validate name after expected autofill or after removing the value whilst still in the input
  useEffect(() => {
    if (((!prevName || prevName?.length < 3) && name?.length > 3)) validateName();
    if (prevName?.length && !name?.length) validateName();
  }, [name, prevName, validateName]);

  useEffect(() => {
    if (((!prevPassword || prevPassword?.length < 2) && password?.length > 2) || // expected autofill
        (prevPassword?.length && !password?.length) || // removed value
        (password?.length >= passwordPolicy.length.min && password?.length <= passwordPolicy.length.max) || // long enough
        (password?.length > passwordPolicy.length.max) // too long
    ) validatePassword();
  }, [password, prevPassword, validatePassword]);

  // Zuko activity tracking
  useEffect(() => {
    if (tokenValid && nameAlreadySet !== undefined) {
      const zukoScript = document.createElement('script');
      zukoScript.src = 'https://assets.zuko.io/js/v2/client.min.js';
      document.body.appendChild(zukoScript);

      // Only initiate tracking when Zuko has loaded
      zukoScript.addEventListener('load', () => {
        if (window.Zuko?.trackMedium) window.Zuko?.trackMedium();
        window.Zuko?.trackForm({target:document.getElementById('invited-set-password-form'),slug})?.setAttribute('name-already-set', nameAlreadySet).trackEvent(window.Zuko?.FORM_VIEW_EVENT);
      });
    }
  }, [tokenValid, nameAlreadySet]);

  // Zuko custom events tracking
  useEffect(() => {
    if (nameErrorMsg) window.Zuko?.trackForm({slug}).trackEvent({type: `Name: ${nameErrorMsg}`});
  }, [name, nameErrorMsg]);

  useEffect(() => {
    if (passwordErrorMsg) window.Zuko?.trackForm({slug}).trackEvent({type: `Password: ${passwordErrorMsg}`});
  }, [passwordErrorMsg]);

  useEffect(() => {
    if (termsSelected === false) window.Zuko?.trackForm({slug}).trackEvent({type: 'Terms: Please accept the terms so you can use the Zuko platform.'});
  }, [termsSelected]);

  return (
    <Container fluid className="page login-background" id="invite-set-password-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Create Account</title>
      </Helmet>
      <Col className="center-column justify-content-md-center">
        <Row className="g-0 login-logo justify-content-center">
          <div className="logo-wrapper">
            <div className="zuko-full-colour-logo" role="img" alt="Zuko logo"></div>
          </div>
        </Row>
        {tokenValid && <>
          <Row className="g-0 login-title mb-3 justify-content-center">
            <h1>Create Your Account</h1>
          </Row>
          {createAccountError &&
              <Row className="alert-row g-0">
                <Alert variant="danger">
                  <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                  <p className="alert-text m-0" dangerouslySetInnerHTML={{ __html: createAccountError }} />
                </Alert>
              </Row>}
          <Row className="g-0 justify-content-center" id="login-container">
            <Col className="col-auto p-0" id="login-container-col">
              <Form noValidate onSubmit={handleCreateAccount} className="login-form" id="invited-set-password-form">

                {nameAlreadySet ? <>

                  <FormGroup className="form-group" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" autoComplete="false" value={name || ''} readOnly />
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="form-group" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="you@example.com" autoComplete="username" value={email || ''} readOnly />
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </FormGroup> </> :
                  <>
                    <FormGroup className="form-group" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="you@example.com" autoComplete="username" value={email || ''} readOnly />
                      <Form.Control.Feedback></Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup controlId="name" className={`form-group ${nameIsValid === true ? 'valid' : nameIsValid === false ? 'invalid' : ''}`}>
                      <Form.Label>Name</Form.Label>
                      <ValidInputWrapper>
                        <Form.Control type="text" placeholder="Enter your name" name="name" autoComplete="false" value={name || ''} maxLength={255}
                          className={((submitAttempted && !name) || (nameIsValid === false)) ? 'invalid-input' : nameIsValid ? 'is-valid' : ''}
                          onChange={({target: {value}}) => {
                            setName(value);
                            if (nameIsValid === false) {
                              setNameIsValid(null);
                              setNameErrorMsg(null);
                            }
                          }} required
                          onBlur={() => validateName()}
                        />
                      </ValidInputWrapper>
                      <Form.Control.Feedback type="invalid" data-testid="name-invalid-feedback">{nameErrorMsg}</Form.Control.Feedback>
                    </FormGroup>
                  </>}

                <FormGroup controlId="password" className={`form-group ${passwordIsValid === true ? 'valid' : passwordIsValid === false ? 'invalid' : ''}`}>
                  <Form.Label>Password</Form.Label>
                  <ValidInputWrapper>
                    <InputGroup>
                      <Form.Control type={passwordShown ? 'text' : 'password'} placeholder="Enter a password" name="password" autoComplete="new-password" minLength={passwordPolicy.length.min} value={password || ''}
                        className={((submitAttempted && !password) || (passwordIsValid === false)) ? 'invalid-input' : passwordIsValid ? 'is-valid' : ''}
                        onChange={({target: {value}}) => {
                          setPassword(value);
                          if (passwordIsValid === false) {
                            setPasswordIsValid(null);
                            setPasswordErrorMsg(null);
                          }
                        }}
                        onBlur={() => validatePassword()} required
                      />
                      <InputGroup.Text className="password-show-icon-container" onClick={() => setPasswordShown(!passwordShown)}>
                        {passwordShown ? <AiOutlineEye size="20px" className="grey-icon" title="Show password"/> :
                          <AiOutlineEyeInvisible size="20px" className="grey-icon" title="Show password"/>}
                      </InputGroup.Text>
                    </InputGroup>
                  </ValidInputWrapper>
                  {!passwordErrorMsg && <Form.Control.Feedback className="feedback-row feedback-info">
                    Your password must be at least {passwordPolicy.length.min} characters long, and no more than {passwordPolicy.length.max} characters.
                  </Form.Control.Feedback>}
                  {passwordErrorMsg && <Form.Control.Feedback type="invalid"className="feedback-row" data-testid="password-invalid-feedback">{passwordErrorMsg}</Form.Control.Feedback>}
                </FormGroup>

                {nameAlreadySet === false &&
                  <fieldset className={`checkbox-fieldset mt-3 ${termsIsInvalid ? 'displaying-error' : ''}`}>
                    <FormGroup controlId="termsandpolicycheckbox" className={`form-group d-inline-flex align-items-center mb-0 ${termsSelected ? 'valid' : termsSelected === false ? 'invalid' : ''}`}>
                      <Form.Check type="checkbox" name="terms-and-policy-checkbox" isInvalid={termsIsInvalid} data-testid="terms-checkbox" checked={termsSelected || false} required
                        className={`d-flex mt-0`}
                        onChange={() => setTermsSelected(!termsSelected)} />
                      <Form.Label className="mb-0 align-middle ps-2">I accept the&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/terms-and-conditions">Terms & Conditions<HiExternalLink className="align-top" /></a>&nbsp;and&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/privacy-policy">Privacy Policy<HiExternalLink className="align-top" /></a></Form.Label>
                    </FormGroup>
                    <Form.Control.Feedback type="invalid" data-testid="terms-invalid-feedback" className={`pb-0 feedback-row checkbox-feedback`}>
                      {termsIsInvalid && 'Please accept so you can use the Zuko platform.'}
                    </Form.Control.Feedback>
                  </fieldset>}

                <Row className="g-0 login-btn justify-content-center mt-3">
                  <Button variant="primary" className="submit-btn" type="submit" onClick={() => setSubmitAttempted(true)} disabled={isSubmitting}>Create Account</Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </>}
      </Col>
    </Container>
  );
};

export default InvitedAccountSetPassword;
