import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import commaNumber from 'comma-number';
import {loadStripe} from '@stripe/stripe-js';
import { TiTick } from "react-icons/ti";
import { SwitchTransition, CSSTransition, TransitionGroup } from 'react-transition-group';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { withRouter } from "react-router-dom";

import NavBar from '../../NavBar';
import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import AppContext from '../../AppContext';
import api from '../../api';
import './ChoosePlan.scss';
import Card from 'react-bootstrap/Card';
import ChoosePlanCompanyDetailsForm from './ChoosePlanCompanyDetailsForm';

const zukoSlug = '2dbb44d4ce0c66f4';
const replayProduct = 'replay';
class ChoosePlan extends React.Component {
  static contextType = AppContext;

  state = {
    plans: [],
    selectedCurrencyCode: 'gbp',
    fixedCurrencyCode: null,
    selectedPlanInterval: 'year',
    showCreateCustomer: false,
    customer: {},
    creatingCustomer: false,
    creatingCustomerError: false,
    creatingSession: false,
    selectedPlan: {},
    selectedPlanId: null,
    dismissSuccessMsg: false,
    dismissCancelMsg: false,
    loading: false,
    planLoadingError: false,
    customerLoadingError: false,
  };

  constructor (props) {
    super(props);
    this.mixpanel = this.props.mixpanel;
    this.toggleCurrency = this.toggleCurrency.bind(this);
    this.togglePlanInterval = this.togglePlanInterval.bind(this);
    this.handlePlanSelect = this.handlePlanSelect.bind(this);
    this.handleCompanyInfoSubmit = this.handleCompanyInfoSubmit.bind(this);
    this.handleCancelCreateCustomer = this.handleCancelCreateCustomer.bind(this);
    this.zukoRef = React.createRef();
  }

  async componentDidMount () {
    try {
      const { currentUser: user } = this.context;
      this.setState({loading: true, customerLoadingError: false});
      let { data: { customer } } = await api.get(`/billing/customers/${this.props.match.params.uuid}`);
      // If the Stripe Customer has been deleted, then we want to allow the user to sign up again.
      // We pretend that there's been no Customer found in Stripe, so that a new one is created for the user.
      if (customer && customer.deleted) customer = null;

      // Make sure that the customer has an address object
      // Older Stripe customers sometimes have a null address
      if (customer) customer.address = customer.address || {};

      // Fix the currency already set for the customer
      if (customer?.currency) {
        const allowedCurrencies = ['gbp', 'usd', 'eur'];
        if (allowedCurrencies.includes(customer?.currency)) this.setState({fixedCurrencyCode: customer?.currency});
        if (!allowedCurrencies.includes(customer?.currency)) this.setState({customerLoadingError: 'There are no available plans in the currency used on your account. Please contact Zuko support.'});
      }

      try {
        const { data: { plans } } = await api.get('/billing/plans');
        this.setState({
          availablePlans: plans.reduce((acc, plan) => {
            const { currency, recurring: { interval },
              product: { metadata: { replayEnabled } },
            } = plan;

            if (replayEnabled === 'true') {
              if (!acc.hasOwnProperty(replayProduct)) acc[replayProduct] = {};
              if (!acc[replayProduct].hasOwnProperty(currency)) acc[replayProduct][currency] = {};
              if (!acc[replayProduct][currency].hasOwnProperty(interval)) acc[replayProduct][currency][interval] = [];
              acc[replayProduct][currency][interval].push(plan);
            } else {
              if (!acc.hasOwnProperty(currency)) acc[currency] = {};
              if (!acc[currency].hasOwnProperty(interval)) acc[currency][interval] = [];
              acc[currency][interval].push(plan);
            }
            return acc;
          }, {}),
          user,
          customer: customer || this.state.customer,
          loading: false, planLoadingError: false,
        });
      } catch (e) {
        this.setState({ loading: false, planLoadingError: (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Sorry, something went wrong loading the plans. Please try again.' });
      }
    } catch (e) {
      this.setState({loading: false, customerLoadingError: (e.response && (e.response.status === 401)) ? 'Not logged in' :
        (e.response && (e.response.status === 404)) ? 'Organisation not found' : 'Sorry, something went wrong. Please try again.'});
    }

    // When the component has rendered, track it in Mixpanel
    const { mixpanel } = this.props;
    mixpanel.identify(this.context.currentUser.email);
    mixpanel.track('Page View', Object.assign({ page: 'ChoosePlan' }, this.props.location.state?.organisation?.name ? {
      'Organisation Name': this.props.location.state?.organisation.name,
      'Organisation Uuid': this.props.match?.params?.uuid,
    } : {}));

    // Load Zuko to be used during stripe setup
    if (!window.Zuko) {
      const zukoScript = document.createElement('script');
      zukoScript.src = 'https://assets.zuko.io/js/v2/client.min.js';
      document.body.appendChild(zukoScript);
    }

    this.setState({
      replayEnabled: true,
    });
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.state.selectedCurrencyCode !== prevState.selectedCurrencyCode ||
      this.state.selectedPlanInterval !== prevState.selectedPlanInterval ||
      this.state.replayEnabled !== prevState.replayEnabled ||
      (!prevState.availablePlans && this.state.availablePlans)) {
      this.setState({
        plans: this.state.replayEnabled ?
          this.state.availablePlans[replayProduct][this.state.fixedCurrencyCode || this.state.selectedCurrencyCode][this.state.selectedPlanInterval] :
          this.state.availablePlans[this.state.fixedCurrencyCode || this.state.selectedCurrencyCode][this.state.selectedPlanInterval],
      });
    }
  }

  getCurrencySymbolFromCode (code) {
    switch (code.toLowerCase()) {
    case 'gbp':
      return '£';
    case 'usd':
      return '$';
    case 'eur':
      return '€';
    default:
      throw new Error(`Unsupported currency code: '${code}'`);
    }
  }

  togglePlanInterval () {
    this.setState({ selectedPlanInterval: this.otherPlanInterval(this.state.selectedPlanInterval) });
  }

  otherPlanInterval (interval) {
    return interval.toLowerCase() === 'month' ? 'year' : 'month';
  }

  toggleCurrency (selectedCurrencyValue) {
    this.setState({ selectedCurrencyCode: selectedCurrencyValue });
  }

  handlePlanSelect (selectedPlan) {
    const { mixpanel } = this.props;
    mixpanel.track('Choose a plan', Object.assign({ page: 'ChoosePlan', plan: selectedPlan.id }, this.props.location.state?.organisation?.name ? {
      'Organisation Name': this.props.location.state?.organisation.name,
      'Organisation Uuid': this.props.match?.params?.uuid,
    } : {}));
    this.setState({showCreateCustomer: true, selectedPlan, selectedPlanId: selectedPlan.id});
  }

  async handleCompanyInfoSubmit ({name, address}) {
    if (process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase() === 'production' &&
      !process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) throw new Error('REACT_APP_STRIPE_PUBLISHABLE_KEY is required');
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    try {
      this.setState({creatingCustomer: true, creatingCustomerError: false});
      const { data: { customer } } = await api.post('/billing/customers', {
        organisationUuid: this.props.match.params.uuid,
        name,
        email: this.context.currentUser.email,
        address,
      });
      this.setState({customer, creatingCustomer: false, creatingSession: true});

      this.zukoRef?.current?.trackEvent({type: 'Confirmed company details'});

      const { mixpanel } = this.props;
      mixpanel.track('Confirmed company details', Object.assign({ page: 'ChoosePlan' }, this.props.location.state?.organisation?.name ? {
        'Organisation Name': this.props.location.state?.organisation.name,
        'Organisation Uuid': this.props.match?.params?.uuid,
      } : {}));

      const { data: {session: {id: sessionId}} } = await api.post('/billing/checkout/sessions', {
        customerId: customer.id,
        selectedPlanId: this.state.selectedPlanId,
      });

      this.zukoRef?.current?.trackEvent({type: 'Being redirected to Stripe Checkout'});

      mixpanel.track('Being redirected to Stripe Checkout', Object.assign({ page: 'ChoosePlan' }, this.props.location.state?.organisation?.name ? {
        'Organisation Name': this.props.location.state?.organisation.name,
        'Organisation Uuid': this.props.match?.params?.uuid,
      } : {}));

      // Zuko completion tracking
      this.zukoRef?.current?.trackEvent(window.Zuko?.COMPLETION_EVENT);

      await stripe.redirectToCheckout({sessionId});
    } catch (e) {
      this.zukoRef?.current?.trackEvent({type: `Error confirming company details: ${e.message}`});
      this.setState({creatingCustomer: false, creatingCustomerError: true, creatingSession: false});
    }
  }

  handleCancelCreateCustomer = () => {
    this.setState({showCreateCustomer: false, selectedPlanId: null, selectedPlan: {}, creatingCustomerError: false});
  };

  render () {
    return (
      <Container fluid className="choose-plan page">
        <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}><title>Choose a plan</title></Helmet>
        <div className="nav-wrapper">
          <NavBar mixpanel={this.props.mixpanel}/>
        </div>
        <div className="main-content">
          <Col className="center-column justify-content-md-center position-relative">
            <FeedbackRow
              classList={['allow-scroll-under-nav']}
              mixpanel={this.props.mixpanel}
              page={'ChoosePlan'}
              org={this.props.location.state?.organisation}
              messageContent={'choosing a plan'} />
            <AppAlerts />
            <Row className="title-row g-0">
              <Col className="p-0">
                <h1 id="form-title">
                  {this.props.location.state && this.props.location.state.organisation && this.props.location.state.organisation.name && this.props.location.state.organisation.name}
                </h1>
              </Col>
            </Row>
            <SwitchTransition>
              <CSSTransition key={!this.state.showCreateCustomer ? 'showing-plans' : 'showing-billing'} timeout={100} classNames="step-fade">
                {!this.state.showCreateCustomer ?
                  <div>
                    <Row className="g-0">
                      <Col className="p-0 text-center">
                        <h1 className="step-title">Analytics | Choose Plan | Step 1 of 3</h1>
                      </Col>
                    </Row>
                    <Row className="tag-line-row g-0">
                      <Col className="p-0 text-center">
                        <p>You're just a few steps away from gaining full Zuko insight into your forms.</p>
                      </Col>
                    </Row>
                    {this.state.loading &&
                    <Row className="g-0 justify-content-center">
                      <Col className="plans-middle-msg text-center">
                        <p><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Loading plans...</p>
                      </Col>
                    </Row>}
                    {!this.state.loading && (this.state.planLoadingError || this.state.customerLoadingError) &&
                      <Row className="g-0 justify-content-center">
                        <Col className="plans-middle-msg text-center">
                          <p>{(this.state.customerLoadingError || this.state.planLoadingError)}</p>
                        </Col>
                      </Row>}
                    {!this.state.loading && !this.state.planLoadingError && !this.state.customerLoadingError && <>
                      <Row className="g-0 d-flex justify-content-between mb-4">
                        <Col id="currency-toggles" className="p-0">
                          {!this.state.fixedCurrencyCode &&
                            <ToggleButtonGroup type="radio" name="currency" value={this.state.selectedCurrencyCode} onChange={this.toggleCurrency} defaultValue="gbp">
                              <ToggleButton variant="outline-secondary" id="gbp" value="gbp" data-testid="currency-toggle-gbp">£</ToggleButton>
                              <ToggleButton variant="outline-secondary" id="usd" value="usd" data-testid="currency-toggle-usd">$</ToggleButton>
                              <ToggleButton variant="outline-secondary" id="eur" className="me-0" value="eur" data-testid="currency-toggle-eur">€</ToggleButton>
                            </ToggleButtonGroup>}
                        </Col>
                        <Col className="p-0">
                          <div id="plan-length-toggles" className="p-0 d-flex justify-content-center">
                            <ToggleButtonGroup type="radio" name="plan-length" value={this.state.selectedPlanInterval} onChange={this.togglePlanInterval} defaultValue="month">
                              <ToggleButton variant="outline-secondary" id="month" value="month">Monthly</ToggleButton>
                              <ToggleButton variant="outline-secondary" id="year" value="year">Annual (20% off)</ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                        </Col>
                        <Col className="p-0 d-flex justify-content-end">
                          <div id="replay-checkbox-wrapper" className="d-inline-flex align-items-center ms-3">
                            <Form.Check type="checkbox" checked={this.state.replayEnabled} id="replayEnabled"
                              onChange={({target: {checked}}) => this.setState({replayEnabled: checked})}/>
                            <Form.Label className="mb-0 ms-2 fs-5" htmlFor="replayEnabled">Include Session Replay<sup><span className="badge badge-pill badge-primary beta-tab-badge">NEW</span></sup></Form.Label>
                          </div>
                        </Col>
                      </Row>
                      <CardGroup className="p-0 mb-4 col justify-content-center">
                        <TransitionGroup component={null}>
                          {this.state.plans.concat([{id: 'custom-plan'}]).map(({ id, unitAmount, product, currency }, i) => (
                            <CSSTransition key={i} appear={true} timeout={100} classNames="plan-fade">
                              <Col className="plan-card-col" data-testid={id}>
                                <div className={`card-border-${id === 'custom-plan' ? 'custom' : i}`}>
                                  <Card key={i} className="m-0 px-3 py-4 text-center">
                                    <Card.Body className="p-0 d-flex flex-column justify-content-between">
                                      {id === 'custom-plan' ? <>
                                        <small>Over</small>
                                        <p className="session-count-limit my-0">50,000</p>
                                        <small>sessions per month</small>
                                        <p className="custom-package-text mt-2 px-2">Zuko can track millions of visits across as many forms as you need with a custom package.</p> </> : <>
                                        <small>Up to</small>
                                        <p className="session-count-limit my-0">{commaNumber(product.metadata.sessionCountLimit)}</p>
                                        <small>sessions per month</small>
                                        <SwitchTransition>
                                          <CSSTransition key={this.state.selectedPlanInterval} timeout={100} classNames="amount-fade"><div>
                                            <SwitchTransition>
                                              <CSSTransition key={currency} timeout={100} classNames="amount-fade">
                                                <p className="monthly-change-amount my-0">{`${this.getCurrencySymbolFromCode(currency)}${commaNumber(((this.state.selectedPlanInterval === 'year' ? unitAmount/12 : unitAmount) / 100))}`}</p>
                                              </CSSTransition></SwitchTransition>
                                            <small>per month{this.state.selectedPlanInterval === 'year' && <span className="smaller-text text-nowrap"> (billed annually)</span>}</small>
                                          </div>
                                          </CSSTransition>
                                        </SwitchTransition>
                                      </>
                                      }
                                      <div className="features-list text-start d-flex justify-content-center">
                                        <ul className={`${product?.metadata?.sessionCountLimit === '5000' ? 'lite-list' : ''}`}>
                                          {this.state.replayEnabled && <li className="session-replay-item"><span>Session replay included</span></li>}
                                          <li><TiTick size="18px" className="tick-icon"/> Unlimited trackable forms</li>
                                          <li><TiTick size="18px" className="tick-icon"/> Unlimited users</li>
                                          {(id === 'custom-plan' || product?.metadata.sessionCountLimit !== '5000') && <>
                                            <li><TiTick size="18px" className="tick-icon"/> Implementation support</li>
                                            <li><TiTick size="18px" className="tick-icon"/> All features and reports</li>
                                          </>}
                                          {product?.metadata?.sessionCountLimit === '5000'&& <>
                                            <li><TiTick size="18px" className="tick-icon"/> Access to basic reports</li>
                                          </>}
                                          {id === 'custom-plan' &&
                                            <li><TiTick size="18px" className="tick-icon"/> API access</li>
                                          }
                                        </ul>
                                      </div>
                                      <div className="align-self-center">
                                        {id === 'custom-plan' ? <a href="mailto:sales@zuko.io?subject=Zuko Custom Package Request" className="choose-plan-btn btn btn-primary">Email Sales</a> :
                                          <Button variant="primary" className="choose-plan-btn justify-self-center"
                                            onClick={() => this.handlePlanSelect({
                                              id,
                                              sessionCountLimit: product.metadata.sessionCountLimit,
                                              replayEnabled: product.metadata.replayEnabled,
                                              unitAmount,
                                              currency,
                                            })}>Select Plan</Button>}
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </Col>
                            </CSSTransition>
                          ))}
                        </TransitionGroup>
                      </CardGroup>
                      <Row className="g-0 text-center">
                        <Col>
                          <p>By signing up to a paid plan with us, you hereby agree to our <a href="https://www.zuko.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a>.</p>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row className="g-0">
                        <Col className="text-center">
                          <h1 className="step-title">FAQs</h1>
                        </Col>
                      </Row>
                      <Row className="g-0 faqs-row">
                        <Col md={4}>
                          <Card>
                            <Card.Body>
                              <Card.Title>I've used up my free trial allowance. When will tracking restart?</Card.Title>
                              <Card.Text>Your new sessions will appear in your account and start tracking within 10 minutes of successful payment. Please check for incoming events in the <Link to="/live">Live</Link> chart.</Card.Text>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body>
                              <Card.Title>Can we pay by invoice / bank transfer?</Card.Title>
                              <Card.Text>If you are on a custom package or are prepared to sign up for a 12 month period we can accept payment on invoice.</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card>
                            <Card.Body>
                              <Card.Title>What happens if our forms reach the monthly session limit?</Card.Title>
                              <Card.Text>If your forms hit your session limit for the month they will stop tracking until the next month begins.</Card.Text>
                              <Card.Text>To avoid this, you can upgrade at any time within the Zuko app. You will be notified by email if you are approaching your monthly session limit.</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card>
                            <Card.Body>
                              <Card.Title>Which payment methods do you accept?</Card.Title>
                              <Card.Text>Payment for a selected plan is taken through Stripe which accepts credit cards, debit cards and many other payment methods from around the world.</Card.Text>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body>
                              <Card.Title>Will I have to pay taxes on top of the cost?</Card.Title>
                              <Card.Text>If you are based in the UK, you will be charged VAT at the current rate. Businesses based outside the UK will not be charged any local taxes.</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </>}
                  </div> :
                  <div>
                    <Row className="title-row billing-title-row g-0">
                      <Col className="p-0 text-center">
                        <h1 className="step-title">Enter Billing Details | Step 2 of 3</h1>
                      </Col>
                    </Row>
                    <div className="border-company-details mb-3">
                      <Card className="billing-details-card">
                        <Card.Body>
                          <Row className="g-0 card-content">
                            {(this.state.creatingCustomer || this.state.creatingSession) &&
                                <Col className="transferring-msg-col d-flex justify-content-center align-items-center">
                                  <p className="text-center"><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Transferring to payment...</p>
                                </Col>}
                            {!this.state.creatingCustomer && !this.state.creatingSession &&
                              <Col>
                                <ChoosePlanCompanyDetailsForm
                                  zukoSlug={zukoSlug}
                                  zukoRef={this.zukoRef}
                                  handleSubmit={this.handleCompanyInfoSubmit}
                                  customer={this.state.customer}
                                  selectedPlan={this.state.selectedPlan}
                                  selectedPlanInterval={this.state.selectedPlanInterval}
                                  getCurrencySymbolFromCode={this.getCurrencySymbolFromCode}
                                  creatingCustomerError={this.state.creatingCustomerError}
                                  handleCancelCreateCustomer={this.handleCancelCreateCustomer} />
                              </Col>
                            }
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                    <Row className="g-0 text-center">
                      <Col>
                        <p>By signing up to a paid plan with us, you hereby agree to our <a href="https://www.zuko.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a>.</p>
                      </Col>
                    </Row>
                  </div>}
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </div>
        <script src="https://js.stripe.com/v3/"/>
      </Container>
    );
  }
}

const ChoosePlanWithRouter = withRouter(ChoosePlan);

export default ChoosePlanWithRouter;
