import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import Col from 'react-bootstrap/Col';
import CompletionPathArrow from './CompletionPathArrow';
import { formatToPercentOneDecimal, formatToCurrencyWithDecimals } from '../../utils';
import ReplayLinkGridItem from '../../Components/ReplayLinkGridItem';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { TfiBarChartAlt } from "react-icons/tfi";
import {
  compileQueryString,
} from '../../utils';

const CompletionPathChart = ({completionPathData, lostOpportunityValues, currencyCode, setShowSessionReplayCTAModal, submitField}) => {
  const {
    query,
  } = useContext(AppContext);

  return (
    <div className="grid-scrollable-area" data-testid="completion-path-chart-wrapper">
      <Col id="session-flows-chart-grid" className="p-0">
        <div className="views-cell d-flex flex-column align-items-center justify-content-center text-center">
          <span className="stats-container">
            <p className="mb-0 stat-label text-center">Views</p>
            <span className="stat-number text-center">{completionPathData[0][0].toLocaleString()}</span>
          </span>
        </div>
        <div className="arrow-cell-1-top position-relative">
          <div className="arrow-overlay-top position-absolute"><p className="stat-number mb-0 px-1">{formatToPercentOneDecimal(completionPathData[1][0][1])}</p></div>
        </div>
        <div className="arrow-cell-1">
          <CompletionPathArrow gradientNumber={1} />
        </div>
        <div className="arrow-cell-1-bottom position-relative">
          <div className="arrow-overlay-bottom position-absolute"><p className="stat-number mb-0 px-1">{formatToPercentOneDecimal(completionPathData[1][1][1])}</p></div>
        </div>
        <div className="starters-cell d-flex flex-column align-items-center justify-content-center text-center">
          <span className="stats-container">
            <p className="mb-0 stat-label text-center">Starters</p>
            <span className="stat-number text-center">{completionPathData[1][0][0].toLocaleString()}</span>
          </span>
        </div>
        <div className="non-starters-cell d-flex flex-column align-items-center justify-content-center text-center">
          <span className="stats-container">
            <p className="mb-0 stat-label text-center">Non-starters</p>
            <span className="stat-number text-center">{completionPathData[1][1][0].toLocaleString()}</span>
            <div className="lost-opportunity-cell-figure d-flex flex-column align-items-center justify-content-center text-center">
              <p className="mb-0 stat-label text-center">Lost opportunity</p>
              <span className="stat-number text-center">{lostOpportunityValues?.length > 0 ?
                formatToCurrencyWithDecimals({
                  currencyCode,
                  decimalPlaces: 0,
                  value: lostOpportunityValues[0],
                }) : '-'}</span>
            </div>
          </span>
        </div>
        <div className="arrow-cell-2-top position-relative">
          <div className="arrow-overlay-top position-absolute"><p className="stat-number mb-0 px-1">{formatToPercentOneDecimal(completionPathData[2][0][1])}</p></div>
        </div>
        <div className="arrow-cell-2">
          <CompletionPathArrow gradientNumber={2} />
        </div>
        <div className="arrow-cell-2-bottom position-relative">
          <div className="arrow-overlay-bottom position-absolute"><p className="stat-number mb-0 px-1">{formatToPercentOneDecimal(completionPathData[2][1][1])}</p></div>
        </div>
        <div className="submit-attempted-cell wide-cell d-flex flex-column align-items-center justify-content-center text-center">
          <span className="stats-container">
            <p className="mb-0 stat-label text-center">Submit attempted</p>
            <div className="d-grid replay-link-grid">
              <div></div>
              <span className="stat-number text-center">{completionPathData[2][0][0].toLocaleString()}</span>
              <div className="ps-3">
                {(completionPathData[3][1][0] && completionPathData[3][1][0] !== '0') ?
                  <div className="grid-item">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="page-link-tooltip" className="fs-6">Open <i>Field Flow</i> for sessions where submit was attempted.
                          <br></br><br></br>
                        Which fields did non-completions jump back to?</Tooltip>}>
                      <Link to={`/field-flow${(query?.form && query?.time && submitField) ? compileQueryString({form: query.form, time: query.time, filters: query.filters,
                        sessionOutcomes: [],
                        fieldFlow: {
                          flowFieldIdentifier: submitField?.identifier,
                        }
                      }) : ''}`}
                      className={`video-icon-wrap`}>
                        <TfiBarChartAlt size="18px" className={`grey-icon transform-rotate-90`}/></Link>
                    </OverlayTrigger>
                  </div> : null}
              </div>
            </div>
          </span>
        </div>
        <div className="abandoned-before-cell wide-cell d-flex flex-column align-items-center justify-content-center text-center">
          <span className="stats-container">
            <p className="mb-0 stat-label text-center">Abandoned without submitting</p>
            <span className="stat-number text-center">{completionPathData[2][1][0].toLocaleString()}</span>
            <div className="lost-opportunity-cell-figure d-flex flex-column align-items-center justify-content-center text-center">
              <p className="mb-0 stat-label text-center">Lost opportunity</p>
              <span className="stat-number text-center">{lostOpportunityValues?.length > 0 ?
                formatToCurrencyWithDecimals({
                  currencyCode,
                  decimalPlaces: 0,
                  value: lostOpportunityValues[1],
                }) : '-'}</span>
            </div>
          </span>
        </div>
        <div className="arrow-cell-3-top position-relative">
          <div className="arrow-overlay-top position-absolute"><p className="stat-number mb-0 px-1">{formatToPercentOneDecimal(completionPathData[3][0][1])}</p></div>
        </div>
        <div className="arrow-cell-3">
          <CompletionPathArrow gradientNumber={3} />
        </div>
        <div className="arrow-cell-3-bottom position-relative">
          <div className="arrow-overlay-bottom position-absolute"><p className="stat-number mb-0 px-1">{formatToPercentOneDecimal(completionPathData[3][1][1])}</p></div>
        </div>
        <div className="submit-success-cell wide-cell d-flex flex-column align-items-center justify-content-center text-center">
          <span className="stats-container">
            <p className="mb-0 stat-label text-center">Completed on first submit</p>
            <span className="stat-number text-center">{completionPathData[3][0][0].toLocaleString()}</span>
          </span>
        </div>
        <div className="submit-failed-cell wide-cell d-flex flex-column align-items-center justify-content-center text-center">
          <p className="mb-0 stat-label text-center">First submit failed</p>
          <span className="stat-number text-center">{completionPathData[3][1][0].toLocaleString()}</span>
        </div>
        <div className="arrow-cell-4-top position-relative">
          <div className="arrow-overlay-top position-absolute">{completionPathData[4][0][1] ?
            <p className="stat-number mb-0 px-1">{formatToPercentOneDecimal(completionPathData[4][0][1])}</p> : null}
          </div>
        </div>
        <div className="arrow-cell-4">
          <CompletionPathArrow gradientNumber={4} />
        </div>
        <div className="arrow-cell-4-bottom position-relative">
          <div className="arrow-overlay-bottom position-absolute">{completionPathData[4][1][1] ?
            <p className="stat-number mb-0 px-1">{formatToPercentOneDecimal(completionPathData[4][1][1])}</p> : null}
          </div>
        </div>
        <div className="re-submit-success-cell wide-cell d-flex flex-column align-items-center justify-content-center text-center">
          <span className="stats-container">
            <p className="mb-0 stat-label text-center">Completed on a further submit</p>
            <div className="d-grid replay-link-grid">
              <div></div>
              <span className="stat-number text-center">{completionPathData[4][0][0].toLocaleString()}</span>
              <div className="ps-3">
                {(completionPathData[4][0][0] && completionPathData[4][0][0] !== '0') ?
                  <ReplayLinkGridItem
                    loading={false}
                    tooltipText={<>Open <i>Session Replay</i> for sessions where first attempt at submit failed, but subsequent attempt successful</>}
                    setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                    replayEnabled={query?.form?.organisation?.replayEnabled}
                    queryStringObj={(query?.form && query?.time && submitField) ? {form: query.form, time: query.time, filters: query.filters,
                      sessionOutcomes: [{value: 'completed'}],
                      sessionFilters: {
                        abandonedFieldIdentifier: null,
                        interactedWithFieldIdentifiers: [submitField.identifier],
                        metrics: [{
                          for: submitField.identifier,
                          operator: 'gte',
                          type: 'rtf_count',
                          value: '1'
                        }],
                      }} : null}
                  /> : null}
              </div>
            </div>
          </span>
        </div>
        <div className="submit-abandon-cell wide-cell d-flex flex-column align-items-center justify-content-center text-center">
          <span className="stats-container">
            <p className="mb-0 stat-label text-center">Abandoned after submit attempt</p>
            <div className="d-grid replay-link-grid">
              <div></div>
              <span className="stat-number text-center">{completionPathData[4][1][0].toLocaleString()}</span>
              <div className="ps-3">
                {(completionPathData[4][1][0] && completionPathData[4][1][0] !== '0') ?
                  <ReplayLinkGridItem
                    loading={false}
                    tooltipText={<>Open <i>Session Replay</i> for sessions where all attempts at submit failed</>}
                    setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                    replayEnabled={query?.form?.organisation?.replayEnabled}
                    queryStringObj={(query?.form && query?.time && submitField) ? {form: query.form, time: query.time, filters: query.filters,
                      sessionOutcomes: [{value: 'abandoned'}],
                      sessionFilters: {
                        abandonedFieldIdentifier: null,
                        interactedWithFieldIdentifiers: [submitField.identifier],
                        metrics: [],
                      }} : null}
                  /> : null}
              </div>
            </div>
            <div className="lost-opportunity-cell-figure d-flex flex-column align-items-center justify-content-center text-center">
              <p className="mb-0 stat-label text-center">Lost opportunity</p>
              <span className="stat-number text-center">{lostOpportunityValues?.length > 0 ?
                formatToCurrencyWithDecimals({
                  currencyCode,
                  decimalPlaces: 0,
                  value: lostOpportunityValues[2],
                }) : '-'}</span>
            </div>
          </span>
        </div>
      </Col>
    </div>
  );
};

export default CompletionPathChart;