import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { VscChromeClose } from "react-icons/vsc";

import AlertsChartImg from '../images/AlertsChart.png';
import AlertsConfigImg from '../images/AlertsConfig.png';

import './Alerts.scss';

const AlertsInfoOverlay = ({mixpanel, showInfo, setShowInfo}) => {
  return (
    <div className={`page-info-card ${showInfo === null ? 'd-none' : ''} ${showInfo ? 'open' : 'closed'}`}>
      <div className="card-contents">
        <Row className="g-0 card-title-row">
          <Col className="p-0">
            <Card.Title as="h3">Alerts</Card.Title>
          </Col>
          <Col className="p-0 text-end card-tooltip">
            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowInfo(false)} title="Return to page"/>
          </Col>
        </Row>
        <Row className="g-0 text-content">
          <Col lg={7} className="ps-0">
            <Card.Text className="mb-3 subtitle">You can choose to be alerted by email when there is a change in visitors completing your form, or a change in visitors abandoning your form.</Card.Text>
            <Card.Text>Typically alerts are configured to inform you when there are low numbers of completions, or high numbers of abandoned sessions on your forms.</Card.Text>
            <Card.Text>Start by adding an alert for one of your forms, and select the metric type you want to be alerted about.</Card.Text>
            <dl>
              <dt>Completed Session Count</dt>
              <dd>A completed session is counted when Zuko has received a <code>completion</code> event after interaction events. You will have set up the <code>completion</code> event to fire
                        when the visitor successfully completes your form.</dd>
              <dt>Abandoned Session Count</dt>
              <dd>An abandoned session is counted when Zuko stops receiving interaction events for a session - if a visitor does not complete your form, or is inactive for 30 minutes, then this
                          becomes an abandoned session.</dd>
              <dt>Abandon Rate</dt>
              <dd>The % of visitors who started your form, but did not complete or remained inactive for 30 minutes.</dd>
              <dt>Completion Rate</dt>
              <dd>The % of visitors who started your form and then went on to complete.</dd>
            </dl>
            <Card.Text>The graph plots the hourly value for that metric over the selected time period to give you a reference point for choosing your threshold.</Card.Text>
            <figure className="text-center img-wrapper">
              <img id="Alerts-chart-Img" src={AlertsChartImg} alt="Alerts-chart" className="card-info-img"></img>
            </figure>
            <Card.Text>You then set the parameters for when the abandon or completion count for a form is higher or lower than average. When that threshold is reached you’ll receive an email alert.</Card.Text>
            <Card.Text>Alerts can be sent at all hours of the day or during specific hours. Your form may experience low completion rates during the night time and so you may wish to choose to be alerted during business hours only.</Card.Text>
            <figure className="text-center img-wrapper">
              <img id="Alerts-config-Img" src={AlertsConfigImg} alt="Alerts-config" className="card-info-img"></img>
            </figure>
            <Card.Text>All email addresses subscribed will be sent a confirmation email and will need to opt in to receive the alerts they are subscribed to. Recipients can unsubscribe at any time through a link in each alerts email.</Card.Text>
          </Col>
          <Col lg={5}>
            <div className="card-tip-box">
              <h4 className="pb-3">How to use this and what to look for</h4>
              <Card.Text>Setting up alerts for your forms provides an early warning sign of performance issues. You can catch unexpected spikes or dips in abandon or completion rates.</Card.Text>
              <Card.Text>It can be useful to set up multiple alerts for the same form, either for different metrics, different thresholds and to send to different visitors.</Card.Text>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AlertsInfoOverlay;
