import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import AppContext from '../AppContext';

import { invalidTimeZones } from '../Page/OrgEdit';

import './OrgDetailsForm.scss';

const OrgAgencyClientDetailsForm = ({type, handleSubmit, org, orgAgency, agencyForClient,
  handleResetErrorMessagesForField, errorMessagesForField,
  handleResetSuccessMessage, currentUserIsStandard }) => {
  const {
    currentUser,
  } = useContext(AppContext);
  const [name, setName] = useState();
  const [sessionLimit, setSessionLimit] = useState();
  const [replayEnabled, setReplayEnabled] = useState();
  const [, setContractType] = useState();
  const [timeZone, setTimeZone] = useState();
  const [notes, setNotes] = useState();
  const [saveAttempted, setSaveAttempted] = useState();

  useEffect(() => {
    if (org) {
      setName(org.name);
      setTimeZone(org.timeZone);
      setNotes(org.notes);
      setReplayEnabled(org.replayEnabled);
      setSessionLimit(org.sessionLimit);
      setContractType(org.contractType);
    }

    if (type === 'create' && !org) {
      setTimeZone(orgAgency?.timeZone);
      setSessionLimit(0);
      setContractType(orgAgency?.contractType);
    }
  },[type, org, orgAgency]);

  const nameIsReadOnly = () => {
    if (currentUser.accountManager) return false;
    if (currentUserIsStandard) return true;
    return !currentUser.organisations.some(o => o.type === 'Agency');
  };

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      if (!saveAttempted) setSaveAttempted(true);

      handleSubmit({name, timeZone, notes, sessionLimit, type: 'AgencyClient', replayEnabled,
        agencyUserIsEditingClient: currentUser.organisations.some(o => o.type === 'Agency')});
    }} className="zuko-app-form" id="org-details-form">
      <FormGroup className="form-group" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" maxLength={255} required
          className={(saveAttempted && !name) && "invalid-input"}
          readOnly={nameIsReadOnly()}
          value={name || ''} onChange={({target: {value}}) => {
            setName(value);
            handleResetErrorMessagesForField('name');
            if (handleResetSuccessMessage) handleResetSuccessMessage();
          }}/>
        {(saveAttempted && !name) && <div className="invalid-input-feedback">Please add a name.</div>}
      </FormGroup>

      <FormGroup className="form-group" controlId="timeZone">
        <Form.Label>Time Zone</Form.Label>
        <Form.Control as="select" required
          className="w-50" readOnly={currentUserIsStandard} disabled={currentUserIsStandard}
          value={timeZone || 'UTC'} onChange={({target: {value}}) => {
            setTimeZone(value);
            handleResetErrorMessagesForField('timeZone');
            if (handleResetSuccessMessage) handleResetSuccessMessage();
          }}>
          <option value="" disabled hidden>Select&hellip;</option>
          {moment.tz.names().map(t => {
            return !invalidTimeZones.includes(t) ? <option value={t} key={t}>{t}</option> : null;
          })}
        </Form.Control>
      </FormGroup>

      {(currentUser.accountManager) && <>
        <FormGroup className="form-group" controlId="sessionLimit">
          <Form.Label>Session Limit</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          <Form.Control className={`w-25 ${((saveAttempted && sessionLimit === '') || errorMessagesForField?.hasOwnProperty('sessionLimit')) && "invalid-input"}`} type="number"
            readOnly={!currentUser.accountManager}
            value={sessionLimit ?? ''} onChange={({target: {value}}) => {
              setSessionLimit(value);
              handleResetErrorMessagesForField('sessionLimit');
              if (handleResetSuccessMessage) handleResetSuccessMessage();
            }}/>
          <div className="input-feedback">You can edit here, but it is best to go to the Agency's Manage Session Limits tab.</div>
        </FormGroup>

        {type === 'edit' && <FormGroup className="form-group" controlId="replayEnabled">
          <div className="d-inline-flex align-items-center">
            <Form.Check type="checkbox" checked={replayEnabled}
              onChange={(e) => {
                setReplayEnabled(e.target.checked);
              }}/>
            <Form.Label className="mb-0 ms-2">Session Replay Enabled</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          </div>
          <div className="input-feedback">
            <p className="mb-0">With replay enabled, all trackable forms in this Organisation will be able to send recording data.</p>
          </div>
        </FormGroup>}

        <FormGroup className="form-group" controlId="notes">
          <Form.Label>Notes</Form.Label><span className="am-only-input-tag ms-2">AM only</span>
          <Form.Control type="text" maxLength={255}
            value={notes || ''} onChange={({target: {value}}) => {
              setNotes(value);
              handleResetErrorMessagesForField('notes');
              if (handleResetSuccessMessage) handleResetSuccessMessage();
            }}/>
        </FormGroup>
      </>}

      <Row className="form-actions-row">
        {!currentUserIsStandard &&
        <Col className="p-0">
          <Button variant="primary" className="submit ms-0" type="submit">Save</Button>
        </Col>}
      </Row>
      <div className="mt-3">{agencyForClient && <Link to={`/organisations/${agencyForClient.uuid}/edit`}>Go back to agency</Link>}</div>
    </Form>);
};

export default OrgAgencyClientDetailsForm;
