import React, { useState, useRef, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination,useFlexLayout } from 'react-table';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import { BiColumns } from 'react-icons/bi';
import { VscWarning } from 'react-icons/vsc';
import { FaSortDown, FaSortUp, FaAngleDown, FaSpinner } from 'react-icons/fa';


function GlobalFilter({globalFilter, setGlobalFilter}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = (value) => setGlobalFilter(value || undefined);
  return (
    <span className="datatable-search-filter">
      <label className="me-2">Search:</label>
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </span>
  );
}

const SubmissionsTable = ({
  columns,
  data,
  totalCurrentMonthSubmissions,
  handleDeleteSubmissions,
  isDeletingSubmissions,
  deleteSubmissionsError,
  deleteSubmissionsSuccess,
  handleRemoveDeleteSubmissionsMessages,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state,
    rows,
    prepareRow,
    allColumns,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    initialState: {
      pageSize: 10,
      sortBy: [{ id: 'savedAt', desc: true }],
    },
  },
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  usePagination,
  );
  const [selectRows, setSelectRows] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState();
  const outsideOverlay = useRef();

  useEffect(() => {
    if (deleteSubmissionsSuccess) {
      setShowConfirmDelete(false);
      setSelectRows([]);
    }
  }, [deleteSubmissionsSuccess]);

  return (
    <div className="submissions-table">
      {columns?.length > 0 &&
      <div className="d-flex flex-wrap justify-content-between table-search-row browser-only align-items-center pb-2">
        <div>
          <p className="card-title-stats mb-0">Submissions this month: {totalCurrentMonthSubmissions.toLocaleString()}</p>
        </div>
        <div className="d-flex flex-wrap align-items-center">
          <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter}/>
          <OverlayTrigger placement="bottom" trigger={'click'} rootClose overlay={<Popover id="submissions-table-column-toggle">
            <Popover.Body>
              {allColumns.map((column) => {
                return (
                  <div key={column.id} className="mb-2">
                    <label className="d-flex align-items-center">
                      <input type="checkbox"  className="me-2" {...column.getToggleHiddenProps()}
                        disabled={(!state.hiddenColumns.includes(column.id) && (state.hiddenColumns.length + 1 === columns.length))} // Don't allow final column to be hidden
                      />{' '}
                      {column.id}
                    </label>
                  </div>
                );
              })}
            </Popover.Body>
          </Popover>}>
            <Button className="px-3 mx-2 d-flex align-items-center toggle-columns-btn" variant="outline-secondary"><BiColumns className="me-1" size="16px"/>Manage Columns</Button>
          </OverlayTrigger>
        </div>
      </div>}
      <div className="table-wrap mb-3 ms-2">
        <Table className="sentry-mask datatable mb-0" striped hover borderless {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
                <th className="selection-column d-flex justify-content-center align-items-center" ref={outsideOverlay}>
                  <Form.Check
                    type="checkbox"
                    aria-label="Select All Submissions"
                    id={`select-box-all`}
                    value={`select-all`}
                    checked={selectRows.length === page.length}
                    title={selectRows.length === page.length ? 'Select all rows' : ''}
                    onChange={() => {
                      setSelectRows(selectRows.length === page.length ? [] : [...new Array(page.length).keys()]);
                    }}>
                  </Form.Check>
                  <OverlayTrigger placement="bottom" trigger={'click'} rootClose overlay={<Popover id="submissions-table-select-action">
                    <Popover.Body>
                      <Row className="g-0 header-row text-start"><h5>Actions</h5></Row>
                      <div className="pt-2">
                        <p className="clickable-text mb-0" onClick={() => {
                          outsideOverlay.current.click();
                          setShowConfirmDelete(true);
                        }}>Delete Submissions</p>
                      </div>
                    </Popover.Body>
                  </Popover>}>
                    <Button className="ms-2 btn-style-removed btn-dropdown-selection-menu"><FaAngleDown className="angle-icon" size="16px"/></Button>
                  </OverlayTrigger>
                </th>
                {headerGroup.headers.map(column => {
                  return (
                    <th className={`header-row-${column.id} d-flex justify-content-center align-items-center`}
                      {...column.getHeaderProps(column.getSortByToggleProps())} title="Sort up/down"><div className="header-text-with-sort">{column.render('Header')}</div>
                      <div className="table-sort-icons d-flex flex-column justify-content-center">
                        {column.canSort && <>
                          <FaSortUp className={`sort-up ${(column.isSorted && !column.isSortedDesc) ? 'sorted' : ''}`} size="16px"/>
                          <FaSortDown className={`sort-down ${(column.isSorted && column.isSortedDesc) ? 'sorted' : ''}`} size="16px"/>
                        </>}
                      </div>
                    </th>);
                })}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const rowSelected = selectRows.includes(i);
              return (
                <tr {...row.getRowProps()}>
                  <td className="selection-column">
                    <Form.Check
                      type="checkbox"
                      aria-label="Select submission"
                      id={`select-box-${i}`}
                      value={`select-${i}`}
                      checked={rowSelected}
                      title={rowSelected ? 'Select this row' : ''}
                      onChange={() => {
                        setSelectRows(rowSelected ? selectRows.filter(r => r !== i) : selectRows.concat(i));
                      }}>
                    </Form.Check>
                  </td>
                  {row.cells.map(cell => {
                    const isBoolean = typeof cell.value === 'boolean';
                    return (
                      <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>{isBoolean ? String(cell.value) : cell.render('Cell')}</td>
                    );})}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {rows.length > 0 &&
          <div className="d-flex justify-content-between align-items-center paginate-info-row">
            <FormGroup className="d-flex align-items-center" controlId="show-rows">
              <p className="mb-0 text-nowrap">Submissions per page:</p>
              <Form.Select value={state.pageSize} aria-label="Submissions per page" onChange={e => setPageSize(Number(e.target.value))} className="ms-2">
                {[10, 50, 100].map((size) => (<option key={size} value={size}>{size}</option>))}
              </Form.Select>
            </FormGroup>
            <div className="pagination p-2 justify-content-center">
              {rows?.length > 10 && <>
                <div className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
                </div>
                <div className={`page-item ${!canNextPage ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
                </div>
              </>}
            </div>
            <div>
              <p className="mb-0">Showing page {state.pageIndex +1} of {pageCount} pages</p>
            </div>
          </div>}

      <Modal
        centered
        show={showConfirmDelete ? true : false}
        onExited={() => handleRemoveDeleteSubmissionsMessages()}
        onHide={() => setShowConfirmDelete(false)}
        id="builder-delete-submissions-modal"  aria-labelledby="builder-delete-submissions-modal">
        <Modal.Header>
          <Modal.Title as={'h3'}>Delete Submissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteSubmissionsError &&
            <Alert variant="outline-danger">
              <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
              <p className="alert-text m-0">{deleteSubmissionsError}</p>
            </Alert>}
          {isDeletingSubmissions &&
              <div className="loading-area d-flex justify-content-center align-items-center">
                <FaSpinner size="18px" className="spinning-icon" title="Deleting submissions..."/>
              </div>}
          {!isDeletingSubmissions && selectRows.length > 0 && <>
            <p><strong>Permanently delete selected submissions</strong>.</p>
            <p>You have selected {selectRows.length} submissions to delete. They cannot be restored. Before deletion, you can back up the unwanted submissions  by exporting them to CSV.</p>
          </>}
          {!isDeletingSubmissions && !selectRows.length > 0 &&
            <p>No submissions selected yet.</p>}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="outline-secondary" className="cancel mx-1" onClick={() => setShowConfirmDelete(false)}>Cancel</Button>
          {selectRows.length > 0 && <Button variant="danger" className="mx-1" onClick={() => {
            handleDeleteSubmissions({
              submissions: page
                .filter((p, i) => selectRows.includes(i))
                .map(p => p.original),
            });
          }}>Delete Selected</Button>}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default React.memo(SubmissionsTable);
