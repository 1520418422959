import { useState, useEffect, useContext } from 'react';
import BuilderContext from './Context';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import NavBar from './NavBar';
import Forms from './Forms';
import ProfileEdit from '../Page/ProfileEdit';
import BuilderOrgEdit from './OrgEdit';
import BuilderChoosePlan from './ChoosePlan';
import api from '../api';
import './index.scss';
import AppContext from '../AppContext';

// TODO: add app alerts

const Builder = ({mixpanel}) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [formsLoading, setFormsLoading] = useState();
  const [formsError, setFormsError] = useState();
  const [forms, setForms] = useState();
  const [form, setForm] = useState();

  const { currentUser } = useContext(AppContext);

  // Reset state for a new form
  useEffect(() => {
    setFormsError(null);
  }, [form?.uuid]);

  useEffect(() => {
    if (currentUser.organisations.length) {
      (async () => {
        try {
          setFormsLoading(true);
          setFormsError(null);
          const { data: { forms } } = await api.get('/builder/forms');
          setForms(forms);
        } catch (e) {
          const defaultError = 'Sorry, something went wrong loading forms. Please refresh the page.';
          if (e?.response) {
            switch (e.response?.status) {
            case 401:
              setFormsError('Not logged in');
              break;
            case 403:
              history.replace('/builder/forms');
              break;
            default:
              setFormsError(defaultError);
            }
          } else {
            setFormsError(defaultError);
          }

        } finally {
          setFormsLoading(false);
        }
      })();
    } else {
      setFormsError('This user is not a member of any organisations.');
    }
  }, [history, currentUser.organisations]);

  // Update forms anytime form changes
  useEffect(() => {
    if (form && (form?.uuid !== 'new')) setForms(prev => {
      if (prev) {
        const index = prev.findIndex(f => f.uuid === form.uuid);
        if (index > -1) prev[index] = form;
        if (index === -1) prev = prev.concat(form); // New form
      }
      return [...prev];
    });
  }, [form]);

  useEffect(() => {
    // Identify the mixpanel user on page load for any subsequent tracking
    mixpanel?.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  return (
    <Container fluid className="form-builder page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Builder</title>
      </Helmet>
      <div className="nav-wrapper browser-only">
        <NavBar mixpanel={mixpanel} form={form}/>
      </div>

      <main>
        <BuilderContext.Provider value={{
          forms, form, setForm, setFormsError, formsLoading, formsError,
        }}>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/forms`} />
            </Route>
            <Route path={`${path}/profile`}>
              <ProfileEdit mixpanel={mixpanel} product={'builder'}/>
            </Route>
            <Route path={`${path}/organisations/:uuid/edit`}>
              <BuilderOrgEdit mixpanel={mixpanel} />
            </Route>
            <Route path={`${path}/organisations/:uuid/choose-plan`}>
              <BuilderChoosePlan mixpanel={mixpanel} />
            </Route>
            <Route exact path={`${path}/forms`}>
              <Forms mixpanel={mixpanel}/>
            </Route>
            <Route path={`${path}/forms/:formUuid`}>
              <Forms mixpanel={mixpanel}/>
            </Route>
          </Switch>
        </BuilderContext.Provider>
      </main>
    </Container>
  );
};

export default Builder;
