import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdContentCopy } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { VscWarning, VscCheck } from "react-icons/vsc";
import {
  Link,
} from "react-router-dom";
import './PublishingModal.scss';

const PublishModal = ({
  show,
  handleCloseModal,
  isPublishing,
  formUuid,
  hostedFormUrl,
  success,
  error,
}) => {
  const [urlCopyMsg, setUrlCopyMsg] = useState(false);
  const [showUrlCopyMsg, setShowUrlCopyMsg] = useState(false);

  const handleUrlCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(hostedFormUrl);
      setUrlCopyMsg('URL copied');
    } catch (e) {
      setUrlCopyMsg('Auto copy failed. Please try copying the URL manually.');
    } finally {
      setShowUrlCopyMsg(true);
    }
  };

  useEffect(() => {
    if (showUrlCopyMsg) setTimeout(() => {setShowUrlCopyMsg(null);}, 2500);
  },[showUrlCopyMsg]);

  return (
    <Modal
      centered
      show={show}
      onHide={handleCloseModal}
      backdrop="static" // To avoid closing by clicking outside
      keyboard={false} // To avoid closing by clicking esc
      id="builder-publishing-modal" className="d-flex" aria-labelledby="publish-form-modal">
      <Modal.Header closeButton={success}>
        <Modal.Title as={'h3'}>Publish</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center">
        {isPublishing && <div className="d-inline-flex justify-content-center">
          <FaSpinner size="20px" className="spinning-icon me-2"/>
          <p className="mb-0">Publishing is in progress...</p>
        </div>}
        {success && <>
          <Alert variant="outline-success" closeVariant="white">
            <div className="alert-svg-icon my-auto"><VscCheck size="26px"/></div>
            <p className="alert-text m-0">Your form has been successfully published.</p>
          </Alert>
          <div className="pt-3">
            <p className="mb-0">View your form here:</p>
            <a href={hostedFormUrl} target="_blank" rel="noopener noreferrer">{hostedFormUrl}</a>
            <div className="d-flex justify-content-center my-3">
              <OverlayTrigger show={showUrlCopyMsg ? true : false} placement="top"
                onExited={() => setShowUrlCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                overlay={
                  <Tooltip id="copy-text-tooltip" className="position-fixed">{urlCopyMsg}</Tooltip>}>
                <Button variant="outline-secondary" className="mx-0 copy-text-button d-flex align-items-center" onClick={handleUrlCopyClick}>
                  <MdContentCopy size="20px" title="Copy URL" className="me-1"/> Copy URL</Button>
              </OverlayTrigger>
            </div>
            <p>You can now start collecting submissions and analytics data. It may take a few minutes for analytics to first go live on your form.</p>
          </div>
          <Link to={`/builder/forms/${formUuid}/share-embed`} className="text-center" onClick={() =>  handleCloseModal()}><Button variant="primary" className="builder-primary-cta mx-auto">Share/Embed</Button></Link>
        </>}
        {error && <>
          <Alert variant="danger" closeVariant="white">
            <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
            <p className="alert-text m-0" dangerouslySetInnerHTML={{ __html: error }} />
          </Alert>
          <Button variant="outline-primary" className="builder-outline-primary-cta mx-auto my-3" onClick={() => handleCloseModal()}>Close</Button>
        </>}
      </Modal.Body>
    </Modal>);
};

export default PublishModal;
