import React, { useEffect, useState, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import AppContext from '../../AppContext';

import '../../Components/OrgDetailsForm.scss';

const OrgDetailsForm = ({org, type, handleSubmit,
  handleResetErrorMessagesForField, errorMessagesForField,
  handleResetSuccessMessage, currentUserIsStandard}) => {
  const {
    currentUser,
  } = useContext(AppContext);
  const [name, setName] = useState();
  const [submissionLimit, setSubmissionLimit] = useState();
  const [notes, setNotes] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [saveAttempted, setSaveAttempted] = useState(false);


  useEffect(() => {
    if (org) {
      setName(org.name);
      setSubmissionLimit(org.builderSubmissionLimit);
      setNotes(org.hasOwnProperty('notes') ? org.notes : '');
      setSubscriptionStatus(org.hasOwnProperty('subscriptionStatus') ? org.subscriptionStatus : undefined);
    }
  },[org, type]);

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      if (!saveAttempted) setSaveAttempted(true);

      handleSubmit({name, notes});
    }} className="zuko-app-form" id="org-details-form">
      <FormGroup className="form-group" controlId="name">
        <Form.Label>Name</Form.Label>
        <Col md={7}>
          <Form.Control className={((saveAttempted && !name) || errorMessagesForField?.hasOwnProperty('name')) && "invalid-input"} type="text" value={name || ''}
            readOnly={currentUserIsStandard} disabled={currentUserIsStandard}
            required placeholder="Organisation Name" maxLength={255} onChange={({target: {value}}) => {
              setName(value);
              handleResetErrorMessagesForField('name');
              if (handleResetSuccessMessage) handleResetSuccessMessage();
            }}/>
          {(saveAttempted && !name) && <div className="invalid-input-feedback">Please add a name.</div>}
        </Col>
      </FormGroup>

      <FormGroup className="form-group" controlId="submissionLimit">
        <Form.Label>Submission Limit</Form.Label>
        <Form.Control type="number" readOnly value={submissionLimit || ''} />
      </FormGroup>

      {(subscriptionStatus || (currentUser.accountManager && subscriptionStatus !== undefined)) &&
        <FormGroup className="form-group" controlId="subscriptionStatus">
          <Form.Label>Subscription Status</Form.Label>
          <Form.Control type="text" readOnly required value={subscriptionStatus || ''}>
          </Form.Control>
        </FormGroup>}

      {currentUser.accountManager &&
        <FormGroup className="form-group" controlId="notes">
          <Form.Label>Notes</Form.Label>
          <Form.Control type="text" value={notes || ''} maxLength={255} onChange={({target: {value}}) => {
            setNotes(value);
            handleResetErrorMessagesForField('notes');
            if (handleResetSuccessMessage) handleResetSuccessMessage();
          }}/>
        </FormGroup>}

      <Row className="form-actions-row">
        {!currentUserIsStandard &&
        <Col className="p-0">
          <Button variant="primary" className="submit ms-1 me-0" type="submit">Save</Button>
        </Col>}
      </Row>
    </Form>
  );
};

export default OrgDetailsForm;
