
import React, { useState, useEffect } from 'react';
import { HiLink } from 'react-icons/hi';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

/**
 * @typedef Props
 * @prop {string} [queryString]
 */

/**
 * @param {Props} props
 */
const CopyUrlIcon = ({queryString = ''}) => {
  const originalMsg = 'Share this report by copying the link.';
  const [showCopyUrlMsg, setShowCopyUrlMsg] = useState();
  const [copyUrlMsg, setCopyUrlMsg] = useState(originalMsg);

  const handleCopyUrl = async () => {
    try {
      setShowCopyUrlMsg(false);
      await navigator.clipboard.writeText(window.location.origin + window.location.pathname + queryString);
      setCopyUrlMsg('Copied!');
    } catch (e) {
      setCopyUrlMsg('Sorry, failed to copy to clipboard. Please try copying the URL manually.');
    } finally {
      setShowCopyUrlMsg(true);
    }
  };

  // Remove copy msgs after a short time
  useEffect(() => {
    if (showCopyUrlMsg && copyUrlMsg !== originalMsg) setTimeout(() => {setShowCopyUrlMsg(false);}, 2500);
  },[showCopyUrlMsg, copyUrlMsg]);

  return (<OverlayTrigger show={showCopyUrlMsg ? true : false}
    onExited={() => setCopyUrlMsg(originalMsg)} // Safely replace the message once the tooltip is no longer visible
    overlay={<Tooltip id="copy-url-icon-tooltip">{copyUrlMsg}</Tooltip>}>
    <div className="d-inline-block px-1 url-copy-icon" onMouseEnter={() => setShowCopyUrlMsg(true)} onMouseLeave={() => (copyUrlMsg === originalMsg && setShowCopyUrlMsg(false))} onClick={handleCopyUrl}>
      <HiLink size="20px" className="grey-icon" title="Click to copy URL"/>
    </div>
  </OverlayTrigger>);
};


export default CopyUrlIcon;