import { useState, useEffect, useContext } from 'react';
import BuilderContext from '../Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConfigureQuestion from './ConfigureQuestion';
import ConfigureTitle from './ConfigureTitle';
import ConfigureEditableText from './ConfigureEditableText';
import Alert from 'react-bootstrap/Alert';
import { VscInfo, VscChromeClose } from "react-icons/vsc";
import { CSSTransition } from 'react-transition-group';
import './Configure.scss';
import { componentTypeTitle } from './index';

const Configure = ({
  showConfigure,
  handleCloseConfigure,
  questionIndex,
  stepIndex,
  type,
  handleSaveConfigureItem,
  handleSaveInlineEditItem,
  handleAddQuestion,
  handleAddParagraphText,
  handleDeselectItem,
  handleRemoveMessages,
  currentKeys,
  questionKeysToUse,
}) => {
  const {
    form,
  } = useContext(BuilderContext);
  const [item, setItem] = useState();

  const displayQuestionType = type === 'question' && (item?.componentType && componentTypeTitle({componentType: item.componentType}));

  useEffect(() => {
    switch (type) {
    case 'question':
    case 'paragraph-text':
      setItem({...form.builderSteps[stepIndex].builderQuestions[questionIndex]});
      break;
    case 'step-title':
    case 'step-description':
      setItem({...form.builderSteps[stepIndex]});
      break;
    case 'form-title':
    case 'form-description':
      setItem({...form});
      break;
    case 'completion-message':
    case 'completion-title':
      setItem({
        ...form.completion || {title: '', message: ''}
      });
      break;
    default:
      return;
    }
  }, [type, stepIndex, questionIndex, form]);

  return (
    <CSSTransition
      in={showConfigure}
      timeout={400}
      classNames="slide-in-right-col"
      onExited={() => handleDeselectItem()}
      unmountOnExit>
      <Col className={`question-config p-0 scrollable-column d-block`}>
        <div className="config-container">
          <Row className="g-0 mb-2">
            <Col className="p-0">
              {displayQuestionType ? <h3 className="mb-0">Configure {displayQuestionType}</h3> : <h3 className="mb-0">Configure</h3>}
            </Col>
            <Col className={`p-0 col-auto d-flex align-items-center ${!showConfigure ?  'd-none' : ''}`}>
              <VscChromeClose size="20px" className="grey-icon" onClick={() => handleCloseConfigure()} title="Close"/>
            </Col>
          </Row>

          {(!item || (item && !type)) &&
        <Row className="g-0 justify-content-center">
          <Alert variant="info" className="my-3 w-auto">
            <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
            <p className="alert-text m-0">Select an element on the form to edit.</p>
          </Alert>
        </Row>}
          {item && item.hasOwnProperty('componentType') && item.componentType !== 'paragraphText' && type === 'question' && <ConfigureQuestion
            key={`${stepIndex}_${questionIndex}`}
            item={item}
            questionIndex={questionIndex}
            stepIndex={stepIndex}
            handleSaveInlineEditItem={handleSaveInlineEditItem}
            handleSaveConfigureItem={handleSaveConfigureItem}
            handleAddQuestion={handleAddQuestion}
            handleRemoveMessages={handleRemoveMessages}
            currentKeys={currentKeys}
            questionKeysToUse={questionKeysToUse}
          />}
          {item && !item.hasOwnProperty('componentType') && (type === 'step-title' || type === 'form-title' || type === 'completion-title') && <ConfigureTitle
            key={type}
            item={item}
            handleSaveConfigureItem={handleSaveConfigureItem}
            handleRemoveMessages={handleRemoveMessages}
            type={type}
            questionIndex={questionIndex}
            stepIndex={stepIndex}
            handleSaveInlineEditItem={handleSaveInlineEditItem}
          />}
          {item && !item.hasOwnProperty('componentType') && (type === 'form-description' || type === 'step-description' || type === 'completion-message') && <ConfigureEditableText
            key={type}
            item={item}
            type={type}
            handleSaveConfigureItem={handleSaveConfigureItem}
            handleRemoveMessages={handleRemoveMessages}
            questionIndex={questionIndex}
            stepIndex={stepIndex}
            handleSaveInlineEditItem={handleSaveInlineEditItem}
          />}
          {item && item.hasOwnProperty('componentType') && item.componentType === 'paragraphText' && (type === 'paragraph-text') && <ConfigureEditableText
            key={questionIndex} // TODO: flickers when open and move an item. Would be best to use a consistent UUID
            item={item}
            type={type}
            handleSaveConfigureItem={handleSaveConfigureItem}
            handleAddParagraphText={handleAddParagraphText}
            handleRemoveMessages={handleRemoveMessages}
            questionIndex={questionIndex}
            stepIndex={stepIndex}
            handleSaveInlineEditItem={handleSaveInlineEditItem}
          />}
        </div>
      </Col>
    </CSSTransition>
  );
};

export default Configure;
