import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReplayProvider from './replayContext';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import NavBar from '../../NavBar';
import ScrollToTop from '../../Components/ScrollToTop';
import AppContext from '../../AppContext';
import SessionsSearch from './SessionsSearch';
import Player from './Player';
import { useAppForms } from '../../hooks';

import './SessionReplay.scss';

const SessionReplay = ({mixpanel}) => {
  const { path } = useRouteMatch();
  const { currentUser,
  } = useContext(AppContext);
  useAppForms();

  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  useEffect(() => {
    mixpanel.track('Page View', Object.assign({ page: 'SessionReplay' }));
  }, [mixpanel]);

  return (
    <Container fluid className="session-replay page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Session Replay</title>
      </Helmet>
      <div className="nav-wrapper browser-only">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <main>
        <ScrollToTop />
        <ReplayProvider>
          <Switch>
            <Route exact path={path}>
              <SessionsSearch mixpanel={mixpanel}/>
            </Route>
            <Route path={`${path}/:replayId`}>
              <Player mixpanel={mixpanel} />
            </Route>
          </Switch>
        </ReplayProvider>
      </main>
    </Container>
  );
};

export default SessionReplay;
