import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BuilderContext from './Context';
import AppContext from '../AppContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import SummaryTable from '../Page/FieldData/SummaryTable.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import api from '../api';
import ScrollToTop from '../Components/ScrollToTop';
import DatePicker from '../Components/DatePicker';
import ChartTip from '../Components/ChartTip';
import moment from 'moment-timezone';
import { VscInfo } from 'react-icons/vsc';
import { AiOutlineLineChart } from "react-icons/ai";
import './Analytics.scss';

import { labelForField, identifierForField } from '../utils.js';
import { zScoreOfProportions } from '../Page/FieldData';

const Analytics = ({mixpanel}) => {
  const { currentUser, setCurrentOrg } = useContext(AppContext);
  const {
    form,
  } = useContext(BuilderContext);

  const [time, setTime] = useState();
  const [analyticsForm, setAnalyticsForm] = useState();
  const [analyticsFormLoading, setAnalyticsFormLoading] = useState();

  const [visProgress, setVisProgress] = useState(20);
  const [visData, setVisData] = useState();
  const [visLoading, setVisLoading] = useState();
  const [visError, setVisError] = useState();
  const [chartTip, setChartTip] = useState();

  const [abandonedFieldsOverviewLoading, setAbandonedFieldsOverviewLoading] = useState(true);
  const [abandonedFieldsOverviewData, setAbandonedFieldsOverviewData] = useState(null);
  const [fieldReturnsOverviewLoading, setFieldReturnsOverviewLoading] = useState(true);
  const [fieldReturnsOverviewData, setFieldReturnsOverviewData] = useState(null);
  const [fieldTimesOverviewLoading, setFieldTimesOverviewLoading] = useState(true);
  const [fieldTimesOverviewData, setFieldTimesOverviewData] = useState(null);

  const defaultError = 'Sorry, something went wrong loading the chart data. Please refresh the page.';
  const noDataError = 'No data in selected timeframe';

  // Sort field order null/'' to bottom of the data array
  const sortFieldOrder = (data) => data.sort((rowA,rowB) => {
    const a = rowA.fieldOrder;
    const b = rowB.fieldOrder;
    return (a === null || a === '') - (b === null || b === '') || (a > b) - (a < b);
  });

  const handleDateTimeRangeChange = (start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'BuilderAnalytics',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
    });

    setTime({start, end});
  };

  const loadAbandonedFieldsOverview = useCallback(async ({time}) => {
    try {
      setAbandonedFieldsOverviewLoading(true);
      setAbandonedFieldsOverviewData(null);

      const { data } = await api.get('/visualisations/abandoned-fields', {
        params: {
          formUuid: analyticsForm?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        },
      });

      const isData = (data && Array.isArray(data) && data?.length > 0) ? true : false;

      setAbandonedFieldsOverviewData(isData ? data : []);
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setVisError('Not logged in');
        break;
      case 404:
        setVisError('Form not found');
        break;
      default:
        setVisError(defaultError);
      }
    } finally {
      setAbandonedFieldsOverviewLoading(false);
    }
  }, [analyticsForm?.uuid]);

  const loadFieldReturnsOverview = useCallback(async ({time}) => {
    try {
      setFieldReturnsOverviewLoading(true);
      setFieldReturnsOverviewData(null);

      const { data } = await api.get('/visualisations/return-to-field', {
        params: {
          formUuid: analyticsForm?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        },
      });

      const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;

      setFieldReturnsOverviewData(isData ? data
        .map((row) => {
          const p1 = row.abandonedSessionsReturned/100;
          const p2 = row.completedSessionsReturned/100;
          const p = row.allSessionsReturned/100;
          const n1 = row.abandonedSessionsTotal;
          const n2 = row.completedSessionsTotal;
          const zScore = zScoreOfProportions({p1, p2, p, n1, n2});
          const significantDiff = zScore <= -1.65 || zScore >= 1.65;

          return {
            ...row,
            significantDiff,
            ...significantDiff && {zScore},
          };
        }) : []);
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setVisError('Not logged in');
        break;
      case 404:
        setVisError('Form not found');
        break;
      default:
        setVisError(defaultError);
      }
    } finally {
      setFieldReturnsOverviewLoading(false);
    }
  }, [analyticsForm?.uuid]);

  const loadFieldTimesOverview = useCallback(async ({time}) => {
    try {
      setFieldTimesOverviewLoading(true);
      setFieldTimesOverviewData(null);

      const { data } = await api.get('/visualisations/time-in-field', {
        params: {
          formUuid: analyticsForm?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        },
      });

      const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;

      setFieldTimesOverviewData(isData ? data : []);
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setVisError('Not logged in');
        break;
      case 404:
        setVisError('Form not found');
        break;
      default:
        setVisError(defaultError);
      }
    } finally {
      setFieldTimesOverviewLoading(false);
    }
  }, [analyticsForm?.uuid]);

  useEffect(() => {
    mixpanel?.track('Page View', { page: 'BuilderAnalytics' });
  }, [mixpanel]);

  // Reset data states from previous form
  useEffect(() => {
    if (form?.analyticsForm?.uuid) {
      setAbandonedFieldsOverviewData(null);
      setFieldReturnsOverviewData(null);
      setFieldTimesOverviewData(null);
      setTime(null);
      setChartTip(null);
    }
  }, [form?.analyticsForm?.uuid]);

  // Fetch org's timeZone
  useEffect(() => {
    if (form?.analyticsForm?.uuid) (async () => {
      try {
        setAnalyticsForm(null);
        let { data: { form: analyticsForm } } = await api.get(`/forms/${form.analyticsForm.uuid}`);
        setAnalyticsFormLoading(false);
        setAnalyticsForm(analyticsForm);
      } catch (e) {
        setVisError('Sorry something went wrong loading the form. Please refresh the page.');
      } finally {
        setAnalyticsFormLoading(false);
      }
    })();
  }, [form?.analyticsForm?.uuid]);

  useEffect(() => {
    if (analyticsForm?.organisation?.uuid) setCurrentOrg(analyticsForm?.organisation);
  }, [analyticsForm?.organisation, setCurrentOrg]);

  // Set default time
  useEffect(() => {
    if (analyticsForm?.organisation?.timeZone) {
      setTime({
        start: moment.tz(analyticsForm?.organisation?.timeZone).subtract(30, 'days').startOf('day'),
        end: moment.tz(analyticsForm?.organisation?.timeZone).endOf('day'),
      });
    }
  }, [analyticsForm?.organisation?.timeZone]);

  // Trigger data fetch from multiple sources
  useEffect(() => {
    if (analyticsForm?.uuid && analyticsForm.organisation?.timeZone && time?.start && time?.end) {
      setVisError(null);
      setVisLoading(true);
      setVisProgress(20);
      setVisData(null);
      loadAbandonedFieldsOverview({time});
      loadFieldReturnsOverview({time});
      loadFieldTimesOverview({time});
    }
  }, [analyticsForm?.uuid, analyticsForm?.organisation?.timeZone,
    loadFieldReturnsOverview, loadAbandonedFieldsOverview, loadFieldTimesOverview,
    time
  ]);

  // Handle progress bar
  useEffect(() => {
    let interval;
    if (analyticsForm?.uuid && analyticsForm.organisation?.timeZone && time && visProgress < 100) {
      interval = setInterval(() => setVisProgress((prevProgress) => prevProgress + 20), 200);
    }
    return () => clearInterval(interval);
  }, [visProgress, analyticsForm?.uuid, analyticsForm?.organisation?.timeZone, time]);

  // Compile data
  useEffect(() => {
    if (!abandonedFieldsOverviewLoading && abandonedFieldsOverviewData &&
      !fieldReturnsOverviewLoading && fieldReturnsOverviewData &&
      !fieldTimesOverviewLoading && fieldTimesOverviewData
    ) {
      setVisProgress(100);

      const compiledData = Object.values(abandonedFieldsOverviewData.concat(fieldReturnsOverviewData).concat(fieldTimesOverviewData)
        .reduce((acc, item) => {
          const identifier = identifierForField({
            htmlTagName: item.htmlTagName || '',
            htmlType: item.htmlType || '',
            htmlName: item.htmlName || '',
            htmlId: item.htmlId || '',
          });
          if (item.hidden) return acc;
          if (!acc[identifier]) acc[identifier] = {
            htmlTagName: item.htmlTagName,
            htmlType: item.htmlType,
            htmlName: item.htmlName,
            htmlId: item.htmlId,
            fieldOrder: item.fieldOrder,
            label: labelForField({...item, label: item.fieldLabel}),
          };

          if (item.hasOwnProperty('allSessionsTotal')) acc[identifier].allSessionsTotal = item.allSessionsTotal;
          if (item.hasOwnProperty('abandonRate')) acc[identifier].abandonRate = item.abandonRate;
          if (item.hasOwnProperty('percentOfAbandons')) acc[identifier].percentOfAbandons = item.percentOfAbandons;
          if (item.hasOwnProperty('allSessionsReturned')) {
            acc[identifier].allSessionsReturned = item.allSessionsReturned;
            acc[identifier].abandonedSessionsReturned = item.abandonedSessionsReturned;
            acc[identifier].completedSessionsReturned = item.completedSessionsReturned;
            acc[identifier].differenceInReturnRate = item.abandonedSessionsReturned ? item.abandonedSessionsReturned - item.completedSessionsReturned : null;
          }
          if (item.hasOwnProperty('significantDiff')) {
            acc[identifier].significantDiff = item.significantDiff;
            acc[identifier].zScore = item.zScore;
          }
          if (item.hasOwnProperty('allMeanRtf')) acc[identifier].allMeanRtf = item.allMeanRtf;
          if (item.hasOwnProperty('allMeanTif')) acc[identifier].allMeanTif = item.allMeanTif;

          return acc;
        }, {}));

      if (!compiledData.length) setVisError(noDataError);
      if (compiledData.length) setVisData(sortFieldOrder(compiledData));

      setChartTip({
        highestAbandonedField: compiledData.reduce((acc, item) => {
          if (!item.hasOwnProperty('percentOfAbandons')) return acc;
          return acc.percentOfAbandons > item.percentOfAbandons ? acc : item;
        }, {}),
      });
      setVisLoading(false);
    }
  }, [abandonedFieldsOverviewLoading, abandonedFieldsOverviewData,
    fieldReturnsOverviewLoading, fieldReturnsOverviewData,
    fieldTimesOverviewLoading, fieldTimesOverviewData,
  ]);


  // Reset progress bar
  useEffect(() => {
    if (visData) setVisProgress(0);
  }, [visData]);

  return (
    <div className="builder-analytics-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Builder | Analytics</title>
      </Helmet>
      <ScrollToTop />
      <div className="main-content">
        <Col className="center-column">
          <div className="pt-2 mb-3">
            <FeedbackRow
              classList={['allow-scroll-under-nav']}
              mixpanel={mixpanel}
              page={'Analytics'}
              messageContent={'analytics'} />
          </div>
          <AppAlerts showOrgAlerts={form?.published} />
          {form && !form.published &&
            <Alert variant="info" closeVariant="white">
              <div className="alert-svg-icon my-auto"><VscInfo size="20px" className="me-2"/></div>
              <p className="alert-text m-0">Please first pubilsh your form from the <Link to={`/builder/forms/${form.uuid}/build`}>Build page</Link>.</p>
            </Alert>}
          {form?.published && visError === noDataError &&
            <Alert variant="info" closeVariant="white">
              <div className="alert-svg-icon my-auto"><VscInfo size="20px" className="me-2"/></div>
              <p className="alert-text m-0">Please check that your form is live and receiving <Link to={`/builder/forms/${form.uuid}/submissions`}>Submissions</Link>. If not,
              please contact <a href="mailto:support@zuko.io">support</a>, or review your analytics <Link to={`/users/${currentUser.uuid}/organisations_usage`}>Usage</Link>.</p>
            </Alert>}
          <Card className="mt-3">
            <Card.Body>
              <Row className="g-0 card-title-row justify-content-between">
                <Col className="p-0 col-auto">
                  <Card.Title as="h3">Fields Overview</Card.Title>
                </Col>
                <Col className="p-0 col-auto">
                  {form && form.published && form.analyticsForm?.uuid && <Link to={`/insights?form[uuid]=${form.analyticsForm.uuid}`} className="d-flex align-items-center">
                    Explore more analytics <AiOutlineLineChart size="22px" className="ms-1"/>
                  </Link>}
                </Col>
              </Row>
              {form && !form.published && <p className="fw-light fs-5">Available when published.</p>}
              {(!form || form?.published) &&
                <div className="card-content d-flex flex-column">
                  <Row className="g-0 justify-content-between">
                    <Col className="p-0 col-auto">
                      <p>A quick snapshot of your field data powered by Zuko Form Analytics.</p>
                    </Col>
                    <Col className="p-0 col-auto d-flex">
                      <ChartTip
                        dataLoading={!form || visLoading || analyticsFormLoading}
                        isData={visData?.length > 0}
                        mixpanel={mixpanel}
                        page={'BuilderAnalytics'}
                        vis={'Fields Overview'}>
                        {chartTip?.highestAbandonedField?.label && <>
                          <p>In this data, the biggest point of abandonment on your form is the field labelled:</p>
                          <p className="tip-field">{chartTip?.highestAbandonedField?.label}</p>
                          <p>This represents <span className="tip-stat">
                            {chartTip?.highestAbandonedField?.percentOfAbandons.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%'}</span> of your form's
                              total abandons.</p>
                          <h4 className="pt-2">What next?</h4>
                          <ul className="mb-0">
                            <li>Explore <Link to={`/field_aggregate?form[uuid]=${form.analyticsForm.uuid}`} target="_blank" onClick={() => {mixpanel.track('Clicked Quick Tip Link', { page: 'BuilderAnalytics', vis: 'Fields Overview' });}}>Field Data analytics</Link> to find more insights on when, where and why users are abandoning your form.</li>
                          </ul>
                        </>}
                        {!chartTip?.highestAbandonedField?.label &&
                          <p className="mb-0">This tip looks at abandoned fields, but there are no abandons in your data.</p>}
                      </ChartTip>
                    </Col>
                  </Row>
                  <Row className="g-0 justify-content-between overflow-auto">
                    <Col className="p-0 col-auto" id="datepicker">
                      <DatePicker
                        startTime={time?.start}
                        endTime={time?.end}
                        onApply={handleDateTimeRangeChange}
                        timeZone={analyticsForm?.organisation?.timeZone}
                        timePicker={false}
                        autoApply={true}
                        alwaysShowCalendars={true}
                        ranges={{
                          'Today': [moment().startOf('day'), moment().endOf('day')],
                          'Last 7 Days': [moment().subtract(7, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                          'Last 30 Days': [moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                          'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                          'Last Month': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').endOf('day')],
                          'Last 12 Months': [moment().subtract(1, 'years').startOf('day'), moment().endOf('day')],
                        }}
                      />
                    </Col>
                  </Row>
                  {visError ?
                    <div className="d-flex justify-content-center flex-grow-1">
                      <p className="text-center my-auto">{visError}</p>
                    </div> :
                    (!form || visLoading || analyticsFormLoading) ?
                      <div className="progress-area d-flex flex-grow-1">
                        <div className="w-100">
                          <ProgressBar className="my-auto" animated now={visProgress}/>
                          <p>{visProgress >= 110 && 'The data is being computed, please wait...'}</p>
                        </div>
                      </div> :
                      visData &&
                      <div className="card-vis">
                        <SummaryTable
                          data={visData}
                          showSessionReplayColumns={false}
                        />
                      </div>
                  }
                </div>}
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default Analytics;
