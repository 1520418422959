import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import AppContext from '../../AppContext.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import api from '../../api';
import { FaCog } from "react-icons/fa";
import { VscChevronLeft } from 'react-icons/vsc';
import CopyUrlIcon from '../../Components/CopyUrlIcon';
import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';
import moment from 'moment-timezone';
import AttributesIcons from './AttributesIcons.tsx';
import { formatMillisecondsToTimeString } from '../../utils.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FaInfoCircle } from 'react-icons/fa';
import { VscInfo } from 'react-icons/vsc';

import { Form, Time, Filter, FilterForSelect, } from '../../types/types.ts';
import { MetricRuleType } from '../../types/sessions.ts';
import { Session } from '../../types/sessions.ts';
import { compileQueryString } from '../../utils';

import './Player.scss';

interface LocationState {
  form: Form
  session: Session
}

const Player = ({mixpanel}) => {
  const { replayId }: { replayId: string }  = useParams() || {};
  const location = useLocation();
  const history = useHistory();
  const {
    formsGroupedByFormUuid,
    query,
  } = useContext(AppContext);
  const state = location.state as LocationState;
  const [formForReplay, setFormForReplay] = useState<Form | undefined>();
  const [sessionForReplay, setSessionForReplay] = useState<Session | undefined>();
  const [error, setError] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(20);
  const [showDurationDiscrepancy, setShowDurationDiscrepancy] = useState(false);

  const {
    time,
    form,
    filters,
    sessionOutcomes,
    sessionFilters,
  }: {
    time: Time,
    form: Form,
    filters: FilterForSelect[],
    sessionOutcomes: Filter[],
    sessionFilters: {
      abandonedFieldIdentifier: string,
      interactedWithFieldIdentifiers: string[],
      metrics: MetricRuleType[],
    }
  } = query || {};

  const replayRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (replayId) (async () => {
      const progressID = setInterval(() => setLoadingProgress((prevProgress) => prevProgress + 30), 200);
      try {
        setIsLoading(true);
        setError(null);
        setShowDurationDiscrepancy(false);

        const { data: { events, session } } = await api.get(`/replays/recordings/${replayId}`);

        const parsedEvents = JSON.parse(events);

        const controlsHeight = 80;
        if (replayRef.current) {
          // Remove current player if one already shown
          if (replayRef.current.getElementsByClassName('rr-player').length > 0) {
            [...replayRef.current.getElementsByClassName('rr-player')].map(e => e.remove());
          }

          new rrwebPlayer({
            target: replayRef.current,
            props: {
              events: parsedEvents,
              autoPlay: false,
              width: replayRef.current.offsetWidth,
              height: replayRef.current.offsetHeight - controlsHeight,
              skipInactive: true,
              mouseTail: {
                strokeStyle: '#6950a5',
              },
            }
          });
        }

        setSessionForReplay(session);
        setLoadingProgress(100);

        const firstEvent = parsedEvents[0].timestamp;
        const lastEvent = parsedEvents[parsedEvents.length -1].timestamp;
        const recordingLength = lastEvent - firstEvent;
        const durationWithMarginForError = (session.duration * 30)/100;
        if (recordingLength < durationWithMarginForError) setShowDurationDiscrepancy(true);
      } catch (e) {
        const defaultError = 'Sorry, something went wrong loading the replay';
        if (e?.response) {
          switch (e.response?.status) {
          case 401:
            setError('Not logged in');
            break;
          case 403:
            history.replace('/');
            break;
          case 404:
            setError('Replay recording not found.');
            break;
          default:
            setError(defaultError);
          }
        } else {
          setError(defaultError);
        }
        setLoadingProgress(100);
      } finally {
        setIsLoading(false);
        clearInterval(progressID);
        setLoadingProgress(0);
      }
    })();
  }, [replayId, history]);

  useEffect(() => {
    if (state?.form) {
      setFormForReplay(state.form);
    }
  }, [state?.form]);

  useEffect(() => {
    if (state?.session) {
      setSessionForReplay(state.session);
    }
  }, [state?.session]);

  useEffect(() => {
    if (sessionForReplay && !formForReplay && formsGroupedByFormUuid) {
      setFormForReplay(formsGroupedByFormUuid[sessionForReplay.formUuid]);
    }
  }, [sessionForReplay, formForReplay, formsGroupedByFormUuid]);

  return (<>
    <div className="main-content" id="player">
      <Col className="center-column justify-content-md-center">
        <div className="player-navigate-row browser-only">
          <Link to={`/session-replay${(form && time) ? compileQueryString({form: formForReplay || form, time, filters, sessionOutcomes, sessionFilters}) : ''}`} className="d-flex align-items-center"><VscChevronLeft className="mex-1" size="24px" /> Back to Sessions</Link>
        </div>
        <div className="pb-1">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'SessionReplay'}
            org={formForReplay?.organisation}
            messageContent={'Session Replay Player'} />
        </div>
        <AppAlerts showOrgAlerts={true} />
        <Row className="title-row g-0 browser-only">
          {formForReplay?.organisation?.name && formForReplay.label && <>
            <Col className="p-0">
              <h1 id="form-title" data-testid="page-title">
                {`${formForReplay?.organisation?.name} | ${formForReplay.label} | `}
                <a href={formForReplay.url} target="_blank" rel="noopener noreferrer">{formForReplay.url}</a>
              </h1>
            </Col>
            <Col className="p-0 text-end my-auto ms-auto col-auto">
              <CopyUrlIcon />
              <Link to={`/forms/${formForReplay.uuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
            </Col> </>
          }
        </Row>
        <div className="d-flex flex-row flex-grow-1">
          <Card>
            <Card.Body className="d-flex flex-column">
              <Row className="g-0 card-title-row">
                <Col className="p-0">
                  <Card.Title as="h3">Replay Recording</Card.Title>
                </Col>
              </Row>
              {error && <div className="h-100 d-flex justify-content-center align-items-center"><p>{error}</p></div>}
              <div className="d-flex h-100" ref={replayRef}>
                {!error && isLoading &&
                  <div className="progress-area d-flex flex-grow-1">
                    <ProgressBar className="my-auto" animated now={loadingProgress}/>
                  </div>}
              </div>
            </Card.Body>
          </Card>
          <Card id="session-details-card">
            <Card.Body className="d-flex flex-column">
              <Row className="g-0 card-title-row">
                <Col className="p-0">
                  <Card.Title as="h3">Session Details</Card.Title>
                </Col>
              </Row>
              <dl className="mb-0">
                <div>
                  <dt>Start Time</dt>
                  <dd>{sessionForReplay && moment.tz(sessionForReplay?.startedAt, formForReplay?.organisation?.timeZone).format('llll')}</dd>
                </div>
                <div>
                  <dt>Outcome</dt>
                  <dd className="text-capitalize">{sessionForReplay?.outcome}</dd>
                </div>
                <div>
                  <dt className="d-flex">Active Duration <OverlayTrigger placement="top"
                    overlay={
                      <Popover>
                        <Popover.Body className="fs-6">
                          <i>Active Duration</i> is the time from when the session started tracking interactions, to the end of the session.
                          <br></br><br></br>
                          This is different to the <i>Total Session Duration</i> which is from when the session was triggered (typically with the View event), to the end of the session.
                        </Popover.Body>
                      </Popover>}>
                    <span className="d-flex align-content-top ps-1"><FaInfoCircle size="12px" className="column-info-icon info"/></span>
                  </OverlayTrigger></dt>
                  <dd className="d-flex align-items-center flex-wrap">{sessionForReplay && (Object.hasOwn(sessionForReplay, 'completedAt') || Object.hasOwn(sessionForReplay, 'abandonedAt')) &&
                    formatMillisecondsToTimeString((sessionForReplay.completedAt || sessionForReplay.abandonedAt) - sessionForReplay.startedAt)} {showDurationDiscrepancy && <OverlayTrigger placement="top"
                    overlay={
                      <Popover>
                        <Popover.Body className="fs-6">
                          <p>The length of a recording can be shorter than the length of the session.</p>
                          <p>This session could hold multiple recordings, or the reason could be form-specific, browser or trigger-related.</p>
                          <p className="mb-0">If you'd like us to explore the reason why this session's recording is shorter, please contact support.</p>
                        </Popover.Body>
                      </Popover>}>
                    <span className="ms-2 d-flex align-items-center small duration-msg-badge"><VscInfo size="14px" className="me-1 column-info-icon info"/>Recording is shorter</span>
                  </OverlayTrigger>}</dd>
                </div>
                <div>
                  <dt>Session Field Returns</dt>
                  <dd>{sessionForReplay?.rtfCount}</dd>
                </div>
                <div>
                  <dt>Default Attributes</dt>
                  <dd>
                    <AttributesIcons
                      deviceType={sessionForReplay?.attributes['deviceType']}
                      browserFamily={sessionForReplay?.attributes['browserFamily']}
                      operatingSystem={sessionForReplay?.attributes['Operating System']}
                      visitorType={sessionForReplay?.attributes['Visitor Type']}
                      autofillTriggered={sessionForReplay?.attributes['autofillTriggered']}
                      trafficMedium={sessionForReplay?.attributes['trafficMedium']}
                    /></dd>
                </div>
              </dl>
              {sessionForReplay && sessionForReplay.replayIds?.length > 1 && <>
                <h4 className="mt-2">Replays</h4>
                <div className="scrollable-list">
                  <div className="d-flex flex-column fit-content">
                    {sessionForReplay.replayIds.map(id => <>{id === replayId ?
                      <Button className="view-replay-cta text-nowrap my-1 mx-0 w-100" disabled>This Replay</Button> :
                      <Link
                        to={{
                          pathname: `/session-replay/${id}`,
                          state: {form, session: sessionForReplay}
                        }}
                        onClick={() => {
                        // Remove the current player so that the loading bar can be seen
                          if (replayRef.current) [...replayRef.current.getElementsByClassName('rr-player')].map(e => e.remove());
                        }}>
                        <Button className="view-replay-cta text-nowrap my-1 mx-0 w-100">View Replay</Button>
                      </Link>}</>
                    )}
                  </div>
                </div>
              </>}
            </Card.Body>
          </Card>
        </div>
      </Col>
    </div>
  </>);
};

export default Player;
