import React from 'react';
import DefaultError from './DefaultError';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
  // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    let storedUser;
    try {
      storedUser = JSON.parse(localStorage.getItem('zukoAppUser'));
      this.props.mixpanel.identify(storedUser.email);
      this.props.mixpanel.track('Page View', {
        page: 'Error Boundary',
        error: error.message,
      });
    } catch (e) {/* Do nothing - no user */}
  }

  render() {
    if (this.state.hasError) {
      return (
        <DefaultError title="Oops! Something went wrong!" message="Please try again shortly" />
      );
    }

    return this.props.children;
  }
};

export default ErrorBoundary;
