import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Gallery from './Gallery';
import Theme from './Theme';
import './EditTypeWrapper.scss';

const EditTypeWrapper = ({
  formHasBeenCreated,
  handleCreateQuestion,
  handleCreateParagraphText,
  handleAddStepAttr,
  handleAddFormAttr,
  handleAddCompletionAttr,
  showSubmitted,
  handleSaveTheme,
  setQueue,
  setCanPublish,
  handleRemoveMessages,
}) => {
  const [editType, setEditType] = useState('gallery');

  return (
    <Col className="left-fixed-column d-block p-0">
      <Tabs
        activeKey={editType}
        onSelect={(type) => setEditType(type)}
        className="toggle-edit-type"
      >
        <Tab eventKey="gallery" title="Gallery">
          <Gallery
            showSubmitted={showSubmitted}
            handleAddCompletionAttr={handleAddCompletionAttr}
            formHasBeenCreated={formHasBeenCreated}
            handleCreateQuestion={handleCreateQuestion}
            handleCreateParagraphText={handleCreateParagraphText}
            handleAddStepAttr={handleAddStepAttr}
            handleAddFormAttr={handleAddFormAttr} />
        </Tab>
        <Tab eventKey="theme" title="Theme" disabled={!formHasBeenCreated}>
          <Theme
            setQueue={setQueue}
            setCanPublish={setCanPublish}
            handleRemoveMessages={handleRemoveMessages}
            handleSaveTheme={handleSaveTheme} />
        </Tab>
      </Tabs>
    </Col>
  );
};

export default EditTypeWrapper;
