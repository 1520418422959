import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { formatSecondsToTimeString } from '../utils';

import api from '../api';

const SegmentStats = ({segment, index, time, addToAllStats}) => {
  const [stats, setStats] = useState(null);
  const [progress, setProgress] = useState(20);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSegmentStats = async () => {
      const progressID = setInterval(() => setProgress((prevProgress) => prevProgress + 30), 50);
      try {
        setLoading(true);
        setStats(null);

        const { data: { metrics } } = await api.get('/segment_metrics', {
          params: {
            formUuid: segment.form.uuid,
            timePeriod: {start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
            filter: segment.filters.reduce((acc, {key, value}) => {
              if (!acc.hasOwnProperty(key)) acc[key] = [];
              acc[key].push(value);
              return acc;
            }, {}),
          }
        });

        setStats(metrics);
        setLoading(false);
        setProgress(0);
        clearInterval(progressID);
      } catch (e) {
        setLoading(false);
        setError('Something went wrong');
        setProgress(0);
        clearInterval(progressID);
      }
    };
    fetchSegmentStats();
  }, [segment, time?.start, time?.end]);

  // Keep a copy of all stats on the parent component to be used in the PDF export
  useEffect(() => {
    if (stats) addToAllStats({segmentUuid: segment.uuid, stats});
  }, [stats, addToAllStats, segment.uuid]);

  return (
    <div className="segment-stats">
      {stats ? <>
        <h5 className="metric-title">Views</h5>
        <p className="metric" data-testid={`view-stat-segment-${segment.uuid}`}>{stats.views.toLocaleString()}</p>
        <h5 className="metric-title">View to Starter Rate</h5>
        <p className="metric">{stats.viewToStarterRate === null ? 'N/A' : `${stats.viewToStarterRate.toLocaleString(undefined, {maximumFractionDigits: 2})}%`}</p>
        <h5 className="metric-title">Starters</h5>
        <p className="metric">{stats.starters.toLocaleString()}</p>
        <h5 className="metric-title">Starter to Completion Rate</h5>
        <p className="metric">{stats.starterToCompletionRate === null ? 'N/A' : `${stats.starterToCompletionRate.toLocaleString(undefined, {maximumFractionDigits: 2})}%`}</p>
        <h5 className="metric-title">Completions</h5>
        <p className="metric">{stats.completions.toLocaleString()}</p>
        <h5 className="metric-title">View to Completion Rate</h5>
        <p className="metric">{stats.viewToCompletionRate === null ? 'N/A' : `${stats.viewToCompletionRate.toLocaleString(undefined, {maximumFractionDigits: 2})}%`}</p>
        <h5 className="metric-title">Avg. Total Field Returns</h5>
        <p className="metric">{stats.avgFieldReturns === null ? 'N/A' : stats.avgFieldReturns.toLocaleString(undefined, {maximumFractionDigits: 2})}</p>
        <h5 className="metric-title">Avg. Session Duration</h5>
        <p className="metric">{stats.avgDuration === null ? 'N/A' : `${formatSecondsToTimeString(stats.avgDuration)}`}</p>
      </> : error ?
        <p className="text-center">{error}</p>
        : loading &&
            <div className="progress-area">
              <ProgressBar animated now={progress}/>
            </div>}
    </div>
  );
};

export default SegmentStats;
