import { useContext, useEffect, useState } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import ProgressionContext, { ProgressionContextType } from '../../contexts/ProgressionContext';
import Hint from '../question/HintWithLink';
import Legend from '../question/Legend';
import GroupItemLabel from '../question/GroupItemLabel';
import { CheckboxGroupInterface } from './interfaces/CheckboxGroupInterface';
import { usePrevious } from '../../hooks';
import { slugify } from '../../utils';
import { FormDataHash } from '../../types/FormDataHash';
import OtherOption from './OtherOption';
import Button from 'react-bootstrap/Button';
import { FaPlus, FaList } from 'react-icons/fa';
import BulkOptions from './BulkOptions';

const CheckboxGroup = ({ invalidFeedback, question, stepIndex, questionIndex }: {question: CheckboxGroupInterface, invalidFeedback: JSX.Element|JSX.Element[],
  stepIndex: number, questionIndex: number}) => {
  const { values, setValues, zuko, inEdit, handleSaveInlineEditItem } = useContext(SessionContext) as SessionContextType;
  const { attemptedSubmitAt } = useContext(ProgressionContext) as ProgressionContextType;
  const { id, label, hint = null, required, options } = question;
  const valueArray = values[id] as Array<string>;
  const [isValid, setIsValid] = useState<boolean>();
  const { otherOption } = question;
  const otherIsEnabled = otherOption?.enabled || false;
  const [showBulkAddOptions, setShowBulkAddOptions] = useState(false);

  const prevValueArray = usePrevious(valueArray?.toString());
  const classList: string[] = [];
  if (required) {
    if (isValid === false) classList.push('is-invalid');
    // NB. is-valid style is not applied
  }

  const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev: FormDataHash<string>) => {
      let newArray = (prev[id] || []) as Array<string>;
      if (newArray.includes(value)) {
        newArray = newArray.filter((a: string) => a !== value);
      } else {
        newArray = [value].concat(newArray); // Always add new value to the start so 'Other' can remain at the end
      }
      return {...prev, [id]: newArray};
    });
  };

  useEffect(() => {
    if (isValid === true || isValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${isValid ? 'valid' : 'invalid'}: ${id}`);
    }
  }, [isValid, id, zuko]);

  // Set validity used in Zuko tracking
  useEffect(() => {
    if (required && valueArray?.length) setIsValid(true);
  }, [required, valueArray]);

  // When checkboxes are all unticked
  useEffect(() => {
    if (required && prevValueArray?.length && !valueArray?.length) setIsValid(false);
  }, [prevValueArray, valueArray, required, setIsValid]);

  // When attempted continue/submit check validity
  useEffect(() => {
    if (attemptedSubmitAt) setIsValid(valueArray?.length > 0);
  }, [attemptedSubmitAt, valueArray]);

  return (<fieldset className="checkbox-group-fieldset" aria-describedby={`${id}-hint`}>
    <Legend label={label} required={required} stepIndex={stepIndex} questionIndex={questionIndex} />
    <Hint id={`${id}-hint`} text={hint} stepIndex={stepIndex} questionIndex={questionIndex} />
    {invalidFeedback}
    {inEdit && showBulkAddOptions &&
      <BulkOptions
        options={options}
        stepIndex={stepIndex}
        questionIndex={questionIndex}
        setShowBulkAddOptions={setShowBulkAddOptions} />}
    {!showBulkAddOptions && <div className="checkbox-group">{options?.map((option, i) =>
      <div className="checkbox-item" key={`${id}-${option}-${i}`}>
        <input
          id={`${id}-${slugify(option)}`}
          required={required && !(valueArray?.length > 0)}
          type="checkbox"
          name={id}
          value={option}
          onChange={onChange}
          className={classList.join(' ')}
          aria-describedby={`${id}-hint`}
          checked={valueArray?.includes(option) ?? false} />
        <GroupItemLabel id={inEdit ? '' :  // NB. we don't declare the htmlFor to avoid engaging the input whilst in edit mode
          `${id}-${slugify(option)}`} label={option} stepIndex={stepIndex} questionIndex={questionIndex}
        itemIndex={i} type={'question-option'} options={options} />
      </div>
    )}
    {otherIsEnabled &&
      <OtherOption
        type={'checkbox'}
        id={id}
        required={required}
        classList={classList}
        stepIndex={stepIndex}
        questionIndex={questionIndex}
      />}
    {inEdit && <div className="mb-2">
      <Button variant="outline-primary" className="ms-0 mt-1 px-3 py-2 option-button" onClick={() => {
        handleSaveInlineEditItem({type: 'question-options', item: (options || []).concat('Option'), stepIndex, questionIndex});
      }}><FaPlus className="me-2 plus-inside-btn" />Add Option</Button>
      <Button variant="outline-primary" className="ms-2 me-0 mt-1 px-3 py-2 option-button" onClick={() => setShowBulkAddOptions(true)}>
        <FaList className="me-2 plus-inside-btn" />Paste List</Button>
      {!otherIsEnabled &&
        <Button variant="outline-primary" className="ms-2 mt-1 px-3 py-2 option-button" onClick={() => {
          handleSaveInlineEditItem({type: 'question-other-option', item: { enabled: true }, stepIndex, questionIndex});
        }}><FaPlus className="me-2 plus-inside-btn" />Add 'Other' Option</Button>}
    </div>}
    </div>}
  </fieldset>);
};

export default CheckboxGroup;
