import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import TabContainer from 'react-bootstrap/TabContainer';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import MultiStepProgressBar from '../../Components/MultiStepProgressBar';
import ReactPaginate from 'react-paginate';
import arrayMove from 'array-move';
import { VscCheck, VscWarning } from 'react-icons/vsc';
import { FaSpinner } from 'react-icons/fa';
import { BiColumns } from 'react-icons/bi';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { HiExternalLink } from 'react-icons/hi';
import { chunk, labelForField, msTimestampToDate } from '../../utils';
import { usePrevious } from '../../hooks';
import api from '../../api';
import LabellingToolImg from '../../images/LabellingToolImg.png';
import TrackedFieldsTableImg from '../../images/TrackedFieldsTableImg.png';
import FeedbackRow from '../../Components/FeedbackRow';

import './FormFieldsWizard.scss';

const maxFieldsPerPage = 15;
const maxFieldsResultsSize = 200;

const FormFieldsWizard = ({
  showWizard,
  handleCloseWizard,
  unconfiguredFieldsLoading,
  unconfiguredFieldsError,
  unconfiguredFields,
  fieldMergingRules,
  handleSaveField,
  fieldSaved,
  configuredFields,
  handleSaveReorderedFields,
  handleSaveHiddenFields,
  errorSaving,
  savingFields,
  configuredFieldsLoading,
  configuredFieldsError,
  form,
  handleFetchConfiguredFields,
  mixpanel,
}) => {
  const [wizardCurrentStep, setWizardCurrentStep] = useState(1);
  const [interactivePath, setInteractivePath] = useState(false);
  const [trackedFieldsPath, setTrackedFieldsPath] = useState(false);
  const [labellingToolLoading, setLabellingToolLoading] = useState(false);
  const [labellingToolError, setLabellingToolError] = useState(false);
  const [initiateMoveToStep, setInitiateMoveToStep] = useState();
  const [unconfiguredFieldsToDisplay, setUnconfiguredFieldsToDisplay] = useState([]);
  const [unconfiguredFieldsSortedOn, setUnconfiguredFieldsSortedOn] = useState('htmlName');
  const [unconfiguredFieldsFilterInput, setUnconfiguredFieldsFilterInput] = useState();
  const [unconfiguredFieldsFilter, setUnconfiguredFieldsFilter] = useState();
  const [unconfiguredPageFields, setUnconfiguredPageFields] = useState([]);
  const [currentUnconfiguredPage, setCurrentUnconfiguredPage] = useState(0);
  const [forcePage, setForcePage] = useState();
  const [expandNameCol, setExpandNameCol] = useState(false);
  const [expandIDCol, setExpandIDCol] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [selectedFieldLabel, setSelectedFieldLabel] = useState();
  const [selectedFieldLabelError, setSelectedFieldLabelError] = useState();
  const [configuredFieldsExclHidden, setConfiguredFieldsExclHidden] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(['htmlTagName', 'htmlType', 'htmlName', 'htmlId', 'mergingRule', 'lastTracked']);
  const [hiddenUnconfiguredFields, setHiddenUnconfiguredFields] = useState([]);
  const [hiddenConfiguredFields, setHiddenConfiguredFields] = useState([]);

  const prevUnconfiguredFieldsToDisplay = usePrevious(unconfiguredFieldsToDisplay);
  const prevSavingFields = usePrevious(savingFields);
  const prevConfiguredFieldsLoading = usePrevious(configuredFieldsLoading);

  const sortUnconfiguredFieldsOnAttr = ({fields, attr}) => {
    return fields.sort((a, b) => {
      switch (attr) {
      case 'lastTracked':
        return b[attr] - a[attr];
      default:
        const aAttr = a[attr]?.toLowerCase();
        const bAttr = b[attr]?.toLowerCase();

        return (aAttr === null || aAttr === '') - (bAttr === null || bAttr === '') || (aAttr > bAttr) - (aAttr < bAttr);
      }
    });
  };

  const handleUnconfiguredPageChange = (e) => {
    const selectedPageIndex = e.selected;
    const chunks = chunk(unconfiguredFieldsToDisplay, maxFieldsPerPage);
    setCurrentUnconfiguredPage(selectedPageIndex);
    setUnconfiguredPageFields(chunks[selectedPageIndex]);
  };

  const handleLaunchLabellingTool = async () => {
    try {
      setLabellingToolLoading(true);
      setLabellingToolError(null);
      await api.get(`/access/field_labeller_session`);
      window.open(form.url, `zukoFieldLabeller_${form.slug}_${form.uuid}`, 'noopener, noreferrer');
      mixpanel.track('Launched Fields Labelling Tool', {
        'Form Uuid': form.uuid,
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      });
    } catch (e) {
      setLabellingToolError(true);
    } finally {
      setLabellingToolLoading(false);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return; // Dropped outside the list

    const reorderedFields = arrayMove(configuredFieldsExclHidden, result.source.index, result.destination.index);
    setConfiguredFieldsExclHidden(reorderedFields);
  };

  // Set unconfigured fields to display, and update anytime they are sorted, updated or filtered
  useEffect(() => {
    if (unconfiguredFields?.length) {
      let updatedFields = sortUnconfiguredFieldsOnAttr({fields: [...unconfiguredFields], attr: unconfiguredFieldsSortedOn});

      if (fieldMergingRules.length) {
        updatedFields = updatedFields.filter((field) => {
          for (const {regexp, htmlAttribute} of fieldMergingRules) {
            if (field[htmlAttribute].match(`^${regexp}$`)) {
              return false;
            }
          }
          return true;
        });
      }

      if (unconfiguredFieldsFilter?.length) {
        updatedFields = updatedFields.filter(({htmlName, htmlId, htmlTagName, htmlType}) => (
          (htmlName?.toLocaleLowerCase().includes(unconfiguredFieldsFilter?.toLocaleLowerCase()) ||
          htmlId?.toLocaleLowerCase().includes(unconfiguredFieldsFilter?.toLocaleLowerCase()) ||
          htmlTagName?.toLocaleLowerCase().includes(unconfiguredFieldsFilter?.toLocaleLowerCase()) ||
          htmlType?.toLocaleLowerCase().includes(unconfiguredFieldsFilter?.toLocaleLowerCase()))
        ));
      }

      setUnconfiguredFieldsToDisplay(updatedFields);
    } else {
      // Last fields removed so set to empty
      setUnconfiguredFieldsToDisplay([]);
    }

  }, [unconfiguredFields, unconfiguredFieldsSortedOn, fieldMergingRules, unconfiguredFieldsFilter]);

  // Set unconfigured fields per page
  useEffect(() => {
    // On first page load, set the fields for the first page
    if (prevUnconfiguredFieldsToDisplay && !prevUnconfiguredFieldsToDisplay.length && unconfiguredFieldsToDisplay.length) {
      setUnconfiguredPageFields(chunk(unconfiguredFieldsToDisplay, maxFieldsPerPage)[0]);
    }

    // When unconfigured fields are sorted, changed or filtered, reset the page fields
    if (prevUnconfiguredFieldsToDisplay && prevUnconfiguredFieldsToDisplay.length > 0 && (prevUnconfiguredFieldsToDisplay.length !== unconfiguredFieldsToDisplay.length ||
      (prevUnconfiguredFieldsToDisplay.length === unconfiguredFieldsToDisplay.length &&
        !prevUnconfiguredFieldsToDisplay.every((f, i) => f.identifier === unconfiguredFieldsToDisplay[i].identifier)))) {
      const pages = chunk(unconfiguredFieldsToDisplay, maxFieldsPerPage);
      // If there are no more fields left on the page, move to previous
      const selectedPage = (pages.length > 0 && (pages.length - 1) < currentUnconfiguredPage) ? pages.length - 1 : currentUnconfiguredPage;
      setUnconfiguredPageFields(pages[selectedPage]);
      if (currentUnconfiguredPage !== selectedPage) setCurrentUnconfiguredPage(selectedPage);
      if (!pages.length) {
        setForcePage(null);
      } else {
        setForcePage(selectedPage);
      }
    }

  }, [prevUnconfiguredFieldsToDisplay, unconfiguredFieldsToDisplay, currentUnconfiguredPage]);

  // Set configured fields to display - excl hidden fields
  useEffect(() => {
    if (configuredFields?.length) setConfiguredFieldsExclHidden(configuredFields.filter(f => !f.hidden));
  }, [configuredFields]);

  // Set configured fields hidden fields
  useEffect(() => {
    if (configuredFields?.length) setHiddenConfiguredFields(configuredFields.filter(f => f.hidden).map(f => f.identifier));
  }, [configuredFields]);

  useEffect(() => {
    if (prevSavingFields && !savingFields && initiateMoveToStep && !errorSaving) {
      setWizardCurrentStep(initiateMoveToStep);
      setInitiateMoveToStep(null);
    }
  }, [savingFields, prevSavingFields, initiateMoveToStep, errorSaving]);

  useEffect(() => {
    if (interactivePath && prevConfiguredFieldsLoading && !configuredFieldsLoading && initiateMoveToStep && !configuredFieldsError) {
      setWizardCurrentStep(initiateMoveToStep);
      setInitiateMoveToStep(null);
    }
  }, [interactivePath, configuredFieldsLoading, prevConfiguredFieldsLoading, initiateMoveToStep, configuredFieldsError]);

  const noTrackedFields = !unconfiguredFieldsLoading && !unconfiguredFieldsError && unconfiguredFieldsToDisplay && !unconfiguredFieldsToDisplay.length > 0 &&
  !configuredFieldsLoading && !configuredFieldsError && configuredFields && !configuredFields.length > 0;

  const noUnconfiguredFields = !unconfiguredFieldsLoading && !unconfiguredFieldsError && unconfiguredFieldsToDisplay && !unconfiguredFieldsToDisplay.length > 0 &&
    !configuredFieldsLoading && !configuredFieldsError && configuredFields && configuredFields.length > 0;

  const handleStepNumberClick = useCallback((number) => {
    setWizardCurrentStep(number);
  }, []);

  return (
    <Modal show={showWizard} onHide={handleCloseWizard} onExited={() => {
      setWizardCurrentStep(1);
      setInteractivePath(false);
      setTrackedFieldsPath(false);
      setLabellingToolError(null);
      setInitiateMoveToStep(null);
      // Reset state potentially changed by the user
      setUnconfiguredFieldsFilterInput(null);
      setUnconfiguredFieldsFilter(null);
      setHiddenColumns(['htmlTagName', 'htmlType', 'htmlName', 'htmlId', 'mergingRule', 'lastTracked']);
      setExpandNameCol(false);
      setExpandIDCol(false);
      setSelectedField(null);
      setSelectedFieldLabel(null);
      setSelectedFieldLabelError(null);
      setCurrentUnconfiguredPage(0);
    }} id="fields-wizard-modal" className="d-flex" aria-labelledby="fields-wizard-modal">
      <Modal.Body className="d-flex flex-column col">
        <MultiStepProgressBar
          currentStep={wizardCurrentStep}
          stepValues={[1,2,3,4]}
          stepNames={['Label', 'Order', 'Hide', 'Complete']}
          onStepNumberClick={handleStepNumberClick}
        />
        <div className="pe-2">
          <FeedbackRow
            mixpanel={mixpanel}
            page={'Field Config'}
            org={form?.organisation}
            messageContent={'this fields wizard'} />
        </div>
        <div className="wizard-step-content d-flex flex-column px-2 flex-grow-1">
          {noTrackedFields ? <p className="mt-5 align-self-center">No fields have been tracked yet. Please check the <Link to={`/forms/${form?.uuid}/tracking_code`}>Tracking Code</Link>.</p> : <>
            {wizardCurrentStep === 1 && !interactivePath && !trackedFieldsPath &&
              <div className="choose-path pb-1 pt-2 d-flex flex-column flex-grow-1">
                <h3 className="mb-1 text-center">How would you like to label your fields?</h3>
                <Row className="g-0">
                  <Col className="text-center option-col">
                    <Button onClick={() => setInteractivePath(true)} data-testid="labelling-tool">Labelling Tool</Button>
                    <div className="option-text">
                      <p className="mt-2 mb-0">Use a custom labelling tool directly on your form.</p>
                      <p className="mb-0">Interact with your form and label the fields as you go.</p>
                    </div>
                  </Col>
                  <Col className="text-center option-col">
                    <Button onClick={() => setTrackedFieldsPath(true)} data-testid="tracked-fields">Unconfigured Fields List</Button>
                    <div className="option-text">
                      <p className="mt-2 mb-0">View your tracked fields in a table.</p>
                      <p className="mb-0">Search to find the field you want to label and save them one by one.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col className="text-center option-col my-0">
                    <h5 className="fw-light">Example of labelling fields on a live form</h5>
                    <div className="">
                      <figure className="mb-0">
                        <img src={LabellingToolImg} alt="Labelling-tool" width="90%" height="auto" className="card-info-img"></img>
                      </figure>
                    </div>
                  </Col>
                  <Col className="text-center option-col my-0">
                    <h5 className="fw-light">Example of selecting tracked fields</h5>
                    <div className="">
                      <figure className="mb-0">
                        <img src={TrackedFieldsTableImg} alt="Tracked-fields-table" width="80%" height="auto" className="card-info-img"></img>
                      </figure>
                    </div>
                  </Col>
                </Row>
              </div>}

            {wizardCurrentStep === 1 && interactivePath &&
              <div className="interactive-mode pb-1 max-modal-height-container d-flex flex-column flex-grow-1">
                <div className="pt-2">
                  <p>Click the launch button and your form will open in a new pop-up window. You can then click fields and buttons to label your form fields from start to finish. Close the tool once you've labelled the fields, then return here and you can continue on to order your fields.</p>
                </div>
                <Row className="g-0 pt-2 pb-3 justify-content-center">
                  <Col className="form-link-container col-auto d-flex justify-content-center align-items-center p-3">
                    <p className="mb-0">{form.url}</p>
                    {!labellingToolLoading && <Button onClick={() => handleLaunchLabellingTool()}>Launch Field Labelling Tool</Button>}
                    {labellingToolLoading && <div className="loading-btn-replace"><FaSpinner size="18px" className="spinning-icon me-1 mb-1" title="Loading form..."/></div>}
                  </Col>
                </Row>
                <p className="mt-3 mb-0">View <a href="https://www.zuko.io/guides/field-labelling-wizard" target="_blank" rel="noopener noreferrer">labelling guide<HiExternalLink className="align-top"/></a>.</p>
                {labellingToolError &&
                  <Row className="g-0 pt-1 alert-row">
                    <Alert variant="outline-danger">
                      <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                      <p className="alert-text m-0 ps-3 pe-0 py-0">Oops, sorry the labelling tool cannot be launched. Please try again or contact <a href="mailto:support@zuko.io">support</a>.</p>
                    </Alert>
                  </Row>}
                <div>
                  <h5 className="further-info-title">Tips</h5>
                  <ul className="labelling-tool-tips mb-1">
                    <li className=""><span className="li-main-point">Zuko must be loaded onto your form.</span> If your form is not tracking, please review the <Link to={`/forms/${form?.uuid}/tracking_code`}>Tracking Code</Link>.</li>
                    <li className="mt-1"><span className="li-main-point">You must stay within the tab opened by the Zuko App.</span> If you need to navigate within your site to reach the form, this is fine as long as you remain in the same tab.</li>
                    <li className="mt-1"><span className="li-main-point">Label one form at a time.</span> If you have a multi-step form, or the same URL with multiple forms, open the Labelling Tool separately for each form.</li>
                    <li className="mt-1"><span className="li-main-point">Label your continue/submit button before you actually complete your form.</span> Once you complete your form the Labelling Tool may no longer be on your form's page.</li>
                    <li className="mt-1"><span className="li-main-point">As well as labelling fields, you can also hide fields directly in the  Labelling Tool.</span> You can then review all of your hidden fields on Step 3 of the wizard.</li>
                    <li className="mt-1"><span className="li-main-point">If a field has a label saved already which is not what you were expecting, that field may have the same HTML attributes as another field.</span> Please add a unique <code>name</code> or <code>id</code> to the fields.</li>
                    <li className="mt-1"><span className="li-main-point">If you click on an element that is not tracked by Zuko you will not be able to immediately label it.</span> Typically this is if it hasn’t been constructed as a standard form element. Find out <a href="https://www.zuko.io/guides/tracking-non-standard-form-elements-in-zuko" target="_blank" rel="noopener noreferrer">how to track these elements<HiExternalLink className="align-top"/></a>.</li>
                  </ul>
                </div>
              </div>}

            {wizardCurrentStep === 1 && trackedFieldsPath && <>
              {!selectedField &&
                <div className="choose-tracked-field pb-1 pt-2 max-modal-height-container d-flex flex-column">
                  {unconfiguredFieldsLoading && <div className="mt-5 align-self-center"><FaSpinner size="18px" className="spinning-icon me-1 mb-1" title="Loading fields..."/></div>}
                  {unconfiguredFieldsError && <p className="mt-5 align-self-center error-text">{unconfiguredFieldsError}</p>}
                  {noUnconfiguredFields &&
                    <p className="mt-5 align-self-center">All fields have been configured. Please continue to field ordering.</p>}
                  {!unconfiguredFieldsLoading && !unconfiguredFieldsError && ((unconfiguredFieldsToDisplay && unconfiguredFieldsToDisplay.length > 0) || unconfiguredFieldsFilterInput) && <>
                    <Row className="g-0 pt-2">
                      <p className="mb-0">Here are your tracked fields that you can now configure. Select a field you'd like to label. You can use the HTML <code>name</code> and <code>id</code> to help you identify the field on your form.</p>
                    </Row>
                    <Row className="g-0 justify-content-between table-search-row">
                      <Col className="p-0 d-flex align-items-end"><p className="mb-0">Number of unconfigured fields: {unconfiguredFieldsToDisplay.length}</p></Col>
                      <Col className="p-0 col-auto">
                        <Form className="d-inline-flex">
                          <input
                            value={unconfiguredFieldsFilterInput || ''}
                            onChange={(({target: {value}}) => {
                              if (value) {
                                setUnconfiguredFieldsFilterInput(value);
                              } else {
                                setUnconfiguredFieldsFilterInput('');
                                setUnconfiguredFieldsFilter('');
                              }
                            })}
                          />
                          <Button type="submit" onClick={(e) => {e.preventDefault(); setUnconfiguredFieldsFilter(unconfiguredFieldsFilterInput);}}>Search</Button>
                        </Form>
                      </Col>
                    </Row>
                    <Table striped responsive hover borderless id="unconfigured-table" className="mb-0" data-testid="wizard-unconfigured-table">
                      <thead>
                        <tr>
                          <th className="tagname-column">Tag Name
                            <span onClick={() => setUnconfiguredFieldsSortedOn('htmlTagName')} className="table-sort-icons">
                              <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlTagName' ? 'sorted' : ''}`}></i></span>
                          </th>
                          <th className="type-column">Type
                            <span onClick={() => setUnconfiguredFieldsSortedOn('htmlType')} className="table-sort-icons">
                              <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlType' ? 'sorted' : ''}`}></i></span>
                          </th>
                          <th className={`name-column ${expandNameCol ? 'expand' : ''}`}>Name
                            <span onClick={() => setExpandNameCol(!expandNameCol)} className="ps-2 expand-column-icon">
                              {expandNameCol ? <FiMinimize2 size="14px" title="Minimise Name column"/> : <FiMaximize2 size="14px" title="Expand Name column"/>}</span>
                            <span onClick={() => setUnconfiguredFieldsSortedOn('htmlName')} className="ps-2 table-sort-icons">
                              <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlName' ? 'sorted' : ''}`}></i></span>
                          </th>
                          <th className={`id-column ${expandIDCol ? 'expand' : ''}`}>ID
                            <span onClick={() => setExpandIDCol(!expandIDCol)} className="ps-2 expand-column-icon">
                              {expandIDCol ?  <FiMinimize2 size="14px" title="Minimise ID column"/>: <FiMaximize2 size="14px" title="Expand ID column"/>}</span>
                            <span onClick={() => setUnconfiguredFieldsSortedOn('htmlId')} className="ps-2 table-sort-icons">
                              <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlId' ? 'sorted' : ''}`}></i></span>
                          </th>
                          <th className="text-center last-tracked-column">Last Tracked
                            <span onClick={() => setUnconfiguredFieldsSortedOn('lastTracked')} className="table-sort-icons">
                              <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'lastTracked' ? 'sorted' : ''}`}></i></span></th>
                          <th className="btn-col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {unconfiguredPageFields?.map((field, i) => {
                          return (
                            <tr key={`unlabelled-field-${i}`}>
                              <td>{field.htmlTagName}</td>
                              <td>{field.htmlType}</td>
                              <td className={`name-column ${expandNameCol ? 'expand' : ''}`} title={field.htmlName}>{field.htmlName}</td>
                              <td className={`id-column ${expandIDCol ? 'expand' : ''}`} title={field.htmlName}>{field.htmlId}</td>
                              <td className="text-center">{msTimestampToDate({msTimestamp: field.lastTracked, timeZone: form?.organisation?.timeZone})}</td>
                              <td className="btn-col text-center py-1 ps-1 pe-3">
                                <Button className="select-btn m-0" onClick={() => {
                                  setSelectedField({...field, label: labelForField(field)});
                                  // setWizardCurrentStep(prev => prev+1);
                                  setUnconfiguredFieldsFilterInput(null);
                                  setUnconfiguredFieldsFilter(null);
                                }} data-testid={`wizard-select-field-${i}`}>Select</Button>
                              </td>
                            </tr>);
                        })}
                      </tbody>
                    </Table>
                    {(currentUnconfiguredPage === (maxFieldsResultsSize/maxFieldsPerPage) - 1 && unconfiguredFieldsToDisplay.length === maxFieldsResultsSize) &&
                      <div className="text-center">
                        <p>You have reached the maximum number of unconfigured fields that can be provided.</p>
                      </div>}
                    <ReactPaginate
                      pageCount={Math.ceil(unconfiguredFieldsToDisplay.length / maxFieldsPerPage)}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={0}
                      onPageChange={handleUnconfiguredPageChange}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      activeClassName={'active'}
                      forcePage={forcePage ?? -1}
                    />
                    <p className="mb-0 pt-2 mt-auto"><strong>Note: </strong>Unconfigured fields will still be displayed in Zuko reports (unless hidden) but this will be under a default label (usually their HTML <code>name</code> or <code>id</code>) and you will not have control over their order.</p>
                  </>}
                </div>}

              {selectedField &&
              <div className="label-tracked-field pb-1 pt-2 max-modal-height-container">
                <Row className="g-0 pt-2">
                  <p className="mb-0">Label your field using the text box on the right. This is how the field will be displayed across all Zuko reports.</p>
                </Row>
                <Table striped responsive hover borderless id="configuring-table" data-testid="wizard-configuring-table">
                  <thead>
                    <tr>
                      <th className="tagname-column">Tag Name</th>
                      <th className="type-column">Type</th>
                      <th className={`name-column ${expandNameCol ? 'expand' : ''}`}>Name
                        <span onClick={() => setExpandNameCol(!expandNameCol)} className="ps-2 expand-column-icon">
                          {expandNameCol ? <FiMinimize2 size="14px" title="Minimise Name column"/> : <FiMaximize2 size="14px" title="Expand Name column"/>}</span>
                      </th>
                      <th className={`id-column ${expandIDCol ? 'expand' : ''}`}>ID
                        <span onClick={() => setExpandIDCol(!expandIDCol)} className="ps-2 expand-column-icon">
                          {expandIDCol ?  <FiMinimize2 size="14px" title="Minimise ID column"/>: <FiMaximize2 size="14px" title="Expand ID column"/>}</span>
                      </th>
                      <th className="label-column">Label</th>
                      <th className="btn-col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{selectedField.htmlTagName}</td>
                      <td>{selectedField.htmlType}</td>
                      <td className={`name-column ${expandNameCol ? 'expand' : ''}`} title={selectedField.htmlName} onClick={() => {
                        if (selectedField.htmlName?.length) {
                          if (selectedFieldLabelError) setSelectedFieldLabelError(null);
                          setSelectedFieldLabel(selectedField.htmlName);
                        }}}>{selectedField.htmlName}</td>
                      <td className={`id-column ${expandIDCol ? 'expand' : ''}`} title={selectedField.htmlId} onClick={() => {
                        if (selectedField.htmlId?.length) {
                          if (selectedFieldLabelError) setSelectedFieldLabelError(null);
                          setSelectedFieldLabel(selectedField.htmlId);
                        }}}>{selectedField.htmlId}</td>
                      <td className="label-column">
                        {(fieldSaved !== selectedField.identifier) && <>
                          <Form.Control className={`${selectedFieldLabelError ? "invalid-input" : ''}`} aria-label="Label" type="text" value={selectedFieldLabel ?? selectedField.label} required
                            htmlSize={selectedField.label.length}
                            onChange={({target: {value}}) => {
                              if (!value.length) setSelectedFieldLabelError('Please add a label');
                              if (selectedFieldLabelError && value.length) setSelectedFieldLabelError(null);
                              setSelectedFieldLabel(value);
                            }}/>
                          {selectedFieldLabelError && <div className="invalid-input-feedback">{selectedFieldLabelError}</div>}
                        </>}
                        {(fieldSaved === selectedField.identifier) && (selectedFieldLabel ?? selectedField.label)}
                      </td>
                      <td className="btn-col text-center py-1 ps-1 pe-3">
                        {(fieldSaved !== selectedField.identifier) && <>
                          {savingFields ? <FaSpinner size="14px" className="spinning-icon me-1 mb-1" title="Saving..."/> :
                            <Button className="select-btn m-0" onClick={() => {
                              handleSaveField({
                                ...selectedField,
                                label: (selectedFieldLabel || selectedField.label),
                                hidden: false,
                              });
                            }} disabled={selectedFieldLabel === ''} data-testid={`wizard-save-field-label-${selectedField.identifier}`}>Save Label</Button>}
                        </>}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {(fieldSaved === selectedField.identifier) && <>
                  <Row className="g-0 pt-1 alert-row fade-in-row">
                    <Alert variant="outline-success">
                      <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                      <p className="alert-text m-0 ps-3 pe-0 py-0">Your field label has been successfully saved. You can now select more fields to label or click Continue to move to the next step (field ordering).</p>
                    </Alert>
                  </Row>
                  <Row className="g-0 justify-content-end pt-3 fade-in-row">
                    <Button className="choose-another-cta mx-1" disabled={(fieldSaved !== selectedField.identifier)} onClick={() => {
                      setSelectedField(null);
                      setSelectedFieldLabel(null);
                      setSelectedFieldLabelError(null);
                    }}>Select Another Field</Button>
                  </Row> </>}
                {(!savingFields && errorSaving) && <p>{errorSaving}</p>}
              </div>}
            </>}

            {wizardCurrentStep === 2 && <>
              <div className="order-fields pt-2 max-modal-height-container">
                {!configuredFieldsExclHidden.length > 0 && <p className="mt-5 text-center">No fields have been saved yet. Please go back to select a field to label or continue to manage hidden fields.</p>}
                {configuredFieldsExclHidden.length > 0 && <>
                  <Row className="g-0 pt-2">
                    <p className="mb-0">Order your fields using the drag and drop <i className="fa fa-bars"></i> to reflect the order the fields occur in your form. This is the default order the fields will be displayed in Zuko reports.</p>
                  </Row>
                  <Row className="g-0 justify-content-end">
                    <OverlayTrigger placement="bottom" trigger={'click'} rootClose overlay={<Popover id="datatable-column-toggle">
                      <Popover.Body>
                        {['htmlTagName', 'htmlType', 'htmlName', 'htmlId', 'mergingRule', 'lastTracked'].map((column) => {
                          const hiddenDisplayName = {
                            htmlTagName: 'HTML Tag Name',
                            htmlType: 'HTML Type',
                            htmlName: 'HTML Name',
                            htmlId: 'HTML ID',
                            mergingRule: 'Merging Rule',
                            lastTracked: 'Last Tracked',
                          };
                          return (
                            <div key={column}>
                              <label>
                                <input type="checkbox" onChange={() => {
                                  if (hiddenColumns.includes(column)) setHiddenColumns(hiddenColumns.filter(c => c !== column));
                                  if (!hiddenColumns.includes(column)) setHiddenColumns(hiddenColumns.concat(column));
                                }} checked={!hiddenColumns.includes(column)}/>{' '}
                                {hiddenDisplayName[column]}
                              </label>
                            </div>
                          );
                        })}
                      </Popover.Body>
                    </Popover>}>
                      <Button className="px-3 mx-2 d-flex align-items-center toggle-columns-btn" variant="outline-secondary"><BiColumns className="me-1 plus-inside-btn" size="16px"/>Manage Columns</Button>
                    </OverlayTrigger>
                  </Row>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Table striped responsive hover borderless id="reordering-table" className="mb-0" data-testid="wizard-reordering-table">
                      <thead>
                        <tr>
                          <th className="grip-column"></th>
                          <th className="order-column text-center">Order</th>
                          <th className="label-column">Label</th>
                          {!hiddenColumns.includes('htmlTagName') && <th className="tagname-column">Tag Name</th>}
                          {!hiddenColumns.includes('htmlType') && <th className="type-column">Type</th>}
                          {!hiddenColumns.includes('htmlName') && <th className={`name-column ${expandNameCol ? 'expand' : ''}`}>Name
                            <span onClick={() => setExpandNameCol(!expandNameCol)} className="ps-2 expand-column-icon">
                              {expandNameCol ? <FiMinimize2 size="14px" title="Minimise Name column"/> : <FiMaximize2 size="14px" title="Expand Name column"/>}</span></th>}
                          {!hiddenColumns.includes('htmlId') && <th className={`id-column ${expandIDCol ? 'expand' : ''}`}>ID
                            <span onClick={() => setExpandIDCol(!expandIDCol)} className="ps-2 expand-column-icon">
                              {expandIDCol ?  <FiMinimize2 size="14px" title="Minimise ID column"/>: <FiMaximize2 size="14px" title="Expand ID column"/>}</span></th>}
                          {!hiddenColumns.includes('mergingRule') && <th className="text-center">Merging Rule</th>}
                          {!hiddenColumns.includes('lastTracked') && <th className="text-center">Last Tracked</th>}
                        </tr>
                      </thead>
                      <Droppable droppableId="droppable" direction="vertical">
                        {(provided, snapshot) => (
                          <tbody ref={provided.innerRef} {...provided.droppableProps}>
                            {configuredFieldsExclHidden.map((field, rowIndex) => (
                              <Draggable key={`field-${field.identifier}`} draggableId={(field.unconfigured && field.merged) ? field.regexp : field.identifier} index={rowIndex}>
                                {(provided, snapshot) => {
                                  const { merged, enabled, htmlTagName, htmlType, htmlName, htmlId, label, lastTracked } = field;
                                  return (
                                    <tr ref={provided.innerRef} {...provided.draggableProps}
                                      className={`field-row ${(rowIndex % 2) === 0 ? 'even' : 'odd'}`}  key={`configured-field-${field.identifier}`}>
                                      <td className={`grip-column text-center`}>
                                        <span {...provided.dragHandleProps} className={(label === null || label === undefined || label.length < 1) ? 'disabled-drag' : 'drag-icon'}><i className="fa fa-bars"/></span></td>
                                      <td className={`field-order text-center`}>{rowIndex + 1}</td>
                                      <td className={`label-column`}>{label}</td>
                                      {!hiddenColumns.includes('htmlTagName') && <td className={`tagname-column`} title={htmlTagName}>{htmlTagName}</td>}
                                      {!hiddenColumns.includes('htmlType') && <td className={`type-column`} title={htmlType}>{htmlType}</td>}
                                      {!hiddenColumns.includes('htmlName') && <td className={`name-column ${expandNameCol ? 'expand' : ''}`} title={htmlName}>{htmlName}</td>}
                                      {!hiddenColumns.includes('htmlId') && <td className={`id-column ${expandIDCol ? 'expand' : ''}`} title={htmlId}>{htmlId}</td>}
                                      {!hiddenColumns.includes('mergingRule') &&
                                          <td className={`text-center`} title={merged ? `Merged ${label}` : ''}>
                                            {merged &&
                                            <span><i className="fa fa-check"/>
                                              {enabled === false && <p className="mergedDisabled">(disabled)</p>}
                                            </span>}
                                          </td>}
                                      {!hiddenColumns.includes('lastTracked') &&
                                      <td className="text-center">{lastTracked ? msTimestampToDate({msTimestamp: lastTracked, timeZone: form?.organisation?.timeZone}) : (!merged ? '>12 months' : '-')}</td>}
                                    </tr>
                                  );
                                }}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </Table>
                  </DragDropContext>
                </>}
              </div>
            </>}

            {wizardCurrentStep === 3 && <>
              <div className="hide-fields pt-2 max-modal-height-container">
                <Row className="g-0 pt-2">
                  <p className="mb-0">Here you can hide any fields so that they don't show up in the Zuko reports. The data isn’t deleted (you can unhide it later) but it will help tidy up your reports.
                      You might want to do this to remove fields with a low number of interactions or there may be fields you simply aren't interested in.</p>
                </Row>
                <TabContainer defaultActiveKey={`${noUnconfiguredFields ? 'configured' : 'unconfigured'}`}>
                  <Row className="g-0 justify-content-center">
                    <Col className="col-auto px-0 pb-1">
                      <Nav className="flex-row">
                        <Nav.Item>
                          <Nav.Link eventKey="unconfigured" className="unconfigured-link" disabled={noUnconfiguredFields}>Unconfigured Fields</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="configured" className="configured-link" disabled={!configuredFields.length}>Configured Fields</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <TabContent>
                    <TabPane eventKey="unconfigured" className="h-100 unconfigured position-relative">
                      {unconfiguredFieldsLoading && <div className="mt-5 align-self-center"><FaSpinner size="18px" className="spinning-icon me-1 mb-1" title="Loading fields..."/></div>}
                      {unconfiguredFieldsError && <p className="mt-5 text-center error-text">{unconfiguredFieldsError}</p>}
                      {!unconfiguredFieldsLoading && !unconfiguredFieldsError && ((unconfiguredFieldsToDisplay && unconfiguredFieldsToDisplay.length > 0) || unconfiguredFieldsFilterInput) && <>
                        <Row className="g-0 j table-search-row">
                          <Col className="p-0 d-flex justify-content-end">
                            <Form className="d-inline-flex">
                              <input
                                value={unconfiguredFieldsFilterInput || ''}
                                onChange={(({target: {value}}) => {
                                  if (value) {
                                    setUnconfiguredFieldsFilterInput(value);
                                  } else {
                                    setUnconfiguredFieldsFilterInput('');
                                    setUnconfiguredFieldsFilter('');
                                  }
                                })}
                              />
                              <Button type="submit" onClick={(e) => {e.preventDefault(); setUnconfiguredFieldsFilter(unconfiguredFieldsFilterInput);}}>Search</Button>
                            </Form>
                          </Col>
                        </Row>
                        <Table striped responsive hover borderless id="hiding-unconfigured-table" className="mb-0" data-testid="wizard-hiding-unconfigured-table">
                          <thead>
                            <tr>
                              <th className="tagname-column">Tag Name
                                <span onClick={() => setUnconfiguredFieldsSortedOn('htmlTagName')} className="table-sort-icons">
                                  <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlTagName' ? 'sorted' : ''}`}></i></span>
                              </th>
                              <th className="type-column">Type
                                <span onClick={() => setUnconfiguredFieldsSortedOn('htmlType')} className="table-sort-icons">
                                  <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlType' ? 'sorted' : ''}`}></i></span>
                              </th>
                              <th className={`name-column ${expandNameCol ? 'expand' : ''}`}>Name
                                <span onClick={() => setExpandNameCol(!expandNameCol)} className="ps-2 expand-column-icon">
                                  {expandNameCol ? <FiMinimize2 size="14px" title="Minimise Name column"/> : <FiMaximize2 size="14px" title="Expand Name column"/>}</span>
                                <span onClick={() => setUnconfiguredFieldsSortedOn('htmlName')} className="ps-2 table-sort-icons">
                                  <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlName' ? 'sorted' : ''}`}></i></span>
                              </th>
                              <th className={`id-column ${expandIDCol ? 'expand' : ''}`}>ID
                                <span onClick={() => setExpandIDCol(!expandIDCol)} className="ps-2 expand-column-icon">
                                  {expandIDCol ?  <FiMinimize2 size="14px" title="Minimise ID column"/>: <FiMaximize2 size="14px" title="Expand ID column"/>}</span>
                                <span onClick={() => setUnconfiguredFieldsSortedOn('htmlId')} className="ps-2 table-sort-icons">
                                  <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlId' ? 'sorted' : ''}`}></i></span>
                              </th>
                              <th className="text-center last-tracked-column">Last Tracked
                                <span onClick={() => setUnconfiguredFieldsSortedOn('lastTracked')} className="table-sort-icons">
                                  <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'lastTracked' ? 'sorted' : ''}`}></i></span></th>
                              <th className="hide-column text-center">Hide</th>
                            </tr>
                          </thead>
                          <tbody>
                            {unconfiguredFieldsToDisplay?.map((field, i) => {
                              const {identifier, htmlTagName, htmlType, htmlName, htmlId, lastTracked} = field;
                              const hidden = hiddenUnconfiguredFields.includes(identifier);
                              return (
                                <tr key={`unlabelled-field-${i}`}>
                                  <td className={`${hidden ? 'hidden-field-row' : ''}`}>{htmlTagName}</td>
                                  <td className={`${hidden ? 'hidden-field-row' : ''}`}>{htmlType}</td>
                                  <td className={`name-column ${hidden ? 'hidden-field-row' : ''} ${expandNameCol ? 'expand' : ''}`}>{htmlName}</td>
                                  <td className={`id-column ${hidden ? 'hidden-field-row' : ''} ${expandIDCol ? 'expand' : ''}`}>{htmlId}</td>
                                  <td className="text-center">{msTimestampToDate({msTimestamp: lastTracked, timeZone: form?.organisation?.timeZone})}</td>
                                  <td className="hide-column text-center">
                                    <Form.Switch
                                      type="switch"
                                      aria-label="Hide/Unhide"
                                      id={`wizard-${identifier}`}
                                      value={identifier}
                                      checked={hidden}
                                      title={hidden ? 'Hidden from data' : 'Shown in data'}
                                      onChange={() => setHiddenUnconfiguredFields(hidden ? hiddenUnconfiguredFields.filter(id => id !== identifier) :
                                        hiddenUnconfiguredFields.concat(identifier))}></Form.Switch>
                                  </td>
                                </tr>);
                            })}
                          </tbody>
                        </Table>
                      </>}
                      <p className="mb-0 pt-2 position-absolute bottom-msg"><strong>Note: </strong>Unconfigured fields will still be displayed in Zuko reports (unless hidden) but this will be under a default label (usually their HTML <code>name</code> or <code>id</code>) and you will not have control over their order.</p>
                    </TabPane>
                    <TabPane eventKey="configured" className="h-100 configured">
                      <Row className="g-0 justify-content-end">
                        <OverlayTrigger placement="bottom" trigger={'click'} rootClose overlay={<Popover id="datatable-column-toggle">
                          <Popover.Body>
                            {['htmlTagName', 'htmlType', 'htmlName', 'htmlId', 'mergingRule', 'lastTracked'].map((column) => {
                              const hiddenDisplayName = {
                                htmlTagName: 'HTML Tag Name',
                                htmlType: 'HTML Type',
                                htmlName: 'HTML Name',
                                htmlId: 'HTML ID',
                                mergingRule: 'Merging Rule',
                                lastTracked: 'Last Tracked',
                              };
                              return (
                                <div key={column}>
                                  <label>
                                    <input type="checkbox" onChange={() => {
                                      if (hiddenColumns.includes(column)) setHiddenColumns(hiddenColumns.filter(c => c !== column));
                                      if (!hiddenColumns.includes(column)) setHiddenColumns(hiddenColumns.concat(column));
                                    }} checked={!hiddenColumns.includes(column)}/>{' '}
                                    {hiddenDisplayName[column]}
                                  </label>
                                </div>
                              );
                            })}
                          </Popover.Body>
                        </Popover>}>
                          <Button className="px-3 mx-2 d-flex align-items-center toggle-columns-btn" variant="outline-secondary"><BiColumns className="me-1 plus-inside-btn" size="16px"/>Manage Columns</Button>
                        </OverlayTrigger>
                      </Row>
                      <Table striped responsive hover borderless id="hiding-configured-table" className="mb-0" data-testid="wizard-hiding-configured-table">
                        <thead>
                          <tr>
                            <th className="order-column text-center">Order</th>
                            <th className="label-column">Label</th>
                            {!hiddenColumns.includes('htmlTagName') && <th className="tagname-column">Tag Name</th>}
                            {!hiddenColumns.includes('htmlType') && <th className="type-column">Type</th>}
                            {!hiddenColumns.includes('htmlName') && <th className={`name-column ${expandNameCol ? 'expand' : ''}`}>Name
                              <span onClick={() => setExpandNameCol(!expandNameCol)} className="ps-2 expand-column-icon">
                                {expandNameCol ? <FiMinimize2 size="14px" title="Minimise Name column"/> : <FiMaximize2 size="14px" title="Expand Name column"/>}</span></th>}
                            {!hiddenColumns.includes('htmlId') && <th className={`id-column ${expandIDCol ? 'expand' : ''}`}>ID
                              <span onClick={() => setExpandIDCol(!expandIDCol)} className="ps-2 expand-column-icon">
                                {expandIDCol ?  <FiMinimize2 size="14px" title="Minimise ID column"/>: <FiMaximize2 size="14px" title="Expand ID column"/>}</span></th>}
                            {!hiddenColumns.includes('mergingRule') && <th className="text-center">Merging Rule</th>}
                            {!hiddenColumns.includes('lastTracked') && <th className="text-center">Last Tracked</th>}
                            <th className="hide-column text-center">Hide</th>
                          </tr>
                        </thead>
                        <tbody>
                          {configuredFields.length > 0 && configuredFields.map((field, rowIndex) => {
                            const { identifier, merged, enabled, htmlTagName, htmlType, htmlName, htmlId, label, lastTracked } = field;
                            const hidden = hiddenConfiguredFields.includes(identifier);
                            return (
                              <tr
                                className={`field-row ${(rowIndex % 2) === 0 ? 'even' : 'odd'}`} key={`configured-field-${identifier}`}>
                                <td className={`field-order text-center ${hidden ? 'hidden-field-row' : ''}`}>{rowIndex + 1}</td>
                                <td className={`label-column ${hidden ? 'hidden-field-row' : ''}`}>{label}</td>
                                {!hiddenColumns.includes('htmlTagName') && <td className={`tagname-column ${hidden ? 'hidden-field-row' : ''}`} title={htmlTagName}>{htmlTagName}</td>}
                                {!hiddenColumns.includes('htmlType') && <td className={`type-column ${hidden ? 'hidden-field-row' : ''}`} title={htmlType}>{htmlType}</td>}
                                {!hiddenColumns.includes('htmlName') && <td className={`name-column ${hidden ? 'hidden-field-row' : ''} ${expandNameCol ? 'expand' : ''}`} title={htmlName}>{htmlName}</td>}
                                {!hiddenColumns.includes('htmlId') && <td className={`id-column ${hidden ? 'hidden-field-row' : ''} ${expandIDCol ? 'expand' : ''}`} title={htmlId}>{htmlId}</td>}
                                {!hiddenColumns.includes('mergingRule') &&
                                <td className={`text-center ${hidden ? 'hidden-field-row' : ''}`} title={merged ? `Merged ${label}` : ''}>
                                  {merged &&
                                  <span><i className="fa fa-check"/>
                                    {enabled === false && <p className="mergedDisabled">(disabled)</p>}
                                  </span>}
                                </td>}
                                {!hiddenColumns.includes('lastTracked') &&
                                <td className={`text-center ${hidden ? 'hidden-field-row' : ''}`}>{lastTracked ? msTimestampToDate({msTimestamp: lastTracked, timeZone: form?.organisation?.timeZone}) : (!merged ? '>12 months' : '-')}</td>}
                                <td className="hide-column text-center">
                                  <Form.Switch
                                    type="switch"
                                    aria-label="Hide/Unhide"
                                    id={`wizard-${identifier}`}
                                    value={identifier}
                                    checked={hidden}
                                    title={hidden ? 'Hidden from data' : 'Shown in data'}
                                    onChange={() => {
                                      setHiddenConfiguredFields(hidden ? hiddenConfiguredFields.filter(id => id !== identifier) :
                                        hiddenConfiguredFields.concat(identifier));
                                    }}></Form.Switch>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                </TabContainer>
              </div>
            </>}

            {wizardCurrentStep === 4 && <>
              <div className="completed-fields">
                <Row className="g-0">
                  <Col className="pb-1 max-modal-height-container d-flex flex-column">
                    {configuredFields.length > 0 ? <>
                      <Row className="g-0 pt-2">
                        <p className="mb-0">You've completed the wizard. Here are your configured {configuredFieldsExclHidden.length} fields.{hiddenConfiguredFields.length > 0 && ` You have also hidden ${hiddenConfiguredFields.length} fields from Zuko reports.`} If you'd like to label more fields, change the order again or hide some fields you can go back to those steps.</p>
                      </Row>
                      {configuredFieldsExclHidden.length > 0 &&
                      <Table responsive hover borderless id="completed-table" className="mb-0" data-testid="wizard-completed-table">
                        <thead>
                          <tr>
                            <th className="order-column text-center">Order</th>
                            <th className="label-column">Label</th>
                          </tr>
                        </thead>
                        <tbody>
                          {configuredFieldsExclHidden.map((field, rowIndex) => {
                            const { identifier, label } = field;
                            return (
                              <tr className={`field-row ${(rowIndex % 2) === 0 ? 'even' : 'odd'}`} key={`configured-field-${identifier}`}>
                                <td className={`field-order text-center`}>{rowIndex + 1}</td>
                                <td className={`label-column`}>{label}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>}
                    </> :
                      <p className="mt-5 text-center">No fields have been configured yet, please first select a field to label.</p>}
                  </Col>
                </Row>
              </div>
            </>}
          </>}
        </div>
      </Modal.Body>
      <Modal.Footer className={`justify-content-between`}>
        {wizardCurrentStep === 1 && <>
          {!interactivePath && !trackedFieldsPath && <>
            <Button variant="outline-secondary" className="cancel mx-1" onClick={() => {handleCloseWizard();}}>Cancel</Button>
            <Button variant="outline-primary" className="mx-1" onClick={() => {setWizardCurrentStep(2);}}>Skip Labelling</Button></>}

          {interactivePath && <>
            <Button variant="outline-secondary" className="cancel mx-1" onClick={() => {
              setInteractivePath(false);
              setInitiateMoveToStep(null);
              setLabellingToolError(null);
            }}>Back</Button>
            <div className="d-flex align-items-center">
              {configuredFieldsError && <p className="mb-0">{configuredFieldsError}</p>}
              {configuredFieldsLoading || (!configuredFieldsLoading && initiateMoveToStep === 2 && !configuredFieldsError) ? <span className="spinner-container smaller-btn d-inline-flex justify-content-center align-items-center"><FaSpinner size="14px" className="spinning-icon me-1 mb-1" title="Loading fields..."/></span> :
                <Button className="mx-1" onClick={() => {
                  handleFetchConfiguredFields();
                  setInitiateMoveToStep(2);
                  setLabellingToolError(null);
                }}>Continue</Button>}
            </div>
          </>}

          {trackedFieldsPath && !selectedField && <>
            <Button variant="outline-secondary" className="cancel mx-1" onClick={() => {
              setTrackedFieldsPath(false);
            }}>Back</Button>

            {!noTrackedFields && !noUnconfiguredFields &&
              <Button variant="outline-primary" className="mx-1" onClick={() => {
                setWizardCurrentStep(2);
                setUnconfiguredFieldsFilterInput(null);
                setUnconfiguredFieldsFilter(null);
              }}>Skip Labelling</Button>}
            {noUnconfiguredFields && <Button className="mx-1" onClick={() => setWizardCurrentStep(2)}>Continue</Button>}
          </>}

          {trackedFieldsPath && selectedField && <>
            <div>
              <Button variant="outline-secondary" className="cancel mx-1" onClick={() => {
                setSelectedField(null);
                setSelectedFieldLabel(null);
                setSelectedFieldLabelError(null);
              }}>Back</Button>
            </div>
            <div>
              <Button className="mx-1" disabled={(fieldSaved !== selectedField.identifier)} onClick={() => {
                setWizardCurrentStep(2);
                setSelectedField(null);
                setSelectedFieldLabel(null);
                setSelectedFieldLabelError(null);
              }}>Continue</Button>
            </div>
          </>}
        </>}

        {(wizardCurrentStep === 2) && <>
          <div>
            <Button variant="outline-secondary" className="cancel mx-1" onClick={() => {setWizardCurrentStep(1);}}>Back</Button>
          </div>
          <div className="d-flex align-items-center">
            {errorSaving && errorSaving.includes('reordered') && <p className="mb-0">{errorSaving}</p>}
            <Button variant="outline-primary" className="mx-1" onClick={() => {
              setWizardCurrentStep(3);
              setInitiateMoveToStep(null);
            }}>Skip Ordering</Button>
            {savingFields || (!savingFields && initiateMoveToStep === 3 && !errorSaving) ? <span className="spinner-container d-inline-flex justify-content-center align-items-center"><FaSpinner size="14px" className="spinning-icon me-1 mb-1" title="Saving..."/></span> :
              <Button className="mx-1" onClick={() => {
                if (configuredFieldsExclHidden.length > 0) {
                  handleSaveReorderedFields({configuredFieldsExclHidden});
                  setInitiateMoveToStep(3);
                } else {
                  setWizardCurrentStep(3);
                }
              }}>{configuredFieldsExclHidden.length > 0 ? 'Save & Continue' : 'Continue'}</Button>}
          </div>
        </>}
        {wizardCurrentStep === 3 && <>
          <div>
            <Button variant="outline-secondary" className="cancel mx-1" onClick={() => {setWizardCurrentStep(2);}}>Back</Button>
          </div>
          <div className="d-flex align-items-center">
            {errorSaving && errorSaving.includes('hidden') && <p className="mb-0">{errorSaving}</p>}
            <Button  variant="outline-primary" className="mx-1" onClick={() => {setWizardCurrentStep(4); setInitiateMoveToStep(null);}}>Skip Hiding</Button>
            {savingFields || (!savingFields && initiateMoveToStep === 5 && !errorSaving) ? <span className="spinner-container d-inline-flex justify-content-center align-items-center"><FaSpinner size="14px" className="spinning-icon me-1 mb-1" title="Saving..."/></span> :
              <Button className="mx-1" onClick={() => {
                handleSaveHiddenFields({hiddenFields: hiddenConfiguredFields.concat(hiddenUnconfiguredFields)});
                setInitiateMoveToStep(4);
              }}>Save & Continue</Button>}
          </div>
        </>}
        {wizardCurrentStep === 4 && <>
          <div>
            <Button className="choose-another-cta mx-1" onClick={() => setWizardCurrentStep(1)}>Select Field</Button>
            <Button className="choose-another-cta mx-1" onClick={() => setWizardCurrentStep(2)}>Order Fields</Button>
            <Button className="choose-another-cta mx-1" onClick={() => setWizardCurrentStep(3)}>Hide Fields</Button>
          </div>
          <div>
            <Button className="mx-1" onClick={() => {
              handleCloseWizard();
            }}>Done</Button>
          </div></>}
      </Modal.Footer>
    </Modal>
  );
};

export default FormFieldsWizard;
