import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import TabContainer from 'react-bootstrap/TabContainer';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import escapeRegExp from 'lodash.escaperegexp';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import arrayMove from 'array-move';
import { FaSpinner, FaInfoCircle } from "react-icons/fa";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";
import { VscInfo, VscCheck, VscWarning } from "react-icons/vsc";
import { chunk, labelForField, msTimestampToDate } from '../../utils';

import NavBar from '../../NavBar';
import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import ConfiguredFieldRow from './FormFieldsConfiguredFieldRow';
import FormFieldsWizard from './FormFieldsWizard';
import api from '../../api';
import AppContext from '../../AppContext';
import { usePrevious } from '../../hooks';

import './FormFieldsConfig.scss';

const maxFieldsPerPage = 10;
const maxFieldsResultsSize = 200;

const FormFieldsConfig = ({mixpanel}) => {
  const location = useLocation();
  const { uuid: formUuid } = useParams();
  const {
    currentUser,
    showFieldsConfigInfo,
    setShowFieldsConfigInfo,
  } = useContext(AppContext);
  const [form, setForm] = useState(location.state?.form);
  const [errorLoadingForm, setErrorLoadingForm] = useState(null);
  const [unconfiguredFields, setUnconfiguredFields] = useState([]);
  const [unconfiguredFieldsToDisplay, setUnconfiguredFieldsToDisplay] = useState([]);
  const [unconfiguredFieldsLoading, setUnconfiguredFieldsLoading] = useState(true);
  const [unconfiguredFieldsError, setUnconfiguredFieldsError] = useState(null);
  const [unconfiguredFieldsSortedOn, setUnconfiguredFieldsSortedOn] = useState('htmlName');
  const [unconfiguredFieldsFilterInput, setUnconfiguredFieldsFilterInput] = useState();
  const [unconfiguredFieldsFilter, setUnconfiguredFieldsFilter] = useState();
  const [configuredFields, setConfiguredFields] = useState([]);
  const [configuredFieldsLoading, setConfiguredFieldsLoading] = useState(true);
  const [configuredFieldsError, setConfiguredFieldsError] = useState(null);
  const [pageFields, setPageFields] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [forcePage, setForcePage] = useState();
  const [fieldMergingRuleTargetAttribute, setFieldMergingRuleTargetAttribute] = useState('name');
  const [fieldMergingRuleValid, setFieldMergingRuleValid] = useState(false);
  const [fieldMergingRulePattern, setFieldMergingRulePattern] = useState('');
  const [fieldMergingRuleRegexp, setFieldMergingRuleRegexp] = useState('');
  const [fieldMergingRuleError, setFieldMergingRuleError] = useState();
  const [labelErrors, setLabelErrors] = useState({});
  const [canBeSaved, setCanBeSaved] = useState(false);
  const [savingFields, setSavingFields] = useState(false);
  const [saveSuccessMessage, setSaveSuccessMessage] = useState(null);
  const [savingFieldsErrors, setSavingFieldsErrors] = useState(null);
  const [fieldMergingRules, setFieldMergingRules] = useState([]);
  const [originalConfiguredFields, setOriginalConfiguredFields] = useState([]);
  const [showUnsavedFieldsMsg, setShowUnsavedFieldsMsg] = useState(false);
  const [showHiddenFields, setShowHiddenFields] = useState(false);
  const [expandIDRow, setExpandIDRow] = useState(false);
  const [expandNameRow, setExpandNameRow] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [fieldSavedForWizard, setFieldSavedForWizard] = useState();
  const [errorSavingForWizard, setErrorSavingForWizard] = useState();
  const [savingFieldsForWizard, setSavingFieldsForWizard] = useState();
  const [reFetchConfiguredFields, setReFetchConfiguredFields] = useState();

  const configuredFieldsRef = useRef();
  const scrollableConfiguredTable = useRef();

  const prevUnconfiguredFieldsToDisplay = usePrevious(unconfiguredFieldsToDisplay);
  const prevReFetchConfiguredFields = usePrevious(reFetchConfiguredFields);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    if (form) mixpanel.track('Page View', {
      page: 'Field Config',
      'Form Uuid': form.uuid,
      'Organisation Name': form.organisation.name,
      'Organisation Uuid': form.organisation.uuid,
      'Organisation Contract Type': form.organisation.contractType,
    });
  }, [mixpanel, currentUser.email, form]);

  useEffect(() => {
    const getForm = async () => {
      try {
        let { data: { form } } = await api.get(`/forms/${formUuid}`);
        setForm(form);
      } catch (e) {
        setErrorLoadingForm((e.response && (e.response.status === 404)) ? 'Form not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong. Form Details could not load.');
      }
    };
    // Not linked to from another tab so use fetch the form
    if (!location?.state?.form) {
      getForm();
    }
  }, [formUuid, location]);

  const fetchConfiguredFields = useCallback(async () => {
    try {
      setConfiguredFieldsLoading(true);
      setConfiguredFieldsError(null);
      let { data: { fields } } = await api.get('/fields', {
        params: {
          formUuid,
          labelled: true,
          include: ['last_tracked'],
        }
      });

      fields = fields.map((f, i) => {
        f.order = i + 1;
        if (f.label === null) f.label = '';
        return f;
      });
      setConfiguredFields(fields);
      setOriginalConfiguredFields(fields);
    } catch (e) {
      setConfiguredFieldsError('Something went wrong fetching configured fields.');
    } finally {
      setReFetchConfiguredFields(false);
      setConfiguredFieldsLoading(false);
    }
  }, [formUuid]);

  useEffect(() => {
    const fetchUnconfiguredFields = async () => {
      try {
        setUnconfiguredFieldsError(null);
        const { data: { fields } } = await api.get('/fields', {
          params: {
            formUuid,
            labelled: false,
            include: ['last_tracked'],
          }
        });

        setUnconfiguredFields(fields);
        setUnconfiguredFieldsLoading(false);
      } catch (e) {
        setUnconfiguredFieldsError('Something went wrong fetching fields to configure.');
        setUnconfiguredFieldsLoading(false);
      }
    };

    if (formUuid) {
      fetchUnconfiguredFields();
      fetchConfiguredFields();
    }

  }, [formUuid, fetchConfiguredFields]);

  useEffect(() => {
    if (reFetchConfiguredFields) fetchConfiguredFields();
  }, [reFetchConfiguredFields, fetchConfiguredFields]);

  // Remove any updated Configured fields from Unconfigured
  useEffect(() => {
    if (!reFetchConfiguredFields && prevReFetchConfiguredFields && configuredFields?.length && unconfiguredFields?.length) {
      const configuredIdentifiers = configuredFields.map(({identifier}) => identifier);
      setUnconfiguredFields(unconfiguredFields.filter(({identifier}) => !configuredIdentifiers.includes(identifier)));
    }
  }, [reFetchConfiguredFields, prevReFetchConfiguredFields, configuredFields, unconfiguredFields]);

  // Set all unconfigured fields to display, and update anytime they are sorted, updated or filtered
  useEffect(() => {
    if (unconfiguredFields?.length) {
      let updatedFields = unconfiguredFields.map((field, unconfiguredFieldsIndex) => ({ ...field, unconfiguredFieldsIndex}));
      updatedFields = sortFieldsOnAttr({fields: [...updatedFields], attr: unconfiguredFieldsSortedOn});

      if (fieldMergingRules.length) {
        updatedFields = updatedFields.filter((field) => {
          for (const {regexp, htmlAttribute} of fieldMergingRules) {
            if (field[htmlAttribute].match(`^${regexp}$`)) {
              return false;
            }
          }
          return true;
        });
      }

      if (unconfiguredFieldsFilter?.length) {
        updatedFields = updatedFields.filter(({htmlName, htmlId, htmlTagName, htmlType}) => (
          (htmlName?.toLocaleLowerCase().includes(unconfiguredFieldsFilter?.toLocaleLowerCase()) ||
          htmlId?.toLocaleLowerCase().includes(unconfiguredFieldsFilter?.toLocaleLowerCase()) ||
          htmlTagName?.toLocaleLowerCase().includes(unconfiguredFieldsFilter?.toLocaleLowerCase()) ||
          htmlType?.toLocaleLowerCase().includes(unconfiguredFieldsFilter?.toLocaleLowerCase()))
        ));
      }

      setUnconfiguredFieldsToDisplay(updatedFields);
    } else {
      // Last fields removed so set to empty
      setUnconfiguredFieldsToDisplay([]);
    }

  }, [unconfiguredFields, unconfiguredFieldsSortedOn, fieldMergingRules, unconfiguredFieldsFilter]);

  // Set unconfigured fields per page
  useEffect(() => {
    // On first page load, set the fields for the first page
    if (prevUnconfiguredFieldsToDisplay && !prevUnconfiguredFieldsToDisplay.length && unconfiguredFieldsToDisplay.length) {
      setPageFields(chunk(unconfiguredFieldsToDisplay, maxFieldsPerPage)[0]);
    }

    // When unconfigured fields are sorted, changed or filtered, reset the page fields
    if (prevUnconfiguredFieldsToDisplay && prevUnconfiguredFieldsToDisplay.length > 0 && (prevUnconfiguredFieldsToDisplay.length !== unconfiguredFieldsToDisplay.length ||
      (prevUnconfiguredFieldsToDisplay.length === unconfiguredFieldsToDisplay.length &&
        !prevUnconfiguredFieldsToDisplay.every((f, i) => f.identifier === unconfiguredFieldsToDisplay[i].identifier)))) {
      const pages = chunk(unconfiguredFieldsToDisplay, maxFieldsPerPage);
      // If there are no more fields left on the page, move to previous
      const selectedPage = (pages.length > 0 && (pages.length - 1) < currentPage) ? pages.length - 1 : currentPage;
      setPageFields(pages[selectedPage]);
      if (currentPage !== selectedPage) setCurrentPage(selectedPage);
      if (!pages.length) {
        setForcePage(null);
      } else {
        setForcePage(selectedPage);
      }
    }

  }, [prevUnconfiguredFieldsToDisplay, unconfiguredFieldsToDisplay, currentPage]);

  const sortFieldsOnAttr = ({fields, attr}) => {
    return fields.sort((a, b) => {
      switch (attr) {
      case 'lastTracked':
        return b[attr] - a[attr];
      default:
        const aAttr = a[attr]?.toLowerCase();
        const bAttr = b[attr]?.toLowerCase();

        return (aAttr === null || aAttr === '') - (bAttr === null || bAttr === '') || (aAttr > bAttr) - (aAttr < bAttr);
      }
    });
  };

  const handleMoveToConfigured = (selectedFieldPageIndex) => {
    const selectedUnconfiguredFieldIndex = (pageFields[selectedFieldPageIndex].unconfiguredFieldsIndex);
    const selectedField = unconfiguredFields[selectedUnconfiguredFieldIndex];

    setConfiguredFields([{
      ...selectedField,
      unconfigured: true,
      label: labelForField(selectedField),
      order: null,
      merged: false,
      hidden: false,
    }].concat(configuredFields));

    if (scrollableConfiguredTable?.current) scrollableConfiguredTable.current.scrollTop = 0;

    setUnconfiguredFields(unconfiguredFields.filter((field, i) => i !== selectedUnconfiguredFieldIndex));

    setCanBeSaved(true);
    if (saveSuccessMessage) setSaveSuccessMessage(null);
  };

  const handleRemoveFromConfigured = useCallback((selectedField) => {
    const {identifier, merged, unconfiguredFieldsIndex } = selectedField;

    setConfiguredFields(configuredFields.filter((f) => f.identifier !== identifier));

    if (!merged) {
      const newUnconfigured = unconfiguredFields.slice();
      newUnconfigured.splice(unconfiguredFieldsIndex || 0, 0, selectedField);
      setUnconfiguredFields(newUnconfigured);
    }

    if (merged) setFieldMergingRules(fieldMergingRules.filter(r => !selectedField[r.htmlAttribute]?.match(`^${r.regexp}$`)));

    if (labelErrors[identifier]) {
      const newErrors =  {...labelErrors};
      delete newErrors[identifier];
      setLabelErrors(newErrors);
    }
  }, [configuredFields, unconfiguredFields, fieldMergingRules, labelErrors]);

  const handlePageChange = (e) => {
    const selectedPageIndex = e.selected;
    const chunks = chunk(unconfiguredFieldsToDisplay, maxFieldsPerPage);
    setCurrentPage(selectedPageIndex);
    setPageFields(chunks[selectedPageIndex]);
  };

  const handleFieldMergingRulePatternChange = ({target: {value: pattern}}) => {
    setFieldMergingRuleValid(true);
    setFieldMergingRulePattern(pattern);
    setFieldMergingRuleRegexp(escapeRegExp(pattern).replace(/\\\*/g, '.*'));
    setFieldMergingRuleError(null);
  };

  const handleStageFieldMergingRule = () => {
    const htmlAttribute = `html${fieldMergingRuleTargetAttribute.charAt(0).toUpperCase() + fieldMergingRuleTargetAttribute.slice(1)}`;

    const fieldMergingRuleExists = (configuredFields || []).filter(f => f.merged && f[htmlAttribute] === fieldMergingRuleRegexp).length > 0;
    if (fieldMergingRuleExists) {
      setFieldMergingRuleError('A rule with this pattern already exists.');
      return;
    }

    setConfiguredFields([{
      [htmlAttribute]: fieldMergingRuleRegexp,
      regexp: fieldMergingRuleRegexp,
      targetAttribute: fieldMergingRuleTargetAttribute,
      unconfigured: true,
      label: fieldMergingRuleRegexp,
      order: null,
      merged: true,
      hidden: false,
      identifier: `${fieldMergingRuleRegexp}-${fieldMergingRuleTargetAttribute}`, // Temporary identifier to handle labelling errors
    }].concat(configuredFields));

    if (scrollableConfiguredTable?.current) scrollableConfiguredTable.current.scrollTop = 0;

    setFieldMergingRuleTargetAttribute('name');
    setFieldMergingRuleValid(false);
    setFieldMergingRulePattern('');
    setFieldMergingRuleRegexp('');

    // Log the rule so that the displayed unconfigured fields can be filtered
    setFieldMergingRules([{ regexp: fieldMergingRuleRegexp, htmlAttribute }].concat(fieldMergingRules));

    setCanBeSaved(true);
    if (saveSuccessMessage) setSaveSuccessMessage(null);
  };

  const handleHideField = useCallback((fieldIndex) => {
    const newFields = configuredFields.slice();
    newFields[fieldIndex] = {...newFields[fieldIndex], hidden: !newFields[fieldIndex].hidden};
    setConfiguredFields(newFields);
    setCanBeSaved(true);
    setSaveSuccessMessage(null);
  }, [configuredFields]);

  const onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedFields = arrayMove((!showHiddenFields ? configuredFields.filter(f => !f.hidden) : configuredFields), result.source.index, result.destination.index);
    setConfiguredFields(reorderedFields.concat(!showHiddenFields ? configuredFields.filter(f => f.hidden) : []));
    setCanBeSaved(true);
    setSaveSuccessMessage(null);
  };

  const updateLabel = useCallback(({identifier, value}) => {
    // Set/unset label error message for field currently editing
    if (value.length < 1) {
      setLabelErrors(prev => ({...prev, [identifier]: 'Please add a label.'}));
    } else if (value.length > 255) {
      value = value.slice(0, 255);
      setLabelErrors(prev => ({...prev, [identifier]: 'A label can only be 255 characters long.'}));
    } else if (labelErrors[identifier] && value.length >= 1) {
      const newErrors =  {...labelErrors};
      delete newErrors[identifier];
      setLabelErrors(newErrors);
    }

    const newFields = configuredFields.map(f => f.identifier === identifier ? {...f, label: value} : f );
    setConfiguredFields(newFields);
    setCanBeSaved(true);
    setSaveSuccessMessage(null);
  }, [configuredFields, labelErrors]);

  const enableMergedFieldToggle = useCallback(({identifier, enabled}) => {
    // Only update the disabled state if the original state was enabled i.e. don't allow a rule to be re-enabled.
    if (originalConfiguredFields.find(f => f.identifier === identifier)?.enabled) {
      const newFields = configuredFields.map(f => f.identifier === identifier ? {...f, enabled} : f);
      setConfiguredFields(newFields);
      setCanBeSaved(true);
      setSaveSuccessMessage(null);
    }
  }, [configuredFields, originalConfiguredFields]);

  const addOnLastTracked = ({fields, fieldsToSave}) => {
    const fieldsWithLastTracked = fieldsToSave.reduce((acc, field) => {
      acc[field.identifier] = field.lastTracked;
      return acc;
    }, {});
    return fields.map(f => ({...f, lastTracked: fieldsWithLastTracked[f.identifier]}));
  };

  const handleSaveFields = async () => {
    const fieldsToSave = configuredFields.map((field, i) => {
      const {unconfigured, unconfiguredFieldsIndex, ...remainingField} = field;
      return {
        ...remainingField,
        order: i + 1,
      };
    });

    try {
      setSavingFields(true);
      setSavingFieldsErrors(null);
      setShowUnsavedFieldsMsg(false);
      let { data: { fields } } = await api.put('/fields', {
        fields: fieldsToSave,
        formUuid
      });
      fields = addOnLastTracked({fields, fieldsToSave});
      setSaveSuccessMessage('Fields saved');
      window.scrollTo({ behavior: 'smooth', top: configuredFieldsRef.current.offsetTop + 170 });
      setCanBeSaved(false);
      if (fields && fields.length > 0) setConfiguredFields(fields);
      setOriginalConfiguredFields(fields);
      mixpanel.track('Saved Fields', {
        'Form Uuid': formUuid,
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      });
      if (fieldMergingRuleError) setFieldMergingRuleError(null);
    } catch (e) {
      let errors;
      if (e.response && e.response.data && e.response.data.errors) {
        errors = e.response.data.errors.map((e) => e.message);
      } else {
        errors = ['Oops, something went wrong saving the fields. Please try again.'];
      }
      setSavingFieldsErrors(errors);
    } finally {
      setSavingFields(false);
    }
  };

  const handleWarnUnsavedFields = () => {
    setShowUnsavedFieldsMsg(true);
    window.scrollTo({ behavior: 'smooth', top: configuredFieldsRef.current.offsetTop + 170 });
  };

  const handleCancelChanges = () => {
    setCanBeSaved(false);
    setShowUnsavedFieldsMsg(false);
    setConfiguredFields(originalConfiguredFields);

    const unconfiguredFieldsToReturn = configuredFields.filter(f => f.unconfigured && !f.merged);
    if (unconfiguredFieldsToReturn.length) setUnconfiguredFields(unconfiguredFields.concat(unconfiguredFieldsToReturn));

    const mergingRulesToReturn = configuredFields.filter(f => f.unconfigured && f.merged);
    if (mergingRulesToReturn) setFieldMergingRules([]);

    setLabelErrors({});
  };

  const handleBlockIfUnsaved = (e) => {
    if (canBeSaved) {
      e.preventDefault();
      e.returnValue = true;
      handleWarnUnsavedFields();
    }
  };

  const hasUnconfiguredFields = configuredFields && (configuredFields.filter((f) => f.unconfigured).length > 0);
  const hasMergedFields = configuredFields && (configuredFields.filter((f) => f.merged).length > 0);

  const ConfiguredFieldsButtonRow = () => ( <>
    <Row className="g-0 justify-content-end button-row">
      <Col className="px-0 pb-0 pt-2 col-auto">
        {showUnsavedFieldsMsg && <Button variant="outline-primary" className="ms-0 me-1" type="reset" onClick={handleCancelChanges}>Cancel Changes</Button>}
        <Button variant="primary" className="save me-0 ms-1" onClick={(e) => {e.preventDefault(); handleSaveFields();}} type="submit"
          disabled={savingFields || !canBeSaved || Object.keys(labelErrors).length > 0 || configuredFields.filter((f) => f.unconfigured && f.label.length === 0).length > 0}
          data-testid="save-fields">{savingFields ? <FaSpinner size="12px" className="spinning-icon me-1 mb-1" title="Saving..."/> : 'Save'}</Button>
      </Col>
    </Row>
    {savingFieldsErrors &&
      <Row className="alert-row g-0 mt-2 saved-msg">
        <Alert dismissible variant="danger" closeVariant="white">
          <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
          <div>
            {savingFieldsErrors
              .map((e, i) => (
                <p key={`save-errors-${i}`} className="alert-text m-0 py-1">{e}</p>))}
          </div>
        </Alert>
      </Row>}
  </>
  );

  const handleCloseWizard = useCallback(() => {
    setShowWizard(false);
    setFieldSavedForWizard(null);
    setErrorSavingForWizard(null);
    setSavingFieldsForWizard(null);
  }, []);

  const handleSaveFieldFromWizard = useCallback(async (newField) => {
    const fieldsToSave = configuredFields.concat(newField).map((field, i) => {
      return {
        ...field,
        order: i + 1,
      };
    });

    try {
      setFieldSavedForWizard(null);
      setErrorSavingForWizard(null);
      setSavingFieldsForWizard(true);
      let { data: { fields } } = await api.put('/fields', {
        fields: fieldsToSave,
        formUuid
      });
      fields = addOnLastTracked({fields, fieldsToSave});

      setFieldSavedForWizard(newField.identifier);
      setConfiguredFields(fields);
      setUnconfiguredFields(unconfiguredFields.filter(f => f.identifier !== newField.identifier));
      mixpanel.track('Saved Fields From Wizard', {
        action: 'label',
        'Form Uuid': formUuid,
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      });
    } catch (e) {
      setErrorSavingForWizard('Oops, something went wrong saving the field. Please try again.');
    } finally {
      setSavingFieldsForWizard(false);
    }
  }, [configuredFields, unconfiguredFields, formUuid, form, mixpanel]);

  const handleSaveReorderedFieldsFromWizard = useCallback(async ({configuredFieldsExclHidden}) => {
    // The Wizard orders visible fields only, so hidden fields are positioned at the bottom
    const fieldsToSave = configuredFieldsExclHidden.concat(configuredFields.filter(f => f.hidden)).map((field, i) => {
      return {
        ...field,
        order: i + 1,
      };
    });

    try {
      setErrorSavingForWizard(null);
      setSavingFieldsForWizard(true);
      let { data: { fields } } = await api.put('/fields', {
        fields: fieldsToSave,
        formUuid
      });
      fields = addOnLastTracked({fields, fieldsToSave});
      setConfiguredFields(fields);
      setOriginalConfiguredFields(fields);
      mixpanel.track('Saved Fields From Wizard', {
        action: 'reorder',
        'Form Uuid': formUuid,
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      });
    } catch (e) {
      setErrorSavingForWizard('Oops, something went wrong saving the reordered fields. Please try again.');
    } finally {
      setSavingFieldsForWizard(false);
    }

  }, [configuredFields,  formUuid, form, mixpanel]);

  const handleSaveHiddenFieldsFromWizard = useCallback(async ({hiddenFields}) => {
    const unconfiguredFieldsToSave = unconfiguredFields?.filter(f => hiddenFields.includes(f.identifier)).map(f => ({...f, label: labelForField(f)}));

    const fieldsToSave = configuredFields.concat(unconfiguredFieldsToSave || []).map((field, i) => {
      return {
        ...field,
        hidden: hiddenFields.includes(field.identifier),
        order: i + 1,
      };
    });

    try {
      setErrorSavingForWizard(null);
      setSavingFieldsForWizard(true);

      let { data: { fields } } = await api.put('/fields', {
        fields: fieldsToSave,
        formUuid
      });
      fields = addOnLastTracked({fields, fieldsToSave});
      setConfiguredFields(fields);
      setOriginalConfiguredFields(fields);
      setUnconfiguredFields(unconfiguredFields.filter(f => !hiddenFields.includes(f.identifier)));
      mixpanel.track('Saved Fields From Wizard', {
        action: 'hidden',
        'Form Uuid': formUuid,
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      });
    } catch (e) {
      setErrorSavingForWizard('Oops, something went wrong saving the hidden fields. Please try again.');
    } finally {
      setSavingFieldsForWizard(false);
    }
  }, [configuredFields, unconfiguredFields, formUuid, form, mixpanel]);

  const handleFetchConfiguredFields = useCallback(() => {
    setReFetchConfiguredFields(true);
  }, []);

  const handleDeleteField = useCallback(async ({identifier}) => {
    if (canBeSaved) {
      handleWarnUnsavedFields();
    } else {
      try {
        await api.delete(`/fields`, {params: {formUuid, identifier}});

        const newFields = configuredFields.filter((f) => f.identifier !== identifier);
        setConfiguredFields(newFields);

        setSaveSuccessMessage('Field deleted. Refresh the page to re-fetch Fields to Configure.');
      } catch (e) {
        setSavingFieldsErrors(['Something went wrong deleting a field.']);
      }
    }
  }, [formUuid, configuredFields, canBeSaved]);

  return (
    <Container fluid className="field-config page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Edit Form Fields</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel} handleClick={handleBlockIfUnsaved}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Field Config'}
            org={form?.organisation}
            messageContent={'labelling and ordering fields'} />
          <AppAlerts showOrgAlerts={true} />
          <Row className="title-row g-0">
            <Col className="p-0">
              <h1 id="form-title">
                {form && form.label && form.url && form.organisation.name && (<>
                  {`${form.organisation.name} | ${form.label} | `} <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
                </>)}
              </h1>
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <TabContainer defaultActiveKey="fieldConfig">
                <Row className="g-0 card-title-row justify-content-between">
                  <Col className="py-0 ps-0 pe-2">
                    <Card.Title as="h3">Label & Order Form Fields</Card.Title>
                  </Col>
                  <Col className="p-0 col-auto">
                    <Nav variant="tabs" className="justify-content-end" id="card-header-nav-tabs">
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname: `/forms/${formUuid}/edit`, state: {form}}} onClick={handleBlockIfUnsaved}>Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fieldConfig">Label Fields</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname:`/forms/${formUuid}/integrations`, state: {form}}}>Integrations</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname: `/forms/${formUuid}/tracking_code`, state: {form}}} onClick={handleBlockIfUnsaved}>Tracking Code</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row className="g-0 card-content">
                  <TabContent>
                    <TabPane eventKey="fieldConfig">
                      {errorLoadingForm ? <div id="form-load-error"><h3>{errorLoadingForm}</h3></div> : <>
                        <Accordion className="field-config-accordion" activeKey={showFieldsConfigInfo ? '0' : null}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={() => setShowFieldsConfigInfo(prev => !prev)} className={`d-flex align-items-center ${showFieldsConfigInfo ? 'accordion-open' : ''}`}>
                            How to configure your fields
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>In the Zuko app, fields are auto-labelled with one of their HTML attributes, such as the <code>name</code> or <code>id</code>. Here, you can configure your fields to use your own labels, and even order
                                the fields to reflect the order that they appear in the form. There are two ways to do this:</p>

                              <h4>Manual Configuration</h4>
                              <ul>
                                <li>You first add fields <i className="fa fa-plus-square"></i> to be configured from the <strong>Fields to Configure</strong> table.</li>
                                <li>Once in the <strong>Configured Fields</strong> table, the label is auto-populated. You can edit the label to something you'd prefer.</li>
                                <li>Then drag and drop <i className="fa fa-bars"></i> to re-order the fields so they reflect the order they appear in the form on your site.</li>
                                <li>Click to <strong>Save</strong> your changes.</li>
                                <li>Consider if you need to create a  <strong>Merging Rule</strong>:</li>
                                <p>If you see multiple fields that look very similar but have one changeable attribute (for example, the <code>id</code> is different for each similar field),
                                you may have a <i>dynamic</i> field that changes for each visitor which Zuko tracks separately. You can add a <strong>Merging Rule</strong> so that they show as a
                                  single field in your reporting.</p>
                              </ul>

                              <h4>Step-by-Step Wizard</h4>
                              <p className="mb-0">The wizard guides you through each step to configure field labels, set the order and hide any fields if needed.</p>

                              <ul>
                                <li>You can choose to open the <strong>Labelling Tool</strong> to label your fields whilst interacting with your live form.</li>
                                <li>Or, select a field to label from the list of <strong>Unconfigured Fields</strong>.</li>
                              </ul>

                              <Row className="g-0">
                                <Col className="px-0 pt-0 pb-4 justify-content-end">
                                  <Button className="ms-0" onClick={() => {
                                    if (canBeSaved) handleWarnUnsavedFields();
                                    if (!canBeSaved) setShowWizard(true); mixpanel.track('Launched Wizard');
                                  }}>Launch Wizard</Button>
                                </Col>
                              </Row>
                              <p className="mb-0">Feel free to <a href="mailto: support@zuko.io">get in touch</a> with any questions.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <FormFieldsWizard
                          showWizard={showWizard}
                          handleCloseWizard={handleCloseWizard}
                          unconfiguredFieldsLoading={unconfiguredFieldsLoading}
                          unconfiguredFieldsError={unconfiguredFieldsError}
                          unconfiguredFields={unconfiguredFields}
                          fieldMergingRules={fieldMergingRules}
                          handleSaveField={handleSaveFieldFromWizard}
                          fieldSaved={fieldSavedForWizard}
                          configuredFields={configuredFields}
                          handleSaveReorderedFields={handleSaveReorderedFieldsFromWizard}
                          handleSaveHiddenFields={handleSaveHiddenFieldsFromWizard}
                          errorSaving={errorSavingForWizard}
                          savingFields={savingFieldsForWizard}
                          configuredFieldsLoading={configuredFieldsLoading}
                          configuredFieldsError={configuredFieldsError}
                          form={form}
                          handleFetchConfiguredFields={handleFetchConfiguredFields}
                          mixpanel={mixpanel}
                        />

                        <div id="unconfiguredFieldsArea">
                          {(unconfiguredFieldsLoading || unconfiguredFieldsError || (unconfiguredFieldsToDisplay && unconfiguredFieldsToDisplay.length > 0) || unconfiguredFieldsFilterInput ||
                            (!unconfiguredFieldsLoading && !unconfiguredFieldsError && unconfiguredFieldsToDisplay && !unconfiguredFieldsToDisplay.length > 0 &&
                              !configuredFieldsLoading && !configuredFieldsError && configuredFields && !configuredFields.length > 0)) &&
                            <div className="d-flex">
                              <div className="me-auto">
                                <h3 className="card-title" data-testid="unconfigured-title">Fields to Configure</h3>
                              </div>
                              <OverlayTrigger placement="left"
                                overlay={
                                  <Popover>
                                    <Popover.Body>
                                    The <strong>Fields to Configure</strong> table shows fields that Zuko has tracked that haven't yet been labelled.
                                      <br></br><br></br>
                                    To get the best from your field data throughout the Zuko application, you first add fields to the <strong>Configured Fields</strong> table by clicking the <i class="fa fa-plus-square" /> icon, then label and order them.
                                    </Popover.Body>
                                  </Popover>}>
                                <span><FaInfoCircle size="18px" className="info"/></span>
                              </OverlayTrigger>
                            </div>}
                          {unconfiguredFieldsLoading && <div className="text-center"><p><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading...</p></div>}
                          {unconfiguredFieldsError && <p className="error-text">{unconfiguredFieldsError}</p>}
                          {!unconfiguredFieldsLoading && !unconfiguredFieldsError && unconfiguredFieldsToDisplay && !unconfiguredFieldsToDisplay.length > 0 &&
                            !configuredFieldsLoading && !configuredFieldsError && configuredFields && !configuredFields.length > 0 &&
                              <p className="mt-1 no-fields-msg">No fields have been tracked yet. Please check the <Link to={`/forms/${formUuid}/tracking_code`}>Tracking Code</Link>.</p>}
                          {!unconfiguredFieldsLoading && !unconfiguredFieldsError && ((unconfiguredFieldsToDisplay && unconfiguredFieldsToDisplay.length > 0) || unconfiguredFieldsFilterInput) && <>
                            <Row className="g-0 table-search-row">
                              <Col className="p-0 d-flex justify-content-end">
                                <Form className="d-inline-flex">
                                  <input
                                    value={unconfiguredFieldsFilterInput || ''}
                                    onChange={(({target: {value}}) => {
                                      if (value) {
                                        setUnconfiguredFieldsFilterInput(value);
                                      } else {
                                        setUnconfiguredFieldsFilterInput('');
                                        setUnconfiguredFieldsFilter('');
                                      }
                                    })}
                                  />
                                  <Button type="submit" onClick={(e) => {e.preventDefault(); setUnconfiguredFieldsFilter(unconfiguredFieldsFilterInput);}}>Search</Button>
                                </Form>
                              </Col>
                            </Row>
                            <Table striped hover borderless data-testid="unconfigured-table">
                              <thead>
                                <tr>
                                  <th className="tagname-column">Tag Name
                                    <span onClick={() => setUnconfiguredFieldsSortedOn('htmlTagName')} className="table-sort-icons">
                                      <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlTagName' ? 'sorted' : ''}`}></i></span>
                                  </th>
                                  <th className="type-column">Type
                                    <span onClick={() => setUnconfiguredFieldsSortedOn('htmlType')} className="table-sort-icons">
                                      <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlType' ? 'sorted' : ''}`}></i></span>
                                  </th>
                                  <th className={`name-column ${expandNameRow ? 'expand' : ''}`}>Name
                                    <span onClick={() => setExpandNameRow(!expandNameRow)} className="ps-2 expand-column-icon">
                                      {expandNameRow ? <FiMinimize2 size="14px" title="Minimise Name column"/> : <FiMaximize2 size="14px" title="Expand Name column"/>}</span>
                                    <span onClick={() => setUnconfiguredFieldsSortedOn('htmlName')} className="ps-2 table-sort-icons">
                                      <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlName' ? 'sorted' : ''}`}></i></span>
                                  </th>
                                  <th className={`id-column ${expandIDRow ? 'expand' : ''}`}>ID
                                    <span onClick={() => setExpandIDRow(!expandIDRow)} className="ps-2 expand-column-icon">
                                      {expandIDRow ?  <FiMinimize2 size="14px" title="Minimise ID column"/>: <FiMaximize2 size="14px" title="Expand ID column"/>}</span>
                                    <span onClick={() => setUnconfiguredFieldsSortedOn('htmlId')} className="ps-2 table-sort-icons">
                                      <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'htmlId' ? 'sorted' : ''}`}></i></span>
                                  </th>
                                  <th className="text-center last-tracked-column">Last Tracked
                                    <span onClick={() => setUnconfiguredFieldsSortedOn('lastTracked')} className="table-sort-icons">
                                      <i className={`fa fa-sort-down ${unconfiguredFieldsSortedOn === 'lastTracked' ? 'sorted' : ''}`}></i></span></th>
                                  <th className="add-column">Add field</th>
                                </tr>
                              </thead>
                              <tbody>
                                {pageFields && pageFields.map((field, i) => {
                                  return (
                                    <tr key={`unlabelled-field-${i}`}>
                                      <td className="tagname-column">{field.htmlTagName}</td>
                                      <td className="type-column">{field.htmlType}</td>
                                      <td className={`name-column ${expandNameRow ? 'expand' : ''}`}>{field.htmlName}</td>
                                      <td className={`id-column ${expandIDRow ? 'expand' : ''}`}>{field.htmlId}</td>
                                      <td className="text-center">{msTimestampToDate({msTimestamp: field.lastTracked, timeZone: form?.organisation?.timeZone})}</td>
                                      <td className="text-center add-column">
                                        <i className="fa fa-plus-square fa-lg action" title="Configure this field" onClick={() => handleMoveToConfigured(i)} data-testid={`add-field-${i}`}></i>
                                      </td>
                                    </tr>);
                                })}
                              </tbody>
                            </Table>
                            {(currentPage === (maxFieldsResultsSize/maxFieldsPerPage) - 1 && unconfiguredFieldsToDisplay.length === maxFieldsResultsSize) &&
                            <div className="text-center">
                              <p>You have reached the maximum number of unconfigured fields that can be provided.</p>
                            </div>}
                            <ReactPaginate
                              pageCount={Math.ceil(unconfiguredFieldsToDisplay.length / maxFieldsPerPage)}
                              pageRangeDisplayed={10}
                              marginPagesDisplayed={0}
                              onPageChange={handlePageChange}
                              containerClassName={'pagination justify-content-center'}
                              pageClassName={'page-item'}
                              previousLinkClassName={'page-link'}
                              previousClassName={'page-item'}
                              nextLinkClassName={'page-link'}
                              nextClassName={'page-item'}
                              pageLinkClassName={'page-link'}
                              breakClassName={'page-item'}
                              breakLinkClassName={'page-link'}
                              disabledClassName={'disabled'}
                              activeClassName={'active'}
                              forcePage={forcePage ?? -1}
                            />
                          </>}
                        </div>

                        {((unconfiguredFieldsToDisplay && unconfiguredFieldsToDisplay.length > 0) || (configuredFields && configuredFields.length > 0)) &&
                            <div id="newFieldMergingRuleArea">
                              <div className="d-flex">
                                <div className="me-auto">
                                  <h3 className="card-title" data-testid="merging-title">Merging Rules (Advanced)</h3>
                                </div>
                              </div>
                              <div id="merging-rule-info">
                                <p>Create a merging rule when a single field is appearing as multiple rows in the table above. A merging rule works by matching against a single attribute and ignoring the rest.</p>
                                <p>This is an advanced feature as it's easy to match more fields than you need to. If you've not merged fields before and believe you need to, feel free to reach out for help from your account manager.</p>
                                <p><em>Note: Some backslashes (<code>\</code>) and periods (<code>.</code>) may be added to your pattern when you add a merging rule.</em></p>
                                <p> Where the <Form.Select className="merging-rule-control" aria-label="Attribute" onChange={({target: {value}}) => setFieldMergingRuleTargetAttribute(value)} value={fieldMergingRuleTargetAttribute}>
                                  <option value="name">name</option>
                                  <option value="id">id</option>
                                </Form.Select> attribute matches <Form.Control className="merging-rule-control" type="text" placeholder="email" aria-label="Pattern" onChange={handleFieldMergingRulePatternChange} value={fieldMergingRulePattern}/>
                                {fieldMergingRuleValid && <i className="fa fa-plus-square fa-lg action" id="stageFieldMergingRule" title="Create this field merging rule" onClick={handleStageFieldMergingRule} data-testid={`add-merging-rule`}/>}
                                {fieldMergingRuleError && <span className="ms-2 error-text">{fieldMergingRuleError}</span>}
                                </p>
                              </div>
                            </div>}

                        <div id="configuredFieldsArea" ref={configuredFieldsRef}>
                          <div className="d-flex">
                            <div className="me-auto">
                              <h3 className="card-title" data-testid="configured-title">Configured Fields</h3>
                            </div>
                            <OverlayTrigger placement="left"
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    The <strong>Configured Fields</strong> table shows fields that Zuko has tracked that have been labelled.
                                    <br></br><br></br>
                                    To get the best from your field data throughout the Zuko application, you first add fields from the <strong>Fields to Configure</strong> table (if any), label them and order using the <i class="fa fa-bars"/> icon.
                                  </Popover.Body>
                                </Popover>}>
                              <span><FaInfoCircle size="18px" className="info"/></span>
                            </OverlayTrigger>
                          </div>
                          {!configuredFieldsLoading && !configuredFieldsError && configuredFields.length > 0 &&
                          <Row className="alert-row g-0" id="session-info-alert">
                            <Alert dismissible={false} variant={'info'}>
                              <div className="page-alert-svg-icon"><VscInfo size="100%"/></div>
                              <p className="alert-text m-0">Your session will expire after 2 hours. Please make sure you regularly save any changes to Configured Fields.</p>
                            </Alert>
                          </Row>}
                          {saveSuccessMessage &&
                        <Row className="alert-row g-0 mt-2 saved-msg">
                          <Alert dismissible variant="success" closeVariant="white"
                            onClose={() => setSaveSuccessMessage(null)}>
                            <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                            <p className="alert-text m-0">{saveSuccessMessage}</p>
                          </Alert>
                        </Row>}
                          {showUnsavedFieldsMsg &&
                            <Row className="alert-row g-0 mt-2 unsaved-msg">
                              <Alert variant="danger">
                                <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                                <p className="alert-text m-0">You have unsaved fields - please Save them or Cancel Changes.</p>
                              </Alert>
                            </Row>}
                          {configuredFieldsLoading && <div className="text-center"><p><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading...</p></div>}
                          {configuredFieldsError && <p className="error-text">{configuredFieldsError}</p>}
                          {!configuredFieldsLoading && !configuredFieldsError && configuredFields && !configuredFields.length > 0 && <p className="mt-1 no-fields-msg">No fields have been configured yet.</p>}
                          {!configuredFieldsLoading && !configuredFieldsError && configuredFields.length > 0 && <>
                            <Row className="g-0 justify-content-end mt-2">
                              <Col className="p-0 d-flex justify-content-end">
                                <FormGroup controlId="hidden-fields-checkbox" className={`form-group d-inline-flex align-items-center mb-0`}>
                                  <Form.Check type="checkbox" label="Manage hidden fields" className="manage-hidden-checkbox pe-1 d-flex align-items-center" checked={showHiddenFields}
                                    onChange={() => setShowHiddenFields(!showHiddenFields)}/>
                                </FormGroup>
                                <OverlayTrigger placement="left"
                                  overlay={
                                    <Popover id="hide-field-popover">
                                      <Popover.Body>
                                      You can hide a field so that it doesn't show in field level metrics in reports.
                                        <br></br><br></br>
                                      You might do this to focus on a smaller number of fields in the reports, or there may be fields that you simply aren't interested in.
                                      </Popover.Body>
                                    </Popover>}>
                                  <div className="d-flex align-content-top"><FaInfoCircle size="12px" className="column-info-icon info"/></div>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                            <DragDropContext onDragEnd={onDragEnd}>
                              <div className="table-responsive mt-2" ref={scrollableConfiguredTable}>
                                <Table hover borderless data-testid="configured-table">
                                  <thead>
                                    <tr>
                                      <th className="grip-column"></th>
                                      <th className="field-order text-center">Order</th>
                                      <th className="label-column">Label</th>
                                      <th className="tagname-column">Tag Name</th>
                                      <th className="type-column">Type</th>
                                      <th className={`name-column ${expandNameRow ? 'expand' : ''}`}>Name<span onClick={() => setExpandNameRow(!expandNameRow)} className="ps-2 expand-column-icon">
                                        {expandNameRow ? <FiMinimize2 size="14px" title="Minimise Name column"/> : <FiMaximize2 size="14px" title="Expand Name column"/>}</span>
                                      </th>
                                      <th className={`id-column ${expandIDRow ? 'expand' : ''}`}>ID<span onClick={() => setExpandIDRow(!expandIDRow)} className="ps-2 expand-column-icon">
                                        {expandIDRow ?  <FiMinimize2 size="14px" title="Minimise ID column"/>: <FiMaximize2 size="14px" title="Expand ID column"/>}</span>
                                      </th>
                                      <th className="text-center">Last Tracked</th>
                                      {hasMergedFields && <>
                                        <th>Merging Rule</th>
                                        <th>Matched Fields
                                          <OverlayTrigger placement="left"
                                            overlay={
                                              <Popover id="matched-fields-popover">
                                                <Popover.Body>
                                    You can check which fields match the merging rule.
                                                  <br></br><br></br>
                                    The <i>Matched Fields</i> can either be the:
                                                  <ul>
                                                    <li>unmerged fields (saved into Sessions <strong>before</strong> the merging rule was in place)</li>
                                                    <li>merged field (saved into Sessions <strong>after</strong> the merging rule was in place)</li>
                                                  </ul>
                                    This is useful to check that a merging rule successfully applies to the unmerged fields you want to group together.
                                                  <br></br><br></br>
                                    Or, to know that the merging rule has been applied to tracked fields.
                                                </Popover.Body>
                                              </Popover>}>
                                            <span><FaInfoCircle size="12px" className="column-info-icon info"/></span>
                                          </OverlayTrigger>
                                        </th>
                                      </>}
                                      {showHiddenFields &&
                                        <th className="hide-column">Hide
                                        </th>}
                                      {hasUnconfiguredFields && <th className="undo-column"></th>}
                                      {currentUser?.accountManager && <th><span className="am-only-input-tag ms-2">AM only</span></th>}
                                    </tr>
                                  </thead>
                                  <Droppable droppableId="droppable" direction="vertical">
                                    {(provided, snapshot) => (
                                      <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                        {configuredFields.length > 0 && (!showHiddenFields ? configuredFields.filter(f => !f.hidden) : configuredFields).map((field, rowIndex) => (
                                          <Draggable key={`field-${field.identifier}`} draggableId={(field.unconfigured && field.merged) ? field.regexp : field.identifier} index={rowIndex} isDragDisabled={field.label === null || field.label === undefined || field.label.length < 1}>
                                            {(provided, snapshot) => (
                                              <ConfiguredFieldRow
                                                key={`configured-field-${field.identifier}`}
                                                field={field}
                                                formUuid={formUuid}
                                                formOrgTimeZone={form?.organisation?.timeZone}
                                                mixpanel={mixpanel}
                                                rowIndex={rowIndex}
                                                expandIDRow={expandIDRow}
                                                expandNameRow={expandNameRow}
                                                handleRemoveFromConfigured={handleRemoveFromConfigured}
                                                updateLabel={updateLabel}
                                                labelErrors={labelErrors}
                                                hasUnconfiguredFields={hasUnconfiguredFields}
                                                hasMergedFields={hasMergedFields}
                                                handleHideField={handleHideField}
                                                provided={provided}
                                                isDragging={snapshot.isDragging}
                                                showHiddenFields={showHiddenFields}
                                                enableMergedFieldToggle={enableMergedFieldToggle}
                                                handleDeleteField={handleDeleteField}
                                              />
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </tbody>
                                    )}
                                  </Droppable>
                                </Table>
                              </div>
                            </DragDropContext>
                            <ConfiguredFieldsButtonRow />
                          </>}
                        </div>
                      </>}
                    </TabPane>
                  </TabContent>
                </Row>
              </TabContainer>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Container>
  );
};

export default FormFieldsConfig;
