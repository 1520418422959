import { useState, useEffect, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { VscTrash } from 'react-icons/vsc';
import { FaPlus, FaList } from 'react-icons/fa';
import ContentEditableInput from './ContentEditableInput';
import { keyToUse } from '../form-components/utils';
import { decodeAndSantize } from '../utils';
import CountrySelectTopOptions from './CountrySelectTopOptions';
import { slugify } from '../form-components/utils';

const ConfigureQuestion = ({
  item,
  questionIndex,
  stepIndex,
  handleSaveInlineEditItem,
  handleSaveConfigureItem,
  handleRemoveMessages,
  currentKeys,
  questionKeysToUse,
}) => {
  const [question, setQuestion] = useState();
  const [questionKey, setQuestionKey] = useState();
  const [saveAttempted, setSaveAttempted] = useState();
  const [itemKeyAlreadyExists, setItemKeyAlreadyExists] = useState();
  const [showBulkAddOptions, setShowBulkAddOptions] = useState();

  const itemIncludesOptions = ['select', 'radioButtonGroup', 'checkboxGroup'].includes(item?.componentType);
  const itemIncludesOtherOption = ['radioButtonGroup', 'checkboxGroup'].includes(item?.componentType);
  const maxChars = 255;
  const itemKey = slugify(keyToUse({key: item?.key, title: item?.title, questionKeys: questionKeysToUse, questionIndex, stepIndex}));
  const itemCanEditSize = item?.componentType && ['select', 'number', 'telephoneNumber', 'countrySelector'].includes(item.componentType);

  const updateHint = useCallback(({text}) => {
    setQuestion(prev => ({...prev, hint: decodeAndSantize(text)}));
  }, []);

  const updateLabel = useCallback(({text}) => {
    setQuestion(prev => ({...prev, title: decodeAndSantize(text)}));
  }, []);

  const saveHint = useCallback(({text}) => {
    handleSaveInlineEditItem({type: 'question-hint', item: text || null, stepIndex, questionIndex});
  }, [handleSaveInlineEditItem, stepIndex, questionIndex]);

  const saveLabel = useCallback(({text}) => {
    if (text) handleSaveInlineEditItem({type: 'question-title', item: text, stepIndex, questionIndex});
  }, [handleSaveInlineEditItem, stepIndex, questionIndex]);

  const handleSelectTopOptions = useCallback((newOptions) => {
    setQuestion(prev => ({...prev, topOptions: newOptions || []}));
    handleRemoveMessages();
  }, [handleRemoveMessages]);

  const handleSaveTopOptions = useCallback(() => {
    setSaveAttempted(true);
    if (item && question && (item.topOptions !== question.topOptions)) {
      handleSaveInlineEditItem({type: 'question-top-options', item: question.topOptions, stepIndex, questionIndex});
    }
  }, [handleSaveInlineEditItem, item, question, questionIndex, stepIndex]);

  useEffect(() => {
    setQuestion({
      ...item,
      ...item.title && { title: decodeAndSantize(item.title) },
      ...item.hint && { hint: decodeAndSantize(item.hint) },
    });

    // As the checkbox key is hardcoded we should represent its dynamic value here as ID should never be duplicated
    if (item?.componentType === 'checkbox') {
      setQuestionKey(itemKey);
    } else {
      setQuestionKey(item.key && slugify(item.key));
    }
  }, [item, itemKey]);

  return (<div className="configure-form">
    <Form noValidate onSubmit={(e) => {
      e.preventDefault();
      if (!saveAttempted) setSaveAttempted(true);
      if (!question.title || itemKeyAlreadyExists ||
        (question.hint && question.hint?.length > maxChars) ||
        (question.title && question.title?.length > maxChars)
      ) return;
      if ((itemIncludesOptions) && !question.options.every(Boolean)) return;
      handleSaveConfigureItem({ item: {
        ...question,
        key: questionKey || null,
        ...question.subLabels && {
          subLabels: {
            addressLine1: question.subLabels.addressLine1 || null,
            addressLine2: question.subLabels.addressLine2 || null,
            addressLevel1: question.subLabels.addressLevel1 || null,
            postalCode: question.subLabels.postalCode || null,
          }
        }
      }});
      setSaveAttempted(false);
    }} className="w-100" id="builder-field-config-form">
      {item?.componentType === 'checkbox' &&
        <FormGroup className="form-group" controlId="label">
          <ContentEditableInput
            label={'Label'}
            maxChars={maxChars}
            minChars={1}
            innerHtml={question?.title}
            handleRemoveMessages={handleRemoveMessages}
            updateText={updateLabel}
            saveText={saveLabel} />
          <div className="input-feedback">Always include a label on your questions. You can add a link to refer to your T's & C's.</div>
        </FormGroup>}
      {item?.componentType !== 'checkbox' &&
      <FormGroup className="form-group" controlId="label">
        <Form.Label>Label</Form.Label>
        <Form.Control className={((saveAttempted && !question?.title)) && "invalid-input"}
          type="text" value={question?.title || ''} required maxLength={255} autoFocus={(item && !item.title) ? true : false}
          onChange={({target: {value}}) => {
            setQuestion(prev => ({...prev, title: value}));
            handleRemoveMessages();
          }}
          onBlur={() => {
            setSaveAttempted(true);
            if (question.title && item.title !== question.title) {
              handleSaveInlineEditItem({type: 'question-title', item: question.title, stepIndex, questionIndex});
            }
          }} />
        {(saveAttempted && !question?.title) && <div className="invalid-input-feedback">Please add a label for your question</div>}
        <div className="input-feedback">Always include a label on your questions</div>
      </FormGroup>}
      <FormGroup className="form-group" controlId="hint">
        <ContentEditableInput
          label={'Add Help Text'}
          maxChars={maxChars}
          innerHtml={question?.hint}
          handleRemoveMessages={handleRemoveMessages}
          updateText={updateHint}
          saveText={saveHint} />
        <div className="input-feedback">Help text can clarify what you are asking for</div>
      </FormGroup>
      {itemIncludesOptions &&
        <FormGroup className="form-group options" controlId="options">
          <Form.Label>Options</Form.Label>
          {showBulkAddOptions && <>
            <FormGroup controlId="bulk-options" className={`form-group mb-0 bulk-options-group`}>
              <Form.Label className="mb-0">Enter each option on a new line:</Form.Label>
              <Form.Control
                as="textarea"
                className={`${(saveAttempted && !question?.options?.filter(o => o).length) ? 'invalid-input' : 'mb-2'}`}
                value={question?.options?.join('\n') || ''}
                onChange={({target: {value}}) => {
                  setQuestion(prev => {
                    return {...prev, options: value.split('\n')};
                  });
                  handleRemoveMessages();
                }}
                rows={question?.options?.length}
                onBlur={() => {
                  setSaveAttempted(true);
                  const emptyOptionsRemoved = question.options?.filter(o => o);
                  if (emptyOptionsRemoved?.length && item.options !== question.options) {
                    handleSaveInlineEditItem({type: 'question-options', item: emptyOptionsRemoved, stepIndex, questionIndex});
                  }
                }}
              />
              {(saveAttempted && !question?.options?.filter(o => o).length) && <div className="invalid-input-feedback">Please enter at least one option.</div>}
            </FormGroup>
            <div className="d-flex justify-content-end">
              <Button variant="primary" className="mx-0 px-3 py-2" onClick={() => setShowBulkAddOptions(false)} disabled={!question?.options?.filter(o => o).length}>Done</Button>
            </div>
          </>}
          {!showBulkAddOptions && question?.options?.map((o, optionIndex) => <div key={`${question.title}-option-${optionIndex}`} className="single-entry-options">
            <div className="option-item d-flex align-items-center mb-2">
              <Form.Control
                className={`${((saveAttempted && !o)) && "invalid-input"}`}
                type="text" value={o || ''} maxLength={255}
                autoFocus={!o}
                onChange={({target: {value}}) => {
                  setQuestion(prev => {
                    const newOptions = [...prev.options];
                    newOptions[optionIndex] = value;
                    return {...prev, options: newOptions};
                  });
                  handleRemoveMessages();
                }}
                onBlur={() => {
                  setSaveAttempted(true);
                  if (o && item.options !== question.options) {
                    handleSaveInlineEditItem({type: 'question-options', item: question.options, stepIndex, questionIndex});
                  }
                }} />
              {question?.options?.length > 1 && <VscTrash size="18px" className="delete-option"
                onClick={() => {
                  handleRemoveMessages();
                  const newOptions = [...question.options];
                  newOptions.splice(optionIndex, 1);
                  setQuestion(prev => {
                    return {...prev, options: newOptions};
                  });
                  handleSaveInlineEditItem({type: 'question-options', item: newOptions, stepIndex, questionIndex});
                }} title="Delete option"/>}
            </div>
            {(saveAttempted && !o) && <div className="invalid-input-feedback">Please enter the option text, or remove it</div>}
          </div>
          )}
          {!showBulkAddOptions && question && <>
            <div className="d-inline-flex">
              <Button variant="outline-primary" className="ms-0 mt-1 px-3 py-2" onClick={() => {
                setSaveAttempted(false);
                setQuestion(prev => {
                  return {...prev, options: (prev.options || []).concat('')};
                });
                handleRemoveMessages();
              }}><FaPlus className="me-2 plus-inside-btn" />Add Option</Button>
              <Button variant="outline-primary" className="ms-2 me-0 mt-1 px-3 py-2" onClick={() => setShowBulkAddOptions(true)}><FaList className="me-2 plus-inside-btn" />Paste List</Button>
            </div>
            {itemIncludesOtherOption &&
            <div className="mt-2">
              {question?.otherOption?.enabled ?
                <div className="option-item d-flex align-items-center">
                  <Form.Control
                    type="text" defaultValue={'Other'} className="pe-none other-input" />
                  <VscTrash size="18px" className="delete-option"
                    onClick={() => {
                      handleRemoveMessages();
                      const otherOption = { enabled: false };
                      setQuestion(prev => ({...prev, otherOption}));
                      handleSaveInlineEditItem({type: 'question-other-option', item: otherOption, stepIndex, questionIndex});
                    }} title="Remove 'Other' option"/>
                </div> :
                <Button variant="outline-primary" className="ms-0 mt-1 px-3 py-2" onClick={() => {
                  const otherOption = { enabled: true };
                  setQuestion(prev => ({...prev, otherOption}));
                  handleSaveInlineEditItem({type: 'question-other-option', item: otherOption, stepIndex, questionIndex});
                  handleRemoveMessages();
                }}><FaPlus className="me-2 plus-inside-btn" />Add 'Other' Option</Button>}
              <div className="input-feedback">The 'Other' item displays a text box to collect an alternative option</div>
            </div>}
          </>}
        </FormGroup>}
      {item?.componentType === 'countrySelector' &&
         <FormGroup className="form-group top-options" controlId="top-options">
           <Form.Label>Countries to show at the top</Form.Label>
           <div className="input-feedback">Select which countries you'd like to be quickly accessed at the top of the dropdown</div>
           <CountrySelectTopOptions
             selectedOptions={question?.topOptions}
             handleOnChange={handleSelectTopOptions}
             handleOnBlur={handleSaveTopOptions}
           />
         </FormGroup>
      }
      <FormGroup className="form-group pt-1 d-flex flex-column" controlId="question-required">
        <div className="d-inline-flex align-items-center">
          <Form.Check className="d-inline-flex align-items-center" type="checkbox" checked={(question && !question?.required) ?? false}
            onChange={({target: {checked}}) => {
              setQuestion(prev => ({...prev, required: !checked}));
              handleRemoveMessages();
              handleSaveInlineEditItem({type: 'question-required', item: !checked, stepIndex, questionIndex});
            }}/>
          <Form.Label className="mb-0 ms-2">Make field optional</Form.Label>
        </div>
        <div className="input-feedback">Consider removing any fields that are optional</div>
      </FormGroup>

      {item?.componentType === 'address' &&
        <fieldset className="form-group">
          <legend className="mb-0">Sub labels</legend>
          <FormGroup className="form-group mb-1" controlId="sub-label-address-line-1">
            <Form.Control type="text" value={question?.subLabels?.addressLine1 ?? 'Address 1'}
              onChange={({target: {value}}) => {
                setQuestion(prev => ({...prev, subLabels: {...prev.subLabels, addressLine1: value}}));
                handleRemoveMessages();
              }}
              onBlur={() => {
                if (item.subLabels.addressLine1 !== question.subLabels.addressLine1) {
                  handleSaveInlineEditItem({type: 'question-sub-labels', item: {
                    ...question.subLabels,
                    addressLine1: question.subLabels.addressLine1 || null,
                  }, stepIndex, questionIndex});
                }
              }}  />
          </FormGroup>
          <FormGroup className="form-group mb-1" controlId="sub-label-address-line-2">
            <Form.Control type="text" value={question?.subLabels?.addressLine2 ?? 'Address 2'}
              onChange={({target: {value}}) => {
                setQuestion(prev => ({...prev, subLabels: {...prev.subLabels, addressLine2: value}}));
                handleRemoveMessages();
              }}
              onBlur={() => {
                if (item.subLabels.addressLine2 !== question.subLabels.addressLine2) {
                  handleSaveInlineEditItem({type: 'question-sub-labels', item: {
                    ...question.subLabels,
                    addressLine2: question.subLabels.addressLine2 || null,
                  }, stepIndex, questionIndex});
                }
              }}  />
          </FormGroup>
          <FormGroup className="form-group mb-1" controlId="sub-label-address-level-1">
            <Form.Control type="text" value={question?.subLabels?.addressLevel1 ?? 'Town or city'}
              onChange={({target: {value}}) => {
                setQuestion(prev => ({...prev, subLabels: {...prev.subLabels, addressLevel1: value}}));
                handleRemoveMessages();
              }}
              onBlur={() => {
                if (item.subLabels.addressLevel1 !== question.subLabels.addressLevel1) {
                  handleSaveInlineEditItem({type: 'question-sub-labels', item: {
                    ...question.subLabels,
                    addressLevel1: question.subLabels.addressLevel1 || null,
                  }, stepIndex, questionIndex});
                }
              }}  />
          </FormGroup>
          <FormGroup className="form-group mb-0" controlId="sub-label-postal">
            <Form.Control type="text" value={question?.subLabels?.postalCode ?? 'Postcode/ZIP code'}
              onChange={({target: {value}}) => {
                setQuestion(prev => ({...prev, subLabels: {...prev.subLabels, postalCode: value}}));
                handleRemoveMessages();
              }}
              onBlur={() => {
                if (item.subLabels.postalCode !== question.subLabels.postalCode) {
                  handleSaveInlineEditItem({type: 'question-sub-labels', item: {
                    ...question.subLabels,
                    postalCode: question.subLabels.postalCode || null,
                  }, stepIndex, questionIndex});
                }
              }}  />
          </FormGroup>
          <div className="input-feedback">Use the most suitable labels for your address fields.</div>
        </fieldset>}

      {itemCanEditSize &&
        <FormGroup className="form-group d-flex flex-column" controlId="size">
          <Form.Label>Field Width</Form.Label>
          <ButtonGroup className="toggle-buttons">
            {[
              {name: 'XS', value: 'xs'},
              {name: 'S', value: 'sm'},
              {name: 'M', value: 'md'},
              {name: 'L', value: 'lg'},
            ].map((radio, id) => (
              <ToggleButton
                key={id}
                id={`radio-${id}`}
                type="radio"
                variant="outline-secondary"
                name="size"
                className="px-2 py-1"
                value={radio.value}
                checked={question?.size && question.size === radio.value}
                onChange={({target: {value}}) => {
                  setQuestion(prev => ({...prev, size: value}));
                  handleRemoveMessages();
                  handleSaveInlineEditItem({type: 'question-size', item: value, stepIndex, questionIndex});
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <div className="input-feedback">Adjust the width of the field to best suit the information to be provided</div>
        </FormGroup>}

      {item?.componentType === 'date' &&
        <FormGroup className="form-group d-flex flex-column" controlId="date-format">
          <Form.Label>Date Format</Form.Label>
          <Form.Select value={question?.format || ''} id="completion-currency" className="ms-0 currency-select" aria-label="completion value currency code"
            onChange={({target: {value}}) => {
              handleRemoveMessages();
              setQuestion(prev => ({...prev, format: value}));
              handleSaveInlineEditItem({type: 'question-format', item: value, stepIndex, questionIndex});
            }}>
            {[
              {label: 'YYYY-MM-DD', value: 'YYYY-MM-DD'},
              {label: 'MM-DD-YYYY', value: 'MM-DD-YYYY'},
              {label: 'DD-MM-YYYY', value: 'DD-MM-YYYY'},
              {label: 'YYYY', value: 'YYYY'},
              {label: 'MM-YYYY', value: 'MM-YYYY'},
            ].map((opt) => (<option key={opt.value} value={opt.value}>{opt.label}</option>))}
          </Form.Select>
          <div className="input-feedback">Choose the full date format that your visitors will recognise, or ask for a partial date.</div>
        </FormGroup>}
      {item &&
        <FormGroup className="form-group" controlId="key">
          <Form.Label>Unique Field ID</Form.Label>
          <Form.Control type="text" value={questionKey ?? itemKey} maxLength={255} required
            onChange={({target: {value}}) => {
              handleRemoveMessages();
              setQuestionKey(value);
              setItemKeyAlreadyExists(currentKeys.filter(k => k !== item.key).includes(value));
            }}
            onBlur={() => {
              if (!itemKeyAlreadyExists && (questionKey !== undefined) && itemKey !== questionKey) {
                handleSaveInlineEditItem({type: 'question-key', item: questionKey || null, stepIndex, questionIndex});
              }
            }} />
          {itemKeyAlreadyExists && <div className="invalid-input-feedback">The ID entered is already used on another field. Please change to a unique ID.</div>}
          <div className="input-feedback">This ID allows the field to be identified in interaction tracking. By default, it will use the field <i>Label</i>, or you can change it to a different unique value.</div>
        </FormGroup>}

      <Row className="form-actions-row">
        <Col className="p-0 text-center">
          <Button variant="primary" className="save-builder-form-btn ms-1 me-0 mt-2" type="submit">Save</Button>
        </Col>
      </Row>
    </Form>
  </div>
  );
};

export default ConfigureQuestion;
