import React, { useContext, useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './DatePicker.scss';
import { FaRegCalendarAlt } from "react-icons/fa";
import { usePrevious } from '../hooks';

import AppContext from '../AppContext';

const DatePicker = ({ startTime, endTime, onApply, minDate, timeZone: timeZoneFromPage, selectedFormTimeZone,
  ranges, timePicker, autoApply, alwaysShowCalendars }: {
    startTime: moment.Moment,
    endTime: moment.Moment,
    onApply: (start: any, end: any) => void,
    minDate?: moment.Moment,
    timeZone?: string,
    selectedFormTimeZone?: string,
    ranges?: {},
    timePicker?: boolean,
    autoApply?: boolean,
    alwaysShowCalendars?: boolean,
  }) => {
  const { timeZone: timeZoneInContext } = useContext(AppContext);
  const timeZone = timeZoneFromPage || timeZoneInContext;
  moment.tz.setDefault(timeZone);

  const [isShowing, setIsShowing] = useState<boolean>();

  const dateRef = useRef<DateRangePicker | null>(null);
  const prevStartTime: moment.Moment = usePrevious(startTime) as unknown as moment.Moment;
  const prevEndTime: moment.Moment = usePrevious(endTime) as unknown as moment.Moment;

  const handleEvent = ({type}, picker) => {
    setIsShowing(type === 'show');
  };

  useEffect(() => {
    if (dateRef?.current && (startTime && endTime && ((!prevStartTime && !prevEndTime) ||
        (prevStartTime && prevEndTime && ((prevStartTime.format() !== startTime.format()) || (prevEndTime.format() !== endTime.format())))))) {
      // Using these custom ref functions ensures the component re-renders the DateRangePicker
      dateRef.current.setStartDate(startTime);
      dateRef.current.setEndDate(endTime);
    }
  }, [startTime, endTime, prevStartTime, prevEndTime]);

  return (
    <div className="d-flex justify-content-between align-items-center datepicker-container">
      {startTime && endTime && timeZone && <>
        <DateRangePicker
          key={timeZone} // When the timeZone changes, this key ensures the component ranges and calendar re-renders in the new zone - particularly the time selections
          ref={dateRef}
          initialSettings={{
            timePicker: timePicker ?? true,
            timePicker24Hour: timePicker ?? true,
            startDate: startTime,
            endDate: endTime,
            minDate: minDate || moment().subtract(1, 'years').startOf('day'),
            maxDate: moment().endOf('day'),
            alwaysShowCalendars: alwaysShowCalendars || false,
            autoApply: autoApply || false,
            ranges: ranges || {
              'Today': [moment().startOf('day'), moment().endOf('day')],
              'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
              'Last 7 Days': [moment().subtract(7, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
              'Last 30 Days': [
                moment().subtract(30, 'days').startOf('day'),
                moment().subtract(1, 'days').endOf('day')],
              'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month').startOf('day'),
                moment().subtract(1, 'month').endOf('month').endOf('day')],
            },
            locale: {
              format: 'MMM DD, YYYY HH:mm',
              applyLabel: 'Select',
              customRangeLabel: 'Custom Date/Time',
            },
          }}
          onCallback={onApply}
          onEvent={handleEvent}
        >
          <input type="text" className="form-control datepicker-dates" />
        </DateRangePicker>
        <div className="d-flex justify-content-between text-center align-items-center">
          <div className="mx-1">
            <span><small>{(startTime && endTime) && (selectedFormTimeZone || timeZone)}</small></span>
          </div>
          <div className={`indicatorsContainer ${isShowing ? 'calendar-showing' : ''}`} onClick={() => dateRef?.current?.ref.click()}>
            <span className="indicatorSeparator" />
            <div aria-hidden="true" className="indicatorContainer">
              <FaRegCalendarAlt />
            </div>
          </div>
        </div>
      </>}
    </div>
  );
};

export default DatePicker;
