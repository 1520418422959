import React, { useContext, useState } from 'react';
import AppContext from '../AppContext';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { MdOutlineReviews } from "react-icons/md";
import { HiExternalLink } from 'react-icons/hi';

const ReviewPopover = ({mixpanel, outsideOverlayRef, classList = [], page}) => {
  const {
    hasShownReviewOpen, setHasShownReviewOpen,
  } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OverlayTrigger placement="bottom-end" trigger={'click'} rootClose defaultShow={page === 'FieldData' && !hasShownReviewOpen}
      onExited={() =>  {
        setHasShownReviewOpen(true);
        setIsOpen(false);
      }}
      onEntered={() => {
        setIsOpen(true);
        mixpanel.track('Opened Review Popover', { page });
      }}
      overlay={
        <Popover id="review-container" className={classList.join(' ')}>
          <Popover.Body>
            <Row className="g-0 header-row"><h3>Encourage Us!</h3></Row>
            <div className="mt-1">
              <p>We’re a small (but perfectly formed) team and your reviews keep us going. We’d be super grateful if you could leave one for us - we read every one!</p>
              <div className="d-flex flex-column text-center">
                <p className="mb-0"><a target="_blank" rel="noopener noreferrer" href="https://www.g2.com/products/zuko-analytics/take_survey"
                  onClick={() => {mixpanel.track('Clicked Review Link', { page, linkTo: 'G2' });}}
                >Leave a review on G2 <HiExternalLink /></a></p>
                <p className="mb-0">or</p>
                <p className="mb-0"><a target="_blank" rel="noopener noreferrer" href="https://reviews.capterra.com/products/new/ba6bf27d-d6ad-4c31-9ba1-b6e2408f632c/"
                  onClick={() => {mixpanel.track('Clicked Review Link', { page, linkTo: 'Capterra' });}}
                >Leave a review on Capterra <HiExternalLink /></a></p>
              </div>
            </div>
          </Popover.Body>
        </Popover>}>
      <button className={`unstyled-btn ${isOpen ? 'is-open' : null}`}><MdOutlineReviews size="16px" /> Leave a review</button>
    </OverlayTrigger>);
};

export default ReviewPopover;