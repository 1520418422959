import { useState, useCallback, useRef, useEffect } from 'react';
import React from 'react';
import Footer from './components/Footer';
import { FormDataHash } from './types/FormDataHash';
import FormContext, { FormContextType } from './contexts/FormContext';
import SessionContext from './contexts/SessionContext';
import ProgressionContext from './contexts/ProgressionContext';
import Submitted from './Submitted';
import FormCard from './components/FormCard';

function ZukoForm({
  inEdit,
  handleSaveInlineEditItem,
  handleRemoveMessages,
  identifyItem,
  setIdentifyItem,
  setInAppStepIndex,
  inAppStepIndex,
  inAppShowSubmitted,
  setInAppShowSubmitted,
  handleDeleteItem,
  handleMoveQuestion,
}: any) { // TODO: set types
  const { builderSteps, style } = React.useContext(FormContext) as FormContextType;
  const { fontFamily } = style || {};
  const formRef = useRef<HTMLFormElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(inAppStepIndex);
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string|null>(null);
  const [showSubmitted,  setShowSubmitted] = useState(inAppShowSubmitted);
  const [values, setValues] = useState<FormDataHash<string>>({});
  const [attemptedSubmitAt, setAttemptedSubmitAt] = useState<number | undefined | null>();

  // TODO: When there are errors - either scroll to highest field in error, or indicate close to CTAs which field is in erorr, and link to scroll to it.

  const onBackButtonClick = useCallback((e: React.FormEvent<HTMLButtonElement>) => {
    if (inEdit) {
      setIdentifyItem(null);
    }
    setInAppStepIndex((prev: number) => prev - 1);
  }, [inEdit, setIdentifyItem, setInAppStepIndex]);

  const onContinueButtonClick = useCallback((e: React.FormEvent<HTMLButtonElement>) => {
    if (!inEdit && !formRef?.current?.checkValidity()) {
      setAttemptedSubmitAt((new Date()).getTime());
      return;
    } else {
      setAttemptedSubmitAt(null);
    }
    if (inEdit) {
      setIdentifyItem(null);
    }
    setInAppStepIndex((prev: number) => prev + 1);
  }, [inEdit, setIdentifyItem, setInAppStepIndex]);

  const onSubmitButtonClick = useCallback(async () => {
    setSubmissionErrorMessage(null);

    if (inEdit) {
      setInAppShowSubmitted(true);
      return;
    }
    if (!formRef?.current?.checkValidity()) {
      setAttemptedSubmitAt((new Date()).getTime());
      return;
    } else {
      setAttemptedSubmitAt(null);
    }
    try {
      // No submit is actually made in either Edit or Preview mode
      setInAppShowSubmitted(true);
    } catch (e) {
      console.error(e);
      setSubmissionErrorMessage('Sorry, something went wrong submitting the form. Please try again.');
    }
  }, [inEdit, setInAppShowSubmitted]);

  useEffect(() => {
    setCurrentStepIndex(inAppStepIndex);
  }, [inAppStepIndex]);

  useEffect(() => {
    setShowSubmitted(inAppShowSubmitted);
  }, [inAppShowSubmitted]);

  return <div id="zuko-builder-form" className={`${inEdit ? 'in-edit no-validation' : 'show-validation'} ${fontFamily ? '' : 'default-font'}`}
    style={{fontFamily}}>
    <SessionContext.Provider value={{ values, setValues, inEdit, identifyItem, setIdentifyItem,
      handleDeleteItem,
      handleMoveQuestion,
      handleSaveInlineEditItem,
      handleRemoveMessages,
    }}>
      {showSubmitted && <Submitted />}
      {!showSubmitted &&
      <ProgressionContext.Provider value={{
        currentStepIndex: inEdit ? inAppStepIndex : currentStepIndex,
        stepsLength: builderSteps?.length, onBackButtonClick, onContinueButtonClick,
        onSubmitButtonClick, submissionErrorMessage, attemptedSubmitAt
      }}>
        <FormCard formRef={formRef} />
        {!inEdit && <Footer />}
      </ProgressionContext.Provider>}
    </SessionContext.Provider>
  </div>;
}

export default ZukoForm;
