export const getIframeScriptCode = ({iframeId, iframeSrc, formUuid}) => {
  const origin = new URL(iframeSrc).origin;
  return <script type="text/javascript" dangerouslySetInnerHTML={{__html: `(() => {
    const iframe = document.getElementById('${iframeId}');
    const hostedFormOrigin = '${origin}';
    window.addEventListener('message', function (message) {
      if (message.origin !== hostedFormOrigin || message.data.purpose !== 'zukoSetFrameHeight' || message.data.formUuid !== '${formUuid}') return;
      iframe.style.height = message.data.height + 'px';});
    })();`.trim().replace(/\s{2,}|[\n\r\t]+/g, '')
  }}>
  </script>;
};

export const getIframeCodeBlock = ({hostedFormUrl, formUuid, formTitle}) => {
  const iframeId = `Zuko-Forms-${formUuid}`;
  const iframeSrc = `${hostedFormUrl}?inIframe=true`;

  return <>
    <iframe
      src={iframeSrc}
      id={iframeId}
      title={formTitle || 'Zuko Form'}
      style={{
        border: 'none',
        minWidth: '100%',
        maxWidth:'100%',
        height: '450px',
        transition: 'height .3s ease-in-out',
      }}
      scrolling="no">
    </iframe>
    {getIframeScriptCode({iframeId, iframeSrc, formUuid})}
  </>;
};

