import { useContext, useEffect, useState } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';

const BulkOptions = ({ options, setShowBulkAddOptions, stepIndex, questionIndex }: {options: Array<string>, setShowBulkAddOptions: Function,
  stepIndex: number, questionIndex: number}) => {
  const { handleSaveInlineEditItem, handleRemoveMessages } = useContext(SessionContext) as SessionContextType;
  const [bulkAddOptions, setBulkAddOptions] = useState<string[]>([]);
  const isInvalid = (bulkAddOptions.length > 0 && !bulkAddOptions?.filter(o => o).length);

  useEffect(() => {
    setBulkAddOptions(options);
  }, [options]);

  return (<>
    <FormGroup controlId="bulk-options" className={`form-group mb-0 bulk-options-group`}>
      <Form.Label className="mb-0">Enter each option on a new line:</Form.Label>
      <Form.Control
        as="textarea"
        className={`${isInvalid ? 'invalid-input' : 'mb-2'}`}
        value={bulkAddOptions?.join('\n') || ''}
        onChange={({target: {value}}) => {
          setBulkAddOptions(value.split('\n'));
          handleRemoveMessages();
        }}
        rows={bulkAddOptions?.length > 2 ? bulkAddOptions?.length : 2}
        onBlur={() => {
          const emptyOptionsRemoved = bulkAddOptions?.filter(o => o);
          if (emptyOptionsRemoved?.length && options !== bulkAddOptions) {
            handleSaveInlineEditItem({type: 'question-options', item: emptyOptionsRemoved, stepIndex, questionIndex});
          }
        }}
      />
      {isInvalid && <div className="invalid-input-feedback">Please enter at least one option.</div>}
    </FormGroup>
    <div className="d-flex justify-content-end">
      <Button variant="primary" className="mx-0 mb-2 px-3 py-2" onClick={() => setShowBulkAddOptions(false)} disabled={!bulkAddOptions?.filter(o => o).length}>Done</Button>
    </div>
  </>);
};

export default BulkOptions;
