import { isValidWithValue } from '../../utils';

const Year = ({ questionId, questionRequired, yearId, yearRef, yearIsValid, onChange, yearValue, setYearIsValid,
  inEdit }: {
  questionId: string,
  questionRequired: boolean,
  yearId: string,
  yearRef: React.RefObject<HTMLInputElement>,
  yearIsValid: boolean | null | undefined,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  yearValue: string,
  setYearIsValid: Function,
  inEdit?: boolean,
}) => {
  return <div className="field">
    <label htmlFor={inEdit ? '' : yearId}>Year</label>
    <input
      type="text"
      required={questionRequired}
      id={yearId}
      ref={yearRef}
      inputMode="numeric"
      maxLength={4}
      minLength={4}
      pattern="[0-9]*"
      className={`year ${yearIsValid === false ? 'is-invalid' : ''}`}
      onChange={onChange}
      value={yearValue}
      aria-describedby={`${questionId}-hint`}
      aria-invalid={yearIsValid === false}
      aria-errormessage={yearIsValid === false ? `${questionId}-error` : ''}
      onBlur={({target}) => setYearIsValid(isValidWithValue({target, required: questionRequired}))}
    />
  </div>;
};

export default Year;
