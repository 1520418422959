import { useContext } from 'react';
import BuilderContext from '../../Context';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FontSelect from './FontSelect';
import { hexToCssHsl } from '../../utils';
import './Theme.scss';

const Theme = ({
  handleSaveTheme,
  handleRemoveMessages,
  setQueue,
  setCanPublish,
}) => {
  const {
    form, setForm
  } = useContext(BuilderContext);
  return (
    <div className="theme scrollable-column d-flex flex-column justify-content-between">
      <Form noValidate className="w-100" id="builder-field-config-form">
        <h5>Overall Form Style</h5>
        <FormGroup className="form-group" controlId="font-family">
          <Form.Label className="mb-0">Font</Form.Label>
          <FontSelect
            selected={form?.style?.fontFamily || "'Verdana', sans‑serif"}
            handleOnChange={(fontFamily) => {
              handleSaveTheme({type: 'font-family', item: fontFamily});
            }}
          />
        </FormGroup>
        <FormGroup className="form-group" controlId="accent-colour">
          <div className="d-inline-flex align-items-center">
            <Form.Control
              type="color"
              value={form?.style?.accentColor || '#3c2e60'}
              onChange={({target: {value}}) => {
                handleSaveTheme({type: 'accent-colour', item: value});
              }} />
            <Form.Label className="ms-3 mb-0">Accent colour</Form.Label>
          </div>
          <div className="input-feedback">Used in the titles, step progress bar and radios/checkboxes.</div>
        </FormGroup>

        <h5>Continue/Submit Button</h5>
        <FormGroup className="form-group" controlId="primary-button-background">
          <div className="d-inline-flex align-items-center">
            <Form.Control
              type="color"
              value={form?.style?.primaryButton?.background?.match(/#[^\s]+/) || '#198573'}
              onChange={({target: {value}}) => {
                handleSaveTheme({
                  type: 'primary-button',
                  item: {
                    ...form?.style?.primaryButton,
                    background: `linear-gradient(to top, ${hexToCssHsl({hex: value, lightnessChange: -10})}, ${value} 15%)`,
                    hover: {
                      background: `linear-gradient(to top, ${hexToCssHsl({hex: value, lightnessChange: -10})},${hexToCssHsl({hex: value, lightnessChange: -5})} 15%)`,
                    },
                  }});
              }} />
            <Form.Label className="ms-3 mb-0">Background colour</Form.Label>
          </div>
        </FormGroup>

        <FormGroup className="form-group" controlId="primary-button-text-colour">
          <div className="d-inline-flex align-items-center">
            <Form.Control
              type="color"
              value={form?.style?.primaryButton?.color || "#FFFFFF"}
              onChange={({target: {value}}) => {
                handleSaveTheme({
                  type: 'primary-button',
                  item: {
                    ...form?.style?.primaryButton,
                    color: value,
                  }});
              }} />
            <Form.Label className="ms-3 mb-0">Text colour</Form.Label>
          </div>
        </FormGroup>
      </Form>
      <div className="d-flex justify-content-center">
        <Button variant="outline-danger" className="mx-0" onClick={() => {
          const updatedForm = JSON.parse(JSON.stringify(form));
          updatedForm.style = null;
          setForm(updatedForm);
          handleRemoveMessages();
          setCanPublish(false);
          setQueue(prev => (prev || []).concat({
            updatedForm,
            successMessage: 'Theme changes saved.'}));
        }}>Restore Default Theme</Button>
      </div>
    </div>
  );
};

export default Theme;
