import React, { useEffect, useContext, useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import TabContainer from 'react-bootstrap/TabContainer';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { VscWarning, VscCheck, VscInfo } from "react-icons/vsc";
import { FaSpinner } from "react-icons/fa";

import AppContext from '../../AppContext';
import FeedbackRow from '../../Components/FeedbackRow';
import OrgDetailsForm from './OrgDetailsForm';
import api from '../../api';
import { usePrevious } from '../../hooks';
import AppAlerts from '../../Components/AppAlerts';

import '../../Page/OrgEdit/OrgEdit.scss';

const validTabs = ['#details'];

const OrgEdit = ({mixpanel}) => {
  const {
    currentUser,
  } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const { uuid: orgUuid } = useParams();
  const prevLocationKey = usePrevious(location.key);

  const [orgError, setOrgError] = useState(null);
  const [orgLoading, setOrgLoading] = useState(null);
  const [org, setOrg] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showErrorMessagesForField, setShowErrorMessagesForField] = useState();
  const [errorMessagesForField, setErrorMessagesForField] = useState(); // Object response with error messages per field
  const [errorMessages, setErrorMessages] = useState(); // Array of messages
  const [errorMessage, setErrorMessage] = useState(); // Standard catch all single message
  const [selectedTab, setSelectedTab] = useState(validTabs.includes(location.hash) ? location.hash : '#details');
  const [currentUserIsStandard, setCurrentUserIsStandard] = useState();

  const pageTitleRef = useRef();

  const prevOrg = usePrevious(org);

  const handleResetErrorMessages = useCallback(() => {
    if (errorMessage) setErrorMessage(null);
    if (errorMessages) setErrorMessages(null);
    if (errorMessagesForField) setErrorMessagesForField(null);
  },[errorMessage, errorMessages, errorMessagesForField]);

  const handleResetErrorMessagesForField = useCallback((field) => {
    if (errorMessagesForField?.[field]) setErrorMessagesForField(messages => {
      const newMessages = {...messages};
      delete newMessages[field];
      return newMessages;
    });
  },[errorMessagesForField]);

  const handleResetSuccessMessage = useCallback(() => {
    if (successMessage) setSuccessMessage(null);
  },[successMessage]);


  const clearOrgRelatedState = useCallback(() => {
    // Clear all input related to previous Org on change
    handleResetErrorMessages();
    handleResetSuccessMessage();
  }, [handleResetErrorMessages, handleResetSuccessMessage]);

  const displayType = (org) => {
    switch (org?.type) {
    case 'Agency':
      return 'Agency';
    case 'AgencyClient':
      return 'Client';
    default:
      return 'Organisation';
    }
  };

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    if (org && (!prevOrg || (prevOrg?.uuid !== org.uuid))) {
      mixpanel.track('Page View', {
        page: 'OrgEdit',
        product: 'builder',
        'Organisation Name': org.name,
        'Organisation Uuid': org.uuid,
      });
    }
  }, [mixpanel, currentUser.email, org, prevOrg]);

  // Fetch requested Org details
  useEffect(() => {
    const fetchOrg = async () => {
      try {
        setOrgLoading(true);
        setOrgError(null);
        const { data: { organisation, roles } } = await api.get(`/organisations/${orgUuid}`);

        // Consider any non-AM user without an 'admin' role as a 'standard' user
        setCurrentUserIsStandard(!currentUser.accountManager && roles.findIndex(r => (r.resourceUuid === orgUuid && r.name === 'admin')) < 0);
        setOrg(organisation);
      } catch (e) {
        setOrgError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      } finally {
        setOrgLoading(false);
      }
    };
    fetchOrg();
  }, [orgUuid, currentUser?.accountManager]);

  // To support back/forward navigation
  useEffect(() => {
    if (!prevLocationKey || (prevLocationKey && (location.key !== prevLocationKey))) {
      clearOrgRelatedState();

      // If the hash has changed, select the tab
      if (location.hash) {
        setSelectedTab(validTabs.includes(location.hash) ? location.hash : '#details');
      } else {
        setSelectedTab('#details');
      }
    }
  }, [location.key, prevLocationKey, location.hash, clearOrgRelatedState]);

  const handleSubmit = async ({
    name, notes,
  }) => {
    setShowSuccessMessage(false);
    setSuccessMessage(null);
    try {
      handleResetErrorMessages();
      const { data: { organisation: updatedOrg } } = await api.put(`/organisations/${orgUuid}`, {
        organisation: {
          name,
          ...currentUser.accountManager && {
            notes,
          },
        },
      });

      setOrg(prev => ({...prev, ...updatedOrg}));

      setShowSuccessMessage(true);
      setSuccessMessage(`${displayType(updatedOrg)} updated.`);
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef?.current?.offsetTop });

      mixpanel.track('Updated Organisation', {
        page: 'OrgEdit',
        product: 'builder',
        'Organisation Name': updatedOrg.name,
        'Organisation Uuid': updatedOrg.uuid,
      });
    } catch (e) {
      switch (e?.response?.status) {
      case e?.response?.data?.errors && 400:
      case e?.response?.data?.errors && 422:
        if (Array.isArray(e.response.data.errors)) {
          setErrorMessages(e.response.data.errors);
        } else {
          setErrorMessagesForField(e.response.data.errors);
          setShowErrorMessagesForField(true);
        }
        break;
      case 401:
        setOrgError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setErrorMessage('Something went wrong. The organisation has not been updated, please try again.');
      }

      window.scrollTo({ behavior: 'smooth', top: pageTitleRef?.current?.offsetTop });
    }
  };

  const handleBillingLinkClick = async () => {
    try {
      const {data: {portalSession: {url: targetUrl}}} =
      await api.post(`/builder/organisations/${org.uuid}/billing/customer_portal/sessions`);
      window.location.href = targetUrl;
    } catch (e) {
      setOrgError('Sorry something went wrong accessing Billing. Please reload the page.');
    }
  };

  return (
    <div fluid className="org-edit page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Edit {displayType(org)}</title>
      </Helmet>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'OrgEdit'}
            org={org}
            messageContent={`editing an ${displayType(org).toLocaleLowerCase()}`} />
          <AppAlerts showBuilderOrgAlert={true} />
          <Row className="title-row g-0" ref={pageTitleRef}>
          </Row>
          <Card className="edit-card">
            <Card.Body>
              <TabContainer activeKey={selectedTab} onSelect={(t) => {
                history.push(`/builder/organisations/${orgUuid}/edit${t}`);
                mixpanel.track('Changed Tab', {
                  page: 'OrgEdit',
                  tab: `${t}`,
                  ...org && {
                    'Organisation Name': org.name,
                    'Organisation Uuid': org.uuid,
                  }
                });
                if (showSuccessMessage) setShowSuccessMessage(false);
              }}>
                <Row className="g-0 card-title-row justify-content-between">
                  <Col className="p-0">
                    <Card.Title as="h3">Edit {displayType(org)}</Card.Title>
                  </Col>
                  <Col className="p-0 col-auto">
                    <Nav variant="tabs" className="justify-content-end" id="card-header-nav-tabs" activeKey={selectedTab}>
                      <Nav.Item>
                        <Nav.Link eventKey="#details">Details</Nav.Link>
                      </Nav.Item>
                      {
                        org && (
                          (org.builderSubscriptionStatus && org.builderSubscriptionStatus === 'canceled') ||
                          (org.builderSubscriptionStatus && org.builderSubscriptionStatus === 'incomplete_expired') ||
                          (!org.builderPlan)
                        ) &&
                        (currentUserIsStandard ?
                          <OverlayTrigger overlay={
                            <Popover id="standard-access-msg-popover">
                              <Popover.Body>
                                <p>Only admin users can complete sign up.</p>
                                <p>Please contact one of your admin users or email us on support@zuko.io.</p>
                              </Popover.Body>
                            </Popover>}>
                            <div className="d-inline-block">
                              <Button className="me-0 sign-up-btn" disabled={currentUserIsStandard}>Sign Up</Button>
                            </div>
                          </OverlayTrigger> :
                          <Link
                            to={{
                              pathname: `/builder/organisations/${org.uuid}/choose-plan`,
                              state: {organisation: {name: org.name}},
                            }}><Button className="me-0 sign-up-btn">Sign Up</Button>
                          </Link>)
                      }
                      {
                        org && org.type !== 'AgencyClient' &&
                        org.stripeCustomerId && org.builderSubscriptionStatus && org.builderSubscriptionStatus !== 'canceled'
                          && org.builderSubscriptionStatus !== 'incomplete_expired' && org.builderPlan &&
                        <Nav.Item className="pe-2">
                          {currentUserIsStandard ?
                            <OverlayTrigger overlay={
                              <Popover>
                                <Popover.Body>
                                  An admin user in your {displayType(org).toLocaleLowerCase()} can access billing.
                                </Popover.Body>
                              </Popover>}>
                              <div className="d-inline-block">
                                <Nav.Link id="billing-link" disabled={currentUserIsStandard}>Billing</Nav.Link>
                              </div>
                            </OverlayTrigger> :
                            <Nav.Link id="billing-link" onClick={handleBillingLinkClick}>Billing</Nav.Link>}
                        </Nav.Item>
                      }
                    </Nav>
                  </Col>
                </Row>
                <Row className="g-0 card-content">
                  <TabContent>
                    {orgError && <div id="org-load-error"><h3>{orgError}</h3></div>}
                    {currentUserIsStandard && selectedTab !== '#forms' && <Row className="alert-row g-0" id="user-role-info-alert">
                      <Alert dismissible={false} variant={'info'}>
                        <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
                        <p className="alert-text m-0">
                          Please inform an admin user if you wish to make any changes to this {displayType(org).toLocaleLowerCase()}.
                        </p>
                      </Alert>
                    </Row>}
                    <TabPane eventKey="#details" className="min-height-tab">
                      {showSuccessMessage && successMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="success" closeVariant="white"
                            onClose={() => setShowSuccessMessage(false)}>
                            <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                            <p className="alert-text m-0">{successMessage}</p>
                          </Alert>
                        </Row>}
                      {showErrorMessagesForField && (errorMessagesForField && Object.keys(errorMessagesForField).length > 0) &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setShowErrorMessagesForField(false)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <div>
                              {Object.keys(errorMessagesForField)
                                .map((field, i) => (
                                  <p key={`field${i}`} className="alert-text m-0 py-1">{field} {errorMessagesForField[field]?.join(', ')}.</p>))}
                            </div>
                          </Alert>
                        </Row>
                      }
                      {errorMessages?.length > 0 &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setErrorMessages(null)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{errorMessages.map((e) => e.message)}</p>
                          </Alert>
                        </Row>
                      }
                      {errorMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setErrorMessage(null)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{errorMessage}</p>
                          </Alert>
                        </Row>
                      }
                      {!orgError && <>
                        {orgLoading &&
                          <Row className="form-title-row flex-nowrap top-form-row">
                            <Col>
                              <FaSpinner size="18px" className="spinning-icon" title="Loading organisation..."/>
                            </Col>
                          </Row>}

                        {!orgLoading && <>
                          <OrgDetailsForm
                            handleSubmit={handleSubmit}
                            type={'edit'}
                            org={org}
                            handleResetErrorMessagesForField={handleResetErrorMessagesForField}
                            errorMessagesForField={errorMessagesForField}
                            handleResetSuccessMessage={handleResetSuccessMessage}
                            currentUserIsStandard={currentUserIsStandard}
                          />
                        </>}
                      </>}

                    </TabPane>
                  </TabContent>
                </Row>
              </TabContainer>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default OrgEdit;
