import Hint from "./question/HintWithLink";
import Label from "./question/Label";
import Email from './fields/Email';
import Text from './fields/Text';
import Number from './fields/Number';
import Date from './fields/Date';
import Checkbox from './fields/Checkbox';
import CheckboxGroup from './fields/CheckboxGroup';
import Textarea from './fields/Textarea';
import RadioButtonGroup from './fields/RadioButtonGroup';
import Select from './fields/Select';
import TelephoneNumber from './fields/TelephoneNumber';
import Address from './fields/Address';
import { QuestionInterface } from "../interfaces/zuko_form/QuestionInterface";
import InputSuccessWrapper from './InputSuccessWrapper';
import InvalidFeedback from "./InvalidFeedback";
import CountrySelector from "./fields/CountrySelector";
import './Question.scss';

export const optionalTag = <span className="optional-tag"><i>optional</i></span>;

function Question({ question, componentType, stepIndex, questionIndex }: {
  question: QuestionInterface,
  stepIndex: number,
  questionIndex: number,
  componentType: 'address' | 'checkbox' | 'date' | 'email' | 'radioButtonGroup' | 'text' | 'textarea' |
  'number' | 'telephoneNumber' | 'select' | 'countrySelector' | 'checkboxGroup',
}) {
  const { id, label, hint = null, required } = question;
  const { topOptions, subLabels, otherOption, ...restOfQuestion } = question;
  const labelForQuestion = <Label id={id} label={label} required={required} stepIndex={stepIndex} questionIndex={questionIndex} type={'question-title'} />;
  const hintForQuestion = <Hint id={`${id}-hint`} text={hint} stepIndex={stepIndex} questionIndex={questionIndex} />;

  switch (componentType) {
  case 'email':
    return <div className="field">
      {labelForQuestion}
      {hintForQuestion}
      <InvalidFeedback id={id} feedback={'Please enter a valid email address, for example someone@example.com'} />
      <InputSuccessWrapper label={label}>
        <Email {...restOfQuestion} />
      </InputSuccessWrapper>
    </div>;
  case 'text':
    return <div className="field">
      {labelForQuestion}
      {hintForQuestion}
      <InvalidFeedback id={id} feedback={'Please enter the required information'} />
      <InputSuccessWrapper label={label}>
        <Text {...restOfQuestion} />
      </InputSuccessWrapper>
    </div>;
  case 'number':
    return <div className="field">
      {labelForQuestion}
      {hintForQuestion}
      <InvalidFeedback id={id} feedback={'Please enter the requested information in number format'} />
      <InputSuccessWrapper label={label}>
        <Number {...restOfQuestion} />
      </InputSuccessWrapper>
    </div>;
  case 'telephoneNumber':
    return <div className="field">
      {labelForQuestion}
      {hintForQuestion}
      <InvalidFeedback id={id} feedback={'Please enter a telephone number which is at least 8 characters long'} />
      <InputSuccessWrapper label={label}>
        <TelephoneNumber {...restOfQuestion} autoComplete="tel"/>
      </InputSuccessWrapper>
    </div>;
  case 'textarea':
    return <div className="field">
      {labelForQuestion}
      {hintForQuestion}
      <InvalidFeedback id={id} feedback={'Please enter the required information'} />
      <InputSuccessWrapper label={label}>
        <Textarea {...restOfQuestion} />
      </InputSuccessWrapper>
    </div>;
  case 'select':
    return <div className="field">
      {labelForQuestion}
      {hintForQuestion}
      <InvalidFeedback id={id} feedback={'Please choose one of the options'} />
      <InputSuccessWrapper label={label}>
        <Select {...restOfQuestion} topOptions={topOptions} />
      </InputSuccessWrapper>
    </div>;
  case 'countrySelector':
    return <div className="field">
      {labelForQuestion}
      {hintForQuestion}
      <InvalidFeedback id={id} feedback={'Please choose one of the options'} />
      <InputSuccessWrapper label={label}>
        <CountrySelector {...restOfQuestion} topOptions={topOptions} />
      </InputSuccessWrapper>
    </div>;
  case 'checkbox':
    return <div className="field">
      <Checkbox question={restOfQuestion} invalidFeedback={<p className="invalid-feedback">Please check this box to proceed</p>} stepIndex={stepIndex} questionIndex={questionIndex} />
    </div>;
  case 'date':
    return <div className="field">
      <Date question={restOfQuestion} invalidFeedback={
        <InvalidFeedback id={id} feedback={'A valid date must include a year (for example, 1995), a month (for example, 12) and a day (for example, 5)'} />
      } stepIndex={stepIndex} questionIndex={questionIndex} />
    </div>;
  case 'radioButtonGroup':
    return <div className="field">
      <RadioButtonGroup question={{...restOfQuestion,
        options: question.options || [],
        otherOption,
      }} invalidFeedback={
        <InvalidFeedback id={id} feedback={'Please choose an option'} />
      } stepIndex={stepIndex} questionIndex={questionIndex} />
    </div>;
  case 'checkboxGroup':
    return <div className="field">
      <CheckboxGroup question={{...restOfQuestion,
        options: question.options || [],
        otherOption,
      }} invalidFeedback={
        <p className="invalid-feedback">Please choose an option</p>} stepIndex={stepIndex} questionIndex={questionIndex} />
    </div>;
  case 'address':
    return <div className="field">
      <Address question={{...restOfQuestion, subLabels}} invalidFeedback={
        <InvalidFeedback id={id} feedback={'Please enter a full address using all of the fields, except for those marked optional'}/>
      } stepIndex={stepIndex} questionIndex={questionIndex} />
    </div>;
  default:
    console.error(`Unknown component type: ${componentType}`);
    return null;
  }
}

export default Question;
