import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { VscInfo } from 'react-icons/vsc';
import { HiExternalLink } from 'react-icons/hi';
import { Organisation } from '../types/types.ts';
import api from '../api';
import './SessionReplayCTAModal.scss';

const InfoAlert = ({Message}) => <Alert variant="info" closeVariant="white">
  <div className="alert-svg-icon my-auto"><VscInfo size="20px" className="me-2"/></div>
  <Message />
</Alert>;

const SignUpMsgToDisplay = ({organisation, handleBillingLinkClick}: {organisation: Organisation, handleBillingLinkClick: ({uuid}) => void}) => {
  const { contractType, name, uuid, subscriptionStatus, plan } = organisation;

  if (contractType === 'trial') return <>
    <InfoAlert Message={() => <p className="alert-text mb-0">Session replay is not currently enabled in your trial.</p>}/>
    <div className="d-flex flex-column align-items-center">
      <Link to={{pathname: `organisations/${uuid}/choose-plan`, search: '?include-replay=true', state: {organisation: {name}}}}><Button className="sign-up-btn mx-0 my-3">Sign Up</Button></Link>
      <p className="mb-0"><a target="_blank"
        rel="noopener noreferrer" href="https://www.zuko.io/session-replay">Find out more<HiExternalLink className="align-top" /></a> about <i>Session Replay</i>, or contact <a href="mailto:support@zuko.io">support@zuko.io</a>.</p>
    </div>
  </>;

  if (organisation.type === 'AgencyClient') return <>
    <InfoAlert Message={() => <p className="alert-text m-0">Session Replay is not currently enabled in your Agency's plan. Please contact your Agency, or <a href="mailto:support@zuko.io">support@zuko.io</a>.</p>}/>
    <p className="mb-0 mt-2"><a target="_blank"
      rel="noopener noreferrer" href="https://www.zuko.io/session-replay">Find out more<HiExternalLink className="align-top" /></a> about <i>Session Replay</i>.</p>
  </>;

  if (subscriptionStatus && plan) { // Any org with a subscription status does or did have a plan
    // Orgs that are 'canceled' are not tracking or invoiced
    if (subscriptionStatus === 'canceled') return <>
      <InfoAlert Message={() => <p className="alert-text mb-0">Session replay is not currently enabled.</p>}/>
      <div className="d-flex flex-column align-items-center">
        <Link to={{pathname: `organisations/${uuid}/choose-plan`, search: '?include-replay=true', state: {organisation: {name}}}}><Button className="sign-up-btn mx-0 my-3">Sign Up</Button></Link>
        <p className="mb-0"><a target="_blank"
          rel="noopener noreferrer" href="https://www.zuko.io/session-replay">Find out more<HiExternalLink className="align-top" /></a> about <i>Session Replay</i>, or contact <a href="mailto:support@zuko.io">support@zuko.io</a>.</p>
      </div>
    </>;

    // Expected to have a valid Stripe plan, so must be updated via Billing
    if (subscriptionStatus !== 'canceled') return <>
      <InfoAlert Message={() => <p className="alert-text mb-0">Your current subscription does not include session replay.</p>}/>
      <div className="d-flex flex-column align-items-center">
        <Button className="sign-up-btn mx-0 my-3" onClick={() => handleBillingLinkClick({uuid})}>Update plan</Button>
        <p className="mb-0"><a target="_blank"
          rel="noopener noreferrer" href="https://www.zuko.io/session-replay">Find out more<HiExternalLink className="align-top" /></a> about <i>Session Replay</i>, or contact <a href="mailto:support@zuko.io">support@zuko.io</a>.</p>
      </div>
    </>;
  }

  // Any monthly/fixed org without a subscription status is expected to be invoiced or lapsed
  return <>
    <InfoAlert Message={() => <p className="alert-text mb-0">Session replay is not currently enabled on your account. Please contact <a href="mailto:support@zuko.io">support@zuko.io</a> to add it to your package.</p>} />
    <p className="mb-0 mt-2"><a target="_blank"
      rel="noopener noreferrer" href="https://www.zuko.io/session-replay">Find out more<HiExternalLink className="align-top" /></a> about <i>Session Replay</i>.</p>
  </>;
};

const SessionReplayCTAModal = ({
  show,
  organisation,
  handleCloseModal,
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleBillingLinkClick = async ({uuid}) => {
    try {
      const {data: {portalSession: {url: targetUrl}}} =
      await api.post(`/organisations/${uuid}/customer_portal_sessions`); // TODO add a return_url to pass onto the stripe portal
      window.location.href = targetUrl;
    } catch (e) {
      setError('Sorry something went wrong accessing Billing. Please reload the page.');
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleCloseModal}
      id="session-replay-cta-modal" className="d-flex">
      <Modal.Header closeButton={true} className="">
        <Modal.Title as={'h3'}>Session Replay<sup><span className="badge badge-pill badge-primary beta-tab-badge">NEW</span></sup></Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center">
        <div className="">
          <p className=""><i>Session Replay</i> allows you to watch a recording of anonymised user sessions on your forms. This will give you additional insight
            into user behaviour and causes of abandonment.</p>
          {organisation &&
              <SignUpMsgToDisplay organisation={organisation} handleBillingLinkClick={handleBillingLinkClick}/>}
          {error && <p className="error-text">{error}</p>}
        </div>
      </Modal.Body>
    </Modal>);
};

export default SessionReplayCTAModal;
