import { useContext, useRef } from 'react';
import SessionContext, { SessionContextType } from '../contexts/SessionContext';
import EditInlineIcon from './EditInlineIcon';
import { useShowEditIcon } from '../hooks';

const Title = ({ text, type }: { text: string, type: string }) => {
  const { inEdit, handleSaveInlineEditItem, handleRemoveMessages } = useContext(SessionContext) as SessionContextType;
  const [showEditIcon, setShowEditIcon] = useShowEditIcon({type});
  const editableContentRef = useRef<HTMLHeadingElement>(null);

  return inEdit ? <span className="mark d-inline-flex align-items-center"><h1 className="title editable-inline"
    ref={editableContentRef}
    contentEditable={inEdit}
    suppressContentEditableWarning={true}
    spellCheck="false"
    onClick={() => setShowEditIcon(false)}
    onBlur={(e) => {
      if (!inEdit) return;
      handleRemoveMessages();
      if (text !== e.target.innerText) handleSaveInlineEditItem({type, item: e.target.innerText});}}
  ><span className="text-cursor">{text}</span></h1>
  {showEditIcon && <EditInlineIcon setShowEditIcon={setShowEditIcon} itemRef={editableContentRef} />}</span> :
    <h1 className="title">{text}</h1>;
};

export default Title;
