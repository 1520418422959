import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { VscTrash } from "react-icons/vsc";
import { FaBars } from "react-icons/fa";
import Select, { components } from 'react-select';
import { formatMillisecondsToTimeString, labelForField, htmlAttrsForIdentifier } from '../utils';

const FunnelStage = ({
  stage,
  stageIndex,
  stages,
  trackedFields,
  trackedFieldsLoading,
  trackedFieldsError,
  fieldsLookUp,
  editFieldsStages,
  saveStage,
  isEditing,
  funnelData,
  deleteStage,
  dragRef,
  draggableProps,
  isDragging,
  dragHandleProps,
  editContainsErrors,
  setMenuOpenAtIndex,
  clipPath,
  inputWidth,
}) => {
  const stageOrder = stageIndex + 1;
  const [editedName, setEditedName] = useState();
  const [editedFields, setEditedFields] = useState();
  const [hasEditedFields, setHasEditedFields] = useState();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState();

  useEffect(() => {
    if (stage?.fieldIdentifiers) setEditedFields(stage.fieldIdentifiers);
  },[stage?.fieldIdentifiers]);

  useEffect(() => {
    setEditedName(stage.name ?? `Stage ${stageOrder}`);
  },[stage?.name, stageOrder]);

  useEffect(() => {
    if (editContainsErrors) setHasEditedFields(true);
  },[editContainsErrors]);

  useEffect(() => {
    if (stages.length === 1 && !stage.uuid) {
      setHasEditedFields(false);
      if (isConfirmingDelete) setIsConfirmingDelete(false);
    }
  },[stages, stage, isConfirmingDelete]);

  const funnelColRef = useRef();

  const { abandons, progressionRate, meanDuration } = funnelData?.[stage.uuid] || {};

  return (
    <tr ref={dragRef} {...draggableProps} className={`funnel-row ${isConfirmingDelete ? 'keep-row-hover' : ''}`} key={`stage-${stage.uuid}`}>
      <td className="grip-column">
        {isEditing && <span {...dragHandleProps}><FaBars size="12px" title="Move stage" className={`ms-1 editing ${!stage.uuid ? 'disabled' : ''}`} data-testid={`move-stage-${stageIndex}`}/></span>}
      </td>
      <td className={`funnel-column p-0 text-center`} ref={funnelColRef} style={{width: isDragging ? funnelColRef?.current?.getBoundingClientRect().width : '45%'}}>
        <span className="funnel-wrap">
          <div className="d-inline-flex flex-column text-center funnel-bucket" style={{clipPath}}>
            <div className={`funnel-step-row text-center colour${stageOrder}`}>
              {isEditing ?
                <div className="m-auto" style={{maxWidth: inputWidth}}>
                  <Form.Control className={`stage-name-input ${(editedName?.length < 1) ? 'invalid-input' : ''}`} type="text" value={editedName || ''} required placeholder="Name"
                    minLength={1} maxLength={50} htmlSize={editedName ? editedName.length + 1 : 8}
                    onChange={({target: {value}}) => setEditedName(value)} onBlur={() => saveStage({stage: {...stage, name: editedName, fieldIdentifiers: editedFields}, stageIndex})} />
                </div> : <>
                  <div className="m-auto" style={{maxWidth: inputWidth}}>
                    <p className="m-0 funnel-step-name">{stage.name}</p>
                  </div>
                  <p className="m-0 funnel-step-value">{funnelData?.[stage.uuid]?.starters?.toLocaleString() || 'N/A'}</p>
                </>}
            </div>
          </div>
        </span>
      </td>
      {isEditing ? <td className="fields-column" colSpan={3}>
        <Select
          id="fields-select"
          styles={{
            multiValue: (provided, state) => ({...provided,
              background:'#ebedf2',
            }),
            multiValueLabel: (provided, state) => ({...provided,
              color: '#575962',
              fontSize: '1rem',
            }),
            control: (styles, state) => ({...styles,
              paddingTop: '2px',
              paddingBottom: '2px',
              border: (hasEditedFields && !editedFields.length) ?  '1px solid #dc3545' : '1px solid #EBEDF2',
              boxShadow: (hasEditedFields && !editedFields.length) ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)' : null,
            }),
            valueContainer: (styles, state) => ({...styles,
              paddingTop: '0px',
              paddingBottom: '0px',
            }),
            dropdownIndicator: (styles, state) => ({...styles,
              padding: '6px 8px',
              cursor: 'pointer',
              transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
              transition: 'transform .5s ease',
            }),
            clearIndicator: (styles, state) => ({...styles,
              padding: '6px 8px',
              '&:hover': {color: '#DE350B'}
            }),
            option: (styles, state) => ({...styles,
              color: state.isDisabled ? '#9e9e9e' : '#3F4047',
              backgroundColor: (state.isDisabled && !trackedFieldsLoading) ? '#f9f9f9' : null,
              '&:hover': {backgroundColor: state.isFocused && '#F4F5F8'}
            }),
            menu: (styles, state) => ({...styles,
              marginTop: '1px',
              borderRadius: '4px',
              border: '1px solid #EBEDF2',
              boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
            }),
            menuList: (styles, state) => ({...styles,
              maxHeight: '200px',
            }),
          }}
          components={{
            Option: (props => {
              const { htmlTagName, htmlType, htmlName, htmlId, label, value } = props.data;
              const fieldInStage = stages?.filter(s => s.uuid && (s.uuid === fieldsLookUp?.[value]?.stageUuid))?.[0];
              const identifierTooLong = value?.length > 255;

              return <components.Option {...props}
                innerProps={{...props.innerProps,
                  ...props.data.isMerged ? {title: 'Merged Field'} : {title: `HTML Tag Name: ${htmlTagName}\nHTML Type: ${htmlType}\nHTML Name: ${htmlName}\nHTML ID: ${htmlId}`}}}
                className={`${!trackedFieldsLoading ? 'd-flex justify-content-between' : ''}`}>
                {label}
                {!trackedFieldsLoading && fieldInStage && <p className="stage-name-badge mb-0"><span className="option-badge">{fieldInStage?.name}</span></p>}
                {identifierTooLong && <p className="stage-name-badge mb-0"><span className="option-not-selectable">Too long - Cannot be added</span></p>}
              </components.Option>;
            }),
            MultiValueContainer: (props => {
              let HTMLAttrs;
              let isMerged;
              try {
                HTMLAttrs = JSON.parse(props.data.identifier);
              } catch (e) {
                isMerged = true;
              }

              return <components.MultiValueContainer {...props}
                innerProps={{...props.innerProps,
                  ...isMerged ? {title: 'Merged Field'} : HTMLAttrs ? {title: `HTML Tag Name: ${HTMLAttrs[0]}\nHTML Type: ${HTMLAttrs[1]}\nHTML Name: ${HTMLAttrs[2]}\nHTML ID: ${HTMLAttrs[3]}`} : null }}
              />;
            }),
          }}
          options={trackedFieldsLoading ? [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}] :
            trackedFieldsError ? [] : (trackedFields?.length > 0  && fieldsLookUp) ? trackedFields.filter(f => !f.hidden).map(field => {
              return {
                ...field,
                selectable: (fieldsLookUp?.[field.identifier]?.stageUuid || field.identifier.length > 255) ? false : true,
                value: field.identifier,
                label: labelForField(field),
              };
            }) : []}
          onChange={(attrs) => {
            setHasEditedFields(true);
            setEditedFields(attrs?.map(({identifier}) => identifier) || []);

            // Update field stages across the available fields
            editFieldsStages({stageUuid: stage.uuid, fieldIdentifiers: attrs?.map(({identifier}) => identifier) || []});
          }}
          placeholder={!trackedFieldsLoading && 'Select fields...'}
          value={fieldsLookUp && editedFields?.map((identifier) => {
            let field = fieldsLookUp[identifier];
            if (!field) { // This field is likely to have been merged, so is no longer in the fieldsLookUp with its unmerged identifier.
              field = {
                ...htmlAttrsForIdentifier(identifier)
              };
            }

            return {
              identifier,
              value: identifier,
              label: labelForField(field),
            };
          })}
          isMulti={true}
          isOptionDisabled={(option, arr) => {
            if (option.hasOwnProperty('selectable')) return !option.selectable;
            return false;
          }}
          closeMenuOnSelect={false}
          isLoading={trackedFieldsLoading}
          onMenuClose={() => {setMenuOpenAtIndex(null); saveStage({stage: {...stage, name: editedName, fieldIdentifiers: editedFields}, stageIndex});}}
          onMenuOpen={() => setMenuOpenAtIndex(stageIndex)} // To increase table padding when bottom or second-to-bottom menus are open, and so avoid a scroll within a scroll
        />
      </td> : <>
        <td className="metric-value">{abandons?.toLocaleString() || 'N/A'}</td>
        <td className="metric-value">{(typeof progressionRate === 'number') ? `${(Math.round(((progressionRate)* 100) * 100) / 100).toLocaleString()}%` : 'N/A'}</td>
        <td className="metric-value">{(typeof meanDuration === 'number') ? formatMillisecondsToTimeString(meanDuration) : 'N/A'}</td>
      </>}
      <td className="actions-column">
        {isEditing && <>
          <OverlayTrigger placement="top" trigger="click" show={isConfirmingDelete} overlay={<Popover data-testid={`delete-conf-${stageIndex}`} id="delete-stage-tooltip">
            <Popover.Body>
              <p className="mb-1">Are you sure you would like to delete {editedName}?</p>
              <Button variant="outline-secondary" className="cancel me-1" onClick={() => setIsConfirmingDelete(false)}>Cancel</Button>
              <Button variant="outline-danger" className="ms-1 me-0" onClick={() => {deleteStage({stage, stageIndex}); setIsConfirmingDelete(false);}} >Yes, Delete</Button>
            </Popover.Body>
          </Popover>}>
            <span className={`trash-wrap ${(stages.length === 1 && !stage.uuid) ? 'disabled' : ''}`}>
              <VscTrash size="18px" className={`m-1 editing ${(stages.length === 1 && !stage.uuid) ? 'disabled' : isConfirmingDelete ? 'keep-icon-hover' : ''}`}
                onClick={() => setIsConfirmingDelete(!isConfirmingDelete)} data-testid={`delete-stage-${stageIndex}`} title="Delete stage"/>
            </span>
          </OverlayTrigger>
        </>}
      </td>
    </tr>);
};

export default FunnelStage;
