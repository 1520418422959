import React from 'react';
import { StyleSheet, pdf, Document, Page, Text, View, Image, Link as PDFLink, Font } from '@react-pdf/renderer';
import ZukoLargeLogo from '../images/ZukoLargeLogo.png';
import { saveAs } from 'file-saver';
import { formatSecondsToTimeString, formatMillisecondsToTimeString } from '../utils';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf', fontWeight: 300 },
  ],
});

const pdfStyles = StyleSheet.create({
  page: {
    backgroundColor: '#fafafa',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    paddingRight: 8,
    fontFamily: 'Open Sans',
  },
  logoImage: {
    width: 150,
    height: 'auto',
    fontWeight: 600,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
  },
  fullWidthCol: {
    flex: '0 0 100%',
    maxWidth: '100%',
  },
  halfWidthCol: {
    flex: '0 0 50%',
    maxWidth: '50%',
  },
  thirdWidthCol: {
    flex: '0 0 33%',
    maxWidth: '33%',
  },
  quarterWidthCol: {
    flex: '0 0 25%',
    maxWidth: '25%',
  },
  logoTextCol: {
    color: '#50355e',
    fontSize: 23,
    marginTop: 7,
  },
  card: {
    backgroundColor: 'white',
    wordWrap:' break-word',
    borderRadius: 3,
    padding: 4,
    border: '1 solid #ebedf2',
  },
  gridCard: {
    maxHeight: 220,
  },
  cardTitle: {
    color: '#888b96',
    fontSize: 13,
    fontWeight: 300,
  },
  orgTitleText: {
    fontSize: 12,
    color: '#575962',
  },
  queryText: {
    fontSize: 10,
    color: '#575962',
    marginTop: 5,
    paddingRight: 5,
  },
  filtersText: {
    fontSize: 10,
    color: '#575962',
    backgroundColor: '#ebedf2',
    padding: 5,
    borderRadius: 3,
    paddingRight: 5,
  },
  segmentFiltersText: {
    top: 0,
    bottom:0,
    left: 0,
    right: 0,
    fontSize: 8,
    color: '#575962',
    backgroundColor: '#ebedf2',
    padding: 5,
    borderRadius: 3,
  },
  link: {
    color: '#5867dd',
  },
  noDataText: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    color: '#888b96',
  },
  statsRow: {
    color: '#888b96',
  },
  statsCol: {
    justifyContent: 'center',
    textAlign: 'center',
    paddingBottom: 0,
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
  },
  statText: {
    padding: 1,
    fontSize: 7,
  },
  statCurrent: {
    padding: 1,
    fontSize: 9,
  },
  table: {
    display: 'table',
    width: 'auto',
    fontSize: 6,
    textAlign: 'center',
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableHeaderRow: {
    backgroundColor: '#f5f5f5',
    borderBottom: '1 solid #bdc5cd',
    borderTop: '1 solid #c4c6cf',
  },
  topTableHeaderRow: {
    backgroundColor: '#f5f5f5',
    borderTop: '1 solid #c4c6cf',
  },
  topTableHeaderCol: {
    borderRight: '1 solid #c4c6cf',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#c4c6cf',
  },
  firstCol: {
    borderLeft: '1 solid #c4c6cf',
  },
  tableCell: {
    height: 20,
  },
  tableHeaderCell: {
    paddingTop: 5, // To vertically center the text in the cell
    fontWeight: 600,
  },
  maxLabelsMsg: {
    marginLeft: 5,
    fontSize: 7,
    fontWeight: 600,
    color: '#6C6F7A',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  submitField: {
    fontSize: 8,
    color: '#888b96',
    border: '1 solid #c4c6cf',
    borderRadius: 3,
    paddingTop: 3,
    paddingBottom: 2,
    paddingLeft: 3,
    paddingRight: 3,
  },
  selectedAbandonedField: {
    fontSize: 8,
    color: '#888b96',
    border: '1 solid #c4c6cf',
    borderRadius: 3,
    paddingTop: 3,
    paddingBottom: 2,
    paddingLeft: 3,
    paddingRight: 3,
  },
  legendColorBar: {
    padding: 3,
    marginRight: 1,
    marginLeft: 1,
    marginTop: 2,
    marginBottom: 2,
  },
  legendFont: {
    fontSize: 8,
    color: '#888b96',
  },
  abandonedSignificantDiff: {
    backgroundColor: '#f9d2d4',
  },
  completedSignificantDiff: {
    backgroundColor: '#bcdaf6',
  },
  sampleInfo: {
    marginLeft: 5,
    fontSize: 7,
    color: '#6C6F7A',
    fontWeight: 600,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  sampleMsg: {
    fontSize: 7,
    color: '#6C6F7A',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  flowField: {
    fontSize: 8,
    color: '#888b96',
    border: '1 solid #c4c6cf',
    borderRadius: 3,
    paddingTop: 3,
    paddingBottom: 2,
    paddingLeft: 3,
    paddingRight: 3,
  },
  abandonedCell: {
    backgroundColor: '#f3cfd1',
  },
  completedCell: {
    backgroundColor: '#ccecd1',
  },
  metricTitle: {
    fontSize: 8,
    marginTop: 5,
    marginBottom: 2,
  },
  metric: {
    fontWeight: 600,
    fontSize: 10,
  },
  comparisonMetric: {
    fontSize: 8,
    color: '#888b96',
    border: '1 solid #c4c6cf',
    borderRadius: 3,
    paddingTop: 3,
    paddingBottom: 2,
    paddingLeft: 3,
    paddingRight: 3,
  },
});

export const maxLabels = 65; // Reasonable max number of horizontal bar chart labels to display on a single A4 page in the Pdf export

export const maxPortraitSegmentsPDF = 5;

export const generatePdfDownload = async ({
  page,
  title,
  orgName,
  form, time, filters,
  selectedComparison,
  comparison,
  queryString,
  data}) => {

  const {
    // Form Data
    vscaChartImgUrl, vscaSplitByChartImgUrl, vscaData, vscaSplitByAttrData, vscRatesChartImgUrl, avgFieldReturnsChartImgUrl, customEventsTotalData, customEventsTotalChartImgUrl, medianDurationChartImgUrl,
    avgFieldReturnsData, medianDurationData, vscRatesData, customEventsOverviewData, failedSubmissionsChartImgUrl, submitField,
    // Field Data
    fieldsOverviewTableData, abandonedFieldsOverviewVisData,
    fieldAbandonsChartImgUrl, selectedAbandonedField, totalFieldReturnsChartImgUrl, totalFieldReturnsData,
    fieldReturnsOverviewData, fieldTimesOverviewData,
    // Field Flow
    flowChartImgUrl, fieldFlow, samplePercent, totalSessions, sampleSize, flowField,
    // Segment Comparison
    comparisonChartImgUrl, timeZone, allSegmentsStats, segments, metric,
    // Field Segment Comparison
    fieldSegmentTableRows, fieldSegments, metric: fieldSegmentMetric,
  } = data;

  // Datatable cell formatters
  const formatCellToPercent = (cell) => (cell.value * 100).toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';
  const formatCellToStrWithPercent = (cell) => (cell.value === null || cell.value === undefined) ? cell.value : cell.value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';
  const formatCellToSeconds = (cell) => (cell.value === null || cell.value === undefined || cell.value === 'N/A') ? cell.value : formatMillisecondsToTimeString(cell.value);

  const layoutFormData = () => <>
    <View style={[pdfStyles.row]}>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol, {paddingTop: 0}]}>
        <View style={[pdfStyles.card, {maxHeight: 280}]}>
          <Text style={pdfStyles.cardTitle}>Views, Starters, Completions & Abandons</Text>
          {vscaChartImgUrl ? <Image allowDangerousPaths src={vscaChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
          {vscaData && vscaData.stats &&
         <View style={[pdfStyles.row, pdfStyles.statsRow]}>
           <View style={[pdfStyles.col, pdfStyles.quarterWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Views</Text>
             <Text style={[pdfStyles.statCurrent]}>{vscaData.stats.views.current}</Text>
             <Text style={[pdfStyles.statText]}>{vscaData.stats.views.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.quarterWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Starters</Text>
             <Text style={[pdfStyles.statCurrent]}>{vscaData.stats.starters.current}</Text>
             <Text style={[pdfStyles.statText]}>{vscaData.stats.starters.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.quarterWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Completions</Text>
             <Text style={[pdfStyles.statCurrent]}>{vscaData.stats.completions.current}</Text>
             <Text style={[pdfStyles.statText]}>{vscaData.stats.completions.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.quarterWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Abandons</Text>
             <Text style={[pdfStyles.statCurrent]}>{vscaData.stats.abandons.current}</Text>
             <Text style={[pdfStyles.statText]}>{vscaData.stats.abandons.percentDiff}</Text>
           </View>
         </View>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]} wrap={false}>
      <View style={[pdfStyles.col, pdfStyles.halfWidthCol]}>
        <View style={[pdfStyles.card, pdfStyles.gridCard]}>
          <Text style={[pdfStyles.cardTitle, {textAlign: 'center', paddingLeft: 5, paddingRight: 5}]}>Views, Starters, Completions & Abandons, Split By Attribute (Stacked)</Text>
          {vscaSplitByChartImgUrl ? <Image allowDangerousPaths src={vscaSplitByChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
          {vscaSplitByAttrData && vscaSplitByAttrData.stats && vscaSplitByAttrData.stats.total &&
         <View style={[pdfStyles.row, pdfStyles.statsRow]}>
           <View style={[pdfStyles.col, pdfStyles.fullWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>{vscaSplitByAttrData.stats.total.label}</Text>
             <Text style={[pdfStyles.statCurrent]}>{vscaSplitByAttrData.stats.total.current}</Text>
             <Text style={[pdfStyles.statText]}>{vscaSplitByAttrData.stats.total.percentDiff}</Text>
           </View>
         </View>}
        </View>
      </View>
      <View style={[pdfStyles.col, pdfStyles.halfWidthCol]}>
        <View style={[pdfStyles.card, pdfStyles.gridCard]}>
          <Text style={[pdfStyles.cardTitle, {textAlign: 'center'}]}>View, Starter & Completion Rates</Text>
          {vscRatesChartImgUrl ? <Image allowDangerousPaths src={vscRatesChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
          {vscRatesData && vscRatesData.stats &&
         <View style={[pdfStyles.row, pdfStyles.statsRow]}>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>View to Starter</Text>
             <Text style={[pdfStyles.statCurrent]}>{vscRatesData.stats.viewsStarters.current}</Text>
             <Text style={[pdfStyles.statText]}>{vscRatesData.stats.viewsStarters.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>View to Completion</Text>
             <Text style={[pdfStyles.statCurrent]}>{vscRatesData.stats.viewsCompletions.current}</Text>
             <Text style={[pdfStyles.statText]}>{vscRatesData.stats.viewsCompletions.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Starter to Completion</Text>
             <Text style={[pdfStyles.statCurrent]}>{vscRatesData.stats.startersCompletions.current}</Text>
             <Text style={[pdfStyles.statText]}>{vscRatesData.stats.startersCompletions.percentDiff}</Text>
           </View>
         </View>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]} wrap={false}>
      <View style={[pdfStyles.col, pdfStyles.halfWidthCol]}>
        <View style={[pdfStyles.card, pdfStyles.gridCard]}>
          <Text style={[pdfStyles.cardTitle, {textAlign: 'center'}]}>Median Session Duration</Text>
          {medianDurationChartImgUrl ? <Image allowDangerousPaths src={medianDurationChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
          {medianDurationData && medianDurationData.stats &&
         <View style={[pdfStyles.row, pdfStyles.statsRow]}>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Started Duration</Text>
             <Text style={[pdfStyles.statCurrent]}>{medianDurationData.stats.total.current !== 'N/A' ? formatSecondsToTimeString(medianDurationData.stats.total.current) : medianDurationData.stats.total.current}</Text>
             <Text style={[pdfStyles.statText]}>{medianDurationData.stats.total.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Abandon Duration</Text>
             <Text style={[pdfStyles.statCurrent]}>{medianDurationData.stats.abandoned.current !== 'N/A' ? formatSecondsToTimeString(medianDurationData.stats.abandoned.current) : medianDurationData.stats.abandoned.current}</Text>
             <Text style={[pdfStyles.statText]}>{medianDurationData.stats.abandoned.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Completion Duration</Text>
             <Text style={[pdfStyles.statCurrent]}>{medianDurationData.stats.completed.current !== 'N/A' ? formatSecondsToTimeString(medianDurationData.stats.completed.current) : medianDurationData.stats.completed.current}</Text>
             <Text style={[pdfStyles.statText]}>{medianDurationData.stats.completed.percentDiff}</Text>
           </View>
         </View>}
        </View>
      </View>
      <View style={[pdfStyles.col, pdfStyles.halfWidthCol]}>
        <View style={[pdfStyles.card, pdfStyles.gridCard]}>
          <Text style={[pdfStyles.cardTitle, {textAlign: 'center'}]}>Average Field Returns</Text>
          {avgFieldReturnsChartImgUrl ? <Image allowDangerousPaths src={avgFieldReturnsChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
          {avgFieldReturnsData && avgFieldReturnsData.stats &&
         <View style={[pdfStyles.row, pdfStyles.statsRow]}>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Started Returns</Text>
             <Text style={[pdfStyles.statCurrent]}>{avgFieldReturnsData.stats.total.current}</Text>
             <Text style={[pdfStyles.statText]}>{avgFieldReturnsData.stats.total.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Abandon Returns</Text>
             <Text style={[pdfStyles.statCurrent]}>{avgFieldReturnsData.stats.abandoned.current}</Text>
             <Text style={[pdfStyles.statText]}>{avgFieldReturnsData.stats.abandoned.percentDiff}</Text>
           </View>
           <View style={[pdfStyles.col, pdfStyles.thirdWidthCol, pdfStyles.statsCol]}>
             <Text style={[pdfStyles.statText]}>Completion Returns</Text>
             <Text style={[pdfStyles.statCurrent]}>{avgFieldReturnsData.stats.completed.current}</Text>
             <Text style={[pdfStyles.statText]}>{avgFieldReturnsData.stats.completed.percentDiff}</Text>
           </View>
         </View>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row, {marginTop: 15}]}>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <Text style={pdfStyles.cardTitle}>Failed Submissions Over Time</Text>
          <View style={[pdfStyles.row, {justifyContent: 'center', alignItems: 'center', paddingTop: 5}]}>
            {submitField && submitField.label && <Text style={[pdfStyles.submitField]}>{submitField.label}</Text>}
          </View>
          {failedSubmissionsChartImgUrl ? <Image allowDangerousPaths src={failedSubmissionsChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]}>
      {customEventsTotalData && customEventsTotalData.labels && customEventsTotalData.labels.length > 0 &&
      // Force a new page if there are custom events. NB. if we use this flag on the row above the chart bumps up onto the first page, so it needs to be separate.
     <View wrap={false}></View>
      }
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <View style={[pdfStyles.row]}>
            <Text style={[pdfStyles.cardTitle]}>Custom Events Total</Text>
            {customEventsTotalData && customEventsTotalData.labels && customEventsTotalData.labels.length > 65 &&
             <View style={{display: 'flex', flexDirection: 'row'}}>
               <Text style={[pdfStyles.maxLabelsMsg]}>Max {maxLabels} events displayed</Text>
             </View>}
          </View>
          {customEventsTotalChartImgUrl ? <Image allowDangerousPaths src={customEventsTotalChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]}>
      {customEventsTotalData && customEventsTotalData.labels && customEventsTotalData.labels.length > 50 &&
     <View wrap={false}></View>
      }
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <Text style={[pdfStyles.cardTitle]}>Custom Events Overview</Text>
          {customEventsOverviewData && customEventsOverviewData.data ?
            <View style={[pdfStyles.table, {padding: 2}]}>
              <View style={[pdfStyles.tableRow, pdfStyles.topTableHeaderRow]} fixed>
                <View style={[pdfStyles.topTableHeaderCol, pdfStyles.firstCol, {width: '37%'}]}>
                  <Text style={pdfStyles.tableCell}></Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '21%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Total Count</Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '21%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>% Sessions Occurred In</Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '21%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Mean Times Occurred</Text>
                </View>
              </View>
              <View style={[pdfStyles.tableRow, pdfStyles.tableHeaderRow]} fixed>
                <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '37%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Event</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>All</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandoned</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Completed</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>All</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandoned</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Completed</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>All</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandoned</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Completed</Text>
                </View>
              </View>
              {({...customEventsOverviewData}).data.sort((a,b) => {
                return (a.combinedCount < b.combinedCount) - (a.combinedCount > b.combinedCount);
              }).map((r, i) =>
                <View style={pdfStyles.tableRow} key={`event-${i}`}>
                  <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '37%'}]}>
                    <Text style={[pdfStyles.tableCell, {textAlign: 'left', paddingLeft: 2}]}>{r.eventType.replace(/(\n|\t)/gm,'')}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.combinedCount.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.abandonedCount.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.completedCount.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToPercent({value: r.combinedProportion})}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToPercent({value: r.abandonedProportion})}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToPercent({value: r.completedProportion})}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.combinedMeanTimesPerSession.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.abandonedMeanTimesPerSession.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.completedMeanTimesPerSession.toLocaleString()}</Text>
                  </View>
                </View>
              )}
            </View> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
  </>;

  const layoutFieldData = () => <>
    <View style={[pdfStyles.row]}>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <Text style={[pdfStyles.cardTitle]}>Fields Overview</Text>
          {fieldsOverviewTableData ?
            <View style={[pdfStyles.table, {padding: 2}]}>
              <View style={[pdfStyles.tableRow, pdfStyles.tableHeaderRow]} fixed>
                <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '23%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Label</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '11%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Sessions Interacted</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '10%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandon Rate</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '12%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>% of Total Abandons</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '11%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Return Rate</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '9%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Mean Returns</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '11%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Mean Time Spent</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '13%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Behaviour Difference</Text>
                </View>
              </View>
              {[...fieldsOverviewTableData]
                .sort((a,b) => b.allSessionsTotal - a.allSessionsTotal)
                .map((r, i) =>
                  <View style={pdfStyles.tableRow} key={`a-field-${i}`}>
                    <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '23%'}]}>
                      <Text style={pdfStyles.tableCell}>{r.label}</Text>
                    </View>
                    <View style={[pdfStyles.tableCol, {width: '11%'}]}>
                      <Text style={pdfStyles.tableCell}>{r.allSessionsTotal.toLocaleString()}</Text>
                    </View>
                    <View style={[pdfStyles.tableCol, {width: '10%'}]}>
                      <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.abandonRate})}</Text>
                    </View>
                    <View style={[pdfStyles.tableCol, {width: '12%'}]}>
                      <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.percentOfAbandons})}</Text>
                    </View>
                    <View style={[pdfStyles.tableCol, {width: '11%'}]}>
                      <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.allSessionsReturned})}</Text>
                    </View>
                    <View style={[pdfStyles.tableCol, {width: '9%'}]}>
                      <Text style={pdfStyles.tableCell}>{r.allMeanRtf.toLocaleString()}</Text>
                    </View>
                    <View style={[pdfStyles.tableCol, {width: '11%'}]}>
                      <Text style={pdfStyles.tableCell}>{formatCellToSeconds({value: r.allMeanTif})}</Text>
                    </View>
                    <View style={[pdfStyles.tableCol, {width: '13%'}]}>
                      <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.differenceInReturnRate})}</Text>
                    </View>
                  </View>
                )}
            </View> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]}>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <Text style={[pdfStyles.cardTitle]}>Abandoned Fields Overview</Text>
          {abandonedFieldsOverviewVisData ?
            <View style={[pdfStyles.table, {padding: 2}]}>
              <View style={[pdfStyles.tableRow, pdfStyles.tableHeaderRow]} fixed>
                <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '5%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Order</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '41%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Label</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandon Count</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>% of Total Abandons</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandon Rate</Text>
                </View>
              </View>
              {abandonedFieldsOverviewVisData.map((r, i) =>
                <View style={pdfStyles.tableRow} key={`a-field-${i}`}>
                  <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '5%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.fieldOrder}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '41%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.label}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.abandonCount.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.percentOfAbandons})}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.abandonRate})}</Text>
                  </View>
                </View>
              )}
            </View> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]}>
      <View wrap={false}></View>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <Text style={pdfStyles.cardTitle}>Field Abandons Over Time</Text>
          {fieldAbandonsChartImgUrl ? <View>
            <View style={[pdfStyles.row, {justifyContent: 'center', alignItems: 'center'}]}>
              {selectedAbandonedField && selectedAbandonedField.label && <Text style={[pdfStyles.selectedAbandonedField]}>{selectedAbandonedField.label}</Text>}
            </View>
            <Image allowDangerousPaths src={fieldAbandonsChartImgUrl}></Image></View> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]}>
      <View wrap={false}></View>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <Text style={pdfStyles.cardTitle}>Total Field Returns</Text>
          {totalFieldReturnsData && totalFieldReturnsData.labels && totalFieldReturnsData.labels.length > 65 &&
             <View style={{display: 'flex', flexDirection: 'row'}}>
               <Text style={[pdfStyles.maxLabelsMsg]}>Max {maxLabels} fields displayed</Text>
             </View>}
          {totalFieldReturnsChartImgUrl ? <Image allowDangerousPaths src={totalFieldReturnsChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]}>
      {totalFieldReturnsData && totalFieldReturnsData.labels && totalFieldReturnsData.labels.length > 50 &&
              <View wrap={false}></View>
      }
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <Text style={[pdfStyles.cardTitle]}>Field Returns Overview</Text>
          {fieldReturnsOverviewData && fieldReturnsOverviewData.some(r => r.significantDiff) &&
                  <View style={[{display:'flex',flexDirection: 'row', justifyContent: 'center'}]}>
                    <View style={[pdfStyles.legendColorBar, {backgroundColor: '#f9b2b6'}]}></View>
                    <Text style={pdfStyles.legendFont}>Abandoned sessions return to this field significantly more</Text>
                    <View style={[pdfStyles.legendColorBar, {backgroundColor: '#91c2ef', marginLeft: 3}]}></View>
                    <Text style={pdfStyles.legendFont}>Completed sessions return to this field significantly more</Text>
                  </View>}
          {fieldReturnsOverviewData ?
            <View style={[pdfStyles.table, {padding: 2}]}>
              <View style={[pdfStyles.tableRow, pdfStyles.topTableHeaderRow]} fixed>
                <View style={[pdfStyles.topTableHeaderCol, pdfStyles.firstCol, {width: '5%'}]}>
                  <Text style={pdfStyles.tableCell}></Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '36%'}]}>
                  <Text style={pdfStyles.tableCell}></Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '21%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Number of Sessions Interacted</Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '19%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>% Sessions Returned</Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '19%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Mean Returns to Field</Text>
                </View>
              </View>
              <View style={[pdfStyles.tableRow, pdfStyles.tableHeaderRow]} fixed>
                <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '5%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Order</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '36%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Label</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>All</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandoned</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Completed</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>All</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandoned</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Completed</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>All</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandoned</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Completed</Text>
                </View>
              </View>
              {fieldReturnsOverviewData.map((r, i) =>
                <View style={pdfStyles.tableRow} key={`field-returns-${i}`}>
                  <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '5%'}]}>
                    <Text style={[pdfStyles.tableCell]}>{r.fieldOrder}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '36%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.label}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.allSessionsTotal.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.abandonedSessionsTotal.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.completedSessionsTotal.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.allSessionsReturned})}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                    {r.significantDiff && r.zScore > 0 ?
                      <Text style={[pdfStyles.tableCell, pdfStyles.abandonedSignificantDiff]}>{formatCellToStrWithPercent({value: r.abandonedSessionsReturned})}</Text> :
                      <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.abandonedSessionsReturned})}</Text>}
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                    {r.significantDiff && r.zScore < 0 ?
                      <Text style={[pdfStyles.completedSignificantDiff, pdfStyles.tableCell]}>{formatCellToStrWithPercent({value: r.completedSessionsReturned})}</Text> :
                      <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.completedSessionsReturned})}</Text>}
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '5%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.allMeanRtf.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.abandonedMeanRtf.toLocaleString()}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '7%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.completedMeanRtf.toLocaleString()}</Text>
                  </View>
                </View>
              )}
            </View> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
    <View style={[pdfStyles.row]}>
      {fieldReturnsOverviewData && fieldReturnsOverviewData.length > 50 &&
              <View wrap={false}></View>
      }
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          <Text style={[pdfStyles.cardTitle]}>Field Times Overview</Text>
          {fieldTimesOverviewData ?
            <View style={[pdfStyles.table, {padding: 2}]}>
              <View style={[pdfStyles.tableRow, pdfStyles.topTableHeaderRow]} fixed>
                <View style={[pdfStyles.topTableHeaderCol, pdfStyles.firstCol, {width: '5%'}]}>
                  <Text style={pdfStyles.tableCell}></Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '41%'}]}>
                  <Text style={pdfStyles.tableCell}></Text>
                </View>
                <View style={[pdfStyles.topTableHeaderCol, {width: '54%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Mean Time Spent in Field</Text>
                </View>
              </View>
              <View style={[pdfStyles.tableRow, pdfStyles.tableHeaderRow]} fixed>
                <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '5%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Order</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '41%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Label</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>All</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Abandoned</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Completed</Text>
                </View>
              </View>
              {fieldTimesOverviewData.map((r, i) =>
                <View style={pdfStyles.tableRow} key={`field-times-${i}`}>
                  <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '5%'}]}>
                    <Text style={[pdfStyles.tableCell]}>{r.fieldOrder}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '41%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.label}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToSeconds({value: r.allMeanTif})}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToSeconds({value: r.abandonedMeanTif})}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '18%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatCellToSeconds({value: r.completedMeanTif})}</Text>
                  </View>
                </View>
              )}
            </View> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
        </View>
      </View>
    </View>
  </>;

  const layoutFieldFlow = () => <>
    <View style={[pdfStyles.row]}>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol, {paddingTop: 0}]}>
        <View style={[pdfStyles.card]}>
          <View style={[pdfStyles.row]}>
            <Text style={pdfStyles.cardTitle}>Field Flow</Text>
            {fieldFlow?.chart && fieldFlow.chart.data && samplePercent < 100 &&
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                      <Text style={[pdfStyles.sampleInfo]}>Sampled dataset - </Text>
                      <Text style={[pdfStyles.sampleMsg]}>{samplePercent}% of all sessions that have interacted with the selected field. (Total sessions: {totalSessions.toLocaleString()}. Sample size: {sampleSize.toLocaleString()}).</Text>
                    </View>}
            {fieldFlow?.chart && fieldFlow?.chart.data && samplePercent === 100 && <Text style={[pdfStyles.sampleInfo]}>Full dataset</Text>}
          </View>
          <View style={[pdfStyles.row, {justifyContent: 'center', alignItems: 'center', paddingTop: 5}]}>
            {flowField && flowField.label && <Text style={[pdfStyles.flowField]}>{flowField.label}</Text>}
          </View>
          {flowChartImgUrl ? <Image allowDangerousPaths src={flowChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
          {fieldFlow?.table && fieldFlow.table.data &&
                  <View style={[pdfStyles.table, {paddingTop: 10, paddingLeft: 2, paddingRight: 2, paddingBottom: 2}]}>
                    <View style={[pdfStyles.tableRow, pdfStyles.tableHeaderRow]} fixed>
                      <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '5%'}]}>
                        <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Order</Text>
                      </View>
                      <View style={[pdfStyles.tableCol, {width: '45%'}]}>
                        <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Label</Text>
                      </View>
                      <View style={[pdfStyles.tableCol, {width: '25%'}]}>
                        <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Interaction Count</Text>
                      </View>
                      <View style={[pdfStyles.tableCol, {width: '25%'}]}>
                        <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>% of Total Interactions</Text>
                      </View>
                    </View>
                    {fieldFlow?.table.data.map((r, i) =>
                      <View style={pdfStyles.tableRow} key={`field-${i}`}>
                        <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '5%'}]}>
                          <Text style={pdfStyles.tableCell}>{r.order}</Text>
                        </View>
                        <View style={[pdfStyles.tableCol, {width: '45%'}]}>
                          {r.type === 'session_state' && r.label === 'Abandoned' ?
                            <Text style={pdfStyles.tableCell}><View style={[pdfStyles.abandonedCell]}>{r.label}</View></Text> :
                            r.type === 'session_state' && r.label === 'Completed' ?
                              <Text style={[pdfStyles.tableCell]}><View style={[pdfStyles.completedCell]}>{r.label}</View></Text> : <Text style={pdfStyles.tableCell}>{r.label}</Text>}
                        </View>
                        <View style={[pdfStyles.tableCol, {width: '25%'}]}>
                          <Text style={pdfStyles.tableCell}>{r.flow.toLocaleString()}</Text>
                        </View>
                        <View style={[pdfStyles.tableCol, {width: '25%'}]}>
                          <Text style={pdfStyles.tableCell}>{formatCellToStrWithPercent({value: r.percentOfFlows})}</Text>
                        </View>
                      </View>
                    )}
                  </View>}
        </View>
      </View>
    </View>
  </>;

  const layoutSegmentComparison = () => <>
    <View style={[pdfStyles.row]}>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol, {paddingTop: 0}]}>
        <View style={[pdfStyles.card]}>
          {metric && <>
            <Text style={pdfStyles.cardTitle}>Segments Over Time</Text>
            <View style={[pdfStyles.row, {justifyContent: 'space-between', alignItems: 'center', paddingTop: 5}]}>
              {time?.start && time?.end && timeZone &&
                    <Text style={pdfStyles.queryText}>{time.start.tz(timeZone).format('ll HH:mm')} - {time.end.tz(timeZone).format('ll HH:mm')} {timeZone}</Text>}
              <Text style={[pdfStyles.comparisonMetric]}>{metric}</Text>
            </View>
          </>}
          {!metric &&
            <View style={[pdfStyles.row]}>
              {time?.start && time?.end && timeZone &&
                      <Text style={pdfStyles.queryText}>{time.start.tz(timeZone).format('ll HH:mm')} - {time.end.tz(timeZone).format('ll HH:mm')} {timeZone}</Text>}
            </View>}
          {comparisonChartImgUrl ? <Image allowDangerousPaths src={comparisonChartImgUrl}></Image> : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
          {segments && segments.length > 0 && allSegmentsStats && Object.keys(allSegmentsStats).length > 0 &&
                  <View style={[pdfStyles.table, {padding: 2, marginLeft: segments && segments.length > maxPortraitSegmentsPDF ? 30 : 15}]}>
                    <View style={pdfStyles.tableRow}>
                      {segments.map((segment, i) =>
                        <View style={[{paddingLeft:2, paddingRight:2, width: `'${100 / segments.length}%'`}]} key={`segment-${i}`}>
                          <View style={{minHeight: 105}}>
                            <Text style={[pdfStyles.metricTitle]}>Form</Text>
                            <Text style={[pdfStyles.metric]}>{segment.form.label}</Text>
                            <Text style={[pdfStyles.metricTitle]}>Filters</Text>
                            {segment.filters.length > 0 ? segment.filters.map(f =>
                              <Text key={f.value} style={[pdfStyles.segmentFiltersText, {marginBottom:1}]}>{f.key}: {f.value}</Text>) : <Text style={{fontSize: 10}}>none</Text>}
                          </View>
                          <View style={{marginLeft: 3, marginRight: 3, borderBottom: '1 dashed grey'}}></View>
                          {allSegmentsStats[segment.uuid] && <>
                            <View wrap={false}>
                              <Text style={[pdfStyles.metricTitle]}>Views</Text>
                              <Text style={[pdfStyles.metric]}>{allSegmentsStats[segment.uuid].views.toLocaleString()}</Text>
                            </View>
                            <View wrap={false}>
                              <Text style={[pdfStyles.metricTitle]}>View to Starter Rate</Text>
                              <Text style={[pdfStyles.metric]}>{allSegmentsStats[segment.uuid].viewToStarterRate === null ? 'N/A' : `${allSegmentsStats[segment.uuid].viewToStarterRate}%`}</Text>
                            </View>
                            <View wrap={false}>
                              <Text style={[pdfStyles.metricTitle]}>Starters</Text>
                              <Text style={[pdfStyles.metric]}>{allSegmentsStats[segment.uuid].starters.toLocaleString()}</Text>
                            </View>
                            <View wrap={false}>
                              <Text style={[pdfStyles.metricTitle]}>Starter to Completion Rate</Text>
                              <Text style={[pdfStyles.metric]}>{allSegmentsStats[segment.uuid].starterToCompletionRate === null ? 'N/A' : `${allSegmentsStats[segment.uuid].starterToCompletionRate}%`}</Text>
                            </View>
                            <View wrap={false}>
                              <Text style={[pdfStyles.metricTitle]}>Completions</Text>
                              <Text style={[pdfStyles.metric]}>{allSegmentsStats[segment.uuid].completions.toLocaleString()}</Text>
                            </View>
                            <View wrap={false}>
                              <Text style={[pdfStyles.metricTitle]}>View to Completion Rate</Text>
                              <Text style={[pdfStyles.metric]}>{allSegmentsStats[segment.uuid].viewToCompletionRate === null ? 'N/A' : `${allSegmentsStats[segment.uuid].viewToCompletionRate}%`}</Text>
                            </View>
                            <View wrap={false}>
                              <Text style={[pdfStyles.metricTitle]}>Avg. Total Field Returns</Text>
                              <Text style={[pdfStyles.metric]}>{allSegmentsStats[segment.uuid].avgFieldReturns === null ? 'N/A' : allSegmentsStats[segment.uuid].avgFieldReturns}</Text>
                            </View>
                            <View wrap={false}>
                              <Text style={[pdfStyles.metricTitle]}>Avg. Session Duration</Text>
                              <Text style={[pdfStyles.metric]}>{allSegmentsStats[segment.uuid].avgDuration === null ? 'N/A' : `${allSegmentsStats[segment.uuid].avgDuration}s`}</Text>
                            </View>
                          </>}
                        </View>
                      )}
                    </View>
                  </View>}
        </View>
      </View>
    </View>

  </>;

  const segmentWidth = fieldSegments ? `${58 / fieldSegments.length} %` : '10%'; // Spread out in the remaining space

  const formatFieldSegmentValue = (value) => {
    if (value === null) return 'N/A';

    switch (fieldSegmentMetric?.value) {
    case 'abandon_rate':
    case 'pct_of_total_abandons':
      return value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';
    case 'abandon_count':
    case 'sessions_interacted_with_field':
    case  'mean_rtf':
      return value.toLocaleString(undefined, {maximumFractionDigits: 2});
    case 'mean_tif':
      return formatMillisecondsToTimeString(value);
    default:
      return value;
    }
  };

  const layoutFieldSegmentComparison = () => <>
    <View style={[pdfStyles.row]}>
      <View style={[pdfStyles.col, pdfStyles.fullWidthCol]}>
        <View style={[pdfStyles.card]}>
          {fieldSegmentTableRows && fieldSegments &&
            <View style={[pdfStyles.table, {padding: 2}]}>
              <View style={[pdfStyles.tableRow, pdfStyles.tableHeaderRow]} fixed>
                <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '4%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Order</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '30%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Label</Text>
                </View>
                <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                  <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>All Sessions</Text>
                </View>
                {fieldSegments.map(segment =>
                  <View key={`header-${segment.uuid}`} style={[pdfStyles.tableCol, {width: segmentWidth}]}>
                    <View style={{maxHeight: '100%'}}>
                      <Text style={[pdfStyles.tableCell, pdfStyles.tableHeaderCell]}>Filters</Text>
                      {segment.fieldSegmentFilters.length > 0 ? segment.fieldSegmentFilters.map(f =>
                        <Text key={f.value} style={[pdfStyles.segmentFiltersText, {marginBottom:1}]}>{f.key}: {f.value}</Text>) : <Text style={{fontSize: 10}}>none</Text>}
                    </View>
                  </View>
                )}
              </View>
              {fieldSegmentTableRows?.length > 0 ? fieldSegmentTableRows.map((r, i) =>
                <View style={pdfStyles.tableRow} key={`field-${i}`}>
                  <View style={[pdfStyles.tableCol, pdfStyles.firstCol, {width: '4%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.order}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '30%'}]}>
                    <Text style={pdfStyles.tableCell}>{r.label}</Text>
                  </View>
                  <View style={[pdfStyles.tableCol, {width: '8%'}]}>
                    <Text style={pdfStyles.tableCell}>{formatFieldSegmentValue(r.all)}</Text>
                  </View>
                  {fieldSegments.map(segment =>
                    <View key={`field-${i}-segment-${segment.uuid}`} style={[pdfStyles.tableCol, {width: segmentWidth}]}>
                      <Text style={pdfStyles.tableCell}>{formatFieldSegmentValue(r[segment.uuid])}</Text>
                    </View>
                  )}
                </View>
              ) : <Text style={[pdfStyles.noDataText]}>No data to display.</Text>}
            </View>}
        </View>
      </View>
    </View>
  </>;

  const getOrientation = () => {
    if ((page === 'SegmentComparison' && segments?.length > maxPortraitSegmentsPDF) ||
          (page === 'FieldSegmentComparison' && fieldSegments?.length > maxPortraitSegmentsPDF)) {
      return 'landscape';
    } else {
      return 'portrait';
    }
  };

  const completePage = await pdf(
    <Document>
      <Page size="A4" style={pdfStyles.page} tyle={pdfStyles.page} orientation={getOrientation()}>
        <View style={[pdfStyles.row, {justifyContent: 'space-between'}]}>
          <View style={[pdfStyles.col, {padding: 0}]}>
            <View style={[pdfStyles.row]}>
              <View style={[pdfStyles.col]}>
                <PDFLink src={'https://app.zuko.io'}><Image src={ZukoLargeLogo} style={pdfStyles.logoImage}></Image></PDFLink>
              </View>
              <View style={[pdfStyles.col, pdfStyles.logoTextCol]}>
                <Text style={pdfStyles.logoText}>{title}</Text>
              </View>
            </View>
          </View>
          <View style={[{display: 'flex', justifyContent: 'center', alignItems: 'center'}]}>
            <View style={[{display: 'flex', alignItems: 'center'}]}>
              <Text style={[{padding: '4', borderRadius: '3', marginTop: '5'}]}>
                <PDFLink style={[{fontSize: 10}]} src={window.location.origin + window.location.pathname + queryString}>View in app</PDFLink>
              </Text>
            </View>
          </View>
        </View>
        <View style={[pdfStyles.row]}>
          <View style={[pdfStyles.col]}>
            {(page === 'FormData' || page === 'FieldData' || page === 'FieldFlow') && (orgName && form?.label && form?.url) &&
              <Text style={pdfStyles.orgTitleText}>{`${orgName} | ${form?.label} | `} <PDFLink style={pdfStyles.link} src={form.url}>{form.url}</PDFLink></Text>}
            {page === 'SegmentComparison' && (orgName && selectedComparison?.name) &&
              <Text style={pdfStyles.orgTitleText}>{orgName} | {selectedComparison.name}</Text>}
            {page === 'FieldSegmentComparison' && (comparison?.form?.label && comparison?.name) &&
              <Text style={pdfStyles.orgTitleText}>{comparison.form.label } | {comparison.name}</Text>}
          </View>
        </View>
        {(page === 'FormData' || page === 'FieldData' || page === 'FieldFlow') &&
          <View style={[pdfStyles.row, {flexWrap: 'wrap', padding: 5}]}>
            {time?.start && time?.end && form?.organisation?.timeZone &&
              <Text style={pdfStyles.queryText}>{time.start.tz(form?.organisation?.timeZone).format('ll HH:mm')} - {time.end.tz(form?.organisation?.timeZone).format('ll HH:mm')} {form?.organisation?.timeZone}</Text>}
            {filters?.length > 0 && <>
              {filters.map(a => (
                <View style={{display: 'flex', flexDirection: 'row', paddingRight: 3, paddingTop: 1}} key={a.value}><Text style={pdfStyles.filtersText}>{a.key}: {a.label}</Text></View>
              ))}
            </>}
          </View>}
        {page === 'FieldSegmentComparison' && (time?.start && time?.end && comparison?.org?.timeZone) &&
          <View style={[pdfStyles.row, {flexWrap: 'wrap', padding: 5}]}>
            <Text style={pdfStyles.queryText}>{time.start.tz(comparison?.org?.timeZone).format('ll HH:mm')} - {time.end.tz(comparison?.org?.timeZone).format('ll HH:mm')} {comparison?.org?.timeZone}</Text><Text style={pdfStyles.queryText}> | {fieldSegmentMetric?.label}</Text>
          </View>
        }
        {page === 'FormData' ? layoutFormData() :
          page === 'FieldData' ? layoutFieldData() :
            page === 'FieldFlow' ? layoutFieldFlow() :
              page === 'SegmentComparison' ? layoutSegmentComparison() :
                page === 'FieldSegmentComparison' ? layoutFieldSegmentComparison() :
                  null}
      </Page>
    </Document>
  ).toBlob();

  const getFileName = () => {
    switch (page) {
    case 'FormData':
    case 'FieldData':
    case 'FieldFlow':
      return `Zuko ${title} - ${orgName} - ${form?.label}`;
    case 'SegmentComparison':
      return `Zuko ${title} - ${orgName} - ${selectedComparison?.name}`;
    case 'FieldSegmentComparison':
      return `Zuko ${title} - ${comparison.form.label} - ${comparison.name} - ${fieldSegmentMetric?.label}`;
    default:
      return 'Zuko PDF Export';
    }
  };

  saveAs(completePage, getFileName());

  return 'Completed';
};
