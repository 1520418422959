import { MutableRefObject, createContext } from 'react';
import { FormDataHash } from '../types/FormDataHash';

export interface SessionContextType {
  values: FormDataHash<string>,
  setValues: Function,
  zuko?: MutableRefObject<any>, // No types for Zuko, currently
  inEdit?: boolean,
  setIdentifyItem: any,
  identifyItem: any,
  handleDeleteItem: ({stepIndex, type, questionIndex}: any) => void,
  handleMoveQuestion: ({stepIndex, type, questionIndex, newQuestionIndex}: any) => void,
  handleSaveInlineEditItem: ({text, stepIndex, type, questionIndex}: any) => void,
  handleRemoveMessages: Function,
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export default SessionContext;
