import { useContext, useRef, useState, FormEvent } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { optionalTag } from '../Question';
import EditInlineIcon from '../EditInlineIcon';
import { useShowEditIcon } from '../../hooks';

const Legend = ({ label, required, stepIndex, questionIndex }: {
  label: string,
  required?: boolean,
  stepIndex: number,
  questionIndex: number,
}) => {
  const { inEdit, handleSaveInlineEditItem, handleRemoveMessages, setIdentifyItem } = useContext(SessionContext) as SessionContextType;
  const labelRef = useRef<HTMLLegendElement>(null);
  const [inError, setInError] = useState<boolean | null | undefined>();
  const [showEditIcon, setShowEditIcon] = useShowEditIcon({type: 'question', stepIndex, questionIndex});

  const maxChars = 255;

  return <>{inEdit ? <div className="d-inline-flex align-items-center">
    <span className="mark d-inline-flex align-items-center"><legend
      className={`editable-inline ${inError ? 'in-error': ''}`}
      contentEditable={inEdit}
      ref={labelRef}
      suppressContentEditableWarning={true}
      spellCheck="false"
      onClick={() => setShowEditIcon(false)}
      onInput={(e: FormEvent) => {
        const target = e.target as HTMLElement;
        const innerText = target.innerText;
        if (labelRef.current && (innerText?.length === 0 || innerText === '\n')) {
          labelRef.current.innerHTML = '';
          labelRef?.current?.focus();
          setInError(true);
        } else {
          setInError(false);
        }
      }}
      onBlur={(e) => {
        if (!inEdit) return;
        const innerText = labelRef?.current?.innerText;
        if (innerText?.length === 0) {
          labelRef?.current?.focus();
          setInError(true);
        } else {
          setInError(false);
        }

        if (innerText && label !== innerText) {
          handleRemoveMessages();
          const textValidLength = innerText.slice(0, maxChars);
          labelRef.current.innerText = textValidLength;
          handleSaveInlineEditItem({type: 'question-title', item: textValidLength, stepIndex, questionIndex});
        }

        const clickTarget = e.relatedTarget as HTMLElement;
        if (clickTarget && [...clickTarget.classList].includes('configure-icon-container')) setIdentifyItem({stepIndex, type: 'question', questionIndex, showConfigure: true});
      }}
    >{label}</legend>
    {showEditIcon && <EditInlineIcon setShowEditIcon={setShowEditIcon} itemRef={labelRef} />}</span>
    <p className="optional-tag-container">{!required && optionalTag}</p>
  </div> :
    <legend>{label}{!required && optionalTag}</legend>}</>;
};

export default Legend;
