import { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

const ConfigureTitle = ({
  item,
  type,
  handleSaveConfigureItem,
  handleRemoveMessages,
  handleSaveInlineEditItem,
  stepIndex,
  questionIndex,
}) => {
  const [titleItem, setTitleItem] = useState();

  useEffect(() => {
    setTitleItem({...item});
  }, [item]);

  return (<div className="configure-form">
    <Form noValidate onSubmit={(e) => {
      e.preventDefault();
      handleSaveConfigureItem({ item: titleItem });
    }} className="w-100" id="builder-field-config-form">
      <FormGroup className="form-group" controlId="title">
        <Form.Label>Title</Form.Label>
        <Form.Control type="text" value={titleItem?.title || ''} required maxLength={255} autoFocus={(item && !item.title) ? true : false}
          onChange={({target: {value}}) => {
            setTitleItem(prev => ({...prev, title: value}));
            handleRemoveMessages();
          }}
          onBlur={() => {
            if (item.title !== titleItem.title) {
              handleSaveInlineEditItem({type, item: titleItem.title, stepIndex, questionIndex});
            }
          }}  />
      </FormGroup>
      <Row className="form-actions-row">
        <Col className="p-0 text-center">
          <Button variant="primary" className="save-builder-form-btn ms-1 me-0 mt-2" type="submit">Save</Button>
        </Col>
      </Row>
    </Form>
  </div>
  );
};

export default ConfigureTitle;
