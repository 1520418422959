
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

const RoleSelect = ({roleValue, uuid, onChange, currentUserIsStandard}) => {
  const [selected, setSelected] = useState();
  const rolesForSelect = [{label: 'Admin', value: 'admin'}, {label: 'Standard', value: 'standard'}];

  useEffect(() => {
    if (roleValue) setSelected(roleValue);
  }, [roleValue]);

  return (
    <Form.Select value={selected || ''} aria-label="User role" onChange={({target: {value}}) => {
      setSelected(value);
      onChange({uuid, roleValue: value});
    }} className="ms-0 role-select" disabled={currentUserIsStandard} readOnly={currentUserIsStandard}>
      {!selected && <option value="" disabled hidden>Select&hellip;</option>}
      {rolesForSelect.map((opt) => (<option key={opt.value} value={opt.value}>{opt.label}</option>))}
    </Form.Select>
  );
};

export default RoleSelect;