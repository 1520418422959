import React from 'react';

import './DefaultError.scss';

const DefaultError = ({title, message}) => {
  return (
    <div className="error-page" role="img" alt="Zuko background">
      <div className="d-flex justify-content-center">
        <a href="/"><div className="zuko-full-colour-logo" role="img" alt="Zuko logo"></div></a>
      </div>
      <div className="error-message">
        <h1>{title}</h1>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default DefaultError;
