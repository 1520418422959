import DOMPurify from 'dompurify';
import { decodeAndSantize } from '../../utils';
import { useContext, useRef } from 'react';
import SessionContext, { SessionContextType } from '../contexts/SessionContext';
import { config } from '../helpers/sanitizer';
import EditInlineIcon from './EditInlineIcon';
import { useShowEditIcon } from '../hooks';

const CopyWithLink = ({ text, type, stepIndex, questionIndex }: { text: string | null, type: string, stepIndex?: number,
  questionIndex?: number }) => {
  const { handleSaveInlineEditItem, inEdit, handleRemoveMessages } = useContext(SessionContext) as SessionContextType;
  const [showEditIcon, setShowEditIcon] = useShowEditIcon({type, stepIndex, questionIndex});
  const editableContentRef = useRef<HTMLParagraphElement>(null);

  // TODO: add a chart count limit messge - similar to that shown in Configure when surpass the limit
  const maxChars = 2000;
  const santizedInsert = decodeAndSantize(text);

  if (!text) return null;

  return inEdit ? <span className="mark d-inline-flex align-items-start"><p className={`editable-inline ${type}`}
    ref={editableContentRef}
    dangerouslySetInnerHTML={{ __html: santizedInsert }}
    contentEditable={inEdit}
    suppressContentEditableWarning={true}
    spellCheck="false"
    onClick={() => setShowEditIcon(false)}
    onBlur={(e) => {
      if (!inEdit) return;
      const santizedUpdate = DOMPurify.sanitize(e.target.innerHTML, config);
      handleRemoveMessages();
      const textValidLength = santizedUpdate.slice(0, maxChars);
      e.target.innerHTML = textValidLength;
      if (text !== santizedUpdate) handleSaveInlineEditItem({type, item: encodeURI(textValidLength), stepIndex, questionIndex});}}
    onKeyUp={() => {
      if (!inEdit) return;
      handleRemoveMessages();
    }}
    onMouseUp={() => {
      if (!inEdit) return;
      handleRemoveMessages();
    }}
  />
  {showEditIcon && <EditInlineIcon setShowEditIcon={setShowEditIcon} itemRef={editableContentRef} />}</span> :
    <p className={`${type}`} dangerouslySetInnerHTML={{ __html: santizedInsert }} />;
};

export default CopyWithLink;
