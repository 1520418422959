import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToElement = ({elementRef}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const pageTitleArea = 90;
    window.scrollTo({
      top: elementRef.current?.offsetTop + pageTitleArea || 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname, elementRef]);

  return null;
};

export default ScrollToElement;
