import { useContext, useRef, useState, FormEvent } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { optionalTag } from '../Question';
import EditInlineIcon from '../EditInlineIcon';
import { useShowEditIcon } from '../../hooks';

const Label = ({ id, label, required, stepIndex, questionIndex, type }: {
  id: string,
  label: string,
  required?: boolean,
  stepIndex: number,
  questionIndex: number,
  type: 'question-title' | 'question-sub-label-address-line-1' | 'question-sub-label-address-line-2' | 'question-sub-label-address-level-1' | 'question-sub-label-postal-code',
}) => {
  const { inEdit, handleSaveInlineEditItem, handleRemoveMessages, setIdentifyItem } = useContext(SessionContext) as SessionContextType;
  const [inError, setInError] = useState<boolean | null>();
  const labelRef = useRef<HTMLLabelElement>(null);
  const [showEditIcon, setShowEditIcon] = useShowEditIcon({type: 'question', stepIndex, questionIndex});

  const maxChars = 255;

  return <>{inEdit ? <div className="d-inline-flex align-items-center">
    <span className="mark d-inline-flex align-items-center"><label
      // NB. we don't declare the htmlFor to avoid engaging the input whilst in edit mode
      ref={labelRef}
      className={`editable-inline ${inError ? 'in-error': ''}`}
      contentEditable={inEdit}
      suppressContentEditableWarning={true}
      spellCheck="false"
      onClick={() => setShowEditIcon(false)}
      onInput={(e: FormEvent) => {
        const target = e.target as HTMLElement;
        const innerText = target.innerText;
        if (labelRef.current && (innerText?.length === 0 || innerText === '\n')) {
          labelRef.current.innerHTML = '';
          labelRef.current.focus();
          setInError(true);
        } else {
          setInError(false);
        }
      }}
      onBlur={(e) => {
        if (!inEdit) return;
        const innerText = labelRef?.current?.innerText;
        if (innerText?.length === 0) {
          labelRef?.current?.focus();
          setInError(true);
        } else {
          setInError(false);
        }

        if (innerText && label !== innerText) {
          handleRemoveMessages();
          const textValidLength = innerText.slice(0, maxChars);
          labelRef.current.innerText = textValidLength;
          handleSaveInlineEditItem({type, item: textValidLength, stepIndex, questionIndex});
        }

        // If the settings cog is clicked as soon as typing in this field has finished, this makes sure the click is processed to open settings. Otherwise it is just ignored by the wrapper.
        const clickTarget = e.relatedTarget as HTMLElement;
        if (clickTarget && [...clickTarget.classList].includes('configure-icon-container')) setIdentifyItem({stepIndex, type: 'question', questionIndex, showConfigure: true});
      }}
    >{label}</label>
    {showEditIcon && <EditInlineIcon setShowEditIcon={setShowEditIcon} itemRef={labelRef} />}</span>
    <p className={`optional-tag-container ${type === 'question-sub-label-address-line-2' ? 'address-2-tag' : ''}`}>{!required && optionalTag}</p>
  </div> :
    <label htmlFor={id}>{label}{!required && optionalTag}</label>}</>;
};

export default Label;
