import React, { useState, useContext, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { VscCheck, VscWarning } from "react-icons/vsc";
import { FaSpinner } from "react-icons/fa";
import api from '../api';
import AppContext from '../AppContext';

const FeatureRequestButton = ({mixpanel, page, featureType, buttonContent, orgFromPage, interestMessageContent, feedbackMessageContent,
  popoverPlacement, popoverClassList = [], buttonClassList = [],
}) => {
  const { currentUser } = useContext(AppContext);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState();
  const [showFeedbackRequest, setShowFeedbackRequest] = useState();
  const [feedback, setFeedback] = useState('');
  const [feedbackLoading, setFeedbackLoading] = useState();

  const cancelTriggerRef = useRef();

  const handleSubmit = async ({showFeedback, showSuccessMsg}) => {
    try {
      setShowSuccessMsg(null);
      setShowErrorMsg(null);
      setFeedbackLoading(true);

      const org = orgFromPage || currentUser.organisations?.[0];

      await api.post(`/users/${currentUser.uuid}/feedback`, {
        orgUuid: org?.uuid,
        feedback: `FEATURE REQUEST ${featureType}${feedback ? ': ' + feedback: ''}`,
        url: window.location.href,
      });

      setFeedback(null);
      if (showFeedback) setShowFeedbackRequest(true);
      if (showSuccessMsg) setShowSuccessMsg(true);
      mixpanel?.track('Sent Feature Request', { page, ...org && {
        'Organisation Name': org.name,
        'Organisation Uuid': org.uuid,
        'Organisation Contract Type': org.contractType,
      }}
      );
    } catch (e) {
      setShowErrorMsg(true);
    } finally {
      setFeedbackLoading(false);
    }
  };

  return (<div ref={cancelTriggerRef}>
    <OverlayTrigger placement={popoverPlacement} trigger={'click'} rootClose
      onExited={() =>  {
        setShowSuccessMsg(false);
        setShowErrorMsg(false);
        setInputErrorMsg(null);
        setShowFeedbackRequest(false);
      }}
      onEntered={() => {
        mixpanel?.track('Opened Feature Request', { page, ...orgFromPage && {
          'Organisation Name': orgFromPage.name,
          'Organisation Uuid': orgFromPage.uuid,
          'Organisation Contract Type': orgFromPage.contractType,
        }});
      }}
      overlay={
        <Popover id="feature-request-container" className={popoverClassList.join(' ')}>
          <Popover.Body>
            <Row className="g-0 header-row mb-2"><h3>New Feature</h3></Row>
            {feedbackLoading &&
              <div className="loading-area d-flex justify-content-center align-items-center">
                <FaSpinner size="18px" className="spinning-icon" title="Sending..."/>
              </div>}
            {!showSuccessMsg && !showErrorMsg && !feedbackLoading && <>
              {!showFeedbackRequest && <>
                {interestMessageContent}
                <Row className="g-0 justify-content-center button-row">
                  <Button type="submit" className="mx-1 btn-less-padding" onClick={(e) => {
                    e.preventDefault();
                    handleSubmit({showFeedback: true});
                  }}>Email me about {featureType}</Button>
                </Row>
              </>}
              {showFeedbackRequest && <>
                <Alert variant="outline-success">
                  <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                  <p className="alert-text m-0">Thank you. We'll let you know when {featureType} is available.</p>
                </Alert>
                <Form noValidate id="feedback-form" className="mt-2">
                  {feedbackMessageContent}
                  <FormGroup controlId="feedback" className={`form-group mb-0 ${inputErrorMsg ? 'invalid' : ''}`}>
                    <Form.Control
                      as="textarea"
                      value={feedback || ''}
                      onChange={({target: {value}}) => {
                        setFeedback(value);
                        if (inputErrorMsg) setInputErrorMsg(null);
                      }}
                      aria-label="feedback"
                      maxLength={1000}
                    />
                    <Form.Control.Feedback type="invalid" className="input-feedback">{inputErrorMsg}</Form.Control.Feedback>
                    {(!inputErrorMsg && feedback?.length > 990) && <Form.Control.Feedback className="input-feedback feedback-info">
                      {feedback?.length}/1000 characters. <br></br>Please share your extended feedback with <a href="mailto:support@zuko.io">Zuko support</a>.
                    </Form.Control.Feedback>}
                  </FormGroup>
                  <Row className="g-0 justify-content-between button-row">
                    <Button variant="outline-secondary" className="cancel mx-1 btn-less-padding" onClick={() => cancelTriggerRef?.current.click()}>Cancel</Button>
                    <Button type="submit" className="mx-1 btn-less-padding" onClick={(e) => {
                      e.preventDefault();
                      handleSubmit({showSuccessMsg: true});
                    }}>Share Feedback</Button>
                  </Row>
                </Form>
              </>}
            </>}
            {showSuccessMsg &&
              <Alert variant="outline-success">
                <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                <p className="alert-text m-0">Thank you. We'll let you know when {featureType} is available.</p>
              </Alert>}
            {showErrorMsg &&
              <Alert variant="outline-danger">
                <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                <p className="alert-text m-0">Sorry your feature request was not sent. Please contact <a href="mailto:support@zuko.io">support</a>.</p>
              </Alert>}
          </Popover.Body>
        </Popover>}>
      <Button variant="outline-primary" className={`feature-request-btn ${buttonClassList.join(' ')}`}>{buttonContent}</Button>
    </OverlayTrigger>
  </div>
  );
};

export default FeatureRequestButton;