import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';

import api from '../api';

import './SetPassword.scss';
import passwordPolicy from '../password_policy';

const SetPassword = ({mixpanel}) => {
  const history = useHistory();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [tokenValid, setTokenValid] = useState(null);

  useEffect(() => {
    const retrieveToken = async () => {
      try {
        const { data: { user } } = await api.get(`/password-reset/${token}`);
        setTokenValid(true);

        if (user.email) {
          mixpanel.identify(user.email);
          mixpanel.track('Page View', { page: 'Set Password' });
        }
      } catch (e) {
        history.push('/login', { alert: { message: 'The password reset link you visited is invalid. Please try again.', type: 'danger' } });
      }
    };
    retrieveToken();
  }, [history, token, mixpanel]);

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      await api.put(`/password-reset/${token}`, {password});
      mixpanel.track('Password Updated', { page: 'Set Password' });
      history.push('/login', { alert: { message: 'Your password has been set. Please log in.', type: 'success' } });
    } catch (e) {
      history.push('/login', { alert: { message: 'Password reset failed. Please try again.', type: 'danger' } });
    }
  };
  return (
    <Container fluid className="page login-background" id="set-password-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Set Password</title>
      </Helmet>
      <Col className="center-column justify-content-md-center">
        <Row className="g-0 login-logo justify-content-center">
          <div className="logo-wrapper">
            <div className="zuko-full-colour-logo" role="img" alt="Zuko logo"></div>
          </div>
        </Row>
        {tokenValid && <>
          <Row className="g-0 login-title mb-3 justify-content-center">
            <h1>Update Your Password</h1>
          </Row>
          <Row className="g-0 justify-content-center" id="login-container">
            <Col className="col-auto p-0" id="login-container-col">
              <Form onSubmit={handleReset} className="login-form">

                <FormGroup className="form-group" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" name="password" autoComplete="new-password"
                    minLength={passwordPolicy.length.min} maxLength={passwordPolicy.length.max}
                    placeholder="Enter new password"
                    value={password} onChange={({target: {value}}) => setPassword(value)} required/>
                </FormGroup>
                <Form.Text>Your new password must be at least {passwordPolicy.length.min} characters long, and no more than {passwordPolicy.length.max} characters.</Form.Text>

                <Row className="g-0 login-btn justify-content-center mt-3">
                  <Button variant="primary" className="submit-btn" type="submit">Set Password</Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </>}
      </Col>
    </Container>
  );
};

export default SetPassword;
