import FormContext from './contexts/FormContext';
import ZukoForm from './ZukoForm';
import { ZukoFormInterface } from "./interfaces/ZukoFormInterface";

const InAppLoader = ({
  inEdit,
  handleSaveInlineEditItem,
  handleRemoveMessages,
  form,
  setIdentifyItem,
  identifyItem,
  setInAppStepIndex,
  inAppStepIndex,
  inAppShowSubmitted,
  setInAppShowSubmitted,
  handleDeleteItem,
  handleMoveQuestion,
}: { inEdit: true | undefined, handleSaveInlineEditItem: Function, form: ZukoFormInterface, setIdentifyItem: any, identifyItem?: string,
  setInAppStepIndex: (index: number) => void, inAppStepIndex: number,
  setInAppShowSubmitted: (value: boolean) => void, inAppShowSubmitted: boolean | undefined,
  handleDeleteItem: ({stepIndex, type, questionIndex}: any) => void,
  handleMoveQuestion: ({stepIndex, type, questionIndex, newQuestionIndex}: any) => void,
  handleRemoveMessages: Function,
 }) => {
  return <>
    {form && <FormContext.Provider value={{ ...form }}>
      <ZukoForm
        inEdit={inEdit}
        handleSaveInlineEditItem={handleSaveInlineEditItem}
        handleRemoveMessages={handleRemoveMessages}
        setIdentifyItem={setIdentifyItem}
        identifyItem={identifyItem}
        inAppStepIndex={inAppStepIndex}
        setInAppStepIndex={setInAppStepIndex}
        inAppShowSubmitted={inAppShowSubmitted}
        setInAppShowSubmitted={setInAppShowSubmitted}
        handleDeleteItem={handleDeleteItem}
        handleMoveQuestion={handleMoveQuestion}
      />
    </FormContext.Provider>}
  </>;
};

export default InAppLoader;
