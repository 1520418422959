import React, { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { VscChromeClose } from "react-icons/vsc";
import FiltersSelect from '../Components/Select/FiltersSelect';

const SegmentConfig = ({
  column,
  index,
  availableFilters,
  dragHandleProps,
  handleDeleteSegment,
  filtersError,
  filtersLoading,
  handleSaveEditedSegment,
  handleCancelTempSegment,
  handleCreateNewSegment,
  segmentCount,
  comparisonForm
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedFilters, setEditedFilters] = useState(null);
  const [filtersInError, setFiltersInError] = useState(null);
  const filters = editedFilters ? editedFilters : column.fieldSegmentFilters;

  const handleCancelEditing = () => {
    if (column.type === 'temp') handleCancelTempSegment({uuid: column.uuid});
    setIsEditing(false);
    setEditedFilters(null);
  };

  const handleSave = () => {
    if (column.type === 'temp') {
      if (!filters.length) setFiltersInError(true);
      if (filters.length) handleCreateNewSegment({tempUuid: column.uuid, filters, order: column.order});
    } else {
      handleSaveEditedSegment({uuid: column.id, filters, order: column.order});
      setIsEditing(false);
    }
  };

  const handleFiltersChange = useCallback((e) => {
    if (filtersInError) setFiltersInError(null);
    setEditedFilters(e || []);
  }, [filtersInError]);

  return (
    <div className="segment-config" data-testid={`segment-${column.id}`}>
      <div className="edit-icons-area">
        {(!isEditing && column.type !== 'temp') ?
          <span {...dragHandleProps} className="drag-icon"><i className="fa fa-arrows-h fa-lg"/></span> :
          <span {...dragHandleProps}></span>}
        {(!isEditing && column.type !== 'temp') && <>
          <div>
            <span className="segment-icon" onClick={() => setIsEditing(true)} data-testid={`edit-segment-${column.id}`}><i className="fa fa-pencil fa-lg editing"/></span>
            <span className="segment-icon" onClick={() => handleDeleteSegment({uuid: column.id})}  data-testid={`delete-segment-${column.id}`}><i className="fa fa-trash fa-lg editing"/></span>
          </div> </>}
        {(isEditing || (column.type === 'temp' && segmentCount > 2)) && <>
          <VscChromeClose size="20px" onClick={handleCancelEditing} className="segment-icon editing" title="Close"/>
        </>}
      </div>
      <div className={`config-area ${(isEditing || column.type === 'temp') ? 'editing' : ''}`}>
        {(!isEditing && column.type !== 'temp') &&
          <div className="set-config text-center">
            <div>
              <h5 className="segmentConfigTitle">Filters</h5>
              <ul className="segmentConfigFilterList" data-testid="filter-list">
                {filters?.length ? filters.map((filter, i) => {
                  return (<li className="segmentConfigFilterItem" key={`filter-${i}`}>
                    <div className="attribute-key-value-label">{`${filter.key}: ${filter.label ? filter.label : filter.value}`}</div>
                  </li>);
                }): 'none'}
              </ul>
            </div>
          </div>}

        {(isEditing || column.type === 'temp') && <>
          <div className="edit-segment-form editing-segment">
            <form>
              <label className="select-label">Filters:</label>
              <div className="edit-segment-filters text-start">
                <FiltersSelect
                  id={`segment-filters-select-${column.id}`}
                  form={comparisonForm}
                  selectedFilters={editedFilters ? editedFilters : filters.length ? filters.map((f) => ({label: f.value, key: f.key, value: `${f.key}:${f.value}`})) : []}
                  filtersLoading={filtersLoading}
                  availableFilters={availableFilters}
                  filtersLoadingError={filtersError}
                  filtersInError={filtersInError}
                  handleFiltersChange={handleFiltersChange}
                  placeholder={'Select...'}
                  multiValueLabelFontSize={'0.9rem'}
                  valueContainerMaxHeight={'300px'}
                  menuWidth={index === 0 ? '140px' : 'fit-content'}
                  isClearable={false} />
              </div>
            </form>
            <div className="text-center">
              <Button className="load" id="edit-segment-button"
                disabled={column.type !== 'temp' && (!editedFilters || (column.type === 'segment' && filters.length === 0))}
                onClick={handleSave}
                data-testid={`save-segment-${column.id}`}>Save</Button>
            </div>
          </div>
        </>}
      </div>
    </div>
  );
};

export default SegmentConfig;
