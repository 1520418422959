import React from 'react';

const CompletionPathArrow = ({gradientNumber}) => {
  return (
    <svg width="100%" height="200" viewBox="0 0 127 200" fill="none" className="completion-path-arrow">
      <defs>
        <linearGradient id="gradient-1">
          <stop offset="15%" stopColor="#ffc95f" />
          <stop offset="85%" stopColor="#F77F5E" />
        </linearGradient>
        <linearGradient id="gradient-2">
          <stop offset="15%" stopColor="#F77F5E" />
          <stop offset="85%" stopColor="#ee345d" />
        </linearGradient>
        <linearGradient id="gradient-3">
          <stop offset="15%" stopColor="#ee345d" />
          <stop offset="85%" stopColor="#C22959" />
        </linearGradient>
        <linearGradient id="gradient-4">
          <stop offset="15%" stopColor="#C22959" />
          <stop offset="85%" stopColor="#6950A5" />
        </linearGradient>
      </defs>
      <path d="M125.914 36.4142C126.695 35.6332 126.695 34.3668 125.914 33.5858L113.186 20.8579C112.405 20.0768 111.139 20.0768 110.358 20.8579C109.577 21.6389 109.577 22.9052 110.358 23.6863L121.672 35L110.358 46.3137C109.577 47.0948 109.577 48.3611 110.358 49.1421C111.139 49.9232 112.405 49.9232 113.186 49.1421L125.914 36.4142ZM2.00001 85.4661C0.895441 85.4661 1.02411e-05 86.3615 1.01917e-05 87.4661C1.01424e-05 88.5707 0.895441 89.4661 2.00001 89.4661L2.00001 85.4661ZM124.5 33C104.523 33 90.3494 36.3184 79.4171 41.3831C68.4878 46.4464 60.9335 53.1955 54.1655 59.8018C47.3486 66.4558 41.4773 72.7929 33.6686 77.5614C25.9551 82.2717 16.2905 85.4661 2.00001 85.4661L2.00001 89.4661C16.9595 89.4661 27.3574 86.1022 35.7533 80.9752C44.0539 75.9063 50.3389 69.1268 56.9595 62.6642C63.629 56.1541 70.7935 49.7866 81.0985 45.0125C91.4006 40.2398 104.977 37 124.5 37L124.5 33Z"
        fill={`url(#gradient-${gradientNumber})`} />
      <path d="M126.757 179.044C127.285 180.014 126.926 181.229 125.956 181.757L110.146 190.361C109.175 190.889 107.961 190.53 107.433 189.56C106.905 188.59 107.263 187.375 108.234 186.847L122.287 179.199L114.639 165.146C114.111 164.175 114.47 162.961 115.44 162.433C116.41 161.905 117.625 162.263 118.153 163.234L126.757 179.044ZM2.03642 89.9657C0.932034 89.9858 0.0204522 89.1068 0.000346135 88.0024C-0.0197599 86.898 0.859223 85.9864 1.96361 85.9663L2.03642 89.9657ZM124.434 181.918C103.778 175.821 89.5985 166.835 78.974 156.767C68.3788 146.727 61.3817 135.663 55.0695 125.598C48.7071 115.452 43.1203 106.473 35.2761 100.023C27.545 93.6669 17.493 89.6843 2.03642 89.9657L1.96361 85.9663C18.2466 85.6699 29.2591 89.8977 37.8164 96.9334C46.2605 103.876 52.1859 113.47 58.4583 123.472C64.781 133.555 71.5416 144.214 81.7254 153.864C91.88 163.487 105.501 172.159 125.566 178.082L124.434 181.918Z"
        fill={`url(#gradient-${gradientNumber})`} />
    </svg>);

};

export default CompletionPathArrow;