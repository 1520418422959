import { useState, useEffect, useContext, useCallback } from 'react';
import BuilderContext from './Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  useRouteMatch,
  useParams,
  Link,
} from "react-router-dom";
import qs from 'qs';

import Build from './Build';
import Share from './Share';
import Submissions from './Submissions';
import Integrations from './Integrations';
import Analytics from './Analytics';
import PublishModal from './Publishing/PublishModal';
import UnpublishModal from './Publishing/UnpublishModal';
import FeatureRequestButton from '../Components/FeatureRequestButton';
import { TbArrowsSplit2 } from "react-icons/tb";
import Select from 'react-select';
import api from '../api';
import './index.scss';
import AppContext from '../AppContext';
import { FaPlus, FaPencilAlt } from 'react-icons/fa';

const Builder = ({mixpanel}) => {
  const { form, setForm, forms, setFormsError, formsLoading, formsError } = useContext(BuilderContext);
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [showPreview, setShowPreview] = useState();
  const [isEditingName, setIsEditingName] = useState();
  const [formNameError, setFormNameError] = useState();

  // Publishing
  const [showPublishModal, setShowPublishModal] = useState();
  const [showUnpublishModal, setShowUnpublishModal] = useState();
  const [canPublish, setCanPublish] = useState(true);
  const [isPublishing, setIsPublishing] = useState();
  const [publishError, setPublishError] = useState();
  const [publishSuccess, setPublishSuccess] = useState();
  const [isUnpublishing, setIsUnpublishing] = useState();
  const [unpublishError, setUnpublishError] = useState();
  const [unpublishSuccess, setUnpublishSuccess] = useState();

  const { checkout_success } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [showCheckoutSuccessMsg, setShowCheckoutSuccessMsg] = useState(checkout_success);

  let { formUuid: formUuidFromParams } = useParams();
  const formUuid = formUuidFromParams || form?.uuid; // On first load the default form is selected without formUuid in params
  const { currentUser, setUser } = useContext(AppContext);

  const hostedFormUrl = form && `https://forms.zuko.io/${formUuid}`; // Only used once published

  const saveForm = async () => {
    try {
      setFormNameError(null);
      const { data: { form: newForm } } = await api.put(`/builder/forms/${form?.uuid}`, {
        builderForm: form,
      });
      setForm(newForm);
      mixpanel?.track('Saved Form Name', { page: 'Build' });
    } catch (e) {
      setFormNameError('Sorry, something went wrong. The name has not been saved.');
    }
  };

  const handleTriggerPublishForm = async () => {
    setPublishSuccess(false);
    setPublishError(null);
    setIsPublishing(true);
    setShowPublishModal(true);
  };

  const handleUnpublishForm = async () => {
    setShowUnpublishModal(true);
    try {
      setIsUnpublishing(true);
      setUnpublishError(null);
      await api.delete(`/builder/forms/${formUuid}/publish`);
      setUnpublishSuccess(true);
      mixpanel?.track('Unpublished Form');
      setForm(prev => ({...prev, published: false}));
      history.push(`/builder/forms/${formUuid}/build`);
    } catch (e) {
      setUnpublishError('Sorry something went wrong with unpublishing. Please try again.');
    } finally {
      setIsUnpublishing(false);
    }
  };

  const handleCloseModal = useCallback(() => {
    setShowPublishModal(false);
  }, []);

  const handleCloseUnpublishModal = useCallback(() => {
    setShowUnpublishModal(false);
  }, []);

  // Only publish once there are no more changes to make to the form in edit
  useEffect(() => {
    if (canPublish && showPublishModal) (async () => {
      try {
        setPublishSuccess(false);
        setPublishError(null);
        await api.post(`/builder/forms/${formUuid}/publish`);

        let analyticsForm = form.analyticsForm;
        if (!analyticsForm) {
          const {data: { analyticsForm: newAnalyticsForm } } = await api.post(`/builder/forms/${form.uuid}/analytics/form`);
          analyticsForm = newAnalyticsForm;
        }

        setPublishSuccess(true);
        setForm(prev => ({...prev, published: true, analyticsForm}));
        mixpanel?.track('Published Form');
      } catch (e) {
        setPublishError('Sorry something went wrong with publishing. Please close and try again.');
      } finally {
        setIsPublishing(false);
      }
    })();

  }, [canPublish, showPublishModal, form?.analyticsForm, form?.uuid, formUuid, mixpanel, setForm]);

  // Reset state for a new form
  useEffect(() => {
    setFormsError(null);
    setCanPublish(true);
  }, [formUuidFromParams, setFormsError]);

  useEffect(() => {
    if (forms && !formUuid) {
      setForm(forms[0]);
    }
    if (forms && formUuid && formUuid !== 'new' && (form?.uuid !== formUuid)) {
      const foundForm = forms.find(f => f.uuid === formUuid);
      setForm(foundForm);
      if (!foundForm) setFormsError('Form not recognised, please select a different one.');
    }
  }, [form?.uuid, forms, formUuid, setForm, setFormsError]);

  useEffect(() => {
    if (formUuid === 'new') {
      setForm(({uuid: 'new', name: 'Form'})); // Explicitly define a temp uuid to be used for other 'new form' markers
      setShowPreview(false);
    }
  }, [formUuid, setForm]);

  // Brand new user
  useEffect(() => {
    if ((forms?.length === 0) && !formsError && !formsLoading && !formUuid) history.push('/builder/forms/new/build');
  }, [forms, formUuid, formsError, formsLoading, history]);

  const pathLookUp = (formUuidFromParams === 'new' || !formUuidFromParams) ? 'build' : location.pathname.replace(/\/$/, '').match(/[^/]*$/)?.[0];

  // Refetch current user after a successful checkout to make sure their orgs are up to date
  useEffect(() => {
    if (showCheckoutSuccessMsg) {
      (async () => {
        try {
          const { data: { organisations } } = await api.get(`/users/${currentUser.uuid}/organisations`, {
            params: {
              include: ['forms'], // Builder doesn't need these but currentUser in AppContext does
            }
          });
          setUser(prev => ({...prev, organisations}));
        } catch (e) { /* Do nothing as this is a background update */ }
      })();
      mixpanel.track('Successful builder checkout confirmed');
    }
  }, [showCheckoutSuccessMsg, setUser, currentUser.uuid, mixpanel]);

  // Close preview on location change
  useEffect(() => {
    setShowPreview(false);
  }, [location.key]);

  return (<>
    <div className="secondary-nav-wrapper browser-only">
      {!showPreview && <div id="builder-form-nav" className="d-flex p-1 justify-content-between">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex">
            {!isEditingName && <>
              <div className="d-flex align-items-center">
                <Select
                  id="form-select"
                  styles={{
                    control: (styles, state) => ({...styles,
                      border: '1px solid #EBEDF2',
                    }),
                    option: (styles, state) => ({...styles,
                      color: '#3f4047',
                      backgroundColor: state.selectProps.value && (state.selectProps.value.value === state.value) ? "#E2E5Ec" : null,
                      '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                    }),
                    menu: (styles, state) => ({...styles,
                      marginTop: '1px',
                      borderRadius: '4px',
                      border: '1px solid #EBEDF2',
                      boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                    }),
                    dropdownIndicator: (styles, state) => ({...styles,
                      cursor: 'pointer',
                      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                      transition: 'transform .5s ease',
                    }),
                  }}
                  isLoading={formsLoading}
                  options={forms?.map(f => ({...f, label: f.name || 'untitled', value: f.uuid}))}
                  onChange={(f) => {
                    history.push(`/builder/forms/${f.uuid}/${pathLookUp || 'build'}`);
                  }}
                  placeholder="Select a form..."
                  value={form ? {...form, label: form.name || 'untitled', value: form.uuid} : null}
                />
                {formUuid !== 'new' && <Button className="btn-style-removed me-3 btn-in-nav" onClick={() => setIsEditingName(true)}><FaPencilAlt className="me-1 mb-1 plus-inside-btn"/>Edit Name</Button>}
              </div>
            </>}
            {isEditingName &&
           <div className="d-flex align-items-center">
             <Form.Control type="text" className="edit-name-input" value={form?.name || ''} required maxLength={255} autoFocus={true}
               onChange={({target: {value}}) => {
                 setForm(prev => ({...prev, name: value}));
               }} />
             <Button variant={'primary'} className="ms-0 edit-name-save-btn btn-in-nav" onClick={() => {
               saveForm();
               setIsEditingName(false);
             }}>Save</Button>
           </div>}
            {formNameError &&
           <div className="d-flex align-items-center">
             <p className="error-text mb-0 me-3">{formNameError}</p>
           </div>}
            {formUuid !== 'new' && currentUser.organisations.length > 0 &&
              <div className="d-flex align-items-center">
                <Link to="/builder/forms/new/build" className="d-flex align-items-center text-link-in-nav"> <FaPlus className="me-1 plus-inside-btn"/>New Form</Link>
              </div>}
          </div>
          <div className="d-flex">
            {formUuid !== 'new' && (pathLookUp === 'build') &&
            <div className="d-flex align-items-center">
              <FeatureRequestButton
                featureType={'Conditional Logic'}
                page={'Build'}
                mixpanel={mixpanel}
                buttonContent={<><TbArrowsSplit2 size="18px" className="mb-1"/> Conditional Logic</>}
                buttonClassList={['btn-style-removed']}
                popoverClassList={['']}
                popoverPlacement={'bottom-end'}
                interestMessageContent={<>
                  <h5 className="fs-5 mb-3 fw-light">Conditional logic will allow you to show/hide some questions based on the answer to a previous question.</h5>
                  <p>Click the button below if you'd like us to email you when conditional logic is live.</p>
                </>}
                feedbackMessageContent={<>
                  <p>If you have any suggestions on how conditional logic should work in Zuko Form Builder please feel free to share them below:</p>
                </>}
              />
              <Button variant="outline-primary" className="ms-3 builder-outline-primary-cta" onClick={() => setShowPreview(true)} disabled={!form || (form?.uuid === 'new')}>Preview</Button>
              <Button className="ms-3 me-0 builder-primary-cta" disabled={!form || (form?.uuid === 'new')} onClick={() => handleTriggerPublishForm()}>Publish</Button>
            </div>}
          </div>
        </div>
      </div>}
    </div>
    {showPreview && (pathLookUp === 'build') &&
        <Row className="g-0 exit-preview-row">
          <Col className="py-1 d-flex justify-content-center">
            <Button variant="outline-primary" className="builder-outline-primary-cta" onClick={() => setShowPreview(false)}>Back to Edit</Button>
          </Col>
        </Row>}
    <div className={`forms-pages-container ${showPreview ? 'preview-mode' : ''}`}>
      <Switch>
        <Route exact path={path}>
          <Build
            mixpanel={mixpanel}
            setCanPublish={setCanPublish}
            showPreview={showPreview}
            showCheckoutSuccessMsg={showCheckoutSuccessMsg}
            setShowCheckoutSuccessMsg={setShowCheckoutSuccessMsg} />
        </Route>
        <Route path={`${path}/build`}>
          <Build
            mixpanel={mixpanel}
            setCanPublish={setCanPublish}
            showPreview={showPreview} />
        </Route>
        <Route path={`${path}/share-embed`}>
          <Share mixpanel={mixpanel} hostedFormUrl={hostedFormUrl} handleUnpublishForm={handleUnpublishForm} />
        </Route>
        <Route path={`${path}/submissions`}>
          <Submissions mixpanel={mixpanel} />
        </Route>
        <Route path={`${path}/integrations`}>
          <Integrations mixpanel={mixpanel} />
        </Route>
        <Route path={`${path}/analytics`}>
          <Analytics mixpanel={mixpanel} />
        </Route>
      </Switch>
      {form && <>
        <PublishModal
          show={showPublishModal}
          handleCloseModal={handleCloseModal}
          isPublishing={isPublishing}
          formUuid={formUuid}
          hostedFormUrl={hostedFormUrl}
          success={publishSuccess}
          error={publishError} />
        <UnpublishModal
          show={showUnpublishModal}
          handleCloseModal={handleCloseUnpublishModal}
          isUnpublishing={isUnpublishing}
          formUuid={formUuid}
          success={unpublishSuccess}
          error={unpublishError} />
      </>}
    </div>
  </>
  );
};

export default Builder;
