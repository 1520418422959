import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../../AppContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import { VscCheck, VscInfo } from "react-icons/vsc";
import api from '../../api';

const OrgEditClientsTab = ({org, currentUserIsStandard}) => {
  const location = useLocation();
  const [showAgencyClientCreatedMessage, setShowAgencyClientCreatedMessage] = useState();
  const { currentUser, setUser } = useContext(AppContext);

  // AgencyClient has just been created
  useEffect(() => {
    if (location.state && location.state.newAgencyClient) {
      setShowAgencyClientCreatedMessage(true);
    } else {
      setShowAgencyClientCreatedMessage(null);
    }
  }, [location]);

  // Refetch current user after adding a client to make sure their orgs are up to date
  useEffect(() => {
    if (location?.state?.newAgencyClient) {
      (async () => {
        try {
          const { data: { organisations } } = await api.get(`/users/${currentUser.uuid}/organisations`, {
            params: {
              include: ['forms'],
            }
          });
          setUser(prev => ({...prev, organisations}));
        } catch (e) { /* Do nothing as this is a background update */ }
      })();
    }

  }, [location?.state?.newAgencyClient, setUser, currentUser.uuid]);

  return (<>
    {showAgencyClientCreatedMessage &&
      <Row className="alert-row g-0">
        <Alert dismissible variant="success" closeVariant="white"
          onClose={() => setShowAgencyClientCreatedMessage(null)}>
          <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
          <p className="alert-text m-0">Client created. You need to next give them a <Link to="#sessionLimits">Session Limit</Link>, and you can then add their Forms and Users.</p>
        </Alert>
      </Row>}
    {org && <>
      {!currentUserIsStandard &&
      <Row className="alert-row g-0" id="users-info-alert">
        <Alert dismissible={false} variant={'info'}>
          <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
          <p className="alert-text m-0">All <Link to="#users">users</Link> in this agency will have access to all clients listed here. You can add your own client users to each individual client.</p>
        </Alert>
      </Row>}
      <Table responsive id="org-clients-table">
        <thead>
          <tr>
            <th className="w-25">Name</th>
            <th className="link-col"></th>
            <th className="link-col"></th>
            <th className="link-col"></th>
          </tr>
        </thead>
        <tbody>
          {org?.clients?.map(({name, uuid}, i) => (
            <tr key={`client-${i}`}>
              <td className="w-25">{name}</td>
              <td className="link-col"><Link to={`/organisations/${uuid}/edit`} data-testid={`edit-agency-client-${uuid}`}>Edit Details</Link></td>
              <td className="link-col"><Link to={`/organisations/${uuid}/edit#users`} data-testid={`agency-client-${uuid}-users`}>Users</Link></td>
              <td className="link-col"><Link to={`/organisations/${uuid}/edit#forms`} data-testid={`agency-client-${uuid}-forms`}>Forms</Link></td>
            </tr>
          ))}
        </tbody>
      </Table>
      {!currentUserIsStandard &&
      <Row className="g-0 card-bottom-tools">
        <Col className="p-0 d-flex justify-content-end">
          <Link to={{
            pathname: `/organisations/${org.uuid}/agency_clients/new`,
            state: {agency: org},
          }}>
            <Button className="action-btn me-0">Add New Client</Button>
          </Link>
        </Col>
      </Row>}
    </>}
  </>);
};

export default OrgEditClientsTab;
