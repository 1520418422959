import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { VscTrash, VscChevronLeft, VscChevronRight } from 'react-icons/vsc';
import { FaPlus } from 'react-icons/fa6';


const StepActionsRow = ({
  currentStepIndex,
  showSubmitted,
  setShowSubmitted,
  setIdentifyItem,
  builderSteps,
  setCurrentStepIndex,
  handleDeleteStep,
  handleCreateNewStep,
  formHasBeenCreated,
  showPreview,
}) => {

  return (
    <Row className={`g-0 flex-grow-1 step-actions-row bottom-sticky-row w-100 justify-content-between`}>
      <Col className={`p-0 d-flex align-items-center justify-content-end`}>
        {showSubmitted &&
        <button className="btn-style-removed text-nowrap changing-step-ctas d-flex align-items-center" onClick={() => {
          setShowSubmitted(false);
          setIdentifyItem(null);
        }}>
          <VscChevronLeft className="mex-1" size="24px" />Back to Form
        </button>}
        {!showSubmitted && builderSteps?.length > 1 && <>
          {currentStepIndex !== 0 &&
          <button className="me-3 btn-style-removed text-nowrap changing-step-ctas d-flex align-items-center" onClick={() => {
            setCurrentStepIndex(prev => prev - 1);
            setIdentifyItem(null);
          }}>
            <VscChevronLeft className="mex-1" size="24px" />Previous Step
          </button>}
          {!showPreview && <Button variant="outline-primary" className="step-action-btns me-2 text-nowrap px-3 d-flex align-items-center" onClick={() => handleDeleteStep({stepIndex: currentStepIndex})}>
            <VscTrash size="16px" className="me-1" />Delete Step</Button>}
        </>}
      </Col>
      <Col className={`p-0 d-flex align-items-center justify-content-start`}>
        {!showSubmitted && <>
          {!showPreview && <Button variant="outline-primary" className="step-action-btns ms-2 px-3 text-nowrap d-flex align-items-center" onClick={() => handleCreateNewStep()} disabled={!formHasBeenCreated}>
            <FaPlus size="16px" className="me-1"/>Add Step</Button>}
          {builderSteps && (currentStepIndex !== builderSteps.length - 1) &&
          <button className="ms-3 btn-style-removed text-nowrap changing-step-ctas d-flex align-items-center" onClick={() => {
            setCurrentStepIndex(prev => prev + 1);
            setIdentifyItem(null);
          }}>
            Go to Next Step<VscChevronRight className="msx-1" size="24px"/>
          </button>}
          {(!formHasBeenCreated || (builderSteps && (currentStepIndex === builderSteps.length - 1))) &&
        <button className="ms-3 btn-style-removed changing-step-ctas text-nowrap d-flex align-items-center" onClick={() => {
          setShowSubmitted(true);
          setIdentifyItem(null);
        }} disabled={!formHasBeenCreated}>
          Completion Page<VscChevronRight className="msx-1" size="24px"/>
        </button>}
        </>}
      </Col>
    </Row>
  );
};

export default StepActionsRow;
