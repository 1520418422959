import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { MdContentCopy } from 'react-icons/md';

const ReadOnlyFormItems = ({form,
}) => {
  const [formUuidCopyMsg, setFormUuidCopyMsg] = useState();
  const [showFormUuidCopyMsg, setShowFormUuidCopyMsg] = useState(false);
  const [slugCopyMsg, setSlugCopyMsg] = useState();
  const [showSlugCopyMsg, setShowSlugCopyMsg] = useState(false);

  const handleFormUuidCopy = async () => {
    try {
      await navigator.clipboard.writeText(form.uuid);
      setFormUuidCopyMsg('Copied!');
    } catch (e) {
      setFormUuidCopyMsg('Sorry, failed to copy text to clipboard. Please try copying the text manually.');
    } finally {
      setShowFormUuidCopyMsg(true);
    }
  };

  const handleSlugCopy = async () => {
    try {
      await navigator.clipboard.writeText(form.slug);
      setSlugCopyMsg('Copied!');
    } catch (e) {
      setSlugCopyMsg('Sorry, failed to copy text to clipboard. Please try copying the text manually.');
    } finally {
      setShowSlugCopyMsg(true);
    }
  };

  // Remove copy msg after a short time
  useEffect(() => {
    if (showFormUuidCopyMsg) setTimeout(() => {setShowFormUuidCopyMsg(null);}, 2500);
    if (showSlugCopyMsg) setTimeout(() => {setShowSlugCopyMsg(null);}, 2500);
  },[showFormUuidCopyMsg, showSlugCopyMsg]);

  return (
    <Form className="zuko-app-form form-readonly-info mt-3">
      <FormGroup className="form-group" controlId="formUuid">
        <Form.Label>Form Uuid</Form.Label>
        <Col>
          <InputGroup>
            <Form.Control readOnly type="text" defaultValue={form ? form.uuid : ''}/>
            <OverlayTrigger show={showFormUuidCopyMsg ? true : false}
              onExited={() => setFormUuidCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
              overlay={
                <Tooltip id="copy-text-tooltip">{formUuidCopyMsg}</Tooltip>}>
              <InputGroup.Text className="text-copy-container" onClick={handleFormUuidCopy}>
                <MdContentCopy size="20px" className="grey-icon" title="Copy Form Uuid"/>
              </InputGroup.Text>
            </OverlayTrigger>
          </InputGroup>
          <div className="input-feedback">This unique identifier for this form can be used when exporting data from the Zuko API.</div>
        </Col>
      </FormGroup>

      <FormGroup className="form-group mb-1" controlId="formSlug">
        <Form.Label>Form ID (Slug)</Form.Label>
        <Col>
          <InputGroup>
            <Form.Control readOnly type="text" defaultValue={form ? form.slug : ''}/>
            <OverlayTrigger show={showSlugCopyMsg ? true : false}
              onExited={() => setSlugCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
              overlay={
                <Tooltip id="copy-text-tooltip">{slugCopyMsg}</Tooltip>}>
              <InputGroup.Text className="text-copy-container" onClick={handleSlugCopy}>
                <MdContentCopy size="20px" className="grey-icon" title="Copy Slug"/>
              </InputGroup.Text>
            </OverlayTrigger>
          </InputGroup>
          <div className="input-feedback">This brief and unique ID (also known as a Slug) is used in the Tracking Code. Zuko uses the <i>Slug</i>, together with the domain, to track your form.</div>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default ReadOnlyFormItems;
