import { useContext, useRef } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import EditInlineIcon from '../EditInlineIcon';
import { useShowEditIcon } from '../../hooks';

const Title = ({ text, stepIndex }: { text: string, stepIndex: number }) => {
  const { inEdit, handleSaveInlineEditItem, handleRemoveMessages } = useContext(SessionContext) as SessionContextType;
  const [showEditIcon, setShowEditIcon] = useShowEditIcon({type: 'step-title', stepIndex});
  const editableContentRef = useRef<HTMLHeadingElement>(null);

  return inEdit ? <span className="mark d-inline-flex align-items-center"><h2 className="title editable-inline"
    ref={editableContentRef}
    contentEditable={inEdit}
    suppressContentEditableWarning={true}
    spellCheck="false"
    onClick={() => setShowEditIcon(false)}
    onBlur={(e) => {
      if (!inEdit) return;
      handleRemoveMessages();
      if (text !== e.target.innerText) handleSaveInlineEditItem({type: 'step-title', item: e.target.innerText, stepIndex});}}>
    <span className="text-cursor">{text}</span></h2>
  {showEditIcon && <EditInlineIcon setShowEditIcon={setShowEditIcon} itemRef={editableContentRef} />}</span> :
    <h2 className="title">{text}</h2>;
};

export default Title;
