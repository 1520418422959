import { isValidWithValue } from '../../utils';

const Month = ({ questionId, questionRequired, monthId, monthRef, monthIsValid, onChange, monthValue, setMonthIsValid,
  inEdit }: {
  questionId: string,
  questionRequired: boolean,
  monthId: string,
  monthRef: React.RefObject<HTMLInputElement>,
  monthIsValid: boolean | null | undefined,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  monthValue: string,
  setMonthIsValid: Function,
  inEdit?: boolean,
}) => {
  return <div className="field">
    <label htmlFor={inEdit ? '' : monthId}>Month</label>
    <input
      type="text"
      required={questionRequired}
      id={monthId}
      ref={monthRef}
      inputMode="numeric"
      maxLength={2}
      pattern="^(0?[1-9]|1[012])$"
      className={`month ${monthIsValid === false ? 'is-invalid' : ''}`}
      onChange={onChange}
      value={monthValue}
      aria-describedby={`${questionId}-hint`}
      aria-invalid={monthIsValid === false}
      aria-errormessage={monthIsValid === false ? `${questionId}-error` : ''}
      onBlur={({target}) => setMonthIsValid(isValidWithValue({target, required: questionRequired}))} />
  </div>;
};

export default Month;
