import{ useContext, useState, useEffect, useCallback } from 'react';
import { usePrevious } from '../../hooks.js';
import AppContext from '../../AppContext.js';
import {
  labelForField,
  htmlAttrsForIdentifier,
} from '../../utils.js';
import api from '../../api.js';

import { Time, Form, Field, FilterForSelect } from '../../types/types.ts';

const useFetchAbandonedFields = () => {
  const {
    query,
  } = useContext(AppContext);

  const [availableAbandonedFields, setAvailableAbandonedFields] = useState<Field[] | undefined>();
  const [abandonedFieldsLoading, setAbandonedFieldsLoading] = useState(false);
  const [abandonedFieldsError, setAbandonedFieldsError] = useState<string | null>(null);

  const {
    time,
    form,
    filters,
  }: {
    time:Time,
    form: Form,
    filters: FilterForSelect[],
  } = query || {};

  const prevForm = usePrevious(form);
  const prevTime = usePrevious(time);
  const prevFilters = usePrevious(filters);

  const fetchAbandonedFields = useCallback(async () => {
    try {
      setAbandonedFieldsLoading(true);
      setAbandonedFieldsError(null);

      const { data: { fields } } = await api.get(`/fields/abandoned`, {
        params: {
          formUuid: form?.uuid,
          time: {
            start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
          filters: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      setAvailableAbandonedFields(fields
        .map(field => {
          field = {...field, ...htmlAttrsForIdentifier(field.value)};
          return {...field, identifier: field.value, label: labelForField(field)};
        })
        .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true));
      setAbandonedFieldsLoading(false);
    } catch (e) {
      setAbandonedFieldsError('Sorry something went wrong loading abandoned fields');
      setAbandonedFieldsLoading(false);
    }
  }, [form?.uuid, time?.start, time?.end, filters]);

  // Initial load
  useEffect(() => {
    // First loaded page after login - so wait for default time to be set
    if ((prevForm?.uuid && form?.uuid && (prevForm.uuid === form.uuid)) && (!prevTime && time?.start)) {
      fetchAbandonedFields();
    }

    // Moved to page from another
    if ((!prevForm && form?.uuid) && (!prevTime && time?.start)) {
      fetchAbandonedFields();
    }
  }, [form, time, fetchAbandonedFields, prevForm, prevTime]);

  // Form changed
  useEffect(() => {
    if (form?.uuid && prevForm?.uuid && (prevForm.uuid !== form.uuid)) {
      fetchAbandonedFields();
    }
  }, [form?.uuid, fetchAbandonedFields, prevForm]);

  // Time changed
  useEffect(() => {
    if (time?.start && time?.end && prevTime?.start && prevTime?.end &&
      (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
    ) {
      fetchAbandonedFields();
    }
  }, [time, fetchAbandonedFields, prevTime]);

  // Filters changed
  useEffect(() => {
    if (form?.uuid && time?.start && // Required
    ((!prevFilters && filters?.length) || // Filters first set
    (prevFilters?.length && !filters?.length) || // Filters have been removed
    (!prevFilters?.length && filters?.length) || // Filters have been added
    (prevFilters?.length !== filters?.length) // Filters have changed
    )) {
      fetchAbandonedFields();
    }

  }, [form?.uuid, time?.start, filters, prevFilters, fetchAbandonedFields]);

  return {abandonedFieldsLoading, abandonedFieldsError, availableAbandonedFields};
};

export default useFetchAbandonedFields;
