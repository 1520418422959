import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import DOMPurify from 'dompurify';
import { config } from '../form-components/helpers/sanitizer';

import { HiLink } from 'react-icons/hi';
import { VscChromeClose } from "react-icons/vsc";

// TODO: support pasting in a link and recognising it, so save it as a link
// TODO: support trying to add a link to a span of text which already includes a link i.e. selecting multiple nodes
// TODO: fix positioning of the popover when on a small screen as it causes a scroll which doesn't allow the other columns to go to the bottom

const ContentEditableInput = ({
  innerHtml,
  saveText,
  updateText,
  autoFocus,
  maxChars,
  minChars,
  label,
  handleRemoveMessages,
}) => {
  // TODO: make character limit clearer to the user by limiting on the innerText, not HTML (which includes non-text <a> elements)
  const [innerHtmlCharCount, setInnerHtmlCharCount] = useState();
  const [overCharLimit, setOverCharLimit] = useState();
  const [underCharLimit, setUnderCharLimit] = useState();
  const [saveAttempted, setSaveAttempted] = useState();
  const [linkText, setLinkText] = useState();
  const [linkUrl, setLinkUrl] = useState();
  const [cursor, setCursor] = useState();
  const [showLinkConfig, setShowLinkConfig] = useState();
  const [linkNodeInEdit, setLinkNodeInEdit] = useState();

  const contentEditableRef = useRef();
  const inputId = 'content-editable-box';

  const handleBlur = (e) => {
    const santized = DOMPurify.sanitize(e.target.innerHTML, config);

    // Always store the inputted text in state
    updateText({text: encodeURI(santized)});

    // Only save if valid
    if (santized.length <= maxChars && santized !== innerHtml &&
      (!minChars || (minChars && santized.length >= minChars))) {
      saveText({text: encodeURI(santized)});
    }
  };

  // Save current cursor configuration
  const setCursorForSelection = ({selection}) => {
    let nodeIndex;
    // Selection is an A node
    if (selection.focusNode.parentElement.tagName === 'A') {
      nodeIndex = [...contentEditableRef.current.childNodes].findIndex(n => n === selection.focusNode.parentNode);
    } else {
      // Selection is a text node
      nodeIndex = [...contentEditableRef.current.childNodes].findIndex(n => n === selection.focusNode);
    }

    const [startOffset, endOffset] = [selection.anchorOffset, selection.focusOffset].sort((a,b) => a-b);

    setCursor({
      nodeIndex,
      start: startOffset,
      end: endOffset,
      selectedText: selection.focusNode?.textContent?.slice(startOffset, endOffset) || '',
    });
  };

  const handleSaveLink = () => {
    if (!saveAttempted) setSaveAttempted(true);
    if (!linkUrl || !linkText) return;

    // TODO: review if we actually don't need to require that a full URL is set. Review formats allowed in hrefs e.g. when used for anchor links
    const cleanedUrl = linkUrl.replace(/\s+/g, '');
    let urlWithProtocol;

    const isUrlValid = (url) => {
      try {
        const newUrl = new URL(url);
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
      } catch (err) {
        return false;
      }
    };

    // First check if the inputted url is valid.
    if (!isUrlValid(cleanedUrl)) {
      // Include a protocol if missing
      if (!/^https?:\/\//i.test(cleanedUrl)) {
        urlWithProtocol = 'https://' + cleanedUrl;
      }
    }

    const linkToSave =  urlWithProtocol || cleanedUrl;

    // Editing a link
    if (linkNodeInEdit) {
      const node = contentEditableRef.current.childNodes[cursor?.nodeIndex];
      node.href = linkToSave;
      node.textContent = linkText;
    }

    // Creating a link
    if (!linkNodeInEdit) {
      // TODO: add spaces either side of the link if there aren't any
      // TODO: find a better way to add the link attrs target and rel, - rather than hard coded in 4 places

      // Inserting into current single space
      if (cursor && cursor.nodeIndex >= 0 && (cursor?.start === cursor?.end)) {
        // Insert to the start of the whole input
        if (cursor.nodeIndex === 0 && cursor?.start === 0) {
          contentEditableRef.current.innerHTML = `<a href="${linkToSave}" target="_blank" rel="noopener noreferrer">${linkText}</a>` + contentEditableRef.current.innerHTML;
        } else {
          // Insert to the middle of the node or end of the input
          const nodes = contentEditableRef.current.childNodes;
          let node = nodes.item(cursor?.nodeIndex);
          let nodeText = node.nodeValue || node.textContent || '';
          const span = document.createElement('span');
          span.innerHTML = `${nodeText.slice(0,cursor?.start)}<a href="${linkToSave}" target="_blank" rel="noopener noreferrer">${linkText}</a>${nodeText.slice(cursor?.start)}`;
          contentEditableRef.current.replaceChild(span, node);
        }
      }

      // Insert across multiple characters
      if (cursor && cursor.nodeIndex >= 0 && (cursor?.start !== cursor?.end)) {
        const nodes = contentEditableRef.current.childNodes;
        const node = nodes.item(cursor?.nodeIndex);
        const nodeText = node.textContent || node.nodeValue || '';
        const span = document.createElement('span');
        span.innerHTML = `${nodeText.slice(0,cursor?.start)}<a href="${linkToSave}" target="_blank" rel="noopener noreferrer">${linkText}</a>${nodeText.slice(cursor?.end)}`;
        contentEditableRef.current.replaceChild(span, node);
      }

      // Insert to the end of the input as no cursor
      if (!cursor || (cursor && cursor.nodeIndex < 0)) {
        contentEditableRef.current.innerHTML = contentEditableRef.current.innerHTML + `<a href="${linkToSave}" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
      }
    }

    const santized = DOMPurify.sanitize(contentEditableRef.current.innerHTML, config);
    saveText({text: santized});
    setShowLinkConfig(false);
  };

  const processCursorChange = () => {
    const selection = document.getSelection();
    setCursorForSelection({selection});

    if (selection.focusNode.parentElement.tagName === 'A') {
      setLinkNodeInEdit(true);
      setLinkUrl(selection.focusNode.parentElement.href);
      setLinkText(selection.focusNode.textContent);
      setShowLinkConfig(true);
    } else {
      if (showLinkConfig) setShowLinkConfig(false);
    }
  };

  useEffect(() => {
    setInnerHtmlCharCount(innerHtml?.length);
  }, [innerHtml]);

  useEffect(() => {
    setOverCharLimit(maxChars && maxChars > 0 && innerHtmlCharCount > 0 && innerHtmlCharCount > maxChars);
  }, [maxChars, innerHtmlCharCount]);

  useEffect(() => {
    if (minChars) setUnderCharLimit(innerHtmlCharCount < minChars);
  }, [minChars, innerHtmlCharCount]);

  // Focus if empty
  useEffect(() => {
    if (autoFocus && !innerHtml && !contentEditableRef?.current.textContent) {
      contentEditableRef?.current?.focus();
    }
  }, [autoFocus, innerHtml, contentEditableRef]);

  return (<>
    <div className="d-flex justify-content-between">
      <Form.Label className="mt-auto">{label}</Form.Label>
      <div className="input-icon">
        <Button variant="outline-secondary" className="p-1 me-0 mb-1 icon-only-button"
          onClick={() => {
            if (showLinkConfig) return;
            setLinkText(cursor?.selectedText);
            setShowLinkConfig(true);
          }}>
          <HiLink size="20px" title="Click to insert a link"/>
        </Button>
      </div>
    </div>
    <OverlayTrigger placement="bottom-end" show={showLinkConfig === true} rootClose
      onExited={() =>  {
        setLinkText(null);
        setLinkUrl(null);
        setLinkNodeInEdit(false);
        setSaveAttempted(false);
        setCursor(null);
      }}
      overlay={
        <Popover id="link-config-popover">
          <Popover.Body>
            <Row className="g-0 header-row">
              <div className="d-flex justify-content-end align-items-center">
                <VscChromeClose size="16px" className="grey-icon" onClick={() => setShowLinkConfig(false)} title="Close"/>
              </div>
            </Row>
            <Form className="mt-1">
              <FormGroup className="form-group" controlId="link-url">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="url" value={linkUrl || ''} required maxLength={255} autoFocus={!linkUrl?.length}
                  className={((saveAttempted && !linkUrl)) && "invalid-input"}
                  onChange={({target: {value}}) => setLinkUrl(value)} />
                {((saveAttempted && !linkUrl)) && <div className="invalid-input-feedback">Please add a valid URL. For example: https://example-link.com</div>}
                <div className="input-feedback">Enter the full URL</div>
              </FormGroup>
              <FormGroup className="form-group" controlId="link-text">
                <Form.Label>Text</Form.Label>
                <Form.Control type="text" value={linkText || ''} maxLength={255} required
                  className={((saveAttempted && !linkText)) && "invalid-input"}
                  onChange={({target: {value}}) => setLinkText(value)} />
                {((saveAttempted && !linkText)) && <div className="invalid-input-feedback">Please enter the text to display</div>}
                <div className="input-feedback">The clickable text to display</div>
              </FormGroup>
              {!linkNodeInEdit &&
                <Row className="g-0 button-row">
                  <Button className="mx-0" variant="primary" onClick={() => handleSaveLink()}>Insert Link</Button>
                </Row>}
              {linkNodeInEdit &&
                <Row className="g-0 justify-content-between button-row">
                  <Button className="ms-0" variant="primary" onClick={() => handleSaveLink()}>Update Link</Button>
                  <Button variant="outline-primary" className="mx-1" onClick={() => {
                    const nodes = contentEditableRef.current.childNodes;
                    const currentLinkNode = nodes.item(cursor?.nodeIndex);
                    const newTextNode = document.createTextNode(currentLinkNode.textContent);
                    contentEditableRef.current.replaceChild(newTextNode, currentLinkNode);

                    saveText({text: contentEditableRef.current.innerHTML});
                    setShowLinkConfig(false);
                  }}>Remove Link</Button>
                </Row>}
            </Form>
          </Popover.Body>
        </Popover>}>
      <div
        id={inputId}
        ref={contentEditableRef}
        contentEditable={true}
        tabIndex="0"
        role="textbox"
        className={`editable-textarea ${(overCharLimit || underCharLimit) ? 'invalid-input' : ''}`}
        dangerouslySetInnerHTML={{__html: innerHtml || '' }}
        onBlur={(e) => handleBlur(e)}
        onInput={(e) => {
          setInnerHtmlCharCount(e.target.innerHTML.length);
          handleRemoveMessages();
        }}
        onKeyUp={() => {
          processCursorChange();
        }}
        onMouseUp={() => {
          processCursorChange();
        }}
      />
    </OverlayTrigger>
    {overCharLimit === true && <p className="mb-0 invalid-input-feedback">Please shorten the text. {innerHtmlCharCount}/{maxChars} characters used</p>}
  </>
  );
};

export default ContentEditableInput;
