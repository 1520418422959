import SuccessCheck from './SuccessCheck';

const InputSuccessWrapper = ({children, label}: {children: JSX.Element, label: string}) => <div className="input-success-wrapper">
  {children}
  <div className="success-check-container">
    <SuccessCheck label={`The question with the following label is successfully filled in: ${label}`} height={15} />
  </div>
</div>;

export default InputSuccessWrapper;
