import { useCallback, useContext, useMemo } from "react";
import Button from "./fields/Button";
import ProgressionContext, { ProgressionContextType } from "../contexts/ProgressionContext";
import FormContext, { FormContextType } from '../contexts/FormContext';
import SessionContext, { SessionContextType } from '../contexts/SessionContext';
import styleVariables from '../_variables.scss';
import './CTAs.scss';

function CTAs() {
  const { currentStepIndex, stepsLength, onBackButtonClick, onContinueButtonClick, onSubmitButtonClick, submissionErrorMessage } =
    useContext(ProgressionContext) as ProgressionContextType;
  const { style } = useContext(FormContext) as FormContextType;
  const { inEdit } = useContext(SessionContext) as SessionContextType;
  const { primaryButton } = style || {};
  const isMultiStep = stepsLength > 1;
  const errorMsg = <p className="submission-error">{submissionErrorMessage}</p>;

  const primaryButtonStyle = useMemo(() => ({
    ...primaryButton,
    "--primary-button-hover-background": (primaryButton?.hover?.background || styleVariables.primaryButtonHoverBackground)
  } as React.CSSProperties), [primaryButton]);

  const stepSlug = `step-${currentStepIndex + 1}`;
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === stepsLength - 1;

  const SubmitButton = useCallback(() => <Button onClick={onSubmitButtonClick} id={`${stepSlug}-submit`}
    className={`submit-btn ${inEdit ? 'pe-none' : ''}`} type="submit" style={primaryButtonStyle}>Submit</Button>
  , [inEdit, onSubmitButtonClick, primaryButtonStyle, stepSlug]);

  return <div className="cta-row">
    {submissionErrorMessage && errorMsg}
    <div className={`form-actions ${isMultiStep && isFirstStep ? 'first-step' : ''}`}>
      {!isMultiStep && <SubmitButton />}
      {isMultiStep && <>
        {!isFirstStep && <Button onClick={onBackButtonClick} id={`${stepSlug}-back`}
          className={`back-button ${inEdit ? 'pe-none' : ''}`}>Back</Button>}
        {!isLastStep && <Button onClick={onContinueButtonClick} id={`${stepSlug}-continue`}
          className={`continue-btn ${inEdit ? 'pe-none' : ''}`} style={primaryButtonStyle}>Continue</Button>}
        {isLastStep && <SubmitButton />}
      </>}
    </div>
  </div>;
}

export default CTAs;
