import { RefObject, useContext, useMemo } from 'react';
import React from 'react';
import styleVariables from '../_variables.scss';
import Form from './Form';
import FormContext, { FormContextType } from '../contexts/FormContext';
import ProgressionContext, { ProgressionContextType } from "../contexts/ProgressionContext";
import Title from './Title';
import ItemToConfigureWrapper from './ItemToConfigureWrapper';
import ProgressBar from './ProgressBar';
import Copy from './CopyWithLink';
import './FormCard.scss';

function FormCard({ formRef }: { formRef: RefObject<HTMLFormElement>}) {
  const { style, builderSteps, title, description = null } = React.useContext(FormContext) as FormContextType;
  const { accentColor } = style || {};
  const { currentStepIndex } = useContext(ProgressionContext) as ProgressionContextType;
  const questionKeys = useMemo(() =>
    builderSteps?.map(step => step.builderQuestions?.filter(q => q.componentType !== 'paragraphText').map((question: any) => question.key || question.title)).flat()
  , [builderSteps]);

  return <div className="form-card" style={{ "--accent-color": (accentColor || styleVariables.defaultAccentColor) } as React.CSSProperties}>
    {currentStepIndex === 0 && <>
      {title && <ItemToConfigureWrapper type={'form-title'}>
        <Title text={title} type={'form-title'} />
      </ItemToConfigureWrapper>}
      <ItemToConfigureWrapper
        type={'form-description'}
        noContent={description !== null ? false : true}
        stepIndex={currentStepIndex}>
        <Copy text={description} type={'form-description'} />
      </ItemToConfigureWrapper>
      {(title || description) && <hr />}
    </>}
    {builderSteps.length > 1 && <ProgressBar />}
    <Form step={builderSteps[currentStepIndex]} formRef={formRef} questionKeys={questionKeys}/>
  </div>;
}

export default FormCard;
