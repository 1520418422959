const tags = ['a', 'br'];

// Used where we only want to display the text content e.g. submission table header
export const forbidConfig = {
  FORBID_TAGS: tags,
};

export const config = {
  ALLOWED_TAGS: tags,
  ALLOWED_ATTR: ['href', 'target', 'rel'],
};
