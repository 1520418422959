import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import TabContainer from 'react-bootstrap/TabContainer';
import Nav from 'react-bootstrap/Nav';
import NavBar from '../../NavBar';
import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import api from '../../api';
import AppContext from '../../AppContext';

import './FormIntegrations.scss';

const FormIntegrations = ({mixpanel}) => {
  const location = useLocation();
  const history = useHistory();
  const { uuid: formUuid } = useParams();
  const { currentUser } = useContext(AppContext);
  const [form, setForm] = useState(location.state?.form);
  const [formLoading, setFormLoading] = useState(true);
  const [errorLoadingForm, setErrorLoadingForm] = useState(null);
  const [webhookSubscriptions, setWebhookSubscriptions] = useState([]);
  const [webhookLoadingError, setWebhookLoadingError] = useState();
  const [webhookSubscriptionsSaveInProgress, setWebhookSubscriptionsSaveInProgress] = useState(false);
  const [webhookSubscriptionsSaveAttempted, setWebhookSubscriptionsSaveAttempted] = useState(false);
  const [webhookSubscriptionsSaveError, setWebhookSubscriptionsSaveError] = useState(false);
  const [webhookSubscriptionsSaveMessage, setWebhookSubscriptionsSaveMessage] = useState('');

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    if (form) mixpanel.track('Page View', {
      page: 'Integrations',
      'Form Uuid': form.uuid,
      'Organisation Name': form.organisation.name,
      'Organisation Uuid': form.organisation.uuid,
      'Organisation Contract Type': form.organisation.contractType,
    });
  }, [mixpanel, currentUser.email, form]);

  useEffect(() => {
    const getForm = async () => {
      try {
        let { data: { form } } = await api.get(`/forms/${formUuid}`);
        setFormLoading(false);
        setForm(form);
      } catch (e) {
        setFormLoading(false);
        setErrorLoadingForm(true);
      }
    };
    // Linked to from another tab so use the existing form
    if (location.state && location.state?.form) {
      setFormLoading(false);
    } else {
      getForm();
    }
  }, [formUuid, location]);

  const loadWebhookSubscriptions = useCallback(async () => {
    try {
      setWebhookLoadingError(null);
      const {data: {subscriptions: {completed: subscriptions}}} = await api.get(`/forms/${form.uuid}/sessions/webhooks/subscriptions`);
      setWebhookSubscriptions(subscriptions);
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setWebhookLoadingError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setWebhookLoadingError('Something went wrong. Webhook could not load.');
      }
    }
  }, [form?.uuid, history]);

  useEffect(() => {
    if (form?.uuid) loadWebhookSubscriptions();
  }, [form?.uuid, loadWebhookSubscriptions]);

  const updateWebhookEndpointValue = (({target: {value: url}}) => {
    setWebhookSubscriptions([{url}]);
  });

  const saveWebhookEndpoint = (async (e) => {
    e.preventDefault();
    setWebhookSubscriptionsSaveAttempted(false);
    setWebhookSubscriptionsSaveInProgress(true);
    try {
      const {data: {subscription}} =
        await api.post(`/forms/${form.uuid}/sessions/webhooks/subscriptions`, {subscription: {url: webhookSubscriptions[0]?.url}});
      setWebhookSubscriptionsSaveError(false);
      setWebhookSubscriptionsSaveMessage('Webhook endpoint saved.');
      setWebhookSubscriptions([subscription]);
      mixpanel.track('Saved Webhook Endpoint', { page: 'Integrations', product: 'analytics' });
    } catch (e) {
      setWebhookSubscriptionsSaveError(true);
      setWebhookSubscriptionsSaveMessage('Something went wrong saving the webhook endpoint. Please check the URL and try again.');
    } finally {
      setWebhookSubscriptionsSaveAttempted(true);
      setTimeout(() => setWebhookSubscriptionsSaveAttempted(false), 3000);
      setWebhookSubscriptionsSaveInProgress(false);
    }
  });

  return (
    <Container fluid className="integrations page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Integrations</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Integrations'}
            org={form?.organisation}
            messageContent={'the integrations information'} />
          <AppAlerts showOrgAlerts={true} />
          <Row className="title-row g-0">
            <Col className="p-0">
              <h1 id="form-title">
                {form && form.label && form.url && form.organisation.name && (<>
                  {`${form.organisation.name} | ${form.label} | `} <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
                </>)}
              </h1>
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <TabContainer defaultActiveKey="integrations">
                <Row className="g-0 card-title-row justify-content-between">
                  <Col className="p-0 col-auto">
                    <Card.Title as="h3">Integrations</Card.Title>
                  </Col>
                  <Col className="p-0 col-auto">
                    <Nav variant="tabs" className="justify-content-end" id="card-header-nav-tabs">
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname: `/forms/${formUuid}/edit`, state: {form}}}>Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname: `/forms/${formUuid}/fields`, state: {form}}}>Label Fields</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="integrations">Integrations</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname: `/forms/${formUuid}/tracking_code`, state: {form}}}>Tracking Code</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row className="g-0 card-content">
                  <TabContent>
                    <TabPane eventKey="integrations">
                      {formLoading && <div className="text-center"><p><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading...</p></div>}
                      {errorLoadingForm && <div id="form-load-error"><h3>Something went wrong. Form Integrations could not load.</h3></div>}
                      <p className="card-tagline">Here you can configure integrations for your form:</p>
                      {webhookLoadingError && <p>{webhookLoadingError}</p>}
                      {!webhookLoadingError && <Form noValidate className="zuko-app-form" id="form-details-form" onSubmit={saveWebhookEndpoint}>
                        <Row className="form-title-row">
                          <Col className="p-0">
                            <h3>Session completion webhook</h3>
                          </Col>
                        </Row>
                        <FormGroup className="form-group" controlId="endpoint">
                          <Form.Label>Endpoint</Form.Label>
                          <Form.Control type="url" onChange={updateWebhookEndpointValue} required
                            value={(webhookSubscriptions && webhookSubscriptions[0]?.url) || ''}/>
                          <div className="input-feedback">  The full URL for your webhook endpoint. For example: https://example.com/hook</div>
                          {webhookSubscriptionsSaveAttempted && !webhookSubscriptionsSaveInProgress &&
                            <div className={webhookSubscriptionsSaveError ? "invalid-input-feedback" : "valid-input-feedback"}>
                              {webhookSubscriptionsSaveMessage}</div>}
                        </FormGroup>
                        <Row className="form-actions-row">
                          <Col className="p-0">
                            <Button variant="primary" className="submit m-0" type="submit">Save</Button>
                          </Col>
                        </Row>
                      </Form>}
                    </TabPane>
                  </TabContent>
                </Row>
              </TabContainer>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Container>
  );
};

export default FormIntegrations;
