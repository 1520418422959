
import LoadingIndicator from './LoadingIndicator';

export const loadingOptions = [{selectable: false, value: null, label: <LoadingIndicator/>}];

export const formatGroupLabel = (group) => {
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
      <span style={{ color: '#3F4047', fontSize: 14, fontWeight: 500 }}>
        {group.label}
      </span>
      <span style={{display: 'inline-flex',alignItems: 'center'}}>
        <span style={{
          backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 14,
          fontWeight: "normal", lineHeight: "1", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
          {group.options.length}
        </span>
      </span>
    </div>);
};