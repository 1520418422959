
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { useContext, useEffect, useRef } from 'react';
import Hint from '../question/HintWithLink';
import LabelWithLink from '../question/LabelWithLink';
import { CheckboxInterface } from './interfaces/CheckboxInterface';
import { useInputIsValid } from '../../hooks';

const Checkbox = ({ invalidFeedback, question, stepIndex, questionIndex }: {question: CheckboxInterface, invalidFeedback: JSX.Element|JSX.Element[],
  stepIndex: number, questionIndex: number}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { values, setValues, zuko } = useContext(SessionContext) as SessionContextType;
  const { id, label, hint = null, required } = question;
  const value = values[question.id] ?? '';
  const [isValid, setIsValid] = useInputIsValid({value: value.toString(), inputRef, required: required});

  const classList: string[] = [];
  if (required) {
    if (isValid === false) classList.push('is-invalid');
    // NB. is-valid style is not applied
  }

  useEffect(() => {
    if (isValid === true || isValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${isValid ? 'valid' : 'invalid'}: ${id}`);
    }
  }, [isValid, id, zuko]);

  const onChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [id]: checked });
    if (required) setIsValid(checked);
  };

  return <div className="single-checkbox">
    <div className="checkbox-item">
      <input {...question}
        type="checkbox"
        ref={inputRef}
        onChange={onChange}
        aria-describedby={`${id}-hint`}
        aria-invalid={isValid === false}
        aria-errormessage={isValid === false ? `${id}-error` : ''}
        className={classList.join(' ')}
        checked={values[id] === true} />
      <LabelWithLink id={id} label={label} required={required} stepIndex={stepIndex} questionIndex={questionIndex} type={'question-title'} />
    </div>
    <Hint id={`${id}-hint`} text={hint} stepIndex={stepIndex} questionIndex={questionIndex} />
    {invalidFeedback}
  </div>;
};

export default Checkbox;
