import { RefObject, useEffect, useRef, useState, useContext } from 'react';
import ProgressionContext, { ProgressionContextType } from './contexts/ProgressionContext';
import SessionContext, { SessionContextType } from './contexts/SessionContext';
import { isValidWithValue } from './utils';

export function usePrevious(value: string) {
  const ref = useRef<string | undefined>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useInputIsValid = ({value, inputRef, required}: {value: string | undefined, inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>, required: boolean}) => {
  const { attemptedSubmitAt } = useContext(ProgressionContext) as ProgressionContextType;
  const [ forceValidityCheck, setForceValidityCheck] = useState<boolean | null>(null);
  const [isValid, setIsValid] = useState<boolean | undefined | null>();
  const prevValue = usePrevious(value as string);

  // Detects automatic input of >3 chars
  useEffect(() => {
    if (inputRef?.current && (!prevValue || prevValue?.length < 3) && value && value.length > 3) {
      setIsValid(
        isValidWithValue({target: inputRef?.current, required})
      );
    }
  }, [value, prevValue, inputRef, required]);

  // On Back/Forward, adds success validation to inputs
  useEffect(() => {
    if (inputRef?.current &&
          isValidWithValue({target: inputRef?.current, required})) {
      setIsValid(true);
    }
  }, [inputRef, required]);

  // When attempted continue/submit check validity
  useEffect(() => {
    if (attemptedSubmitAt) setForceValidityCheck(true);
  }, [attemptedSubmitAt]);

  useEffect(() => {
    if (forceValidityCheck && inputRef?.current) {
      setForceValidityCheck(false);
      setIsValid(
        isValidWithValue({target: inputRef.current, required})
      );
    }
  }, [forceValidityCheck, required, inputRef]);


  return [isValid, (newValidity: boolean | null) => setIsValid(newValidity)] as const;
};

export const useShowEditIcon = ({type, stepIndex, questionIndex }: {
  type: string,
  stepIndex?: number,
  questionIndex?: number,
}) => {
  const { identifyItem } = useContext(SessionContext) as SessionContextType;
  const [showEditIcon, setShowEditIcon] = useState(true);

  // Show the icon again when the current item is no longer being edited
  useEffect(() => {
    switch (type) {
    case 'form-description':
      if (!(identifyItem && identifyItem.type === 'form-description')) setShowEditIcon(true);
      break;
    case 'step-description':
      if (!(identifyItem && identifyItem.type === 'step-description' && (identifyItem.stepIndex === stepIndex))) setShowEditIcon(true);
      break;
    case 'paragraph-text':
      if (!(identifyItem && identifyItem.type === 'paragraph-text' && (identifyItem.questionIndex === questionIndex))) setShowEditIcon(true);
      break;
    case 'question':
      if (!(identifyItem && identifyItem.type === 'question' && (identifyItem.questionIndex === questionIndex))) setShowEditIcon(true);
      break;
    case 'form-title':
      if (!(identifyItem && identifyItem.type === 'form-title')) setShowEditIcon(true);
      break;
    case 'step-title':
      if (!(identifyItem && identifyItem.type === 'step-title' && (identifyItem.stepIndex === stepIndex))) setShowEditIcon(true);
      break;
    case 'completion-title':
      if (!(identifyItem && identifyItem.type === 'completion-title')) setShowEditIcon(true);
      break;
    case 'completion-message':
      if (!(identifyItem && identifyItem.type === 'completion-message')) setShowEditIcon(true);
      break;
    default:
      return;
    }
  }, [identifyItem, stepIndex, type, questionIndex]);

  return [showEditIcon, (show: boolean) => setShowEditIcon(show)] as const;
};
