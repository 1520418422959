import React, { useEffect, useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import api from '../../api';
import { chunk } from '../../utils';
import { FaAngleDown, FaAngleUp, FaSpinner } from "react-icons/fa";
import { CSSTransition } from 'react-transition-group';
import { msTimestampToDate } from '../../utils';
import AppContext from '../../AppContext';

const maxFieldsPerPage = 10;
const maxFieldsResultsSize = 200;

const ConfiguredFieldRow = ({
  mixpanel,
  rowIndex,
  hasUnconfiguredFields,
  hasMergedFields,
  formUuid,
  formOrgTimeZone,
  field,
  expandIDRow,
  expandNameRow,
  handleRemoveFromConfigured,
  updateLabel,
  labelErrors,
  handleHideField,
  provided,
  isDragging,
  showHiddenFields,
  enableMergedFieldToggle,
  handleDeleteField,
}) => {
  const { currentUser } = useContext(AppContext);
  const [matchedMergedFields, setMatchedMergedFields] = useState();
  const [pageMergedFields, setPageMergedFields] = useState();
  const [currentMergedFieldsPage, setCurrentMergedFieldsPage] = useState(0);
  const [matchedMergedFieldsLoading, setMatchedMergedFieldsLoading] = useState();
  const [matchedMergedFieldsError, setMatchedMergedFieldsError] = useState();
  const [showMergedFields, setShowMergedFields] = useState(false);
  const { identifier, targetAttribute, regexp, merged, enabled, htmlTagName, htmlType, htmlName, htmlId, unconfigured, hidden, label, lastTracked } = field;
  const hasMatchedMergedFields = matchedMergedFields?.unique.length > 0 && (matchedMergedFields?.unique.filter((f) => f.identifier === identifier).length > 0);
  const matchedMergedFieldsComplete = (matchedMergedFieldsLoading || matchedMergedFields || matchedMergedFieldsError);

  useEffect(() => {
    if (showMergedFields) {
      (async () => {
        try {
          setMatchedMergedFieldsLoading(true);
          setMatchedMergedFields(null);
          setMatchedMergedFieldsError(null);
          const { data: { fields } } = await api.get(`/fields/matching_merged`, {
            params: {
              formUuid,
              targetAttribute: targetAttribute || (htmlId && 'id') || (htmlName && 'name') || (htmlTagName && 'tagName') || (htmlType && 'type'),
              regexp: regexp || htmlId || htmlName || htmlTagName || htmlType,
              ...!unconfigured && {identifier},
            }
          });
          setMatchedMergedFields(fields);

          if (fields.unique.length > 10) setPageMergedFields(chunk(fields.unique, maxFieldsPerPage)[0]);

        } catch (e) {
          setMatchedMergedFieldsError(e.response && (e.response.status === 401) ? 'Not logged in' : "We're sorry, but an error occurred loading the fields. Please try again.");
        } finally {
          setMatchedMergedFieldsLoading(false);
        }
      })();
    }
  }, [showMergedFields, identifier, targetAttribute, regexp, htmlTagName, htmlType, htmlName, htmlId, unconfigured, formUuid]);

  const handleMergedFieldsPageChange = (e) => {
    const selectedPageIndex = e.selected;
    const chunks = chunk(matchedMergedFields.unique, maxFieldsPerPage);
    setCurrentMergedFieldsPage(selectedPageIndex);
    setPageMergedFields(chunks[selectedPageIndex]);
  };

  useEffect(() => {
    if (isDragging) setShowMergedFields(false);
  }, [isDragging]);

  return (<>
    <tr ref={provided.innerRef} {...provided.draggableProps}
      className={`field-row ${(rowIndex % 2) === 0 ? 'even' : 'odd'} ${matchedMergedFieldsComplete ? 'matched-merged-showing' : ''}`}>
      <td className={`grip-column ${hidden ? 'hidden-field-row' : ''}`}>
        <span {...provided.dragHandleProps} className={(label === null || label === undefined || label.length < 1) ? 'disabled-drag' : 'drag-icon'}><i className="fa fa-bars"/></span></td>
      <td className={`field-order text-center ${hidden ? 'hidden-field-row' : ''}`}>{rowIndex + 1}</td>
      <td className={`label-column ${hidden ? 'hidden-field-row' : ''}`}>
        <Form.Control className={`${hidden ? 'hidden-field-row' : ''} ${((labelErrors && labelErrors[identifier]) || (unconfigured && !label)) && "invalid-input"}`} aria-label="Label" type="text" value={label} required
          onChange={({target: {value}}) => updateLabel({identifier, value})}/>
        {labelErrors && labelErrors[identifier] && <div className="invalid-input-feedback">{labelErrors[identifier]}</div>}
        {!labelErrors[identifier] && unconfigured && !label && <div className="invalid-input-feedback">Please add a label.</div>}
      </td>
      <td className={`tagname-column ${hidden ? 'hidden-field-row' : ''}`} title={htmlTagName}>{htmlTagName}</td>
      <td className={`type-column ${hidden ? 'hidden-field-row' : ''}`} title={htmlType}>{htmlType}</td>
      <td className={`name-column ${!label ? 'label-empty' : ''} ${hidden ? 'hidden-field-row' : ''} ${expandNameRow ? 'expand' : ''}`} title={htmlName}
        onClick={() => !label && updateLabel({identifier, value: htmlName})}>{htmlName}</td>
      <td className={`id-column ${!label ? 'label-empty' : ''} ${hidden ? 'hidden-field-row' : ''} ${expandIDRow ? 'expand' : ''}`} title={htmlId}
        onClick={() => !label && updateLabel({identifier, value: htmlId})}>{htmlId}</td>
      <td className="text-center">{lastTracked ? msTimestampToDate({msTimestamp: lastTracked, timeZone: formOrgTimeZone}) : (!merged ? '>12 months' : '-')}</td>
      {hasMergedFields && <>
        <td className={`text-center ${hidden ? 'hidden-field-row' : ''}`} title={merged ? `Merged ${label}` : ''}>
          {merged &&
          <span><i className="fa fa-check"/>
            {enabled === false && <p className="mergedDisabled">(disabled)</p>}
            {!unconfigured && currentUser.accountManager && <div className="d-flex">
              <Form.Switch
                type="switch"
                id={identifier}
                value={identifier}
                checked={enabled}
                title={enabled ? 'Enabled' : 'Disabled'}
                onChange={({target: {checked}}) => enableMergedFieldToggle({identifier, enabled: checked})}></Form.Switch><span className="am-only-input-tag ms-2">AM only</span>
            </div>}
          </span>}
        </td>
        <td className="text-center">
          {merged &&
            <p className={`mb-0 clickable ${hidden ? 'hidden-field-row' : ''}`} onClick={() => {mixpanel.track('Opened Merged Fields', {page: 'Field Config'}); setShowMergedFields(!showMergedFields);}}>
              {(!matchedMergedFields && !matchedMergedFieldsError) ? 'Open' : 'Close'}{(!matchedMergedFields && !matchedMergedFieldsError) ? <FaAngleDown size="14px"/> : <FaAngleUp size="14px"/>}
            </p>
          }</td>
      </>}
      {showHiddenFields &&
        <td className="hide-column text-center">
          <Form.Switch
            type="switch"
            id={identifier}
            value={identifier}
            checked={hidden}
            title={hidden ? 'Hidden from data' : 'Shown in data'}
            onChange={() => handleHideField(rowIndex)}></Form.Switch>
        </td>}
      {hasUnconfiguredFields &&
      <td className="undo-column">
        {unconfigured &&
          <i className="fa fa-undo fa-lg action" title="Remove this field" onClick={() => handleRemoveFromConfigured(field)} data-testid={`remove-field-${identifier}`}/>}
      </td>}
      {currentUser?.accountManager && <td>
        {!unconfigured && !merged && <Button variant="outline-danger" onClick={() => handleDeleteField({identifier})}>Delete</Button>}
      </td>}
    </tr>
    <CSSTransition
      key={identifier}
      in={showMergedFields}
      timeout={300}
      classNames="slide"
      unmountOnExit
      onExited={() => setMatchedMergedFields(null)} // Only remove the match results on exit to allow them to remain visible during exit transition
    >
      <tr className="matched-merged-fields-row" {...provided.draggableProps}>
        <td colSpan={hasUnconfiguredFields ? 11 : 10} className={`matched-merged-fields-cell ${matchedMergedFieldsComplete ? 'matched-merged-showing' : ''}`}>
          <div className="p-3">
            <Row className="g-0">
              <Col className="p-0">
                <h4>Matched Fields</h4>
              </Col>
            </Row>
            {matchedMergedFieldsLoading && <FaSpinner size="18px" className="spinning-icon" title="Loading fields..."/>}
            {!matchedMergedFieldsLoading && matchedMergedFieldsError && <p className="mb-0">{matchedMergedFieldsError}</p>}
            {!matchedMergedFieldsLoading && matchedMergedFields && <>
              <h5>Total fields: {matchedMergedFields.total}</h5>
              {matchedMergedFields?.total === 0 && !unconfigured &&
                <p>This merging rule does not match any fields. If you need help with the rule, or would like to delete it, please contact <a href="mailto:support@zuko.io">Zuko support</a>.</p>}
              {matchedMergedFields?.total === 0 && unconfigured &&
                <p>This merging rule does not match any fields. If you need help creating a rule, please contact <a href="mailto:support@zuko.io">Zuko support</a>.</p>}
              {(matchedMergedFields?.total === 1 && matchedMergedFields?.unique[0].identifier === identifier) &&
                <p>The historic fields contained within the merging rule are below. If the rule was created more than 12 months ago some fields may have expired and will not be shown.</p>}
              {matchedMergedFields?.total > 0 && !hasMatchedMergedFields && !unconfigured &&
                <p>This merging rule matches unmerged fields, but there hasn't been any data for them received since the merging rule was created.</p>}
              {matchedMergedFields?.total > 1 && hasMatchedMergedFields && !unconfigured &&
                <p>This merging rule matches unmerged fields (from before the rule was created), and it has been applied to fields received after the rule was created.</p>}
              {matchedMergedFields?.total === 1 && unconfigured &&
                <p>This merging rule only matches one type of unmerged field. It is possible that a merging rule isn't necessary. If you need help creating a rule, please contact <a href="mailto:support@zuko.io">Zuko support</a>.</p>}
              {matchedMergedFields?.total > 1 && unconfigured &&
                <p>This merging rule matches the unmerged fields below. Once saved, all future tracked fields that match this rule will be combined into the single merged field.</p>}
              {matchedMergedFields?.total > 0 && <>
                <Table responsive borderless id="matched-merged-fields-table" data-testid="matched-merged-fields-table">
                  <thead>
                    <tr>
                      <th>Tag Name</th>
                      <th>Type</th>
                      <th>Name</th>
                      <th>ID</th>
                      {hasMatchedMergedFields && <th className="text-center">Merging Rule</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {(pageMergedFields || matchedMergedFields?.unique)?.length > 0 && (pageMergedFields || matchedMergedFields?.unique).map((f, i) =>
                      <tr key={`matched-${i}`}>
                        {f.identifier === identifier ? <>
                          <td>{htmlTagName}</td>
                          <td>{htmlType}</td>
                          <td>{htmlName}</td>
                          <td>{htmlId}</td>
                          <td className="text-center"><span><i className="fa fa-check"/></span></td>
                        </> : <>
                          <td>{f.htmlTagName}</td>
                          <td>{f.htmlType}</td>
                          <td>{f.htmlName}</td>
                          <td>{f.htmlId}</td>
                        </>}
                      </tr>
                    )}
                  </tbody>
                </Table>
                {pageMergedFields?.length && <>
                  {(currentMergedFieldsPage === (maxFieldsResultsSize/maxFieldsPerPage) - 1 && matchedMergedFields?.unique.length === maxFieldsResultsSize) &&
                      <div className="text-center">
                        <p>You have reached the maximum number of matched merged fields that can be displayed.</p>
                      </div>}
                  <ReactPaginate
                    pageCount={Math.ceil(matchedMergedFields?.unique.length / maxFieldsPerPage)}
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={0}
                    onPageChange={handleMergedFieldsPageChange}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    disabledClassName={'disabled'}
                    activeClassName={'active'}
                  />
                </>}
              </>}
            </>}
          </div>
        </td>
      </tr>
    </CSSTransition>
  </>);
};

export default ConfiguredFieldRow;