/**
 * @typedef {{
 *   uuid: String, name: String,
 * }} Organisation
 */

/**
 * @typedef {{
 *   uuid: String, name: String, slug: String, organisation: Organisation,
 * }} Form
 */

/**
 * @typedef {{
 *   uuid: String, form: Form, org: Organisation,
 * }} FieldSegmentComparison
 */

/**
 * @typedef {{
 *   uuid: String
 * }} FieldSegment
 */

import api from './api';

/**
 * Module for querying/reading the field segment comparisons stored in the API
 */
class FieldSegmentComparisons {
  /**
   * The various indices for comparisons
   * @type {Object} of indices
   * @private
   */
  static _indices = {
    uuid: {},
    form: { uuid: {} },
  };

  /**
   * Starts loading of comparisons from the API, if it's not already been started
   * @returns {Promise<*>}
   */
  static async load() {
    if (!this._loading) {
      this.clear();
      this._loading = api.get('/field_segment_comparisons').then(({ data: { comparisons } }) =>
        this._index(comparisons));
    }
    return this._loading;
  }

  /**
   * Holds the loading Promise so that actions can be 'queued' up off the back of loading comparisons
   * @private
   */
  static _loading;

  /**
   * Indexes the comparisons from the API, grouping them in various ways
   * @returns {FieldSegmentComparisons}
   * @private
   */
  static _index(comparisons) {
    const indices = this._indices;
    for (const comparison of comparisons) {
      delete comparison.orgName; // TODO: Remove when the API stops sending this key
      delete comparison.orgUuid; // TODO: Remove when the API stops sending this key

      // Key by UUID
      if (!indices.uuid.hasOwnProperty(comparison?.uuid)) indices.uuid[comparison?.uuid] = comparison;

      // Key by form UUID
      const {form} = comparison;
      if (!indices.form.uuid.hasOwnProperty(form?.uuid)) indices.form.uuid[form?.uuid] = form;
      if (!indices.form.uuid[form?.uuid].hasOwnProperty('comparisons')) indices.form.uuid[form?.uuid].comparisons = {};
      if (!indices.form.uuid[form?.uuid].comparisons.hasOwnProperty(comparison?.uuid))
        indices.form.uuid[form?.uuid].comparisons[comparison.uuid] = comparison;
    }
    return this;
  }

  /**
   * Remove any cached comparisons in the module
   * @returns {FieldSegmentComparisons}
   */
  static clear() {
    this._indices = {
      uuid: {},
      form: { uuid: {} },
    };
    this._loading = null;
    return this;
  }

  /**
   * Returns a count of comparisons
   * @returns {number}
   */
  static get length() {
    return Object.keys(this._indices.uuid).length;
  }

  /**
   * @param uuid FieldSegmentComparison UUID
   * @returns {FieldSegmentComparison} FieldSegmentComparison with the given UUID
   */
  static getByUuid(uuid) {
    return this._indices.uuid[uuid];
  }

  /**
   * @param uuid Form UUID
   * @returns {String: FieldSegmentComparison} FieldSegmentComparisons for the form with the given UUID
   */
  static getByFormUuid(uuid) {
    return this._indices.form.uuid[uuid];
  }

  /**
   * @returns {String: Array<FieldSegmentComparison>} Hash of comparisons, keyed by their UUIDs
   */
  static groupedByUuid() {
    return this._indices.uuid;
  }

  /**
   * @returns {String: Array<FieldSegmentComparison>} Comparisons keyed by the UUID of the form they belong to
   */
  static groupedByFormUuid() {
    return this._indices.form.uuid;
  }

  /**
   * Creates the comparison on the API
   * @param name {String} Name of the comparison
   * @param form {Form} The form that the comparison should belong to
   * @returns {Promise<FieldSegmentComparison>}
   */
  static async create({name, form}) {
    const { data: { comparison } } =
      await api.post(`/field_segment_comparisons`, {formUuid: form.uuid, comparison: {name}});
    Object.assign(comparison, {form: comparison.form, org: comparison.form.organisation, fieldSegments: []});
    this._index((Object.values(this._indices.uuid) || []).concat(comparison));
    return comparison;
  }

  /**
   * Updates the comparison on the API
   * @param comparison {FieldSegmentComparison}
   */
  static async update(comparison) {
    await api.put(`/field_segment_comparisons/${comparison.uuid}`, {comparison: {name: comparison.name}});
    const {form} = comparison;
    this._indices.uuid[comparison.uuid] = comparison;
    this._indices.form.uuid[form.uuid].comparisons[comparison.uuid] = comparison;
    return this;
  }

  /**
   * Deletes the provided comparison
   * @param comparison {FieldSegmentComparison}
   */
  static async delete(comparison) {
    await api.delete(`/field_segment_comparisons/${comparison.uuid}`);
    const {form} = comparison;
    delete this._indices.uuid[comparison.uuid];
    delete this._indices.form.uuid[form.uuid].comparisons[comparison.uuid];
    return this;
  }

  /**
   * Creates a comparison's segment on the API
   * @param comparison {FieldSegmentComparison}
   * @param filters {Object} The new segment's filters
   * @param order {Number} The new segment's order
   * @returns {FieldSegment} The created segment
   */
  static async createSegment({comparison, filters, order}) {
    const { data: { segment } } =
        await api.post(`/field_segments`, {comparisonUuid: comparison.uuid, filters, order});
    const {form, fieldSegments} = comparison;
    const newSegments = (fieldSegments || []).concat(segment);
    this._indices.uuid[comparison.uuid].fieldSegments = newSegments;
    this._indices.form.uuid[form.uuid].comparisons[comparison.uuid].fieldSegments = newSegments;
    return segment;
  }

  /**
   * Updates a comparison's segment on the API
   * @param comparison {FieldSegmentComparison}
   * @param segmentUuid {String} The segment to update
   * @param filters {Object} The segment's filters
   * @param order {Number} The segment's order
   * @returns {FieldSegment} The updated segment
   */
  static async updateSegment({comparison, segmentUuid, filters, order}) {
    const { data: { segment } } =
        await api.put(`/field_segments/${segmentUuid}`, {filters, order});
    const {form, fieldSegments} = comparison;
    const newSegments = fieldSegments.map(s => s.uuid === segmentUuid ? segment : s);
    this._indices.uuid[comparison.uuid].fieldSegments = newSegments;
    this._indices.form.uuid[form.uuid].comparisons[comparison.uuid].fieldSegments = newSegments;
    return segment;
  }


  /**
   * Updates the comparison's segments order on the API
   * @param comparison {FieldSegmentComparison}
   * @param segmentUuids {Array} New order of segments
   * @returns {FieldSegmentComparisons}
   */
  static async updateSegmentsOrder({comparison, segmentUuids}) {
    const { data: { segments: reOrderedSegments } } = await api.post('/field_segments/update_order', {segments: segmentUuids});
    const {form} = comparison;
    this._indices.uuid[comparison.uuid].fieldSegments = reOrderedSegments;
    this._indices.form.uuid[form.uuid].comparisons[comparison.uuid].fieldSegments = reOrderedSegments;
    return this;
  }

  /**
   * Deletes the provided comparison's segment
   * @param comparison {FieldSegmentComparison}
   * @param segmentUuid {String} The segment to delete
   * @returns {FieldSegmentComparisons}
   */
  static async deleteSegment({comparison, segmentUuid}) {
    await api.delete(`/field_segments/${segmentUuid}`);
    const {form, fieldSegments} = comparison;
    const newSegments = fieldSegments.filter(s => s.uuid !== segmentUuid);
    this._indices.uuid[comparison.uuid].fieldSegments = newSegments;
    this._indices.form.uuid[form.uuid].comparisons[comparison.uuid].fieldSegments = newSegments;
    return this;
  }
}

export default FieldSegmentComparisons;
