import React from 'react';
import FormContext, { FormContextType } from '../contexts/FormContext';
import { Link } from 'react-router-dom';
import AppContext from '../../../AppContext';
import './Footer.scss';
const ZukoLogo = require('../../../Zuko_Builder_Full_Colour_Logo.png');

const Footer = () => {
  const { organisation: { builderSubscriptionStatus: subscriptionStatus } = {} } =
    React.useContext(FormContext) as FormContextType;

  const { currentOrg: org } = React.useContext(AppContext);

  // Only show logo for clients without an active subscription
  return <footer>
    {subscriptionStatus !== 'active' && <>
      <div className="forms-by-zuko">
        <a href="https://zuko.io" target="_blank" rel="noopener noreferrer">
          Forms by <img id="zuko-builder-large-logo" src={ZukoLogo} alt="Zuko-logo"></img>
        </a>
      </div>
      <aside className="upgrade-prompt">
        <Link to={{pathname: `/builder/organisations/${org.uuid}/choose-plan`, state: {organisation: {name: org.name}}}}>
          Upgrade</Link> to remove the Zuko logo from your forms.
      </aside>
    </>
    }
  </footer>;
};

export default Footer;
