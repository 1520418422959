import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import qs from 'qs';

import Navbar from 'react-bootstrap/Navbar';
import api from '../api';

import './AlertConfirmSubscription.scss';

const AlertConfirmSubscription = () => {
  const location = useLocation();
  const {uuid: alertUuid} = useParams();
  const [confirmError, setConfirmError] = useState(null);
  const [recipient, setRecipient] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    const confirmSubscription = async () => {
      try {
        const { confirm_token: token } = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (!token) {
          setConfirmError('Please provide a token to confirm your subscription.');
        } else {
          const { data: { recipient } } = await api.put(`/alerts/${alertUuid}/subscriptions/${token}`, { confirm: true });
          if (recipient.email) setRecipient(recipient.email);
          setConfirmed(true);
        }
      } catch (e) {
        setConfirmError((e.response && (e.response.status === 404)) ? 'Sorry, the link you clicked seems to be invalid. You may have already confirmed your subscription to this alert.' :
          'Sorry, we were not able to confirm your subscription.');
      }
    };
    confirmSubscription();
  }, [alertUuid, location.search]);

  return (
    <Container fluid className="page" id="alerts-confirm-subscription-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Alerts</title>
      </Helmet>
      <div className="nav-wrapper">
        <Navbar expand="lg" variant="dark" id="main-nav">
          <Navbar.Brand as={Link} to="/"><div className="zuko-logo" role="img" alt="logo"></div></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        </Navbar>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <Row className="title-row g-0">
            <Col className="p-0">
              <h1>Alerts | Confirm Subscription</h1>
            </Col>
          </Row>
          <Row className="g-0">
            {!confirmError && !confirmed && <p><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading...</p>}
            {confirmed && !recipient && <p>Thank you, you have been subscribed to receive alerts.</p>}
            {confirmed && recipient && <p data-testid="alert-confirmed-msg">Thank you, {recipient} is now subscribed to receive alerts.</p>}
            {confirmError && <p data-testid="alert-error-msg">{confirmError}</p>}
          </Row>
        </Col>
      </div>
    </Container>
  );
};

export default AlertConfirmSubscription;
