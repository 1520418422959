import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Alert from 'react-bootstrap/Alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Card from 'react-bootstrap/Card';
import { VscChromeClose } from "react-icons/vsc";
import { FaCog, FaInfoCircle, FaPlus } from "react-icons/fa";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import 'font-awesome/css/font-awesome.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.css';
import Select from 'react-select';
import arrayMove from 'array-move';
import camelcaseKeys from 'camelcase-keys';

import NavBar from '../NavBar';
import DatePicker from '../Components/DatePicker';
import NoFormsMsg from '../Components/NoFormsMsg';
import AppAlerts from '../Components/AppAlerts';
import PrintPageHeader from '../Components/PrintPageHeader';
import ScrollToTop from '../Components/ScrollToTop';
import FeedbackRow from '../Components/FeedbackRow';
import CopyUrlIcon from '../Components/CopyUrlIcon';
import FiltersSelect from '../Components/Select/FiltersSelect';
import FunnelStage from './FunnelStage';
import AppContext from '../AppContext';
import api from '../api';
import { usePrevious, useAppQuery, useAppForms } from '../hooks';
import {
  compileQueryString,
  formatFiltersForSelectDropDown,
  defaultAttributes,
  formatFormSelectOptions
} from '../utils';
import FunnelChartImg from  '../images/FunnelChart.png';
import moment from 'moment-timezone';

import './FunnelBuilder.scss';
import Forms from '../forms';

// TODO: move to CSS. Can we count the rows of the first tbody to use when setting the completion shape (which is in the next tbody)?
const stylesByFunnelLength = [
  { // 1 stage
    clipPath: [
      'polygon(15% 0px, 85% 0px, 78% 50%, 74% 100%, 26% 100%, 22% 50%)',
    ],
    completion: 'polygon(26% 0, 74% 0, 65% 100%, 35% 100%)',
    inputWidth: [
      '40%',
    ],
  },
  { // 2 stages
    clipPath: [
      'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
      'polygon(5% 0px, 95% 0px, 80% 50%, 74% 100%, 26% 100%, 20% 50%)',
    ],
    completion: 'polygon(26% 0, 74% 0, 65% 100%, 35% 100%)',
    inputWidth: [
      '70%',
      '45%',
    ],
  },
  {  // 3 stages
    clipPath: [
      'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
      'polygon(5% 0, 95% 0, 85% 100%, 15% 100%)',
      'polygon(15% 0, 85% 0, 70% 50%, 64% 100%, 36% 100%, 30% 50%)',
    ],
    completion: 'polygon(36% 0, 64% 0, 60% 100%, 40% 100%)',
    inputWidth: [
      '70%',
      '60%',
      '30%',
    ],
  },
  { // 4 stages
    clipPath: [
      'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
      'polygon(5% 0, 95% 0, 85% 100%, 15% 100%)',
      'polygon(15% 0px, 85% 0px, 75% 100%, 25% 100%)',
      'polygon(25% 0px, 75% 0px, 66% 60%, 64% 100%, 36% 100%, 34% 60%)',
    ],
    completion: 'polygon(36% 0, 64% 0, 60% 100%, 40% 100%)',
    inputWidth: [
      '70%',
      '60%',
      '50%',
      '30%',
    ],
  },
  { // 5 stages
    clipPath: [
      'polygon(0 0, 100% 0, 95% 100%, 5% 100%)',
      'polygon(5% 0, 95% 0, 85% 100%, 15% 100%)',
      'polygon(15% 0px, 85% 0px, 75% 100%, 25% 100%)',
      'polygon(25% 0, 75% 0, 70% 100%, 30% 100%)',
      'polygon(30% 0px, 70% 0px, 66% 50%, 64% 100%, 36% 100%, 34% 50%)'
    ],
    completion: 'polygon(36% 0, 64% 0, 60% 100%, 40% 100%)',
    inputWidth: [
      '70%',
      '60%',
      '50%',
      '40%',
      '28%',
    ],
  },
  { // 6 stages
    clipPath: [
      'polygon(0px 0px, 100% 0px, 96% 100%, 4% 100%)',
      'polygon(4% 0px, 96% 0px, 92% 100%, 8% 100%)',
      'polygon(8% 0px, 92% 0px, 88% 100%, 12% 100%)',
      'polygon(12% 0px, 88% 0px, 84% 100%, 16% 100%)',
      'polygon(16% 0px, 84% 0px, 80% 100%, 20% 100%)',
      'polygon(20% 0px, 80% 0px, 75% 50%, 73% 100%, 27% 100%, 25% 50%)',
    ],
    completion: 'polygon(27% 0px, 73% 0px, 67% 100%, 33% 100%',
    inputWidth: [
      '70%',
      '60%',
      '55%',
      '50%',
      '45%',
      '32%',
    ],
  },
  { // 7 stages
    clipPath: [
      'polygon(0px 0px, 100% 0px, 96% 100%, 4% 100%)',
      'polygon(4% 0px, 96% 0px, 92% 100%, 8% 100%)',
      'polygon(8% 0px, 92% 0px, 88% 100%, 12% 100%)',
      'polygon(12% 0px, 88% 0px, 84% 100%, 16% 100%)',
      'polygon(16% 0px, 84% 0px, 80% 100%, 20% 100%)',
      'polygon(20% 0px, 80% 0px, 76% 100%, 24% 100%)',
      'polygon(24% 0px, 76% 0px, 71% 50%, 69% 100%, 31% 100%, 29% 50%)',
    ],
    completion: 'polygon(31% 0, 69% 0, 65% 100%, 35% 100%)',
    inputWidth: [
      '70%',
      '60%',
      '55%',
      '50%',
      '45%',
      '40%',
      '30%',
    ],
  },
  { // 8 stages
    clipPath: [
      'polygon(0px 0px, 100% 0px, 96% 100%, 4% 100%)',
      'polygon(4% 0px, 96% 0px, 92% 100%, 8% 100%)',
      'polygon(8% 0px, 92% 0px, 88% 100%, 12% 100%)',
      'polygon(12% 0px, 88% 0px, 84% 100%, 16% 100%)',
      'polygon(16% 0px, 84% 0px, 80% 100%, 20% 100%)',
      'polygon(20% 0px, 80% 0px, 76% 100%, 24% 100%)',
      'polygon(24% 0px, 76% 0px, 72% 100%, 28% 100%)',
      'polygon(28% 0px, 72% 0px, 69% 50%, 68% 100%, 32% 100%, 31% 50%)'
    ],
    completion: 'polygon(32% 0, 68% 0, 65% 100%, 35% 100%)',
    inputWidth: [
      '70%',
      '60%',
      '55%',
      '50%',
      '45%',
      '40%',
      '35%',
      '30%',
    ],
  },
  { // 9 stages
    clipPath: [
      'polygon(0px 0px, 100% 0px, 96% 100%, 4% 100%)',
      'polygon(4% 0px, 96% 0px, 92% 100%, 8% 100%)',
      'polygon(8% 0px, 92% 0px, 88% 100%, 12% 100%)',
      'polygon(12% 0px, 88% 0px, 84% 100%, 16% 100%)',
      'polygon(16% 0px, 84% 0px, 80% 100%, 20% 100%)',
      'polygon(20% 0px, 80% 0px, 76% 100%, 24% 100%)',
      'polygon(24% 0px, 76% 0px, 72% 100%, 28% 100%)',
      'polygon(28% 0px, 72% 0px, 68% 100%, 32% 100%)',
      'polygon(32% 0px, 68% 0px, 65% 50%, 64% 100%, 36% 100%, 35% 50%)',
    ],
    completion: 'polygon(36% 0, 64% 0, 60% 100%, 40% 100%)',
    inputWidth: [
      '70%',
      '60%',
      '55%',
      '50%',
      '45%',
      '40%',
      '35%',
      '32%',
      '28%',
    ],
  },
  { // 10 stages
    clipPath: [
      'polygon(0px 0px, 100% 0px, 96% 100%, 4% 100%)',
      'polygon(4% 0px, 96% 0px, 92% 100%, 8% 100%)',
      'polygon(8% 0px, 92% 0px, 88% 100%, 12% 100%)',
      'polygon(12% 0px, 88% 0px, 84% 100%, 16% 100%)',
      'polygon(16% 0px, 84% 0px, 80% 100%, 20% 100%)',
      'polygon(20% 0px, 80% 0px, 76% 100%, 24% 100%)',
      'polygon(24% 0px, 76% 0px, 72% 100%, 28% 100%)',
      'polygon(28% 0px, 72% 0px, 68% 100%, 32% 100%)',
      'polygon(32% 0px, 68% 0px, 66% 100%, 34% 100%)',
      'polygon(34% 0px, 66% 0px, 64% 100%, 36% 100%)',
    ],
    completion: 'polygon(36% 0px, 64% 0px, 60% 100%, 40% 100%)',
    inputWidth: [
      '70%',
      '60%',
      '55%',
      '50%',
      '45%',
      '40%',
      '35%',
      '32%',
      '30%',
      '26%',
    ],
  },
];

const FunnelBuilder = ({mixpanel}) => {
  const history = useHistory();
  useAppForms();
  const {
    currentUser,
    formsGroupedByOrg,
    formsGroupedByFormUuid,
    formsLoading,
    formsLoadingError
  } = useContext(AppContext);

  const {
    query,
    prevQuery,
  } = useAppQuery();
  const {
    form,
    time,
    filters,
  } = query || {};

  const [selectedForm, setSelectedForm] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [selectedFilters, setSelectedFilters] = useState();
  const [availableFilters, setAvailableFilters] = useState();
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersLoadingError, setFiltersLoadingError] = useState(null);
  const [cancelButtonEnabled, setCancelButtonEnabled] = useState(false);
  const [applyButtonEnabled, setApplyButtonEnabled] = useState(false);

  const [funnelStages, setFunnelStages] = useState();
  const [funnelStagesLoading, setFunnelStagesLoading] = useState(true);
  const [funnelStagesProgress, setFunnelStagesProgress] = useState(20);
  const [funnelStagesError, setFunnelStagesError] = useState(null);
  const [trackedFields, setTrackedFields] = useState();
  const [fieldsLookUp, setFieldsLookUp] = useState();
  const [trackedFieldsLoading, setTrackedFieldsLoading] = useState();
  const [trackedFieldsError, setTrackedFieldsError] = useState();
  const [isEditingFunnel, setIsEditingFunnel] = useState();
  const [attemptToCloseEdit, setAttemptToCloseEdit] = useState();
  const [isDragging, setIsDragging] = useState();

  const [createInProgress, setCreateInProgress] = useState();
  const [saveInProgress, setSaveInProgress] = useState();
  const [deleteInProgress, setDeleteInProgress] = useState();
  const [reorderInProgress, setReorderInProgress] = useState();
  const [createStageError, setCreateStageError] = useState();
  const [updateStageError, setUpdateStageError] = useState();
  const [deleteStageError, setDeleteStageError] = useState();
  const [reorderError, setReorderError] = useState();
  const [menuOpenAtIndex, setMenuOpenAtIndex] = useState();

  const [funnelData, setFunnelData] = useState(null);
  const [funnelDataLoading, setFunnelDataLoading] = useState();
  const [funnelDataError, setFunnelDataError] = useState(null);
  const [funnelDataProgress, setFunnelDataProgress] = useState(0);

  const [showInfo, setShowInfo] = useState(null);

  const prevTime = usePrevious(time);
  const prevForm = usePrevious(form);
  const prevFilters = usePrevious(filters);
  const prevSelectedTime = usePrevious(selectedTime);
  const prevSelectedForm = usePrevious(selectedForm);
  const prevFunnelStagesLoading = usePrevious(funnelStagesLoading);
  const prevTrackedFieldsLoading = usePrevious(trackedFieldsLoading);
  const prevIsEditingFunnel = usePrevious(isEditingFunnel);

  const defaultStage = useMemo(() => ({
    order: funnelStages?.length ? funnelStages[funnelStages.length -1].order + 1 : 1,
    name: null,
    fieldIdentifiers: [],
  }), [funnelStages]);

  const formUuid = form?.uuid;
  const orgName = form?.organisation?.name;

  useEffect(() => {
    // Identify the mixpanel user on page load for any subsequent tracking
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Send the page view mixpanel event each time current query reloads the charts
  useEffect(() => {
    if (form) {
      mixpanel.track('Page View', Object.assign({ page: 'FunnelBuilder' },  form.organisation?.uuid ? {
        'Organisation Name': form.organisation?.name,
        'Organisation Uuid': form.organisation?.uuid,
        'Organisation Contract Type': form.organisation?.contractType,
      } : {}));
    }
  }, [mixpanel, form]);

  // Forms error
  useEffect(() => {
    if (!formsLoading && formsLoadingError) {
      setFunnelStagesLoading(false);
      setFunnelDataError(formsLoadingError);
    }
  }, [formsLoading, formsLoadingError]);

  const loadStages = useCallback(async () => {
    const progressID = setInterval(() => setFunnelStagesProgress((prevProgress) => prevProgress + 30), 50);
    try {
      setFunnelStagesLoading(true);

      const { data: { funnelStages } } = await api.get(`/forms/${form.uuid}/funnels/stages`);
      funnelStages.sort((a, b) => a.order - b.order); // Sort stages on their defined order

      if (funnelStages?.length) {
        setFunnelStages(funnelStages);
      } else {
        // Create a temporary example stage to start editing
        setFunnelStagesProgress(100);
        setFunnelStages([defaultStage]);
        setIsEditingFunnel(true);
      }
    } catch (e) {
      setFunnelStagesError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
    } finally {
      setFunnelStagesLoading(false);
      clearInterval(progressID);
    }
  }, [form?.uuid, defaultStage]);

  // Zero stage progress once default stage is showing
  useEffect(() => {
    if (funnelStages?.length === 1 && !funnelStages?.[0].uuid) setFunnelStagesProgress(0);
  }, [funnelStages]);

  const loadFunnel = useCallback(async () => {
    const progressID = setInterval(() => setFunnelDataProgress((prevProgress) => prevProgress + 20), 1000);
    try {
      setFunnelDataLoading(true);
      setFunnelData(null);
      setFunnelDataError(null);

      const { data } = await api.get('/visualisations/funnels/metrics', {
        params: {
          formUuid: form?.uuid,
          time: {start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
          filters: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      const funnel = data.stages.map((uuid, stageIndex) => {
        const values = data.values[stageIndex];

        const stageData = {uuid};
        data.metrics.forEach((metric, metricIndex) => {
          stageData[metric] = values[metricIndex];
        });

        return camelcaseKeys(stageData);
      }).reduce((acc, item) => {
        if (!acc[item.uuid]) acc[item.uuid] = item;
        return acc;
      }, {});

      funnel.completions = data.overallCompletions;

      setFunnelDataProgress(100);
      setFunnelData(funnel);
    } catch (e) {
      setFunnelDataError((e.response && (e.response.status === 404) && e.response?.data?.error && e.response.data.error === 'No stages configured for the requested form') ? 'No funnel has been set up yet - please click to Build Funnel to get started.' :
        (e.response && (e.response.status === 404)) ? 'Funnel or Form not found.' : (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
    } finally {
      setFunnelDataLoading(false);
      clearInterval(progressID);
      setFunnelDataProgress(0);
    }
  },[form?.uuid, filters, time?.end, time?.start]);

  const loadTrackedFields = useCallback(async () => {
    try {
      setTrackedFieldsLoading(true);
      setTrackedFields([]);

      let { data: { fields } } = await api.get('/fields', {
        params: {
          formUuid: form.uuid,
        },
      });
      const allSelectedFields = funnelStages.map(f => f.fieldIdentifiers).flat();

      fields = fields
        .filter(field => allSelectedFields.includes(field.identifier) || (field.hasOwnProperty('hidden') ? !field.hidden : true))
        .sort(({order: orderA}, {order: orderB}) => (orderA === null || orderA === '') - (orderB === null || orderB === '') || (orderA > orderB) - (orderA < orderB));

      setTrackedFields(fields);
    } catch (e) {
      setTrackedFieldsError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
    } finally {
      setTrackedFieldsLoading(false);
    }
  },[form?.uuid, funnelStages]);

  const resetCrudStageErrors = () => {
    setCreateStageError(null);
    setUpdateStageError(null);
    setDeleteStageError(null);
    setReorderError(null);
  };

  // Once a new query has been set - proceed to fetch funnel stages or data
  useEffect(() => {
    if (prevQuery && query && (prevQuery !== query) && // The query has been set on page load or replaced via query params
      query.form?.uuid && query.time?.start && query.time?.end // Required
    ) {
      if (!funnelStages || (prevQuery?.form?.uuid && query?.form?.uuid && (prevQuery.form.uuid !== query.form.uuid))) {
        setIsEditingFunnel(false);
        setFunnelStagesProgress(20);
        setFunnelStagesLoading(true);
        loadStages();
        setTrackedFields(null);
        setFieldsLookUp(null);
        setFunnelData(null);
        setFunnelDataError(null);
        resetCrudStageErrors();
      } else {
        setFunnelDataProgress(20);
        loadFunnel();
      }
    }
  }, [prevQuery, query, funnelStages, loadStages, loadFunnel]);

  // On first load - once have funnel stages, fetch funnel data
  useEffect(() => {
    if (prevFunnelStagesLoading && !funnelStagesLoading && funnelStages?.length && funnelStages?.every(s => s.uuid)) {
      setFunnelDataProgress(50);
      loadFunnel();
      setFunnelStagesProgress(0);
    }
  }, [prevFunnelStagesLoading, funnelStagesLoading, funnelStages, loadFunnel]);

  useEffect(() => {
    if (!trackedFields && isEditingFunnel) {
      loadTrackedFields();
    }
  }, [trackedFields, loadTrackedFields, isEditingFunnel]);

  // With new tracked fields, add stage to fields look up
  useEffect(() => {
    if (prevTrackedFieldsLoading && !trackedFieldsLoading) {
      const stagesByFieldIdentifiers = funnelStages?.reduce((acc, stage) => {
        if (stage.fieldIdentifiers) {

          for (const identifier of stage.fieldIdentifiers) {
            acc[identifier] = {
              stageUuid: stage.uuid,
              // name,
            };
          }
        }
        return acc;
      }, {});
      setFieldsLookUp(trackedFields.reduce((acc, field) => {
        const { identifier } = field;
        if (!acc[identifier]) {
          acc[identifier] = {
            ...field,
            stageUuid: stagesByFieldIdentifiers?.[identifier]?.stageUuid,
          };
        }
        return acc;
      }, {}));
    }
  }, [prevTrackedFieldsLoading, trackedFieldsLoading, trackedFields, funnelStages]);

  const fetchFilters = useCallback(async ({form, time}) => {
    try {
      setFiltersLoading(true);
      setFiltersLoadingError(false);
      const { data: { attributes: filters } } = await api.get(`/data/sessions/attributes`, {
        params: {
          formUuid: form.uuid,
          time: {
            start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
        },
      });
      setAvailableFilters(formatFiltersForSelectDropDown(filters));
    } catch (e) {
      setFiltersLoadingError('Error when fetching attributes');
    } finally {
      setFiltersLoading(false);
    }
  },[]);

  // Initial load and first selections - fetch new attributes
  useEffect(() => {
    // First loaded page after login - so wait for default time to be set
    if ((!selectedForm?.uuid && !selectedTime?.start && prevForm?.uuid && form?.uuid && (prevForm.uuid === form.uuid)) && (!prevTime && time?.start)) fetchFilters({form, time});
    // Moved to page from another
    if ((!selectedForm?.uuid && !prevForm && form?.uuid) && (!selectedTime?.start && !prevTime && time?.start)) fetchFilters({form, time}); // TODO: bring this fetch up to context-level

    // Form first selected
    if (selectedForm?.uuid && (!prevSelectedForm || (prevSelectedForm.uuid !== selectedForm.uuid)) && !selectedTime) fetchFilters({form: selectedForm, time});

    // Time first selected
    if (!selectedForm && selectedTime?.start && selectedTime?.end && (!prevSelectedTime ||
     (prevSelectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== selectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      prevSelectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== selectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
    )) fetchFilters({form, time: selectedTime});
  }, [selectedForm, prevSelectedForm, selectedTime, prevSelectedTime, form, time, fetchFilters, prevForm, prevTime]);

  // When the selected form or time changes - fetch new attributes
  useEffect(() => {
    if (selectedForm?.uuid && selectedTime?.start && selectedTime?.end) fetchFilters({form: selectedForm, time: selectedTime});
  }, [selectedForm, selectedTime, fetchFilters]);

  // Update selected Form on browser back/forward
  useEffect(() => {
    if (prevForm?.uuid && form?.uuid && (prevForm.uuid !== form.uuid) && selectedForm?.uuid &&  (selectedForm.uuid !== form.uuid)) {
      setSelectedForm(form);
    }
  },[prevForm, form, selectedForm]);

  // Update selected time on browser back/forward
  useEffect(() => {
    if (prevTime?.start && prevTime?.end && time?.start && time?.end &&
      (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      (prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))) &&
      selectedTime && (selectedTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      (selectedTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')))) {

      setSelectedTime(time);
    }
  },[prevTime, time, selectedTime]);

  // Update selected filters on browser back/forward
  useEffect(() => {
    if (prevFilters && filters && (prevFilters !== filters) && selectedFilters &&  (selectedFilters !== filters)) {
      setSelectedFilters(filters);
    }
  },[prevFilters, filters, selectedFilters]);

  const handleDateTimeRangeChange = (start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'FunnelBuilder',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
    });
    setSelectedTime({start, end});
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  };

  const handleFormChange = ({value: uuid}) => {
    mixpanel.track('Selected Form', { page: 'FunnelBuilder' });
    setSelectedForm(formsGroupedByFormUuid[uuid]);
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  };

  const handleFiltersChange = useCallback((attrs) => {
    const defaultSelected = attrs?.filter(({key}) => defaultAttributes.includes(key));
    mixpanel.track('Selected Filters', { page: 'FunnelBuilder', ...(defaultSelected?.length > 0) && {filters: defaultSelected.map(f => f.value)}});
    setSelectedFilters(attrs || []);
    setCancelButtonEnabled(true);
    setApplyButtonEnabled(true);
  }, [mixpanel]);

  const cancelQuery = () => {
    setSelectedTime(time);
    setSelectedForm(form);
    setSelectedFilters(filters);
    setCancelButtonEnabled(false);
    setApplyButtonEnabled(false);
  };

  const handleApply = () => {
    history.push(compileQueryString({
      form: selectedForm || form,
      time: selectedTime || time,
      filters: selectedFilters || filters,
    }));
    setCancelButtonEnabled(false);
    setApplyButtonEnabled(false);
  };

  const createStage = async ({stage, stageIndex}) => {
    try {
      resetCrudStageErrors();
      setCreateInProgress(true);
      const { data: savedStage } = await api.post(`/forms/${form.uuid}/funnels/stages`, stage);
      setFunnelStages((prevState) => {
        const newStages = [...prevState];
        newStages[stageIndex] = savedStage;
        return newStages;
      });

      handleEditFieldsStages({stageUuid: savedStage.uuid, fieldIdentifiers: savedStage.fieldIdentifiers});
      mixpanel.track('Created Stage', { page: 'FunnelBuilder' });
    } catch (e) {
      setFunnelStages((prevState) => {
        const newStages = [...prevState];
        newStages[stageIndex].inError = true;
        return newStages;
      });

      setCreateStageError((e.response && (e.response.status === 404)) ? `${stageIndex + 1}: Stage or Form not found - please refresh.` :
        (e.response && (e.response.status === 401)) ? 'Not logged in' :  e.response?.data?.errors?.length > 0 ? e.response?.data?.errors.map(e => `#${stageIndex + 1}: ${e.message}`) :
          'Something went wrong creating a stage ' + (stageIndex + 1) + '.');
    } finally {
      setCreateInProgress(false);
    }
  };

  const updateStage = async ({stage, stageIndex}) => {
    try {
      resetCrudStageErrors();
      setSaveInProgress(true);
      const { data: savedStage } = await api.put(`/forms/${form.uuid}/funnels/stages/${stage.uuid}`, {...stage});
      setFunnelStages((prevState) => {
        const newStages = [...prevState];
        newStages[stageIndex] = savedStage;
        return newStages;
      });

      handleEditFieldsStages({stageUuid: savedStage.uuid, fieldIdentifiers: savedStage.fieldIdentifiers});
      mixpanel.track('Updated Stage', { page: 'FunnelBuilder' });
    } catch (e) {
      setFunnelStages((prevState) => {
        const newStages = [...prevState];
        newStages[stageIndex].inError = true;
        return newStages;
      });

      setUpdateStageError((e.response && (e.response.status === 404)) ? `${stageIndex + 1}: Stage or Form not found - please refresh.` :
        (e.response && (e.response.status === 401)) ? 'Not logged in' :  e.response?.data?.errors?.length > 0 ? e.response?.data?.errors.map(e => `#${stageIndex + 1}: ${e.message}`) :
          'Something went wrong updating a stage ' + (stageIndex + 1) + '.');
    } finally {
      setSaveInProgress(false);
    }
  };


  const handleSaveStage = ({stage, stageIndex}) => {
    // Prevent edit from closing
    setAttemptToCloseEdit(false);

    // Prevent save if there are errors in inputs
    if (!stage?.name?.length > 0 || !stage?.fieldIdentifiers?.length > 0) {
      setFunnelStages((prevState) => {
        const newStages = [...prevState];
        newStages[stageIndex].inError = true;
        return newStages;
      });
      return;
    }

    // Only save the stage if there have been changes
    if (JSON.stringify(stage) === JSON.stringify(funnelStages[stageIndex])) {
      setFunnelStages((prevState) => {
        const newStages = [...prevState];
        newStages[stageIndex].inError = false;
        return newStages;
      });
      return;
    };

    if (!stage.uuid) {
      createStage({stage, stageIndex});
    } else {
      updateStage({stage, stageIndex});
    }
  };

  // Only close edit if no editing errors
  useEffect(() => {
    if (attemptToCloseEdit && funnelStages?.every(s => (s.uuid && !s.inError)) && !createInProgress && !deleteInProgress && !saveInProgress && !reorderInProgress) {
      setFunnelDataLoading(true);
      setIsEditingFunnel(false);
      setAttemptToCloseEdit(false);
    }
  },[attemptToCloseEdit, funnelStages, deleteInProgress, saveInProgress, createInProgress, reorderInProgress]);

  // Returned out of edit mode, with stages - so fetch funnel data
  useEffect(() => {
    if (prevIsEditingFunnel && !isEditingFunnel && funnelStages?.every(s => !s.inError && s.uuid) && !funnelStagesLoading) {
      setFunnelDataProgress(50);
      loadFunnel();
    }
  }, [prevIsEditingFunnel, isEditingFunnel, funnelStages, loadFunnel, funnelStagesLoading]);

  const handleAddStage = () => {
    setFunnelStages((prevState) => {
      return prevState.concat([{...defaultStage, order: funnelStages[funnelStages.length -1].order + 1}]);
    });
  };

  const handleEditFieldsStages = ({stageUuid, fieldIdentifiers}) => {
    let newFields = fieldsLookUp;

    if (stageUuid && fieldIdentifiers?.length) {
      for (const [identifier, field] of Object.entries(newFields)) {
        // Update new fields to be set with stage
        if (field.stageUuid !== stageUuid && fieldIdentifiers.includes(identifier)) newFields[identifier].stageUuid = stageUuid;

        // Remove stage from other fields
        if (field.stageUuid === stageUuid && !fieldIdentifiers.includes(identifier)) delete newFields[identifier].stageUuid;
      };
    }

    if (stageUuid && !fieldIdentifiers?.length) {
      // Remove stage from all fields
      for (const [identifier, field] of Object.entries(newFields)) {
        if (field.stageUuid === stageUuid) delete newFields[identifier].stageUuid;
      };
    }
    setFieldsLookUp(newFields);
  };

  const deleteStage = async ({stage, stageIndex}) => {
    try {
      setDeleteStageError(null);
      setDeleteInProgress(true);
      await api.delete(`/forms/${form.uuid}/funnels/stages/${stage.uuid}`);

      setFunnelStages((prevState) => {
        const newStages = prevState.filter(s => s.uuid !== stage.uuid);
        return newStages.length ? newStages : [defaultStage];
      });

      handleEditFieldsStages({stageUuid: stage.uuid, fieldIdentifiers: []});
      mixpanel.track('Deleted Stage', { page: 'FunnelBuilder' });
    } catch (e) {
      setFunnelStages((prevState) => {
        const newStages = [...prevState];
        newStages[stageIndex].inError = true;
        return newStages;
      });

      setDeleteStageError((e.response && (e.response.status === 404)) ? `${stageIndex + 1}: Stage or Form not found - please refresh.`:
        (e.response && (e.response.status === 401)) ? 'Not logged in' :  e.response?.data?.errors?.length > 0 ? e.response?.data?.errors.map(e => `#${stageIndex + 1}: ${e.message}`) :
          'Something went wrong deleting a stage ' + (stageIndex + 1) + '.');
    } finally {
      setDeleteInProgress(false);
    }
  };

  const handleDeleteStage = ({stage, stageIndex}) => {
    // Prevent edit from closing
    setAttemptToCloseEdit(false);
    resetCrudStageErrors();

    if (!stage.uuid) {
      setFunnelStages((prevState) => {
        const newStages = [...prevState];
        newStages.splice(stageIndex, 1);
        return newStages.length ? newStages : [defaultStage];
      });
    } else {
      deleteStage({stage, stageIndex});
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    // Allow the reordering to happen straight away - but take a copy of the original in case of error
    const originalFunnelStages = [...funnelStages];
    let reorderedStages = [...originalFunnelStages];
    reorderedStages = arrayMove(reorderedStages, result.source.index, result.destination.index);
    setFunnelStages(reorderedStages.map((s, i) => ({...s, order: i + 1})));

    try {
      resetCrudStageErrors();
      setReorderInProgress(true);
      await api.post(`forms/${form.uuid}/funnels/stages/order`, {
        stages: reorderedStages.map((s) => s.uuid),
      });
      mixpanel.track('Reordered Stage', { page: 'FunnelBuilder' });
    } catch (e) {
      setFunnelStages(originalFunnelStages);
      setReorderError((e.response && (e.response.status === 404)) ? `${result.source.index + 1}:Stage or Form not found - please refresh.`:
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong re-ordering the stages.');
    } finally {
      setReorderInProgress(false);
    }
  };

  const formatGroupLabel = (group) => {
    const selectedInGroup = selectedFilters?.filter(attr => attr.key === group.label).length;

    return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
      <span style={{ color: '#3F4047', fontSize: 14, fontWeight: 500 }}>{group.label}</span>
      <span style={{display: 'inline-flex',alignItems: 'center'}}>
        <span style={{fontSize: 10, textAlign: 'center', paddingRight: '3px'}}>
          {selectedInGroup ? `${selectedInGroup} selected` : null}
        </span>
        <span style={{
          backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 14,
          fontWeight: "normal", lineHeight: "1", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
          {group.options.length}
        </span>
      </span>
    </div>);
  };

  const formFilterOption = ({ data: { orgLabel, orgUuid, label: formLabel, value: formUuid } }, currentSearchValue ) => (
    formLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    formUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgUuid
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase()) ||
    orgLabel
      .toLocaleLowerCase()
      .includes(currentSearchValue.toLocaleLowerCase())
  );

  return (
    <Container fluid className="funnel-builder page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Funnel Builder</title>
      </Helmet>
      <div className="nav-wrapper browser-only">
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col className="col-md-auto col-sm-12 pt-0 pe-md-1 mt-1 d-inline-flex" id="datepicker">
            <DatePicker
              startTime={(selectedTime || time)?.start}
              endTime={(selectedTime || time)?.end}
              onApply={handleDateTimeRangeChange}
              timeZone={(form && form?.organisation?.timeZone) || null}
              selectedFormTimeZone={selectedForm?.organisation?.timeZone}
            />
          </Col>
          <Col md={3} className="pt-0 px-md-1 mt-1" id="form-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5Ec" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={formatFormSelectOptions({formsGroupedByOrg, currentForm: form, selectedForm, formsLoadingError, formsLoading})}
              formatGroupLabel={formatGroupLabel}
              onChange={handleFormChange}
              placeholder="Select a form..."
              value={selectedForm?.label ? selectedForm : form?.label ? form : null}
              filterOption={formsGroupedByOrg && Object.values(formsGroupedByOrg).length && formFilterOption}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
            />
          </Col>
          <Col className="pt-0 ps-md-1 mt-1">
            <FiltersSelect
              form={form}
              filters={filters}
              selectedFilters={selectedFilters}
              filtersLoading={filtersLoading}
              availableFilters={availableFilters}
              filtersLoadingError={filtersLoadingError}
              handleFiltersChange={handleFiltersChange} />
          </Col>
        </Row>
        <Row className="g-0 nav-secondary justify-content-end">
          <Col className="pt-1 pb-2 col-auto">
            <Button variant="outline-secondary" className="cancel me-1 ms-0" disabled={!cancelButtonEnabled}
              onClick={cancelQuery} data-testid="cancel-funnel-query">Cancel</Button>
            <Button className="load ms-1 me-0" disabled={!applyButtonEnabled} onClick={handleApply}>Apply</Button>
          </Col>
        </Row>
      </div>
      <ScrollToTop />
      <div className="main-content">
        {(formsLoading || formsLoadingError || !formsGroupedByOrg || Forms.length > 0) ?
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'FunnelBuilder'}
                org={form?.organisation}
                messageContent={'Funnel Builder'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            <Row className="title-row g-0 browser-only">
              <Col className={`p-0 original-content ${showInfo ? 'background' : ''}`}>
                {orgName && form?.label &&
                  <h1 id="form-title" data-testid="page-title">
                    {`${orgName} | ${form?.label} | `}
                    <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
                  </h1>}
              </Col>
              <Col className={`p-0 text-end my-auto ms-auto col-auto original-content ${showInfo ? 'background' : ''}`}>
                {orgName && form?.label && <>
                  <CopyUrlIcon
                    queryString={compileQueryString({form, time, filters})}/>
                  <Link to={`/forms/${formUuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
                </>}
              </Col>
            </Row>
            <Col className="p-0">
              <PrintPageHeader
                pageTitle={'Funnel Builder'}
                orgName={orgName}
                formLabel={form?.label}
                formUrl={form?.url}
                startTime={time?.start}
                endTime={time?.end}
                timeZone={form?.organisation?.timeZone}
                formFilters={selectedFilters || filters}
                searchParams={form?.uuid && time?.start && time?.end && compileQueryString({form, time, filters: filters})}
              />
              <div className="flip-card">
                <Card id="funnel-vis" className={`flip-card-inner ${(showInfo === true) ? 'perform-flip' : ''}`}>
                  <Card.Body className="p-0 d-flex">
                    <div className={`flip-card-front d-flex flex-column flex-grow-1 ${showInfo === null ? '' : showInfo === true ? 'not-visble' : showInfo === false ?'visible' : ''}`}>
                      <Row className="g-0 card-title-row justify-content-between">
                        <Col className="p-0 col-auto">
                          <Card.Title as="h3">Funnel Builder</Card.Title>
                        </Col>
                        <Col className="p-0 text-end card-tooltip">
                          <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon browser-only" onClick={() => {setShowInfo(true); mixpanel.track('Clicked Funnel info', { page: 'FunnelBuilder' });}} title="How to use"/>
                        </Col>
                      </Row>
                      <div className="card-vis">
                        {(funnelStagesLoading || funnelDataLoading || (funnelStages?.every(s => s.uuid) && !isEditingFunnel && !funnelData && !funnelDataError && !funnelStagesError)) ?
                          <div className="progress-area d-flex flex-grow-1">
                            <ProgressBar className="my-auto" animated now={funnelDataProgress || funnelStagesProgress}/>
                          </div> : (funnelStagesError || funnelDataError) ? <>
                            <div className="d-flex justify-content-center flex-grow-1">
                              <p className="text-center my-auto" data-testid="funnel-stage-error">{funnelStagesError || funnelDataError}</p>
                            </div>
                            {funnelDataError && funnelDataError.includes('Build Funnel') &&
                          <Row className="g-0 justify-content-end">
                            <Col className="p-0 col-auto d-flex justify-content-end align-items-center">
                              <Button onClick={() => {setIsEditingFunnel(true); setFunnelDataError(null);}}>Build Funnel</Button>
                            </Col>
                          </Row>} </> :
                            funnelStages && <>
                              {(isEditingFunnel && !funnelData) && <>
                                <Row className="ps-2 alert-row g-0">
                                  <Alert dismissible={false} variant={'info'} className="d-flex flex-column align-items-start first-funnel-info">
                                    <h5 className="ps-2">Getting started</h5>
                                    <ul className="mb-0">
                                      <li>Each stage in the funnel represents a section of your form. For example, one section could be for 'Personal Details', then another for 'Payment'.</li>
                                      <li>Add the relevant fields to each stage.</li>
                                      <li>Rename the stages if you wish by clicking on the boxes on the funnel.</li>
                                      <li>Then, click <i>View Funnel Data</i> to see the number of sessions that interacted with each stage, as well as other key metrics.</li>
                                    </ul>
                                  </Alert>
                                </Row>
                              </>}
                              <div className="card-contents">
                                <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => setIsDragging(true)}>
                                  <div className="funnel-table-wrap">
                                    <Table borderless data-testid="funnel-builder-table" className={`funnel-builder-table ${(trackedFields?.length > 0) && (menuOpenAtIndex === funnelStages.length - 1 ? 'bottom-menu-open' : menuOpenAtIndex === funnelStages.length - 2 ? 'second-to-bottom-menu-open' : '')}`}>
                                      <thead>
                                        <tr>
                                          <th className="grip-column"></th>
                                          {isEditingFunnel && <th className="funnel-column text-center pb-0">Stage Name</th>}
                                          {!isEditingFunnel && <th className="funnel-column text-center pb-0">Sessions
                                            <OverlayTrigger placement="top" trigger={['hover', 'click']}
                                              overlay={
                                                <Popover className="metric-popover">
                                                  <Popover.Body>
                                                    The number of visitor sessions that interacted with a stage.
                                                  </Popover.Body>
                                                </Popover>}>
                                              <span><AiOutlineQuestionCircle size="12px" className="info align-top"/></span>
                                            </OverlayTrigger></th>}
                                          {isEditingFunnel && <th className="fields-column pb-0 bottom-border-cell" colSpan={3}>Fields</th>}
                                          {!isEditingFunnel && <>
                                            <th className="text-center pb-0 bottom-border-cell">Abandons
                                              <OverlayTrigger placement="top" trigger={['hover', 'click']}
                                                overlay={
                                                  <Popover className="metric-popover">
                                                    <Popover.Body>
                                                      The number of visitor sessions that abandoned during a stage.
                                                    </Popover.Body>
                                                  </Popover>}>
                                                <span><AiOutlineQuestionCircle size="12px" className="info align-top"/></span>
                                              </OverlayTrigger></th>
                                            <th className="text-center pb-0 bottom-border-cell">Progression Rate
                                              <OverlayTrigger placement="top" trigger={['hover', 'click']}
                                                overlay={
                                                  <Popover className="metric-popover">
                                                    <Popover.Body>
                                                      The proportion of visitors in a stage who also interacted with a later stage of the funnel.
                                                    </Popover.Body>
                                                  </Popover>}>
                                                <span><AiOutlineQuestionCircle size="12px" className="info align-top"/></span>
                                              </OverlayTrigger></th>
                                            <th className="text-center pb-0 bottom-border-cell">Mean Duration
                                              <OverlayTrigger placement="top" trigger={['hover', 'click']}
                                                overlay={
                                                  <Popover className="metric-popover">
                                                    <Popover.Body>
                                                      The average length of time visitors spent in a stage.
                                                    </Popover.Body>
                                                  </Popover>}>
                                                <span><AiOutlineQuestionCircle size="12px" className="info align-top"/></span>
                                              </OverlayTrigger></th>
                                          </>}
                                          <th className="actions-column bottom-border-cell"></th>
                                        </tr>
                                      </thead>
                                      <Droppable droppableId="droppable" direction="vertical">
                                        {(provided, snapshot) => (
                                          <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                            {funnelStages.length > 0 && funnelStages.map((stage, stageIndex) => {
                                              return (
                                                <Draggable key={`stage-${stage.uuid || stageIndex}`} draggableId={stage.uuid || `${stageIndex}`} index={stageIndex} isDragDisabled={!isEditingFunnel || (isEditingFunnel && (funnelStages?.length < 2 || !stage.uuid))}>
                                                  {(provided, snapshot) => {
                                                    // An unusual work around to keeping drag and drop style now using a flipcard which needs absolute positioning
                                                    if (snapshot.isDragging) {
                                                      provided.draggableProps.style.left = undefined;
                                                      provided.draggableProps.style.top = undefined;
                                                    }
                                                    return <FunnelStage
                                                      dragRef={provided.innerRef}
                                                      draggableProps={provided.draggableProps}
                                                      draggableSnapshot={snapshot}
                                                      isDragging={isDragging}
                                                      dragHandleProps={provided.dragHandleProps}
                                                      stage={stage}
                                                      stageIndex={stageIndex}
                                                      stages={funnelStages}
                                                      trackedFields={trackedFields}
                                                      trackedFieldsLoading={trackedFieldsLoading}
                                                      trackedFieldsError={trackedFieldsError}
                                                      fieldsLookUp={fieldsLookUp}
                                                      editFieldsStages={handleEditFieldsStages}
                                                      saveStage={handleSaveStage}
                                                      deleteStage={handleDeleteStage}
                                                      isEditing={isEditingFunnel}
                                                      editContainsErrors={funnelStages?.some(s => s.inError) || (attemptToCloseEdit && funnelStages?.filter(s => !s.uuid).length > 0)}
                                                      funnelData={funnelData}
                                                      setMenuOpenAtIndex={setMenuOpenAtIndex}
                                                      clipPath={stylesByFunnelLength[funnelStages.length -1].clipPath[stageIndex]}
                                                      inputWidth={stylesByFunnelLength[funnelStages.length -1].inputWidth[stageIndex]}
                                                    />;
                                                  }}
                                                </Draggable>);
                                            })}
                                            {provided.placeholder}
                                          </tbody>
                                        )}
                                      </Droppable>
                                      <tbody>
                                        <tr className="completions-row">
                                          <td className="grip-column"></td>
                                          <td className="funnel-column px-0 pb-0 pt-2 text-center">
                                            <span className="funnel-wrap">
                                              <div className="d-inline-flex flex-column funnel-end-bucket" style={{clipPath: stylesByFunnelLength[funnelStages.length - 1]?.completion}}>
                                                <div className="funnel-step-row colour-completion">
                                                  <p className="m-0 funnel-step-name">Completions</p>
                                                  <p className="m-0 funnel-step-value">{!isEditingFunnel && (funnelData?.completions?.toLocaleString() || 'N/A')}</p>
                                                </div>
                                              </div>
                                            </span>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td className="actions-column text-end" colSpan={2}>
                                            {(isEditingFunnel && funnelStages?.length < 10) && <Button variant="outline-primary" className="px-3" disabled={funnelStages?.length > 9} onClick={handleAddStage} data-testid="add-funnel-stage"><FaPlus className="me-1 plus-inside-btn" />Add Stage</Button>}
                                            {(isEditingFunnel && funnelStages?.length > 9) &&
                                        <OverlayTrigger overlay={<Tooltip id="disabled-add-stage-tooltip">Max 10 funnel stages</Tooltip>}>
                                          <span className="d-inline-block">
                                            <Button variant="outline-primary" className="px-3" style={{ pointerEvents: 'none' }} disabled={funnelStages.length > 4}><FaPlus className="me-1 plus-inside-btn" />Add Stage</Button>
                                          </span>
                                        </OverlayTrigger>}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </DragDropContext>
                                <Row className="g-0 justify-content-end">
                                  <Col className="p-0 col-auto d-flex justify-content-end align-items-center">
                                    {!isEditingFunnel && <Button onClick={() => {setIsEditingFunnel(true); setAttemptToCloseEdit(false);}}>Edit</Button>}
                                    {(isEditingFunnel) && <>
                                      {(funnelStages?.some(s => s.inError) || (attemptToCloseEdit && funnelStages?.filter(s => !s.uuid).length > 0)) &&
                                   <p className="invalid-input-feedback mb-0">All funnel stages require a name and fields.</p>}
                                      {createStageError &&
                                   <p className="invalid-input-feedback mb-0 ms-2">{createStageError}</p>}
                                      {updateStageError &&
                                   <p className="invalid-input-feedback mb-0 ms-2">{updateStageError}</p>}
                                      {reorderError &&
                                   <p className="invalid-input-feedback mb-0 ms-2">{reorderError}</p>}
                                      {deleteStageError &&
                                   <p className="invalid-input-feedback mb-0 ms-2">{deleteStageError}</p>}
                                      <Button onClick={() => setAttemptToCloseEdit(true)}>View Funnel Data</Button>
                                    </>}
                                  </Col>
                                </Row>
                              </div>
                            </>}
                      </div>
                    </div>
                    <div className={`flip-card-back ${showInfo === true ? 'visible' : showInfo === false ? 'not-visble' : ''}`}>
                      <div className="card-contents">
                        <Row className="g-0">
                          <Col className="p-0">
                            <Card.Title as="h3">Funnel Builder</Card.Title>
                          </Col>
                          <Col className="p-0 text-end card-tooltip">
                            <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowInfo(false)} title="Return to funnel"/>
                          </Col>
                        </Row>
                        <Row className="g-0 text-content">
                          <Col lg={6} className="ps-0">
                            <Card.Text className="mb-3 subtitle">Monitor each stage of your form, from start to finish.</Card.Text>
                            <Card.Text>You can group the individual fields in your form into stages, so that you can see which form steps visitors are abandoning on. This
                        tool is ideal for a multi-step form, or simply a form with loose sections that you'd like to group together. The key metrics tracked are:</Card.Text>
                            <dl>
                              <dt>Sessions</dt>
                              <dd>How many visitor sessions interacted with this stage</dd>
                              <dt>Abandons</dt>
                              <dd>How many visitor sessions abandoned during this stage</dd>
                              <dt>Progression Rate</dt>
                              <dd>The proportion of visitors in this stage who also interacted with a later stage of the funnel</dd>
                              <dt>Mean Duration</dt>
                              <dd>The average length of time visitors spent in this stage</dd>
                              <dt>Completions</dt>
                              <dd>The number of visitors in the funnel that successfully completed the form</dd>
                            </dl>

                            <Card.Text>In this example, the funnel shows that Stage 3 has the highest drop off (abandons) by volume, and that stage 4 has the highest proportion of visitors who progress to the next stage.</Card.Text>
                            <figure className="text-center img-wrapper">
                              <img id="Funnel-Chart" src={FunnelChartImg} alt="Funnel-Chart" className="card-info-img"></img>
                            </figure>
                          </Col>
                          <Col lg={6}>
                            <div className="card-tip-box">
                              <h4 className="pb-3">How to use this and what to look for</h4>
                              <Card.Text>Zuko’s Funnel Builder allows you to quickly identify the big picture trends in your form. It is useful to see which steps are interacted with the most, where visitors are abandoning and how long they are spending on each section.</Card.Text>
                              <h5>Begin by building your funnel</h5>
                              <ul>
                                <li>Add up to 5 stages for your form. This is ideal for getting a broad overview of the milestones in your form.</li>
                                <li>Add the relevant fields to each stage in your form. <span className="text-bold">We recommend adding all fields that are likely to be interacted with in each stage to ensure you get the most out of your data</span>. However,
                             if you have some unusual fields that you haven't yet hidden in <Link to={`/forms/${formUuid}/edit`}>Label Fields</Link>, then you could exclude them from your this funnel.</li>
                                <li>You can rename the stages to make them more specific to your form's sections.</li>
                                <li>As soon as you have created funnel stages and added fields you can click <i>View Funnel Data</i> to show the data and insight.</li>
                              </ul>
                              <h5>Unexpected funnel data</h5>
                              <Card.Text>This is a new way to analyse your form's sessions; the way visitor's interact might not always be as expected. Please be aware that visitors may sometimes skip stages and still complete your form, or jump around the stages and abandon back on the first stage. Or missing fields and combined buttons can affect the data too.</Card.Text>
                              <Card.Text>In particular, note that <i>Progression Rate</i> means that a visitor has interacted with a later stage. If visitors skip stages or jump back and forth, the <i>Progression Rate</i> won't translate directly to the <i>Abandons</i> figure in the same stage, nor the <i>Sessions</i> figure in the next stage.</Card.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Col> :
          <NoFormsMsg mixpanel={mixpanel} page={'FunnelBuilder'}/>
        }
      </div>
    </Container>
  );
};

export default FunnelBuilder;
