
import React, { useState, useRef } from 'react';
import { HiLightBulb } from 'react-icons/hi';
import { FaSpinner } from 'react-icons/fa';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';

import './ChartTip.scss';

const ChartTip = ({mixpanel, dataLoading, isData, children, page, vis, buttonText = 'Quick Tip'}) => {
  const [isDisplaying, setIsDisplaying] = useState();
  const outsideOverlay = useRef();

  return (
    <div ref={outsideOverlay}>
      <OverlayTrigger
        onEntered={() => {
          setIsDisplaying(true);
          mixpanel.track('Clicked Quick Tip', { page, vis });
        }}
        onExited={() => setIsDisplaying(false)}
        trigger={['click']}
        rootClose
        placement="bottom-end"
        overlay={
          <Popover className="chart-tip-tooltip">
            <Popover.Body>
              {dataLoading &&
              <div className="loading-area d-flex justify-content-center align-items-center">
                <FaSpinner size="18px" className="spinning-icon" title="Loading Fields Overview insights tip..."/>
              </div>}
              {!dataLoading && <>
                <div className="popover-content">
                  {children}
                </div>
              </>}
            </Popover.Body>
          </Popover>}>
        <Button className="quick-tip-btn d-flex align-items-center" disabled={!isData || dataLoading || !children}>
          <HiLightBulb size="26px" className={`chart-tip-icon ${(!dataLoading && isData && !isDisplaying) ? 'data-loaded' : ''}`} title={`View the ${vis} tip`}/>
          {buttonText && <span className="ms-1">{buttonText}</span>}
        </Button>
      </OverlayTrigger>
    </div>
  );
};


export default ChartTip;