import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaRegQuestionCircle } from 'react-icons/fa';

const InfoPopover = ({loading, children}) => !loading &&
  <OverlayTrigger placement="top" overlay={<Popover id="info-popover">
    <Popover.Body>
      {children}
    </Popover.Body>
  </Popover>}>
    <span><FaRegQuestionCircle size="14px" className="align-top info"/></span>
  </OverlayTrigger>;

export default InfoPopover;
