import React, { useMemo } from 'react';
import { useTable, useFlexLayout, useGlobalFilter } from 'react-table';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { formatPercentToOneDecimal } from '../../utils.js';

const SegmentSplitTable = ({
  data, headerLabels,
}) => {
  const columns = useMemo(() => headerLabels ? [
    {Header: 'Field Label', accessor: 'label'},
    {Header: headerLabels[0], width: 80, accessor: 'p1AbandonRate', Cell: ({value}) => formatPercentToOneDecimal(value)},
    {Header: headerLabels[1], width: 82, accessor: 'p2AbandonRate', Cell: ({value}) => formatPercentToOneDecimal(value)},
  ] : [], [headerLabels]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  useFlexLayout,
  useGlobalFilter,
  );

  return (
    <div className="segment-split-table">
      <Table responsive className="datatable mb-0" hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={`header-row-${column.id} d-flex justify-content-center align-items-center`}
                    {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>);
              })}
            </tr>
          ))}
        </thead>
        <tbody className="table-body" {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  const highestValue = cell.column.id === 'p1AbandonRate'? cell.value > cell.row.cells[2].value : cell.value > cell.row.cells[1].value;
                  const headerIndex = headerLabels.indexOf(cell.column.Header);
                  return (
                    <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>
                      {cell.column.id === 'label' ?
                        <OverlayTrigger placement="top" overlay={<Popover id="html-attributes-popover">
                          <Popover.Body>
                            {cell.row.original?.label &&
                          <dl className="d-flex mb-0">
                            <dt className="me-2 text-nowrap">Field Label:</dt>
                            <dd>{cell.row.original?.label}</dd>
                          </dl>}
                            {cell.row.original?.htmlTagName && cell.row.original?.htmlTagName !== 'null' &&
                          <dl className="d-flex mb-0">
                            <dt className="me-2 text-nowrap">HTML Tag Name:</dt>
                            <dd>{cell.row.original?.htmlTagName}</dd>
                          </dl>}
                            {cell.row.original?.htmlType && cell.row.originaleld?.htmlType !== 'null' &&
                          <dl className="d-flex mb-0">
                            <dt className="me-2 text-nowrap">HTML Type:</dt>
                            <dd>{cell.row.original?.htmlType}</dd>
                          </dl>}
                            {cell.row.original?.htmlName && cell.row.original?.htmlName !== 'null' &&
                          <dl className="d-flex mb-0">
                            <dt className="me-2 text-nowrap">HTML Name:</dt>
                            <dd>{cell.row.original?.htmlName}</dd>
                          </dl>}
                            {cell.row.original?.htmlId && cell.row.original?.htmlId !== 'null' &&
                          <dl className="d-flex mb-0">
                            <dt className="me-2 text-nowrap">HTML ID:</dt>
                            <dd>{cell.row.original?.htmlId}</dd>
                          </dl>}
                          </Popover.Body>
                        </Popover>}>
                          <span className="text-more-info headline-field-label">{cell.render('Cell')}</span>
                        </OverlayTrigger> :
                        <OverlayTrigger placement="top" overlay={<Popover id="html-attributes-popover">
                          <Popover.Body>
                            <h5 className="fs-5 fw-bold">{cell.column.Header}</h5>
                            <dl className="d-flex mb-0">
                              <dt className="me-2 text-nowrap">Abandon count:</dt>
                              <dd>{cell.row.original?.[`n${headerIndex + 1}AbandonCount`]?.toLocaleString()}</dd>
                            </dl>
                            <dl className="d-flex mb-0">
                              <dt className="me-2 text-nowrap">Session interaction count:</dt>
                              <dd>{cell.row.original?.[`i${headerIndex + 1}InteractionCount`]?.toLocaleString()}</dd>
                            </dl>
                          </Popover.Body>
                        </Popover>}>
                          <span className={highestValue ? 'highlight-stat' : ''}>{cell.render('Cell')}</span>
                        </OverlayTrigger>}
                    </td>
                  );})}
              </tr>
            );
          })}
          {!rows?.length > 0 && <tr colSpan={3}><td><span><small>There are no fields with significant differences for this segment. Expanding
            the date range may increase the sample size and improve the likelihood of significance.</small></span></td></tr>}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(SegmentSplitTable);
