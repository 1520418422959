import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const NoFormsMsg = ({mixpanel, page}) => {
  useEffect(() => {
    if (mixpanel && page) mixpanel.track('No Forms Msg', { page });
  }, [mixpanel, page]);

  return (
    <Col className="center-column justify-content-md-center">
      <h1>You are not currently tracking any forms.</h1>
      <div className="pb-3">
        <Link to="/forms/new"><Button className="btn-less-padding ms-0">Add Form</Button></Link>
      </div>
      <div id="intro-video">
        <div className="intro-video-wrapper">
          <p className="intro-video-copy">Find out more on how to get started with an introduction to Zuko:</p>
          <iframe title="intro-video" className="intro-video" src="https://player.vimeo.com/video/367009739" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        </div>
      </div>
    </Col>
  );
};

export default NoFormsMsg;