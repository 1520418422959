import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { FaSpinner } from 'react-icons/fa';
import { VscWarning, VscCheck } from "react-icons/vsc";
import './PublishingModal.scss';

const PublishModal = ({
  show,
  handleCloseModal,
  isUnpublishing,
  success,
  error,
}) => {
  return (
    <Modal
      centered
      show={show}
      onHide={handleCloseModal}
      backdrop="static" // To avoid closing by clicking outside
      keyboard={false} // To avoid closing by clicking esc
      id="builder-publishing-modal" className="d-flex" aria-labelledby="publish-form-modal">
      <Modal.Header closeButton={success}>
        <Modal.Title as={'h3'}>Unpublish</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center">
        {isUnpublishing && <div className="d-inline-flex justify-content-center">
          <FaSpinner size="20px" className="spinning-icon me-2"/>
          <p className="mb-0">Unpublishing is in progress...</p>
        </div>}
        {success && <>
          <Alert variant="outline-success" closeVariant="white">
            <div className="alert-svg-icon my-auto"><VscCheck size="26px"/></div>
            <p className="alert-text m-0">Your form has been unpublished.</p>
          </Alert>
          <div className="pt-3">
            <p>You can re-publish any time.</p>
          </div>
          <Button variant="outline-primary" className="builder-outline-primary-cta mx-auto my-3" onClick={() => handleCloseModal()}>Close</Button>
        </>}
        {error && <>
          <Alert variant="danger" closeVariant="white">
            <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
            <p className="alert-text m-0" dangerouslySetInnerHTML={{ __html: error }} />
          </Alert>
          <Button variant="outline-primary" className="builder-outline-primary-cta mx-auto my-3" onClick={() => handleCloseModal()}>Close</Button>
        </>}
      </Modal.Body>
    </Modal>);
};

export default PublishModal;
