import ProgressionContext, { ProgressionContextType } from '../contexts/ProgressionContext';
import FormContext, { FormContextType } from '../contexts/FormContext';
import { useContext } from 'react';
import './ProgressBar.scss';

const ProgressBar = () => {
  const { currentStepIndex, stepsLength } = useContext(ProgressionContext) as ProgressionContextType;
  const { style } = useContext(FormContext) as FormContextType;
  const { progressBar } = style || {};
  const valueNow = Math.floor((((currentStepIndex + 1) / stepsLength) * 100));

  return (<>
    <p className="progress-text">Step {currentStepIndex + 1} of {stepsLength}</p>
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${valueNow}%`, ...progressBar }}
        role="progressbar" aria-valuenow={valueNow} aria-label="progress bar">
      </div>
    </div></>);
};

export default ProgressBar;
