import { distance, closest } from 'fastest-levenshtein';
import addrs from 'email-addresses';

const commonDomains = [
  'aol.com', 'comcast.net', 'gmail.com', 'googlemail.com','gmx.com', 'gmx.de',
  'hotmail.co.uk','hotmail.com', 'libero.it', 'outlook.com',
  'rediffmail.com','telstra.com', 'wanadoo.es', 'wanadoo.fr',
  'web.de', 'yahoo.co.uk','yahoo.com', 'yandex.com', 'yandex.ru',
  'att.net', 'mac.com', 'me.com', 'sbcglobal.net','verizon.net',
  'icloud.com',
];

/** Suggests if the email matches a common email domain.
 * A match is suggested if the closest found domain has a difference of only 1 or 2 digits from the original.
 *
 * @returns {(Boolean|String)} Returns false if no match/match too distant. otherwise returns the suggested email.
 */
export const suggestEmailMatch = (email) => {
  const parsedEmail = addrs.parseOneAddress(email);
  if (!parsedEmail) return false;

  const { local, domain } = parsedEmail;

  const suggestedDomain = closest(domain, commonDomains);
  const suggestedDistance = distance(domain, suggestedDomain);

  return (suggestedDistance === 1) ? `${local}@${suggestedDomain}` : false;
};
