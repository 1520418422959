import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { GrDownload } from 'react-icons/gr';
import { FaSpinner } from 'react-icons/fa';
import DatePicker from '../Components/DatePicker';
import api from '../api';
import { saveAs } from 'file-saver';

import './Submissions.scss';

const SubmissionsExportModal = ({mixpanel, show, setShow, formUuid}) => {
  const [time, setTime] = useState();
  const [exportError, setExportError] = useState();
  const [isDownloading, setIsDownloading] = useState();
  const timeZone = 'UTC';
  moment.tz.setDefault(timeZone);

  const handleCsvDownload = async () => {
    try {
      setExportError(false);
      setIsDownloading(true);
      mixpanel?.track('Clicked to export', { page: 'Submissions', exportType: 'CSV' });
      const response = await api.get('/builder/export/submissions', {
        params: {
          form_uuid: formUuid,
          timestamp: {
            start: time?.start.clone().utc().valueOf(),
            end: time?.end.clone().utc().valueOf(),
          },
        },
      });
      saveAs(new Blob([response.data]),  `submissions_${formUuid}_${time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')}_${time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')}.csv`);
      setShow(false);
    } catch (e) {
      setExportError(true);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDateTimeRangeChange = (start, end) => {
    setTime({start, end});
  };

  useEffect(() => {
    if (show) mixpanel?.track('Open Submissions Export Modal', { page: 'Submissions' });
  }, [show, mixpanel]);


  useEffect(() => {
    setTime({
      start: moment.tz(timeZone).subtract(30, 'days').startOf('day'),
      end: moment.tz(timeZone).endOf('day'),
    });
  }, [timeZone]);

  return (
    <Modal
      size="lg"
      show={show ? true : false}
      onHide={() => {
        setShow(false);
        setExportError(false);
      }}
      id="builder-submissions-export-modal" aria-labelledby="submissions-export-modal">
      <Modal.Header closeButton>
        <Modal.Title>Export Submissions to CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Select a date range to cover all of the submissions you'd like to export.</p>
        <Row className="g-0">
          <Col className="p-0" id="datepicker">
            <DatePicker
              startTime={time?.start}
              endTime={time?.end}
              onApply={handleDateTimeRangeChange}
              timeZone={timeZone}
              timePicker={false}
              autoApply={true}
              alwaysShowCalendars={true}
              ranges={{
                'Today': [moment().startOf('day'), moment().endOf('day')],
                'Last 7 Days': [moment().subtract(7, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                'Last 30 Days': [moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').endOf('day')],
                'Last 12 Months': [moment().subtract(1, 'years').startOf('day'), moment().endOf('day')],
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {exportError && <p className="mb-0 pe-2 error-text d-inline-block">Error exporting submissions.</p>}
        <Button onClick={() => handleCsvDownload()} className="primary-btn-with-icon">
          {isDownloading ? <FaSpinner size="18px" className="spinning-icon" title="Generating CSV..."/> :
            <GrDownload className="me-1 mb-1" size="14px"/>} Download</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubmissionsExportModal;
