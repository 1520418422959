import React, { useContext } from 'react';
import Select, { StylesConfig, OptionProps, OptionsOrGroups, GroupBase, CSSObjectWithLabel } from 'react-select';
import AppContext from '../../AppContext';
import { Form, LoadingOptionType, SelectFormOptionType, FormOptionValueType } from '../../types/types.tsx';
import {
  formatFormSelectOptions,
} from '../../utils';


const FormSelect = ({selectedForm, handleFormChange, id = 'form-select', placeholder = 'Select a form...',
}) => {
  const {
    formsGroupedByOrg,
    formsLoading,
    formsLoadingError,
    query,
  } = useContext(AppContext);

  const {
    form,
  }: {
    form: Form,
  } = query || {};

  const options: OptionsOrGroups<LoadingOptionType | FormOptionValueType, GroupBase<LoadingOptionType | FormOptionValueType>> | undefined = formatFormSelectOptions(
    {formsGroupedByOrg, currentForm: form, selectedForm, formsLoadingError, formsLoading});

  type IsMulti = false;

  const selectStyles: StylesConfig<LoadingOptionType | FormOptionValueType, IsMulti> = {
    control: (styles, state) => ({...styles,
      border: '1px solid #EBEDF2',
      boxShadow: '',
    }),
    option: (styles: CSSObjectWithLabel, state: OptionProps<LoadingOptionType | FormOptionValueType, false, GroupBase<LoadingOptionType | FormOptionValueType>> & SelectFormOptionType) => {
      const form = state.selectProps.value as unknown as Form;
      return ({...styles,
        color: '#3f4047',
        backgroundColor: state.selectProps.value && (form.uuid === state.value) ? "#E2E5Ec" : undefined,
        '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : undefined}
      });
    },
    menu: (styles, state) => ({...styles,
      marginTop: '1px',
      borderRadius: '4px',
      border: '1px solid #EBEDF2',
      boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
    }),
    dropdownIndicator: (styles, state) => {
      return {
        ...styles,
        cursor: 'pointer',
        padding: '6px 8px',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
        transition: 'transform .5s ease',
      };
    },
  };

  const formatGroupLabel = (group) => {
    return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
      <span style={{ color: '#3F4047', fontSize: 14, fontWeight: 500 }}>{group.label}</span>
      <span style={{display: 'inline-flex',alignItems: 'center'}}>
        <span style={{
          backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 14,
          fontWeight: "normal", lineHeight: "1", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
          {group.options.length}
        </span>
      </span>
    </div>);
  };

  const formFilterOption = ({ data: { orgLabel, orgUuid, label: formLabel, value: formUuid } }, currentSearchValue) => (
    formLabel.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    formUuid.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    orgUuid.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    orgLabel.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase())
  );

  return (
    <Select
      id={id}
      styles={selectStyles}
      options={options || []}
      formatGroupLabel={formatGroupLabel}
      onChange={handleFormChange}
      placeholder={placeholder}
      isLoading={formsLoading}
      value={selectedForm?.label ? selectedForm : form?.label ? form : null}
      filterOption={formsGroupedByOrg && Object.values(formsGroupedByOrg).length && formFilterOption}
      isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
    />
  );
};

export default FormSelect;


