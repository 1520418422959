import { useContext } from 'react';
import SessionContext, { SessionContextType } from '../contexts/SessionContext';
import { VscTrash, VscSettingsGear, VscArrowUp, VscArrowDown } from 'react-icons/vsc';
import Button from 'react-bootstrap/Button';

function ItemToConfigureWrapper({children, stepIndex, type, questionIndex, questionsLength, noContent}: {children: string | JSX.Element | JSX.Element[],
    stepIndex?: number, type: string | null, questionIndex?: number, questionsLength?: number, noContent?: boolean}) {
  const { inEdit, identifyItem, setIdentifyItem, handleDeleteItem, handleMoveQuestion } = useContext(SessionContext) as SessionContextType;

  const questionInEdit = (identifyItem && identifyItem.type === 'question' && type === 'question' && (identifyItem.questionIndex === questionIndex));
  const paragraphTextInEdit = (identifyItem && identifyItem.type === 'paragraph-text' && type === 'paragraph-text' && (identifyItem.questionIndex === questionIndex));
  const stepTitleInEdit = (identifyItem && identifyItem.type === 'step-title' && type === 'step-title' && (identifyItem.stepIndex === stepIndex));
  const formTitleInEdit = (identifyItem && identifyItem.type === 'form-title' && type === 'form-title');
  const formDescriptionInEdit = (identifyItem && identifyItem.type === 'form-description' && type === 'form-description');
  const stepDescriptionInEdit = (identifyItem && identifyItem.type === 'step-description' && type === 'step-description') && (identifyItem.stepIndex === stepIndex);
  const completionTitleInEdit = (identifyItem && identifyItem.type === 'completion-title' && type === 'completion-title');
  const completionMessageInEdit = (identifyItem && identifyItem.type === 'completion-message' && type === 'completion-message');

  return <>
    {!inEdit && children}
    {inEdit && <div className={
      `form-in-edit ${noContent ? 'no-content' : ''} ${type} ${questionInEdit ? 'question-in-edit' : ''} ${stepTitleInEdit ? 'step-title-in-edit' : ''} ${formTitleInEdit ? 'form-title-in-edit' : ''}
      ${formDescriptionInEdit ? 'form-description-in-edit' : ''} ${stepDescriptionInEdit ? 'step-description-in-edit' : ''} ${paragraphTextInEdit ? 'paragraph-text-in-edit' : ''}
      ${completionTitleInEdit ? 'completion-title-in-edit' : ''} ${completionMessageInEdit ? 'completion-message-in-edit' : ''}`
    } onClick={(e) => {
      // Only accept the click if it's not on one of the action buttons
      const target = e.target as HTMLElement | SVGElement;
      const closestButtonClassList = target.closest('button')?.classList;
      const buttonClassesToIgnore = ['delete-icon-container', 'delete-icon', 'up-icon-container', 'up-icon', 'down-icon-container', 'down-icon',
        'configure-icon-container', 'configure-icon'];
      if (closestButtonClassList && buttonClassesToIgnore.some(c => closestButtonClassList.contains(c))) return;

      setIdentifyItem({stepIndex, type, questionIndex});
    }}>
      {(type === 'question' || type === 'paragraph-text') &&
        <div className="up-and-down-icons d-flex flex-column justify-content-center h-100">
          {questionIndex !== undefined && questionIndex > 0 &&
            <Button className="up-icon-container mx-0 p-0" onClick={() => {
              handleMoveQuestion({stepIndex, type, questionIndex, newQuestionIndex: questionIndex - 1});
            }}>
              <VscArrowUp className="up-icon" size="20px"/>
            </Button>}
          {questionIndex !== undefined && questionsLength !== undefined  && questionIndex !== (questionsLength - 1) &&
            <Button className="down-icon-container mx-0 p-0" onClick={() => {
              handleMoveQuestion({stepIndex, type, questionIndex, newQuestionIndex: questionIndex + 1});
            }}>
              <VscArrowDown className="down-icon" size="20px"/>
            </Button>}
        </div>}
      {children}
      {type && !noContent && <Button className="configure-icon-container mx-0 p-0" onClick={() => {
        setIdentifyItem({stepIndex, type, questionIndex, showConfigure: true});
      }}>
        <VscSettingsGear className="configure-icon" size="20px"/>
      </Button>}
      {type && !noContent && <Button className="delete-icon-container mx-0 p-0" onClick={() => {
        handleDeleteItem({stepIndex, type, questionIndex});
      }}>
        <VscTrash className="delete-icon" size="20px"/>
      </Button>}
    </div>}
  </>;
}

export default ItemToConfigureWrapper;
