import Select from 'react-select';

const FontSelect = ({id, selected, handleOnChange}) => {
  const fonts = [
    "'Arial', sans-serif",
    "'Andalé Mono', monospace",
    "'Baskerville', serif",
    "'Bradley Hand', cursive",
    "'Comic Sans MS', cursive",
    "'Courier', monospace",
    "'Georgia', serif",
    "'Gill Sans', sans-serif",
    "'Helvetica', sans-serif",
    "'Impact', sans-serif",
    "'Lucida', monospace",
    "'Luminari', fantasy",
    "'Monaco', monospace",
    "'Palatino', serif",
    "'Tahoma', sans-serif",
    "'Times New Roman', serif",
    "'Trebuchet MS', sans-serif",
    "'Verdana', sans-serif",
  ];

  return <Select
    id={id}
    styles={{
      control: (styles, state) => ({...styles,
        paddingTop: '2px',
        paddingBottom: '2px',
        border: '1px solid #EBEDF2',
      }),
      valueContainer: (styles, state) => ({...styles,
        paddingTop: '0px',
        paddingBottom: '0px',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
          WebkitAppearance: 'none',
          width: '5px',
          height: '1px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '5px',
          backgroundColor: 'rgba(0, 0, 0, .5)',
        },
      }),
      singleValue: (styles, state) => ({...styles,
        fontFamily: state.data.value,
      }),
      dropdownIndicator: (styles, state) => ({...styles,
        padding: '6px 8px',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        transition: 'transform .5s ease',
      }),
      clearIndicator: (styles, state) => ({...styles,
        padding: '6px 8px',
        '&:hover': {color: '#DE350B'}
      }),
      option: (styles, state) => ({...styles,
        color: '#3f4047',
        fontFamily: state.data.value,
        backgroundColor: state.selectProps.value && (state.selectProps.value.value === state.value) ? "#E2E5Ec" : null,
        '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
      }),
      menu: (styles, state) => ({...styles,
        marginTop: '1px',
        borderRadius: '4px',
        border: '1px solid #EBEDF2',
        boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
      }),
      menuList: (styles, state) => ({...styles,
        maxHeight: '200px',
      }),
    }}
    options={fonts.map(font => ({value: font, label: font.split(",")[0].replace(/'/g, '')})) || []}
    onChange={(newOption) => handleOnChange(newOption.value)}
    value={selected ? { value: selected, label: selected?.split(",")[0].replace(/'/g, '') } : null}
  />;
};

export default FontSelect;
