import { useContext, useState, useEffect, useCallback } from 'react';
import { usePrevious } from '../hooks';
import AppContext from '../AppContext';
import {
  formatFiltersForSelectDropDown,
} from '../utils';
import api from '../api';

import { Time, Form, Filter, FilterForSelect, FiltersForSelect } from '../types/types.ts';

const availableSessionOutcomeFilters = [{
  label: 'Session Outcome',
  options: [{
    key: 'sessionOutcome',
    value: 'abandoned',
    label: 'Abandoned',
  }, {
    key: 'sessionOutcome',
    value: 'completed',
    label: 'Completed',
  }]
}];

const useFetchFilters = ({includeSessionOutcome = false}: {includeSessionOutcome?: boolean}) => {
  const {
    query,
  } = useContext(AppContext);

  const [availableFilters, setAvailableFilters] = useState<FiltersForSelect[] | undefined>();
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersError, setFiltersError] = useState<string | null>(null);

  const {
    time,
    form,
  }: {
    time:Time,
    form: Form,
    filters: FilterForSelect[],
    sessionOutcomes: Filter[],
  } = query || {};

  // Previous versions of applied query components
  const prevForm = usePrevious(form);
  const prevTime = usePrevious(time);

  const fetchFilters = useCallback(async ({form, time}) => {
    try {
      setFiltersLoading(true);
      setFiltersError(null);
      const { data: { attributes: filters } } = await api.get(`/data/sessions/attributes`, {
        params: {
          formUuid: form.uuid,
          time: {
            start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
        },
      });
      setAvailableFilters((includeSessionOutcome ? availableSessionOutcomeFilters : []).concat(formatFiltersForSelectDropDown(filters)));
    } catch (e) {
      setFiltersError('Error when fetching attributes');
    } finally {
      setFiltersLoading(false);
    }
  },[includeSessionOutcome]);

  // Initial load and first selections - fetch new attributes
  useEffect(() => {
    // First loaded page after login - so wait for default time to be set
    if ((prevForm?.uuid && form?.uuid && (prevForm.uuid === form.uuid)) && (!prevTime && time?.start)) {
      fetchFilters({form, time});
    }

    // Moved to page from another
    if ((!prevForm && form?.uuid) && (!prevTime && time?.start)) {
      fetchFilters({form, time});
    }

    // Form changed
    if (form?.uuid && prevForm?.uuid && (prevForm.uuid !== form.uuid)) {
      fetchFilters({form, time});
    }

    // Time changed
    if (time?.start && time?.end && prevTime?.start && prevTime?.end &&
     (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
     prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
    ) {
      fetchFilters({form, time});
    }
  }, [form, time, fetchFilters, prevForm, prevTime]);

  return {filtersLoading, filtersError, availableFilters};
};

export default useFetchFilters;
