import { Link } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { AiOutlineVideoCamera } from 'react-icons/ai';
import { compileQueryString } from '../utils';

const ReplayLinkGridItem = ({
  loading,
  tooltipText,
  setShowSessionReplayCTAModal,
  replayEnabled,
  queryStringObj,
}) => (
  <div className="grid-item">
    <OverlayTrigger
      overlay={
        <Tooltip id="page-link-tooltip" className="fs-6">{tooltipText}</Tooltip>}>
      {replayEnabled ?
        <Link to={`/session-replay${queryStringObj ? compileQueryString(queryStringObj) : ''}`}
          className={`${(loading) ? 'pe-none' : ''} video-icon-wrap`}>
          <AiOutlineVideoCamera size="20px" className={`grey-icon ${loading ? 'skeleton-color' : ''}`}/></Link> :
        <span onClick={() => setShowSessionReplayCTAModal(true)} className={`video-icon-wrap ${(loading) ? 'pe-none' : ''}`}>
          <AiOutlineVideoCamera size="20px" className={`grey-icon page-link-icon-disabled ${loading ? 'skeleton-color' : ''}`}/>
        </span>}
    </OverlayTrigger>
  </div>
);

export default ReplayLinkGridItem;
