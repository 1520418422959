import DOMPurify from 'dompurify';
import { config } from './form-components/helpers/sanitizer';

export const decodeAndSantize = (text) => DOMPurify.sanitize(decodeURI(text || ''), config);

export const hexToCssHsl = ({hex, lightnessChange}) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var r = parseInt(result[1], 16);
  var g = parseInt(result[2], 16);
  var b = parseInt(result[3], 16);
  var cssString = '';
  r = r / 255;
  g = g / 255;
  b = b / 255;
  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h, s, l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch(max) {
    case r: h = (g - b) / d + (g < b ? 6 : 0); break;
    case g: h = (b - r) / d + 2; break;
    case b: h = (r - g) / d + 4; break;
    default:
      return;
    }
    h = h / 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  // Adjust lightness if provided
  if (Math.sign(lightnessChange) !== 0) {
    l = l + lightnessChange;
  }

  cssString = h + ',' + s + '%,' + l + '%';
  cssString = 'hsl(' + cssString + ')';

  return cssString;
};