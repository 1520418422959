import DOMPurify from 'dompurify';
import { decodeAndSantize } from '../../../utils';
import { useContext, useRef, useState, FormEvent } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { config } from '../../helpers/sanitizer';
import EditInlineIcon from '../EditInlineIcon';
import { useShowEditIcon } from '../../hooks';
import { optionalTag } from '../Question';

const LabelWithLink = ({ id, label, required, type, stepIndex, questionIndex }: {
  id: string, label: string, type: string, required: boolean
  stepIndex?: number,
  questionIndex?: number }) => {
  const { handleSaveInlineEditItem, inEdit, handleRemoveMessages, setIdentifyItem } = useContext(SessionContext) as SessionContextType;
  const [showEditIcon, setShowEditIcon] = useShowEditIcon({type, stepIndex, questionIndex});
  const [inError, setInError] = useState<boolean | null>();
  const labelRef = useRef<HTMLLabelElement>(null);

  // TODO: add a chart count limit messge - similar to that shown in Configure when surpass the limit
  const maxChars = 2000;
  const santizedInsert = decodeAndSantize(label);

  return inEdit ? <div className="d-inline-flex align-items-center">
    <span className="mark d-inline-flex align-items-center">
      <label className={`editable-inline ${inError ? 'in-error': ''}`}
        ref={labelRef}
        dangerouslySetInnerHTML={{ __html: santizedInsert }}
        contentEditable={true}
        suppressContentEditableWarning={true}
        spellCheck="false"
        onClick={() => setShowEditIcon(false)}
        onInput={(e: FormEvent) => {
          const target = e.target as HTMLElement;
          const innerText = target.innerText;
          if (labelRef.current && (innerText?.length === 0 || innerText === '\n')) {
            labelRef.current.innerHTML = '';
            labelRef.current.focus();
            setInError(true);
          } else {
            setInError(false);
          }
        }}
        onBlur={(e) => {
          const santizedUpdate = DOMPurify.sanitize(e.target.innerHTML, config);
          if (santizedUpdate?.length === 0) {
            labelRef?.current?.focus();
            setInError(true);
          } else {
            setInError(false);
          }

          handleRemoveMessages();
          const textValidLength = santizedUpdate.slice(0, maxChars);
          e.target.innerHTML = textValidLength;
          if (santizedUpdate && label !== santizedUpdate) handleSaveInlineEditItem({type, item: encodeURI(textValidLength), stepIndex, questionIndex});

          // If the settings cog is clicked as soon as typing in this field has finished, this makes sure the click is processed to open settings. Otherwise it is just ignored by the wrapper.
          const clickTarget = e.relatedTarget as HTMLElement;
          if (clickTarget && [...clickTarget.classList].includes('configure-icon-container')) setIdentifyItem({stepIndex, type: 'question', questionIndex, showConfigure: true});
        }}
      />
      {showEditIcon && <EditInlineIcon setShowEditIcon={setShowEditIcon} itemRef={labelRef} />}</span>
    <p className={`optional-tag-container ${type === 'question-sub-label-address-line-2' ? 'address-2-tag' : ''}`}>{!required && optionalTag}</p>
  </div> :
    <><label htmlFor={id} dangerouslySetInnerHTML={{ __html: santizedInsert}} />
      <p className={`optional-tag-container`}>{!required && optionalTag}</p></>;
};

export default LabelWithLink;
