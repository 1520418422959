import React, { useState } from 'react';
import Select, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { formatGroupLabel, loadingOptions } from './utils';

/**
 * @typedef Props
 * @prop {Object} form
 * @prop {Object} filters
 * @prop {Object} [selectedFilters]
 * @prop {boolean} filtersLoading
 * @prop {Array | undefined} availableFilters
 * @prop {String | null} filtersLoadingError
 * @prop {Function} handleFiltersChange
 */

/**
 * @param {Props} props
 */
const FiltersSelect = ({form, filters, selectedFilters, filtersLoading, availableFilters, filtersLoadingError, handleFiltersChange,
  filtersInError,
  id = 'filters-select',
  multiValueLabelFontSize,
  menuWidth,
  valueContainerMaxHeight = '75px',
  placeholder = 'Select a filter...',
  isClearable = true,
}) => {
  const [input, setInput] = useState('');

  return (
    <Select
      id={id}
      styles={{
        multiValue: (provided, state) => ({...provided,
          background:'#ebedf2',
        }),
        multiValueLabel: (provided, state) => ({...provided,
          color: '#575962',
          fontSize: multiValueLabelFontSize || '1rem',
        }),
        control: (styles, state) => ({...styles,
          border: filtersInError ?  '1px solid #dc3545' : '1px solid #EBEDF2',
          boxShadow: filtersInError ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)' : null,
          paddingTop: '2px',
          paddingBottom: '2px',
        }),
        valueContainer: (styles, state) => ({...styles,
          maxHeight: valueContainerMaxHeight,
          overflow: 'scroll',
          paddingTop: '0px',
          paddingBottom: '0px',
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: '5px',
            height: '1px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '5px',
            backgroundColor: 'rgba(0, 0, 0, .5)',
          },
        }),
        dropdownIndicator: (styles, state) => ({...styles,
          padding: '6px 8px',
          cursor: 'pointer',
          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
          transition: 'transform .5s ease',
        }),
        clearIndicator: (styles, state) => ({...styles,
          padding: '6px 8px',
          '&:hover': {color: '#DE350B'}
        }),
        option: (styles, state) => ({...styles,
          color: state.isDisabled ? '#C3C3C3' : '#3F4047',
          backgroundColor: null,
          '&:hover': {backgroundColor: state.isFocused && '#F4F5F8'}
        }),
        menu: (styles, state) => ({...styles,
          marginTop: '1px',
          borderRadius: '4px',
          border: '1px solid #EBEDF2',
          boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
          width: menuWidth || null,
          minWidth: '100%',
          right: 0,
          left: 0,
        }),
      }}
      isClearable={isClearable}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isLoading={filtersLoading}
      options={filtersLoading ? loadingOptions : filtersLoadingError ? [] : availableFilters?.length > 0 ? availableFilters : []}
      components={{
        Option: (props => <components.Option {...props} innerProps={{...props.innerProps}}>
          {(props.data.hasOwnProperty('selectable') && !props.data.selectable) ? props.data.label : // No checkbox for the loading option
            <Form.Check
              type="checkbox"
              label={props.data.label}
              checked={props.isSelected}
              onChange={() => null}
            />}
        </components.Option>),
        MultiValueLabel: (props => <components.MultiValueLabel {...Object.assign( {}, props,
          { children:`${props.data.key}: ${props.data.label}` })} />),
        ClearIndicator: (props => <components.ClearIndicator {...props} innerProps={{...props.innerProps}}>
          <div ref={props.innerProps.ref} style={{ marginTop: '-2px'}}>
            <Button variant="outline-secondary" className="cancel-filters m-0 py-0 px-1" onClick={() => null}>Clear all</Button>
          </div>
        </components.ClearIndicator>),
      }}
      inputValue={input}
      onInputChange={(value, {action}) => {
        if (action === "input-change") setInput(value);
        if (action === "menu-close" || action === 'set-value') setInput('');
      }}
      onChange={handleFiltersChange}
      isDisabled={!form?.uuid}
      placeholder={placeholder}
      value={selectedFilters || filters}
      isMulti={true}
      formatGroupLabel={formatGroupLabel}
      isOptionDisabled={(option) => {
        if (option.hasOwnProperty('selectable')) return !option.selectable;
        return false;
      }}
    />
  );
};

export default FiltersSelect;


